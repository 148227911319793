import { REQUEST_POVRSINE } from "../../const";

export default function PovrsinaReducer(state = [], action) {
  switch (action.type) {
    case REQUEST_POVRSINE:
      return action.povrsine;
    default:
      return state;
  }
}
