import React from 'react'
import { Link } from 'react-router-dom'
import Form, {
  ButtonItem,
  GroupItem,
  SimpleItem,
  EmailRule,
  RequiredRule,
} from 'devextreme-react/form'
import Cookies from 'js-cookie'

import CookieConsent from 'react-cookie-consent'
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box'
import 'devextreme-react/autocomplete'
import eye from '../../../Images/eye.png'
import white_eye from '../../../Images/white_eye.png'
import { Popup, Validator } from 'devextreme-react'
import axios from 'axios'
import { tokenization } from './postLogin'
import Loading from '../Loading/Loading'
import { connect } from 'react-redux'
import gat_logo from '../../../Images/gat.png'
import LoginBackground from '../../../Images/LoginBackground.jpg'
import Admin from '../../../Pages/Admin/Admin'
import KorisnikPanelPage from '../../../Pages/Korisnik/KorisnikPanelPage'
import ServerErrorPage from '../../../Pages/DodatneStranice/ServerErrorPage'

import '../../../Styles/loginStyle.css'
const { clearInterval, setInterval } = window
const log = 'https://api.gat-crm.com:1121/login'

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.checkBoxOptions = {
      text:
        this.props.prevod &&
        this.props.prevod.length !== 0 &&
        this.props.prevod[
          this.props.oz_j.indexOf(localStorage.getItem('lng')) *
            (this.props.prevod.length / this.props.oz_j.length) +
            139
        ]
          ? this.props.prevod[
              this.props.oz_j.indexOf(localStorage.getItem('lng')) *
                (this.props.prevod.length / this.props.oz_j.length) +
                139
            ].item2
          : 'Prikaži lozinku',
      value: false,
    }

    this.passwordOptions = {
      mode: 'password',
    }

    this.passwordButton = {
      icon: this.props.darkMode ? eye : white_eye,
      stylingMode: 'text',

      type: 'default',
      onClick: () => {
        this.setState({
          passwordMode:
            this.state.passwordMode === 'text' ? 'password' : 'text',
        })
      },
    }
    this.validatorAttributes = {
      id: 'login-validator',
    }
    this.state = {
      passwordMode: 'password',
      pwd: '',
      message: '',
      loading: false,
      lng: 'ba',
      modal: false,
    }

    this.mounted = false

    this.handleSubmit = this.handleSubmit.bind(this)
    this.onEmailChanged = this.onEmailChanged.bind(this)
    this.onPWDChanged = this.onPWDChanged.bind(this)
    this.makeTimer = setInterval(() => {
      if (this.mounted)
        this.setState({
          lng:
            document.cookie.split(';').length > 0
              ? document.cookie
                  .split(';')
                  .filter((c) => c.includes('lng'))[0]
                  .split('=')[1]
                  .toString()
              : this.state.lng,
        })
    })
  }
  onEmailChanged(e) {
    this.setState({
      email: e.value,
    })
    // // // // console.log(this.props);
  }
  onPWDChanged(e) {
    this.setState({
      pwd: e.value,
    })
    //// // // console.log(this.props.prevod);
  }

  login = (email, pwd) => {
    this.setState({ loading: true })
    axios
      .post(log, {
        email: email,
        pwd: pwd,
      })
      .then((resp) => {
        // // // // console.log(resp);

        //  console.log(resp.data.item2.firstLogin)
        tokenization(
          resp.data.item1.result,
          resp.data.item2.id,
          resp.data.item2.isAdmin,
          resp.data.item2.siid
        )

        localStorage.setItem('n_k', resp.data.item2.firstLogin)
        this.setState({ loading: false })
      })
      .catch((err) => {
        this.setState({ loading: false })
        const prevod = this.props.prevod
        const oz_j = this.props.oz_j
        if (err.toString().startsWith('TypeError')) {
          this.setState({
            message:
              prevod &&
              prevod.length !== 0 &&
              prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  140
              ]
                ? prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      140
                  ].item2
                : 'Pogrešno unesena lozinka!',
          })
        } else if (err.toString().substr(err.toString().length - 3) === '404')
          this.setState({
            message:
              prevod &&
              prevod.length !== 0 &&
              prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  141
              ]
                ? prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      141
                  ].item2
                : 'Ne postoji korisnički račun s datom e-mail adresom!',
          })
        else if (err.toString().substr(err.toString().length - 3) === '400')
          this.setState({
            message:
              prevod &&
              prevod.length !== 0 &&
              prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  142
              ]
                ? prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      142
                  ].item2
                : 'Još uvijek se čeka odobrenje na registraciju!',
          })
        // // // // console.log(err.toString());
      })
  }

  componentDidMount() {
    this.mounted = false

    this.makeTimer = setInterval(() => {
      if (this.mounted)
        this.setState({
          lng: Cookies.get('lng') ?? this.state.lng,
        })
    }, 1000)
  }

  componentWillMount() {
    this.mounted = true
  }
  componentWillUnmount() {
    this.mounted = false
    clearInterval(this.makeTimer)
  }

  render() {
    let { email, pwd } = this.state
    let { prevod, oz_j } = this.props
    const { customer } = this.state
    if (this.state.loading) return <Loading />
    if (localStorage.getItem('a')) return <Admin />
    if (localStorage.getItem('nmx')) return <KorisnikPanelPage />
    if (this.props.jezik.length == 0) return <ServerErrorPage />
    else
      return (
        <div>
          {this.state.modal ? (
            <div style={{ position: 'absolute', zIndex: '10000' }}>
              <Popup
                shadingColor="rgba(0, 0, 0, 0.5)"
                container=".dx-viewport"
                visible={this.state.modal}
                onHiding={this.setState({ modal: false })}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showTitle={true}
                title={'Upute za upit'}
                style={{ width: '80vw', height: '80vh' }}
              >
                asa
              </Popup>
            </div>
          ) : (
            ''
          )}
          <div id="login">
            <CookieConsent
              style={{
                background: '#07377c',
                zIndex: '100000000',
                position: 'absolute',
                bottom: '0',
              }}
              buttonText={
                prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(Cookies.get('lng')) *
                    (prevod.length / oz_j.length) +
                    256
                ]
                  ? prevod[
                      oz_j.indexOf(Cookies.get('lng')) *
                        (prevod.length / oz_j.length) +
                        256
                    ].item2
                  : 'Razumijem'
              }
              buttonStyle={{ background: 'white', borderRadius: '5px' }}
            >
              {prevod &&
              prevod.length !== 0 &&
              prevod[
                oz_j.indexOf(Cookies.get('lng')) *
                  (prevod.length / oz_j.length) +
                  257
              ]
                ? prevod[
                    oz_j.indexOf(Cookies.get('lng')) *
                      (prevod.length / oz_j.length) +
                      257
                  ].item2
                : 'Ova stranica koristi cookies'}
            </CookieConsent>{' '}
            <div>
              <div>
                <div id="login-image-title-1">
                  <div id="login-image-title-2">
                    <h1>
                      {prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(Cookies.get('lng')) *
                          (prevod.length / oz_j.length) +
                          134
                      ]
                        ? prevod[
                            oz_j.indexOf(Cookies.get('lng')) *
                              (prevod.length / oz_j.length) +
                              134
                          ].item2
                        : 'PROIZVODE PRATIMO OD IDEJE DO UPOTREBE.'}
                    </h1>
                    <h2>
                      {' '}
                      {prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(Cookies.get('lng')) *
                          (prevod.length / oz_j.length) +
                          135
                      ]
                        ? prevod[
                            oz_j.indexOf(Cookies.get('lng')) *
                              (prevod.length / oz_j.length) +
                              135
                          ].item2
                        : 'Uvijek usmjereni ka napretku!'}
                    </h2>
                  </div>
                </div>
                <div id="login-page">
                  <div id="login-image" style={{ height: '100%' }}>
                    <div id="login-image-absolute">
                      <img
                        src={LoginBackground}
                        width="100%"
                        height="100%"
                        alt="login_image"
                      />
                    </div>
                  </div>
                  <div id="login-form">
                    <React.Fragment>
                      <div id="mobile-screen-layout-img-login"></div>
                      <div id="login-form-logo">
                        <img src={gat_logo} alt="logo" />
                      </div>
                      <form action="your-action" onSubmit={this.handleSubmit}>
                        <Form
                          formData={customer}
                          readOnly={false}
                          showColonAfterLabel={true}
                          showValidationSummary={true}
                          validationGroup="customerData"
                        >
                          <GroupItem>
                            <SimpleItem className="login-form-capt-simple-item">
                              <div className="dx-field">
                                <div className="dx-field caption-login-form">
                                  <h1>
                                    {' '}
                                    {prevod &&
                                    prevod.length !== 0 &&
                                    prevod[
                                      oz_j.indexOf(Cookies.get('lng')) *
                                        (prevod.length / oz_j.length)
                                    ]
                                      ? prevod[
                                          oz_j.indexOf(Cookies.get('lng')) *
                                            (prevod.length / oz_j.length)
                                        ].item2
                                      : 'Dobro došli na WEB kalkulator usluge eloksiranja'}
                                  </h1>
                                  <h2 className="dx-field grey-text-login">
                                    {prevod &&
                                    prevod.length !== 0 &&
                                    prevod[
                                      oz_j.indexOf(Cookies.get('lng')) *
                                        (prevod.length / oz_j.length) +
                                        136
                                    ]
                                      ? prevod[
                                          oz_j.indexOf(Cookies.get('lng')) *
                                            (prevod.length / oz_j.length) +
                                            136
                                        ].item2
                                      : 'Prijavite se na svoj korisnički račun'}
                                  </h2>
                                </div>
                              </div>
                            </SimpleItem>

                            <SimpleItem id="login-form-email">
                              <div className="dx-field">
                                <div className="dx-field-label">Email</div>{' '}
                                <br />
                                <div className="dx-field-value">
                                  <TextBox
                                    className="login-textbox"
                                    placeholder="Email..."
                                    onValueChanged={this.onEmailChanged}
                                    value={email}
                                  >
                                    <Validator
                                      elementAttr={this.validatorAttributes}
                                    >
                                      <RequiredRule
                                        message={
                                          prevod &&
                                          prevod.length !== 0 &&
                                          prevod[
                                            oz_j.indexOf(
                                              localStorage.getItem('lng')
                                            ) *
                                              (prevod.length / oz_j.length) +
                                              4
                                          ]
                                            ? prevod[
                                                oz_j.indexOf(
                                                  localStorage.getItem('lng')
                                                ) *
                                                  (prevod.length /
                                                    oz_j.length) +
                                                  4
                                              ].item2
                                            : 'Email je obavezan'
                                        }
                                      />
                                      <EmailRule
                                        message={
                                          prevod &&
                                          prevod.length !== 0 &&
                                          prevod[
                                            oz_j.indexOf(
                                              localStorage.getItem('lng')
                                            ) *
                                              (prevod.length / oz_j.length) +
                                              5
                                          ]
                                            ? prevod[
                                                oz_j.indexOf(
                                                  localStorage.getItem('lng')
                                                ) *
                                                  (prevod.length /
                                                    oz_j.length) +
                                                  5
                                              ].item2
                                            : 'Email je neispravan'
                                        }
                                      />
                                    </Validator>
                                  </TextBox>
                                </div>
                              </div>
                            </SimpleItem>
                            <SimpleItem>
                              <div className="dx-field">
                                <div className="dx-field-label">
                                  {prevod &&
                                  prevod.length !== 0 &&
                                  prevod[
                                    oz_j.indexOf(Cookies.get('lng')) *
                                      (prevod.length / oz_j.length) +
                                      6
                                  ]
                                    ? prevod[
                                        oz_j.indexOf(Cookies.get('lng')) *
                                          (prevod.length / oz_j.length) +
                                          6
                                      ].item2
                                    : 'Lozinka'}
                                </div>
                                <br />
                                <div className="dx-field-value">
                                  <div id="eye-icon-btn">
                                    <TextBox
                                      className="login-textbox"
                                      placeholder={
                                        prevod &&
                                        prevod.length !== 0 &&
                                        prevod[
                                          oz_j.indexOf(Cookies.get('lng')) *
                                            (prevod.length / oz_j.length) +
                                            6
                                        ]
                                          ? prevod[
                                              oz_j.indexOf(Cookies.get('lng')) *
                                                (prevod.length / oz_j.length) +
                                                6
                                            ].item2
                                          : 'Lozinka'
                                      }
                                      stylingMode="filled"
                                      onValueChanged={this.onPWDChanged}
                                      value={pwd}
                                      mode={this.state.passwordMode}
                                    >
                                      <TextBoxButton
                                        name="password"
                                        location="after"
                                        options={this.passwordButton}
                                      />
                                    </TextBox>
                                  </div>
                                </div>
                              </div>
                            </SimpleItem>
                          </GroupItem>

                          <ButtonItem
                            horizontalAlignment="left"
                            buttonOptions={{
                              text:
                                prevod &&
                                prevod.length !== 0 &&
                                prevod[
                                  oz_j.indexOf(Cookies.get('lng')) *
                                    (prevod.length / oz_j.length) +
                                    2
                                ]
                                  ? prevod[
                                      oz_j.indexOf(Cookies.get('lng')) *
                                        (prevod.length / oz_j.length) +
                                        2
                                    ].item2
                                  : 'Prijava',
                              type: 'default',
                              useSubmitBehavior: true,
                              elementAttr: { id: 'login-form-btn-submit' },
                            }}
                          />
                        </Form>
                      </form>
                      <br /> <br />
                      <p style={{ marginLeft: '15px', fontSize: '14px' }}>
                        {prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(Cookies.get('lng')) *
                            (prevod.length / oz_j.length) +
                            137
                        ]
                          ? prevod[
                              oz_j.indexOf(Cookies.get('lng')) *
                                (prevod.length / oz_j.length) +
                                137
                            ].item2
                          : 'Nemate kreiran račun?'}
                        <Link to="/register">
                          {prevod &&
                          prevod.length !== 0 &&
                          prevod[
                            oz_j.indexOf(Cookies.get('lng')) *
                              (prevod.length / oz_j.length) +
                              138
                          ]
                            ? prevod[
                                oz_j.indexOf(Cookies.get('lng')) *
                                  (prevod.length / oz_j.length) +
                                  138
                              ].item2
                            : 'Registrujte se!'}
                        </Link>
                      </p>{' '}
                      <p style={{ marginLeft: '15px', fontSize: '14px' }}>
                        {prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(Cookies.get('lng')) *
                            (prevod.length / oz_j.length) +
                            223
                        ]
                          ? prevod[
                              oz_j.indexOf(Cookies.get('lng')) *
                                (prevod.length / oz_j.length) +
                                223
                            ].item2
                          : 'Zaboravili ste lozinku?'}
                        <Link to="/resetPwd">
                          {prevod &&
                          prevod.length !== 0 &&
                          prevod[
                            oz_j.indexOf(Cookies.get('lng')) *
                              (prevod.length / oz_j.length) +
                              185
                          ]
                            ? prevod[
                                oz_j.indexOf(Cookies.get('lng')) *
                                  (prevod.length / oz_j.length) +
                                  185
                              ].item2
                            : 'Izmjena lozinke'}
                        </Link>
                      </p>
                      <h3 style={{ marginLeft: '15px' }}>
                        {this.state.message}
                      </h3>{' '}
                      <i style={{ textAlign: 'justify' }}>
                        {prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(Cookies.get('lng')) *
                            (prevod.length / oz_j.length) +
                            255
                        ]
                          ? prevod[
                              oz_j.indexOf(Cookies.get('lng')) *
                                (prevod.length / oz_j.length) +
                                255
                            ].item2
                          : 'Ova aplikacija je kreirana i održava se uz finansijsku podršku Evropske unije (EU) i Federalnog ministarstva za ekonomsku saradnju i razvoj Vlade SR Njemačke (BMZ) u okviru EU4Business projekta u BiH. Sadržaj ove aplikacije je isključiva odgovornost GAT d.o.o. i nužno ne odražava stanovišta EU ili BMZ.'}
                      </i>
                      <div id="i_s">
                        <i>
                          {' '}
                          Powered by{' '}
                          <u>
                            {' '}
                            <a href="https://www.b2b.ba" target="_blank">
                              Ivis software
                            </a>
                          </u>
                        </i>
                      </div>
                    </React.Fragment>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
  }

  handleSubmit(e) {
    e.preventDefault()
    this.login(this.state.email, this.state.pwd)
  }
}

const mapStateToProps = (state) => {
  return {
    prevod: state.prevod,
    jezik: state.jezik,
    oz_j: state.oz_j,
  }
}
export default connect(mapStateToProps, null)(Login)
