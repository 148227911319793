import axios from "axios";
import { DOS_A, REQUEST_DOS_A, REQUEST_DOS_B, DOS_B } from "../../const";

export const fetchDAData = (dos_a) => {
  return {
    type: REQUEST_DOS_A,
    dos_a,
  };
};

export const fetchDOSAData = () => {
  return (dispatch) => {
    return axios
      .get(DOS_A)
      .then((response) => {
        dispatch(fetchDAData(response.data));
        //// // // // console.log("TU SAM" ,response.data)
      })
      .catch((error) => {
        //// // // // console.log("err")
        throw error;
      });
  };
};

export const fetchDBData = (dos_b) => {
  return {
    type: REQUEST_DOS_B,
    dos_b,
  };
};

export const fetchDOSBData = () => {
  return (dispatch) => {
    return axios
      .get(DOS_B)
      .then((response) => {
        dispatch(fetchDBData(response.data));
        //// // // // console.log("TU SAM" ,response.data)
      })
      .catch((error) => {
        //// // // // console.log("err")
        throw error;
      });
  };
};
