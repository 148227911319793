import ReactLoading from 'react-loading'
import React, { Component } from 'react'

export default class LoadingCrteza extends Component {
  render() {
    return (
      <div
        style={{
          position: 'absolute',
          width: '100vw',
          height: '100vh',
          top: '0',
          left: '0',
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          justifyContent: 'center',
          zIndex: '100',
        }}
      >
        <div style={{ marginTop: '30vh' }}>
          <ReactLoading
            type={'bars'}
            color={'#083ce7'}
            height={200}
            width={200}
          />
        </div>
      </div>
    )
  }
}
