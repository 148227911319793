import { REQUEST_DOS_B } from "../../../const";

export default function DOSBReducer(state = [], action) {
  switch (action.type) {
    case REQUEST_DOS_B:
      return action.dos_b;
    default:
      return state;
  }
}
