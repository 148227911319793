import { REQUEST_BK } from "../../const";

export default function BKReducer(state = [], action) {
  switch (action.type) {
    case REQUEST_BK:
      return action.bk;
    default:
      return state;
  }
}
