import { REQUEST_LISTA_NARUDZBI, REQUEST_LISTA_UPITA } from "../../const";

export default function UiNReducer(state = [], action) {
  switch (action.type) {
    case REQUEST_LISTA_UPITA:
      return action.lista_upita;
    case REQUEST_LISTA_NARUDZBI:
      return action.lista_narudzbi;
    default:
      return state;
  }
}
