import { Link } from '@material-ui/core'
import React from 'react'
const ErrorPage = () => {
  return (
    <div
      id="error_page"
      style={{
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        top: '0',
        left: '0',
        zIndex: '100',
      }}
    >
      <h1>Oooops!</h1>
      <h2>Error 404 - Page not found</h2>
      <Link href="/">Idi na početnu stranicu</Link>
    </div>
  )
}

export default ErrorPage
