import React from 'react'
import { Button } from 'devextreme-react'
import { connect } from 'react-redux'
import UpitDetalji from '../../SlozeniArtikal/UpitDetalji'
import FadeIn from 'react-fade-in'
import axios from 'axios'
import '../../../../Styles/slozeniArtikalStyle.css'
import OdjavaButton from '../../OdjavaButton'
var mime = require('mime-types')

const gmail = 'mubina.ibrisagic@gat.com.ba'

class SlozeniArtikalAdmin extends React.Component {
  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this)
    this.state = {
      component: '',
      u: {},
    }
  }

  onClick() {
    window.location.href = `mailto:${gmail}?subject=Upit&Body=${this.state.component}`
    this.props.history.replace({
      pathname: '/user/login',
    })
  }

  componentDidMount = () => {
    const u = this.props.location.state.upit // radi lakseg zapisa
    this.setState({
      component:
        u.hppoN === 'Mat eloksiranje'
          ? `
Podaci o proizvodu: %0D%0A  %0D%0A
Broj artikla: ${u.ba}, %0D%0A
________________________________________________________________________________
%0D%0A  %0D%0A
Broj crteža: ${u.bc}, %0D%0A
________________________________________________________________________________
%0D%0A  %0D%0A
Materijal: EN AW ${u.m}, %0D%0A
________________________________________________________________________________
%0D%0A  %0D%0A
Površina jednog komada: ${u.p}, %0D%0A
________________________________________________________________________________
%0D%0A  %0D%0A
Dimenzioni kriterij: ${u.t}, %0D%0A
________________________________________________________________________________
%0D%0A  %0D%0A
Slijepe rupe/navoji manje od Φ5: ${u.s_r ? 'DA' : 'NE'}, %0D%0A
________________________________________________________________________________
%0D%0A  %0D%0A
Način kačenja: ${u.nk}, %0D%0A
________________________________________________________________________________
%0D%0A  %0D%0A
Broj komada: ${u.bk}, %0D%0A
________________________________________________________________________________
%0D%0A  %0D%0A
Način površinske zaštite: ${u.npz}, %0D%0A
________________________________________________________________________________
%0D%0A  %0D%0A
Hemijska predobrada prije eloksiranja: ${u.hppo}, %0D%0A
________________________________________________________________________________
%0D%0A  %0D%0A
${u.min ? `Minute: ${u.min}` : ''}, %0D%0A
________________________________________________________________________________
%0D%0A  %0D%0A
Dodatno hemijsko poliranje( E7 ): ${u.hppo_d ? 'DA' : 'NE'}, %0D%0A
________________________________________________________________________________
%0D%0A  %0D%0A
Debljina oksidnog sloja: ${u.dos}, %0D%0A
________________________________________________________________________________
%0D%0A  %0D%0A
Boja: ${u.b}, %0D%0A
________________________________________________________________________________
%0D%0A  %0D%0A`
          : `Podaci o proizvodu:  %0D%0A %0D%0A 
Broj artikla: ${u.ba}, %0D%0A
________________________________________________________________________________
%0D%0A  %0D%0A
Broj crteža: ${u.bc}, %0D%0A
________________________________________________________________________________
%0D%0A  %0D%0A
Materijal: EN AW ${u.m}, %0D%0A
________________________________________________________________________________
%0D%0A  %0D%0A
Površina jednog komada: ${u.p}, %0D%0A
________________________________________________________________________________
%0D%0A  %0D%0A
Dimenzioni kriterij: ${u.t}, %0D%0A
________________________________________________________________________________
%0D%0A  %0D%0A
Slijepe rupe/navoji manje od Φ5: ${u.s_r ? 'DA' : 'NE'}, %0D%0A
________________________________________________________________________________
%0D%0A  %0D%0A
Način kačenja: ${u.nk}, %0D%0A
________________________________________________________________________________
%0D%0A  %0D%0A
Broj komada: ${u.bk}, %0D%0A
________________________________________________________________________________
%0D%0A  %0D%0A
Način površinske zaštite: ${u.npz}, %0D%0A
________________________________________________________________________________
%0D%0A  %0D%0A
Hemijska predobrada prije eloksiranja: ${u.hppo}, %0D%0A
________________________________________________________________________________
%0D%0A  %0D%0A
Dodatno hemijsko poliranje( E7 ): ${u.hppo_d ? 'DA' : 'NE'}, %0D%0A
________________________________________________________________________________
%0D%0A  %0D%0A
Debljina oksidnog sloja: ${u.dos}, %0D%0A
________________________________________________________________________________
%0D%0A  %0D%0A
Boja: ${u.b}, %0D%0A
________________________________________________________________________________
%0D%0A  %0D%0A`,
    })
    // // // // console.log("Komponenta", this.state.component);
  }

  b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, { type: contentType })
    return blob
  }

  downloadTxtFile = (b64Data, contentType) => {
    const element = document.createElement('a')

    const file = this.b64toBlob(b64Data, mime.lookup(contentType))
    // // // // console.log(mime.lookup(contentType));
    element.href = URL.createObjectURL(file)
    element.download = `${
      this.props.location.state.upit.id
    }.${contentType.substring(contentType.indexOf('.') + 1)}`
    document.body.appendChild(element)
    element.click()
  }

  async submit(e) {
    e.preventDefault()
    const url = `https://api.gat-crm.com:1121/base64/${this.props.location.state.upit.id}`

    return axios
      .get(url)
      .then((resp) => {
        this.downloadTxtFile(resp.data.item2, resp.data.item1)
      })
      .catch((err) => this.setState({ err: true }))
  }

  componentWillUnmount = () => {
    const u = this.props.location.state.upit
    this.props.history.replace({
      pathname: '/upit/a',
      state: {
        upit: {
          siid: u.id,
          ba: u.ba,
          na: u.na,
          bc: u.bc,
          m: 'EN AW ' + u.m,
          p: u.p,
          t: u.t,
          slijepe_rupe: u.s_r,
          nk: u.nk,
          bk: u.bk,
          npz_value: u.npz ? u.npz : '',
          hppo: u.hppo,
          hppoN: u.hppoN,
          // hppo_n: this.state.hppo.substr(2, this.state.hppo.length),
          h_p: u.hppo_d,
          min: u.min,
          dos: u.dos,
          itdos: u.itdos,
          upit: u.upit,
          //b: this.state.selectedBItem ? this.state.selectedBItem.item1 + " " + this.state.selectedBItem.item2 : "",
          //b_n: this.state.b_n ? this.state.b.item2 : "",

          selectedBItem: {
            item1: u.b.substr(0, 2),
            item2: u.b.substr(5, u.b.length - 7),
          },
          sifra_recepta: u.sifra_recepta,
          e6: u.hppo === 'E6' ? u.hppoN : 'Eloksiranje na mjeru',
        },
      },
    })
    this.props.history.push({
      location: {
        pathname: '/upit/a',
        state: {
          a: '1234',
          upit: {
            siid: u.id,
            ba: u.ba,
            na: u.na,
            bc: u.bc,
            m: 'EN AW' + u.m,
            p: u.p ? u.p : 0,
            t: u.t,
            slijepe_rupe: u.s_r ? u.s_r : false,
            nk: u.nk,
            bk: this.state.bk,
            npz_value: u.npz ? u.npz : '',
            hppo: u.hppo,
            // hppo_n: this.state.hppo.substr(2, this.state.hppo.length),
            h_p: u.hppo_d,
            min: u.min,
            dos: u.dos,
            itdos: u.itdos,
            //b: this.state.selectedBItem ? this.state.selectedBItem.item1 + " " + this.state.selectedBItem.item2 : "",
            //b_n: this.state.b_n ? this.state.b.item2 : "",

            selectedBItem: {
              item1: u.b.substr(0, 2),
              item2: u.b.substr(4, u.b.length - 5),
            },
          },
        },
      },
    })
  }
  render() {
    const { prevod, oz_j } = this.props
    if (!this.props.location.state) return <div>Nema parametara</div>
    return (
      <div id="s_a">
        <div>
          <OdjavaButton />
        </div>
        <div id="slozeni_artikal">
          <div className="dx-fieldset">
            <div className="dx-field">
              <div className="dx-field-label" id="title">
                <FadeIn>
                  <h2>
                    {prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        80
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            80
                        ].item2
                      : 'REKAPITULACIJA UNESENIH PODATAKA'}
                  </h2>
                </FadeIn>
              </div>
            </div>
            <div>
              {this.props.location.state ? (
                <UpitDetalji
                  upit={this.props.location.state.upit}
                  sifra_recepta={this.props.sifra_recepta}
                />
              ) : prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    82
                ] ? (
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    82
                ].item2
              ) : (
                'Nema poslanih podataka'
              )}
            </div>
            <div>
              <div>
                <div className="dx-field">
                  <form onSubmit={(e) => this.submit(e)}>
                    <button type="submit" className="k-button">
                      {prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          153
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              153
                          ].item2
                        : 'Download crteža'}
                    </button>
                  </form>
                </div>
              </div>

              <p></p>
            </div>
          </div>
          {/*   <div className="dx-fieldset">
          <div className="dx-field">
            <div className="send-request dx-field-label">
              <Button
                text="Preuzmi PDF"
                type="default"
                width="100%"
                onClick={this.exportPDFWithComponent}
              />
            </div>
          </div>
          </div> */}
          <div className="dx-fieldset">
            <div className="dx-field">
              <div className="send-request dx-field-label">
                <div>
                  <h2>
                    {prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        81
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            81
                        ].item2
                      : 'Slanje upita'}
                  </h2>
                </div>
                <div>
                  <Button
                    text={
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          83
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              83
                          ].item2
                        : 'Pošalji upit'
                    }
                    width="100%"
                    onClick={this.onClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  <div id="a" style={{ position: "absolute", left: "-3000px", top: 0 }}>
          <PDFExport
            ref={(component) => (a = component)}
            paperSize="auto"
            margin={40}
            fileName={`${this.props.location.state.upit.id
              .toString()
              .substr(3)}-${new Date().getFullYear().toString().substr(-2)}`}
            author="Ivis software, B2B d.o.o. Sarajevo, ul. Branilaca Sarajeva 27, 71000 Sarajevo"
          >
            <Report
              podaci={{
                tabela: [
                  {
                    Rb: 1,
                    vašaOznaka: "",
                    bc: this.props.location.state.upit.bc,
                    naziv: {
                      na: this.props.location.state.upit.na,
                      sifra_recepta: this.props.location.state.upit
                        .sifra_recepta,
                    },
                    JM: "KOM",
                    bk: this.props.location.state.upit.bk.toString() + ",00",
                    cijenaEUR: parseFloat(
                      "2,82".replace(",", ".").replace(" ", "") // cijena ne treba 
                    )
                      .toFixed(2)
                      .toString()
                      .replace(".", ","), // doraditi
                    ukupnaCijenaEUR: (  // ukupna cijena ne treba 
                      parseFloat(
                        "2,82".replace(",", ".").replace(" ", "")
                      ).toFixed(2) * this.props.location.state.upit.bk
                    )
                      .toFixed(2)
                      .toString()
                      .replace(".", ","),
                  },
                ],
                brKom: parseInt(
                  JSON.parse(
                    CryptoJS.AES.decrypt(
                      localStorage.getItem("nmx"),
                      "Adna1234"
                    ).toString(CryptoJS.enc.Utf8)
                  )
                ),
                kusid: `${this.props.location.state.upit.id
                  .toString()
                  .substr(3)}-${new Date()
                  .getFullYear()
                  .toString()
                  .substr(-2)}`,
              }}
            />
          </PDFExport>
            </div>*/}
      </div>
    )
  }
  /* exportPDFWithComponent = async () => {
    a.save();
  };
}

class PDF extends React.Component {
  render() {
    return (
      <PDFExport
        ref={(component) => (a = component)}
        paperSize="auto"
        margin={40}
        fileName={`Ponuda ${new Date().getFullYear()}`}
        author="Ivis software, B2B d.o.o. Sarajevo, ul. Branilaca Sarajeva 27, 71000 Sarajevo"
      >
        <Report />
      </PDFExport>
    );
  } */
}

const mapStateToProps = (state) => {
  return {
    prevod: state.prevod,
    jezik: state.jezik,
    oz_j: state.oz_j,
  }
}

export default connect(mapStateToProps, null)(SlozeniArtikalAdmin)
