import { REQUEST_E6_ME } from "../../../const";

export default function E6MEReducer(state = [], action) {
  switch (action.type) {
    case REQUEST_E6_ME:
      return action.e6_me;

    default:
      return state;
  }
}
