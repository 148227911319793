import React from "react";
import dropdown_img from "../../../Images/dropdown-img.png";
export default function ImageIcon() {
  return (
    <img
      src={dropdown_img} //"https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/icons/custom-dropbutton-icon.svg"
      className="custom-icon"
      alt="image_icon"
    />
  );
}
