import { REQUEST_PREVOD_ADMIN, PREVOD_ADMIN } from "../../const";
import axios from "axios";

export const fetchPAData = (prevod_a) => {
  return {
    type: REQUEST_PREVOD_ADMIN,
    prevod_a,
  };
};

export const fetchPrevodAData = () => {
  return (dispatch) => {
    return axios
      .get(PREVOD_ADMIN)
      .then((response) => {
        dispatch(fetchPAData(response.data));
        // // // // console.log("TU SAM", response.data);
      })
      .catch((error) => {
        //// // // // console.log("err")
        throw error;
      });
  };
};
