import { REQUEST_PREVOD } from "../../const";

export default function PrevodReducer(state = [], action) {
  switch (action.type) {
    case REQUEST_PREVOD:
      return action.prevod;
    default:
      return state;
  }
}
