import axios from 'axios'

export const postPovrsina = (
  k9kcptid,
  k9kcpta,
  k9kcptb,
  k9kcptc,
  k9kcptd,
  k9kcptv,
  k9kcptr,
  k9kcptp,
  k9kcptks,
  k9kcptksn
) => {
  axios
    .post('https://api.gat-crm.com:1121/povrsina', {
      k9kcptkp: 'GT',
      k9kcptid: k9kcptid,
      k9kcpta: k9kcpta,
      k9kcptb: k9kcptb,
      k9kcptc: k9kcptc,
      k9kcptd: k9kcptd,
      k9kcptv: k9kcptv,
      k9kcptr: k9kcptr,
      k9kcptp: k9kcptp,
      k9kcptks: k9kcptks,
      k9kcptksn: k9kcptksn,
    })
    .then((resp) => {
      // // // // console.log("RESPONSE", resp);
    })
  // // // // console.log(k9kcptid);
}

export const putPovrsina = (
  k9kcptid,
  k9kcptbd,
  k9kcpta,
  k9kcptb,
  k9kcptc,
  k9kcptd,
  k9kcptv,
  k9kcptr,
  k9kcptp,
  k9kcptks,
  k9kcptksn
) => {
  axios.put(`https://api.gat-crm.com:1121/povrsina/${k9kcptid}/${k9kcptbd}`, {
    k9kcptkp: 'GT',
    k9kcptid: k9kcptid,
    k9kcptbd: k9kcptbd,
    k9kcpta: k9kcpta,
    k9kcptb: k9kcptb,
    k9kcptc: k9kcptc,
    k9kcptd: k9kcptd,
    k9kcptv: k9kcptv,
    k9kcptr: k9kcptr,
    k9kcptp: k9kcptp,
    k9kcptks: k9kcptks,
    k9kcptksn: k9kcptksn,
  })
  // .then((resp) => // // // // console.log("RESPONSE", resp));
}
