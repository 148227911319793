import axios from "axios";
import {
  DOS_2017_A,
  REQUEST_2017_DOS_A,
  REQUEST_2017_DOS_B,
  DOS_2017_B,
} from "../../const";

export const fetchD2017AData = (dos_2017_a) => {
  return {
    type: REQUEST_2017_DOS_A,
    dos_2017_a,
  };
};

export const fetchDOS2017AData = () => {
  return (dispatch) => {
    return axios
      .get(DOS_2017_A)
      .then((response) => {
        dispatch(fetchD2017AData(response.data));
        // // // // console.log("TU SAM", response.data);
      })
      .catch((error) => {
        //// // // // console.log("err")
        throw error;
      });
  };
};

export const fetchD2017BData = (dos_2017_b) => {
  return {
    type: REQUEST_2017_DOS_B,
    dos_2017_b,
  };
};

export const fetchDOS2017BData = () => {
  return (dispatch) => {
    return axios
      .get(DOS_2017_B)
      .then((response) => {
        dispatch(fetchD2017BData(response.data));
        //// // // // console.log("TU SAM" ,response.data)
      })
      .catch((error) => {
        //// // // // console.log("err")
        throw error;
      });
  };
};
