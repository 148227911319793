import React, { useState, useEffect } from 'react'
import FadeIn from 'react-fade-in'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Galerija from '../Galerija'

const NPZOpis = (props) => {
  const [npz, setNpz] = useState('x')

  useEffect(() => {
    setInterval(() => {
      if (localStorage.getItem('npz')) setNpz(localStorage.getItem('npz'))
    }, 100)
  })
  return (
    <FadeIn>
      <div
        className="opis npz_opis"
        style={{
          marginTop: '-145px',
        }}
      >
        <ul
          className="list-opis"
          style={{
            /*marginTop: "45px",*/
            fontSize: '13px',
          }}
        >
          {npz == 'Standardno eloksiranje - S' || npz == 'x' ? (
            <li style={{ listStyleType: 'none' }}>
              {props.prevod &&
              props.prevod.length !== 0 &&
              props.prevod[
                props.oz_j.indexOf(localStorage.getItem('lng')) *
                  (props.prevod.length / props.oz_j.length) +
                  216
              ]
                ? props.prevod[
                    props.oz_j.indexOf(localStorage.getItem('lng')) *
                      (props.prevod.length / props.oz_j.length) +
                      216
                  ].item2
                : 'S-standardno eloksiranje: Elektrohemijski postupak anodne oksidacije aluminijuma u sumpornoj kiselini kao elektrolit. Postupak se primjenjuje u skladu sa normom MIL-A-8625F (Typ II, Class 1 i Class 2). Proziran transparentan sloj, omogućeno bojenje široke palete boja.'}
            </li>
          ) : (
            ''
          )}
          {npz == 'Tvrdo eloksiranje - XH4' || npz == 'x' ? (
            <li>
              {props.prevod &&
              props.prevod.length !== 0 &&
              props.prevod[
                props.oz_j.indexOf(localStorage.getItem('lng')) *
                  (props.prevod.length / props.oz_j.length) +
                  217
              ]
                ? props.prevod[
                    props.oz_j.indexOf(localStorage.getItem('lng')) *
                      (props.prevod.length / props.oz_j.length) +
                      217
                  ].item2
                : 'XH4-Tvrdo eloksiranje: POLYMEROXID XH4 - Hightech-composite-Harteloxal patentiran od Fischer Oberflächentechnik, je elektrohemijski postupak anodne oksidacije aluminijuma u hladno kiselom elektrolitu posebnog sastava. Postupak zadovoljava standarde MIL-8625F u ISO 10074. Detaljne informacije na:'}
              <a onClick={() => window.open('https://www.fot.de', '_blank')}>
                www.fot.de
              </a>
            </li>
          ) : (
            ''
          )}
          {npz == 'Kromatiranje Surtec 650 - C' || npz == 'x' ? (
            <li>
              {props.prevod &&
              props.prevod.length !== 0 &&
              props.prevod[
                props.oz_j.indexOf(localStorage.getItem('lng')) *
                  (props.prevod.length / props.oz_j.length) +
                  264
              ]
                ? props.prevod[
                    props.oz_j.indexOf(localStorage.getItem('lng')) *
                      (props.prevod.length / props.oz_j.length) +
                      264
                  ].item2
                : 'CH-Kromatiranje:  Kromatiranje SurTec 650 je postupak pasiviranja aluminijuma bez heksavalentnog kroma' +
                  '. U skladu sa normom MIL-DTL- 5541-F Type II, -	Class 1A- Za maksimalnu korozionu otpornost, kao predobrada za lakiranje.  -	Class 3 – Za zaštitu od korozije gdje je potreban nizak električni otpor. Ispunjava sve zahtjeve ELV, RoHS i WEEE.'}
            </li>
          ) : (
            ''
          )}
        </ul>
      </div>
    </FadeIn>
  )
}

const mapStateToProps = (state) => {
  return {
    jezik: state.jezik,
    prevod: state.prevod,
    oz_j: state.oz_j,
  }
}

export default connect(mapStateToProps, null)(NPZOpis)
