import { REQUEST_PREVOD, PREVOD } from "../../const";
import axios from "axios";

export const fetchPData = (prevod) => {
  return {
    type: REQUEST_PREVOD,
    prevod,
  };
};

export const fetchPrevodData = () => {
  return (dispatch) => {
    return axios
      .get(PREVOD) // popraviti
      .then((response) => {
        dispatch(fetchPData(response.data));
        //// // // // console.log("TU SAM" ,response.data)
      })
      .catch((error) => {
        //// // // // console.log("err")
        throw error;
      });
  };
};
