import React from 'react'
import { post } from 'axios'
import { connect } from 'react-redux'
import { TextBox } from 'devextreme-react'
import { IconButton } from '@material-ui/core'
import { Button } from 'devextreme-react/button'
import CameraIcon from '../../../../Images/cameraIcon.png'
import '../../../../Styles/jezik.css'
class NoviJezik extends React.Component {
  jezik = {
    oj: '',
    nj: '',
    file: null,
  }
  constructor() {
    super()
    this.state = {
      oj: '',
      nj: '',
      file: null,
    }
    this.onOJChanged = this.onOJChanged.bind(this)
    this.onNJChanged = this.onNJChanged.bind(this)
  }
  onOJChanged(e) {
    this.setState({ oj: e.value })
  }
  onNJChanged(e) {
    this.setState({ nj: e.value })
  }
  setFile(e) {
    this.setState({
      file: e.target.files[0],
      name: e.target.files[0].name,
    })
  }
  async submit(e) {
    // // // // console.log(this.state.file);
    e.preventDefault()
    const url = 'https://api.gat-crm.com:1121/jezik'
    const formData = new FormData()
    formData.append('body', this.state.file)
    formData.append('oj', this.state.oj)
    formData.append('nj', this.state.nj)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    //// // // // console.log("post");
    return post(url, formData, config).then(() => {
      // // // // console.log(resp);
      alert('Dodan novi jezik!')
    })
  }
  render() {
    const { prevod, oz_j } = this.props
    return (
      <div id="novi_jezik">
        <form onSubmit={(e) => this.submit(e)}>
          <div id="lng_form">
            <div>
              <div className="dx-field">
                <div className="dx-field-label">
                  {prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      32
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          32
                      ].item2
                    : 'Oznaka jezika'}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    placeholder={
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          32
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              32
                          ].item2 + '...'
                        : 'Oznaka jezika' + '...'
                    }
                    onValueChanged={this.onOJChanged}
                    value={this.state.oj}
                  />
                </div>
              </div>
              <div className="dx-field">
                <div className="dx-field-label">
                  {prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      33
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          33
                      ].item2
                    : 'Naziv jezika'}
                </div>
                <div className="dx-field-value">
                  <TextBox
                    placeholder={
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          196
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              96
                          ].item2 + '...'
                        : 'Naziv...'
                    }
                    onValueChanged={this.onNJChanged}
                    value={this.state.nj}
                  />
                </div>
              </div>
              <br />
              <br />
            </div>
            <div id="lng_img">
              <div className="dx-field">
                <div className="dx-field-label">
                  {prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      34
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          34
                      ].item2
                    : 'Zastava'}
                </div>
                <div className="dx-field-value">
                  <label htmlFor="files">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <img src={CameraIcon} alt="camera_icon" width="35px" />

                      <p style={{ marginLeft: '15px', marginTop: '4px' }}>
                        {this.state.name}
                      </p>
                    </IconButton>
                  </label>
                  <input
                    style={{ display: 'none' }}
                    type="file"
                    id="files"
                    onChange={(e) => this.setFile(e)}
                  />{' '}
                  <br />
                </div>
              </div>
            </div>
          </div>

          <div className="dx-field">
            <Button
              id="new_lng_btn"
              useSubmitBehavior={true}
              text={
                prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    35
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        35
                    ].item2
                  : 'Dodaj jezik'
              }
            ></Button>
          </div>
        </form>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    jezik: state.jezik,
    prevod: state.prevod,
    oz_j: state.oz_j,
  }
}

export default connect(mapStateToProps, null)(NoviJezik)
