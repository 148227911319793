import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core'
import { Button } from 'devextreme-react/button'
import '../../../../Styles/crtezStyle.css'
var mime = require('mime-types')

class Crtez extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: '',
      src: null,
      contentType: null,
      err: false,
    }
  }

  b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, { type: contentType })
    return blob
  }

  downloadTxtFile = (b64Data, contentType) => {
    const element = document.createElement('a')

    const file = this.b64toBlob(b64Data, mime.lookup(contentType))
    // // // console.log(mime.lookup(contentType));
    element.href = URL.createObjectURL(file)
    element.download = `${this.state.id}.${contentType.substring(
      contentType.indexOf('.') + 1
    )}`
    document.body.appendChild(element)
    element.click()
  }

  async submit(e) {
    e.preventDefault()
    const url = `https://api.gat-crm.com:1121/base64/${this.state.id}`

    return axios
      .get(url)
      .then((resp) => {
        this.downloadTxtFile(resp.data.item2, resp.data.item1)
        this.setState({
          contentType: resp.data.item1,
          src: resp.data.item2,
          err: false,
        })
      })
      .catch((err) => this.setState({ err: true }))
  }

  setId(e) {
    this.setState({ id: e.target.value, src: null })
  }

  render() {
    const { prevod, oz_j } = this.props
    return (
      <div id="draw">
        <div>
          <div>
            <form onSubmit={(e) => this.submit(e)}>
              <h1>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    186
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        186
                    ].item2
                  : 'ID upita'}
                :
              </h1>
              <TextField
                id="standard-basic"
                label={
                  prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      186
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          186
                      ].item2
                    : 'ID upita'
                }
                onChange={(e) => this.setId(e)}
              />

              <Button
                id="draw_btn"
                useSubmitBehavior={true}
                text={
                  prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      153
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          153
                      ].item2
                    : 'Download crteža'
                }
              ></Button>
            </form>
          </div>
        </div>
        {this.state.err ? (
          <div style={{ marginTop: '50px', marginLeft: '10px' }}>
            {prevod &&
            prevod.length !== 0 &&
            prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                188
            ]
              ? prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    188
                ].item2
              : 'Ne postoji crtež za datu šifru upita'}
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    jezik: state.jezik,
    prevod: state.prevod,
    oz_j: state.oz_j,
  }
}

export default connect(mapStateToProps, null)(Crtez)
