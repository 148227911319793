import { REQUEST_JEZIK } from "../../const";

export default function JezikReducer(state = [], action) {
  switch (action.type) {
    case REQUEST_JEZIK:
      return action.jezik;
    default:
      return state;
  }
}
