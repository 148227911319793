import { REQUEST_POVRSINE, POVRSINE } from "../../const";
import axios from "axios";

export const fetchPData = (povrsine) => {
  return {
    type: REQUEST_POVRSINE,
    povrsine,
  };
};

export const fetchPovData = () => {
  return (dispatch) => {
    return axios
      .get(
        POVRSINE +
          (localStorage.getItem("siid")
            ? localStorage.getItem("siid")
            : "00000")
      ) // popraviti
      .then((response) => {
        dispatch(fetchPData(response.data));
        //// // // // console.log("TU SAM" ,response.data)
      })
      .catch((error) => {
        //// // // // console.log("err")
        throw error;
      });
  };
};
