import React, { Component } from 'react'
import '../../../Styles/loadingStyle.css'

export default class Loading extends Component {
  render() {
    return (
      <div
        id="loading"
        style={{
          backgroundColor: 'rgba(5, 5, 5, 0.9)',
          position: 'absolute',
          zIndex: '100000',
          width: '100vw',
          height: '200%',
          top: '0',
          left: '0',
        }}
      >
        <div className="infinity-loader">
          <div className="bg">
            <div className="left-bg" />
            <div className="right-bg" />
          </div>
          <div className="fg">
            <div className="top-left-rect">
              <div />
            </div>
            <div className="bottom-right-rect">
              <div />
            </div>
            <div className="top-right-rect">
              <div />
            </div>
            <div className="bottom-left-rect">
              <div />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
