import React from 'react'
import { connect } from 'react-redux'
import DataGrid, { Column, SearchPanel } from 'devextreme-react/data-grid'
import axios from 'axios'

class OstaliKorisnici extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
    }
    this.makeTimer = setInterval(() => {
      if (!this.state.edit) this.getData()
    }, 1000)
  }

  getData = () => {
    axios.get('https://api.gat-crm.com:1121/users').then((resp) => {
      this.setState({ users: resp.data })

      //this.props.povrsine = resp.data;
    })
  }
  componentDidMount = async () => {
    this.getData()
  }
  render() {
    const { prevod, oz_j } = this.props
    return (
      <div>
        <br /> <br />
        <br /> <br />
        <h3>
          {prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              45
          ]
            ? prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  45
              ].item2
            : 'Odobreni korisnici'}
        </h3>
        <div className="adminPanelGrid">
          <DataGrid
            id="gridContainer"
            dataSource={this.state.users}
            keyExpr="id"
            showBorders={true}
            noDataText={
              prevod &&
              prevod.length !== 0 &&
              prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  46
              ]
                ? prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      46
                  ].item2
                : 'Nema podataka'
            }
            allowColumnReordering={true}
          >
            <SearchPanel
              visible={true}
              placeholder={
                prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    41
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        41
                    ].item2
                  : 'Pretraži...'
              }
            />

            <Column
              dataField="id"
              caption="ID"
              allowEditing={false}
              width={50}
              alignment="center"
            />
            <Column
              dataField="companyName"
              caption={
                prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    42
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        42
                    ].item2
                  : 'Firma'
              }
              width={120} // popraviti kasnije, ukoliko bude naziv firme veći naziv
              allowEditing={false}
            />
            <Column
              dataField="firstName"
              caption={
                prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    44
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        44
                    ].item2
                  : 'Ime'
              }
            />
            <Column
              dataField="lastName"
              caption={
                prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    43
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        43
                    ].item2
                  : 'Prezime'
              }
            />
            <Column dataField="email" caption="Email" />
          </DataGrid>
          <br />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    //users: state.users,
    prevod: state.prevod,
    jezik: state.jezik,
    oz_j: state.oz_j,
  }
}

export default connect(mapStateToProps, null)(OstaliKorisnici)
