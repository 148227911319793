import { REQUEST_NK, NK } from "../../const";
import axios from "axios";

export const fetchN_KData = (nk) => {
  return {
    type: REQUEST_NK,
    nk,
  };
};

export const fetchNKData = () => {
  return (dispatch) => {
    return axios
      .get(NK)
      .then((response) => {
        dispatch(fetchN_KData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};
