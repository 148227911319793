import { REQUEST_HPPE_B } from "../../../const";

export default function HPPEBReducer(state = [], action) {
  switch (action.type) {
    case REQUEST_HPPE_B:
      return action.hppe_b;
    default:
      return state;
  }
}
