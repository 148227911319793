import { REQUEST_2017_DOS_A } from "../../../const";

export default function DOS2017AReducer(state = [], action) {
  switch (action.type) {
    case REQUEST_2017_DOS_A:
      return action.dos_2017_a;

    default:
      return state;
  }
}
