import React from "react";
import "../../../../Styles/korisnikListaStyle.css";

import OdobrenjeKorisnika from "../OdobrenjeKorisnika/OdobrenjeKorisnika";
import OstaliKorisnici from "../OstaliKorisnici/OstaliKorisnici";
import NotFoundErrorPage from "../../../../Pages/DodatneStranice/NotFoundErrorPage";

class KorisnikLista extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: false,
    };
  }

  handleCallback = (childData) => {
    this.setState({ data: childData });
  };
  handleCallbackOstaliKorisnici = (childData) => {
    this.setState({ data: childData });
  };
  componentWillUnmount = () => {
    clearInterval(this.handleCallback);
  };
  render() {
    const { data } = this.state;
    if (!localStorage.getItem("a")) return <NotFoundErrorPage />;
    return (
      <div>
        <OdobrenjeKorisnika parentCallback={this.handleCallback} />
        <OstaliKorisnici parentCallback={this.handleCallback} />
      </div>
    );
  }
}

export default KorisnikLista;
