import React from 'react'
import Form, {
  ButtonItem,
  GroupItem,
  SimpleItem,
  Label,
  CompareRule,
  EmailRule,
  PatternRule,
  RequiredRule,
  StringLengthRule,
  AsyncRule,
  Item,
} from 'devextreme-react/form'
import '../../../Styles/registerStyle.css'
import 'devextreme-react/autocomplete'
import eye from '../../../Images/eye.png'
import white_eye from '../../../Images/white_eye.png'
import RegisterBackground from '../../../Images/RegisterBackground.JPG'
import { Popup, SelectBox, TextBox, Validator } from 'devextreme-react'
import { Button as TextBoxButton } from 'devextreme-react/text-box'
import { useEffect } from 'react'
import axios from 'axios'
import { API, OZNAKE_JEZIKA, PREVOD } from '../../../const'
import { useState } from 'react'
import notify from 'devextreme/ui/notify'
import ErrorPage from '../../../Pages/DodatneStranice/NotFoundErrorPage'

export default function ResetPassword(props) {
  const [email, setEmail] = useState('')
  const [pwd, setPwd] = useState('')
  const [re_pwd, setRePwd] = useState('')
  const [passwordMode, setPasswordMode] = useState('')
  const [rePasswordMode, setRePasswordMode] = useState('')
  const [error, setError] = useState(false)
  const [prevod, setPrevod] = useState([])
  const [oz_j, setOzJ] = useState([])

  useEffect(() => {
    axios
      .request({ method: 'get', url: API + '/getDekrEmail/' + props.id })
      .then((resp) => {
        setEmail(resp.data)
      })
      .catch((err) => setError(true))
  }, [])
  const handleSubmit = (e) => {
    //change pwd
    e.preventDefault()
    // console.log(email, pwd, re_pwd)
    axios
      .request({
        method: 'post',
        url: API + '/post_re_pwd',
        data: { email, pwd, re_pwd },
        headers: { 'Content-Type': 'application/json' },
      })
      .then((resp) => {
        notify(
          {
            message:
              prevod &&
              prevod.length !== 0 &&
              prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  147
              ]
                ? prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      147
                  ].item2
                : 'Podaci su poslani!',
            position: {
              my: 'center top',
              at: 'center top',
            },
          },
          'success',
          3000
        )
        setTimeout(
          () => (window.location.href = window.location.origin + '/#/'),
          3000
        )
      })
  }
  const passwordOptions = {
    mode: 'password',
  }

  const passwordComparison = () => {
    ////// // // // console.log(this.state.pwd)
    return pwd
  }

  useEffect(() => {
    axios.get(PREVOD).then((resp) => setPrevod(resp.data))
    axios.get(OZNAKE_JEZIKA).then((resp) => setOzJ(resp.data))
  }, [])

  if (error) return <ErrorPage />
  else
    return (
      <div>
        <div id="register">
          <div id="register-image">
            <div id="register-image-absolute">
              <img
                src={RegisterBackground}
                alt="register"
                width="100%"
                height="120%"
              />
            </div>
          </div>
          <div id="register-form">
            <React.Fragment>
              <form action="your-action" onSubmit={handleSubmit}>
                <Form
                  readOnly={false}
                  showColonAfterLabel={true}
                  showValidationSummary={true}
                  validationGroup="customerData"
                >
                  <GroupItem>
                    <div id="mobile-screen-layout-img-register"></div>
                  </GroupItem>
                  <GroupItem
                    className="prijava"
                    caption={
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          122
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              122
                          ].item2
                        : 'Podaci'
                    }
                  >
                    <SimpleItem dataField="Email" editorType="dxTextBox">
                      <div className="dx-field">
                        <TextBox
                          placeholder="Email..."
                          onValueChanged={(e) => setEmail(e.value)}
                          value={email}
                        >
                          <Validator>
                            <RequiredRule message="Email je obavezan" />
                            <EmailRule message="Email je neispravan" />
                          </Validator>
                        </TextBox>
                      </div>
                    </SimpleItem>
                    <SimpleItem
                      dataField={
                        prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            6
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                6
                            ].item2
                          : 'Lozinka'
                      }
                      editorType="dxTextBox"
                    >
                      <div className="dx-field">
                        <TextBox
                          placeholder="password ..."
                          stylingMode="filled"
                          onValueChanged={(e) => setPwd(e.value)}
                          value={pwd}
                          mode={passwordMode}
                        >
                          <Validator>
                            <RequiredRule
                              message={
                                prevod &&
                                prevod.length !== 0 &&
                                prevod[
                                  oz_j.indexOf(localStorage.getItem('lng')) *
                                    (prevod.length / oz_j.length) +
                                    7
                                ]
                                  ? prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        7
                                    ].item2
                                  : 'Lozinka je obavezna'
                              }
                            />
                          </Validator>
                          <TextBoxButton
                            name="password"
                            location="after"
                            id="TextBoxButton"
                            options={{
                              //text: '👁',

                              elementAttr: { id: 'eyeicon' },
                              icon: localStorage.getItem('l_m')
                                ? eye
                                : white_eye,
                              stylingMode: 'text',
                              onClick: () => {
                                setPasswordMode(
                                  passwordMode === 'text' ? 'password' : 'text'
                                )
                              },
                            }}
                          />
                        </TextBox>
                      </div>
                    </SimpleItem>
                    <SimpleItem
                      dataField={
                        prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            8
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                8
                            ].item2
                          : 'Ponovna lozinka'
                      }
                      editorType="dxTextBox"
                      editorOptions={passwordOptions}
                    >
                      <div className="dx-field">
                        <TextBox
                          placeholder="Re password ..."
                          stylingMode="filled"
                          onValueChanged={(e) => setRePwd(e.value)}
                          value={re_pwd}
                          mode={rePasswordMode}
                        >
                          <Validator>
                            <RequiredRule
                              message={
                                prevod &&
                                prevod.length !== 0 &&
                                prevod[
                                  oz_j.indexOf(localStorage.getItem('lng')) *
                                    (prevod.length / oz_j.length) +
                                    9
                                ]
                                  ? prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        9
                                    ].item2
                                  : 'Ponovna lozinka je obavezna'
                              }
                            />
                            <CompareRule
                              message={
                                prevod &&
                                prevod.length !== 0 &&
                                prevod[
                                  oz_j.indexOf(localStorage.getItem('lng')) *
                                    (prevod.length / oz_j.length) +
                                    10
                                ]
                                  ? prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        10
                                    ].item2
                                  : 'Lozinka i ponovna lozinka nisu iste. Probajte ponovo!'
                              }
                              comparisonTarget={passwordComparison}
                            />
                          </Validator>
                          <TextBoxButton
                            id="TextBoxButton"
                            name="password"
                            location="after"
                            options={{
                              //text: '👁',
                              icon: localStorage.getItem('l_m')
                                ? eye
                                : white_eye,

                              elementAttr: { id: 'eyeicon' },
                              stylingMode: 'text',
                              onClick: () => {
                                setRePasswordMode(
                                  rePasswordMode === 'text'
                                    ? 'password'
                                    : 'text'
                                )
                              },
                            }}
                          />
                        </TextBox>
                      </div>
                    </SimpleItem>
                  </GroupItem>{' '}
                  <ButtonItem
                    horizontalAlignment="left"
                    buttonOptions={{
                      text:
                        prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            185
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                185
                            ].item2
                          : 'Izmjena lozinke',
                      elementAttr: { id: 'registerButton' },
                      useSubmitBehavior: true,
                    }}
                  />
                </Form>
              </form>
            </React.Fragment>
          </div>
        </div>
      </div>
    )
}
