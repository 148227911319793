import { REQUEST_E6_DHP } from "../../../const";

export default function E6DHPReducer(state = [], action) {
  switch (action.type) {
    case REQUEST_E6_DHP:
      return action.e6_dhp;

    default:
      return state;
  }
}
