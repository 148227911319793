import { REQUEST_NKUZS } from "../../../const";

export default function NKUZSReducer(state = [], action) {
  switch (action.type) {
    case REQUEST_NKUZS:
      return action.num_of_kuzs;
    default:
      return state;
  }
}
