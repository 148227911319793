import React, { useEffect, useState } from 'react'
import TextBox from 'devextreme-react/text-box'
import axios from 'axios'
import { OZNAKE_JEZIKA, PREVOD } from '../../../const'

function NPZFieldF({ data }) {
  const [prevod, setPrevod] = useState([])
  const [oz_j, setOzJ] = useState([])

  useEffect(() => {
    axios.get(PREVOD).then((resp) => setPrevod(resp.data))
    axios.get(OZNAKE_JEZIKA).then((resp) => setOzJ(resp.data))
  }, [])
  return data && data == 'Standardno eloksiranje - S' ? (
    <div className="custom-item select-with-color">
      <TextBox
        value={
          prevod &&
          prevod.length !== 0 &&
          prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              70
          ]
            ? prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  70
              ].item2
            : 'Standardno eloksiranje - S'
        }
      />
    </div>
  ) : data && data == 'Tvrdo eloksiranje - XH4' ? (
    <div className="custom-item select-with-color">
      <TextBox
        value={
          prevod &&
          prevod.length !== 0 &&
          prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              71
          ]
            ? prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  71
              ].item2
            : 'Tvrdo eloksiranje - XH4'
        }
      />
    </div>
  ) : (
    <div className="custom-item select-with-color">
      <TextBox
        value={
          prevod &&
          prevod.length !== 0 &&
          prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              72
          ]
            ? prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  72
              ].item2
            : 'Kromatiranje Surtec 650 - C'
        }
      />
    </div>
  )
}

const NPZField = (data) => <NPZFieldF data={data} />

export default NPZField
