import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import App from './App'
import rootReducer from './store/reducers'
import { BrowserRouter } from 'react-router-dom'

import { fetchUsersData } from './store/actions/UserAction'
import { fetchNotAllowedUsersData } from './store/actions/NotAllowedAction'
import { fetchMATData } from './store/actions/MatAction'
import {
  fetchHPPEAData,
  fetchHPPEBData,
  fetchHPPECData,
} from './store/actions/HppeAction'
import { fetchNpzsData } from './store/actions/NpzAction'
import { fetchDOSAData, fetchDOSBData } from './store/actions/DosAction'
import { fetchITDOSAData, fetchITDOSBData } from './store/actions/ItdosAction'
import { fetchBojaAData, fetchBojaBData } from './store/actions/BAction'
import { fetchSCData } from './store/actions/SCAction'

import {
  fetchNKUSESData,
  fetchNKUZData,
  fetchNSISData,
} from './store/actions/NumAction'

import { fetchNKData } from './store/actions/NKAction'
import { fetchPovData } from './store/actions/PovrsinaAction'
import { fetchPrevodData } from './store/actions/PrevodAction'
import { fetchJezikData } from './store/actions/JezikAction'
import { fetchPrevodAData } from './store/actions/PrevodAdmin'
import { fetchOzJezikData } from './store/actions/OJAction'
import { fetchE6DHPData, fetchE6MEData } from './store/actions/E6MinAction'
import {
  fetchDOS2017AData,
  fetchDOS2017BData,
} from './store/actions/Dos2017Action'
const store = createStore(rootReducer, applyMiddleware(thunk))

store.dispatch(fetchUsersData())
store.dispatch(fetchNotAllowedUsersData())
store.dispatch(fetchMATData())
store.dispatch(fetchHPPEAData())
store.dispatch(fetchHPPEBData())
store.dispatch(fetchHPPECData())
store.dispatch(fetchNpzsData())
store.dispatch(fetchDOSAData())
store.dispatch(fetchDOSBData())
store.dispatch(fetchDOS2017AData())
store.dispatch(fetchDOS2017BData())
store.dispatch(fetchITDOSAData())
store.dispatch(fetchITDOSBData())
store.dispatch(fetchBojaAData())
store.dispatch(fetchBojaBData())
store.dispatch(fetchSCData())
store.dispatch(fetchNKUZData())
store.dispatch(fetchNKUSESData())
store.dispatch(fetchNSISData())
store.dispatch(fetchNKData())
store.dispatch(fetchPovData())
store.dispatch(fetchPrevodData())
store.dispatch(fetchPrevodAData())
store.dispatch(fetchJezikData())
store.dispatch(fetchOzJezikData())
store.dispatch(fetchE6MEData())
store.dispatch(fetchE6DHPData())

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//// // // // console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
