import React from 'react'
import { Editing, SearchPanel, Column } from 'devextreme-react/tree-list'
import NoviJezik from '../Jezik/NoviJezik'
import ServerErrorPage from '../../../../Pages/DodatneStranice/ServerErrorPage'
import { DataGrid, SelectBox } from 'devextreme-react'
import { Button as SmplBtn } from 'devextreme-react/button'
import { connect } from 'react-redux'
import '../../../../Styles/jezik.css'
import axios from 'axios'
import ImageIcon from '../../Items/ImageIcon'
class PregledJezika extends React.Component {
  constructor(props) {
    super(props)
    this.state = { jezik: 'Bosanski' }
    this.onJChanged = this.onJChanged.bind(this)
    this.prevodi =
      this.props.prevod_a.item2[this.props.prevod_a.item1.indexOf('Bosanski')]
    this.makeTimer()
    this.onEditingStart = this.onEditingStart.bind(this)
    this.onEditCanceled = this.onEditCanceled.bind(this)
    this.onSaved = this.onSaved.bind(this)
  }
  makeTimer() {
    setInterval(() => {
      this.prevodi =
        this.props.prevod_a.item2[
          this.props.prevod_a.item1.indexOf(this.state.jezik)
        ]
    }, 1000)
  }
  zavrsiJezik = async () => {
    axios.put(`https://api.gat-crm.com:1121/zavrsi_jezik/${this.state.jezik}`)
    //.then((resp) => // // console.log("Gotov jezik"));
  }
  onJChanged(e) {
    this.prevodi =
      this.props.prevod_a.item2[this.props.prevod_a.item1.indexOf(e.value)]
    this.setState({ jezik: e.value })

    // // // // console.log("prevod", this.props.prevod_a);
  }
  onEditingStart = async (e) => {
    // // // // console.log("Editing");
  }
  onEditCanceled = (e) => {
    // // // // console.log(e.value, "Edit canceled");
  }

  onSaved = async (e) => {
    this.putPrevodAdmin(e.changes[0])
  }
  onRowUpdating = async (e) => {
    // // // // console.log(e.key, "updated");
  }
  render() {
    const { prevod, oz_j } = this.props
    if (!this.props.prevod_a) return <ServerErrorPage />
    return (
      <div id="jezik">
        <NoviJezik prevod={prevod} oz_j={oz_j} />
        <SelectBox
          dataSource={this.props.prevod_a.item1}
          value={this.state.jezik}
          onValueChanged={this.onJChanged}
          dropDownButtonRender={ImageIcon}
          placeholder={
            prevod &&
            prevod.length !== 0 &&
            prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                36
            ]
              ? prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    36
                ].item2
              : 'Izaberite jezik za prevod...'
          }
        />{' '}
        <br /> <br />
        <DataGrid
          id="tabela-prevoda"
          dataSource={this.prevodi}
          showBorders={true}
          onEditorPreparing={this.onEditorPreparing}
          onInitNewRow={this.onInitNewRow}
          onRowRemoving={this.onRowRemoving}
          onRowUpdating={this.onRowUpdating}
          onRowUpdated={this.onRowUpdated}
          //selectedRowKeys={this.state.selectedItemKeys}
          //onSelectionChanged={this.selectionChanged}
          onEditingStart={this.onEditingStart}
          onEditCanceled={this.onEditCanceled}
          onSaved={this.onSaved}
          onSaving={this.onSaving}
          searchEnabled={true}
          noDataText={
            prevod &&
            prevod.length !== 0 &&
            prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                179
            ]
              ? prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    179
                ].item2
              : 'Nema rečenica za prevod!'
          }
        >
          <SearchPanel visible={true} />

          <Editing allowUpdating={true} mode="row" useIcons={true} />
          <Column
            dataField="k9kcpot"
            caption={
              prevod &&
              prevod.length !== 0 &&
              prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  180
              ]
                ? prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      180
                  ].item2
                : 'Bosanski jezik'
            }
            width={300}
          />
          <Column
            dataField="k9kcppt"
            caption={
              `${this.state.jezik} ` +
              /*(prevod &&
              prevod.length !== 0 &&
              prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  181
              ]
                ? prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      181
                  ].item2
                : */ 'jezik'
            }
          />
        </DataGrid>
        <br /> <br />
        <div className="dx-field">
          <SmplBtn
            id="end_lng_btn"
            useSubmitBehavior={true}
            text={
              prevod &&
              prevod.length !== 0 &&
              prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  37
              ]
                ? prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      37
                  ].item2
                : 'Završi jezik'
            }
            onClick={() => this.zavrsiJezik()}
          ></SmplBtn>
        </div>
        {/* <button
          style={{ float: "right" }}
          className="k-button"
          onClick={() => this.zavrsiJezik()}
        >
          Završi jezik
      </button>*/}
        <br />
        <br />
        <br />
      </div>
    )
  }
  putPrevodAdmin = (prevod) => {
    if (!prevod) {
      this.props.prevod &&
      this.props.prevod.length !== 0 &&
      this.props.prevod[
        this.props.oz_j.indexOf(localStorage.getItem('lng')) *
          (this.props.prevod.length / this.props.oz_j.length) +
          180
      ]
        ? alert(
            this.props.prevod[
              this.props.oz_j.indexOf(localStorage.getItem('lng')) *
                (this.props.prevod.length / this.props.oz_j.length) +
                180
            ].item2
          )
        : alert('Ne smije biti prazno polje!')
    } else {
      const formData = new FormData()
      // // // // console.log(prevod);
      formData.append('prevod', prevod.data.k9kcppt)
      axios.put(
        `https://api.gat-crm.com:1121/admin/prevod/${prevod.key.k9kcpid}`,
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
      )

      // // // // console.log("PREVOD", prevod);
    }
  }
}
const mapStateToProps = (state) => {
  return {
    prevod_a: state.prevod_a,
    jezik: state.jezik,
    prevod: state.prevod,
    oz_j: state.oz_j,
  }
}

export default connect(mapStateToProps, null)(PregledJezika)
