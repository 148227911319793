import React from 'react'
import { connect } from 'react-redux'
import { post } from 'axios'
import { Button } from 'devextreme-react/button'
import { IconButton } from '@material-ui/core'
import { ProgressBar } from 'react-bootstrap'
import { PhotoCamera } from '@material-ui/icons'
import { Popup } from 'devextreme-react'
import '../../../../Styles/uploadDrawStyle.css'
import LoadingCrteza from './LoadingCrteza'

class UploadCrteza extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      now: 0,
      id: null,
      file: null,
      bc: null,
      shownPoruka1: false,
      shownPoruka2: false,
    }
    this.onShowPoruka1 = this.onShowPoruka1.bind(this)
    this.onShowPoruka2 = this.onShowPoruka2.bind(this)
    this.onShowPoruka3 = this.onShowPoruka3.bind(this)
    this.onHidePoruka1 = this.onHidePoruka1.bind(this)
    this.onHidePoruka2 = this.onHidePoruka2.bind(this)

    this.onHidePoruka3 = this.onHidePoruka3.bind(this)
  }

  onShowPoruka1() {
    this.setState({ shownPoruka1: true })
  }
  onShowPoruka2() {
    this.setState({ shownPoruka2: true })
  }
  onShowPoruka3() {
    this.setState({ shownPoruka3: true })
  }
  onHidePoruka1() {
    this.setState({ shownPoruka1: false })
  }
  onHidePoruka2() {
    this.setState({ shownPoruka2: false })
  }
  onHidePoruka3() {
    this.setState({ shownPoruka3: false })
  }
  submit = async (e) => {
    this.setState({ file: e.target.files[0] })
    if (
      e.target.files[0].type !== 'application/pdf' &&
      e.target.files[0].type.split('/')[0] !== 'image'
    ) {
      this.onShowPoruka1()
    } else if (!this.props.props.bc || this.props.props.bc === '')
      this.onShowPoruka2()
    else {
      this.setState({ loading: true, now: this.state.now + 20 })
      // // // // console.log(this.state.name);
      const url = `https://api.gat-crm.com:1121/upload`
      const formData = new FormData()
      formData.append('body', e.target.files[0])
      formData.append('id', this.props.props.id)
      formData.append('bc', this.props.props.bc)
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
      return post(url, formData, config)
        .then((resp) => {
          this.setState({ loading: false, now: 100 })
          this.onShowPoruka3()
          this.props.parentCallback(true, e.target.files[0].name)
        })
        .catch((err) => this.setState({ loading: false, now: 0 }))
    }
  }

  componentDidMount = () => {
    setTimeout(() => {
      if (this.props.props.crtez) {
        this.setState({ name: this.props.props.crtez.k9kccnc })
      }
    }, 100)
  }

  render() {
    const { prevod, oz_j } = this.props
    const { file } = this.state

    return (
      <div
        id="upload_draw"
        style={{
          display: 'flex',
        }}
        className={
          /* this.state.now > 0 && this.state.now < 100
            ? 'progress_true'
            :*/ 'progress_false'
        }
      >
        {this.state.loading ? <LoadingCrteza /> : ''}
        <Popup
          shadingColor="rgba(0, 0, 0, 0.5)"
          container=".dx-viewport"
          visible={this.state.shownPoruka1}
          onHiding={this.onHidePoruka1}
          dragEnabled={false}
          closeOnOutsideClick={true}
          showTitle={true}
          title={
            prevod &&
            prevod.length !== 0 &&
            prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                132
            ]
              ? prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    132
                ].item2
              : 'Pogrešan format crteža!'
          }
          width={400}
          height={200}
        ></Popup>{' '}
        <Popup
          shadingColor="rgba(0, 0, 0, 0.5)"
          container=".dx-viewport"
          visible={this.state.shownPoruka2}
          onHiding={this.onHidePoruka2}
          dragEnabled={false}
          closeOnOutsideClick={true}
          showTitle={true}
          title={
            prevod &&
            prevod.length !== 0 &&
            prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                248
            ]
              ? prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    248
                ].item2
              : 'Unesite broj crteža'
          }
          width={400}
          height={200}
        ></Popup>{' '}
        <Popup
          shadingColor="rgba(0, 0, 0, 0.5)"
          container=".dx-viewport"
          visible={this.state.shownPoruka3}
          onHiding={this.onHidePoruka3}
          dragEnabled={false}
          closeOnOutsideClick={true}
          showTitle={true}
          title={
            file && file.name ? 'Dodan crtež' + ' ' + file.name : 'Dodan crtež'
          }
          width={400}
          height={200}
        ></Popup>
        <form style={{ margin: '0 !important' }}>
          <label htmlFor="files" id="label" style={{ margin: '0 !important' }}>
            <Button
              text={
                prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    247
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        247
                    ].item2
                  : 'Upload crteža'
              }
              disabled={this.props.props.bc ? false : true}
            />
          </label>
          <input
            style={{ display: 'none' }}
            type="file"
            id="files"
            onChange={this.submit}
          />
        </form>{' '}
        {/*<ProgressBar
          now={this.state.now}
          label={`${this.state.now}%`}
          variant="success"
        />*/}
      </div>
    )
  }
}

/* <form onSubmit={(e) => this.submit(e)}>
    <Button variant="contained" component="label" type="submit">
      Upload crteža
      <input type="file" onChange={(e) => this.setFile(e)} />
    </Button>
      </form>*/
const mapStateToProps = (state) => {
  return {
    prevod: state.prevod,
    jezik: state.jezik,
    oz_j: state.oz_j,
  }
}

export default connect(mapStateToProps, null)(UploadCrteza)
