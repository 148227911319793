import React, { Component } from 'react'
import LogoutIcon from '../../Images/logoutIcon.png'
export default class OdjavaButton extends Component {
  constructor(props) {
    super(props)
    this.odjava = this.odjava.bind(this)
  }
  odjava() {
    localStorage.removeItem('token')
    localStorage.removeItem('nmx')
    if (localStorage.getItem('a')) localStorage.removeItem('a')
    if (localStorage.getItem('sk')) localStorage.removeItem('sk')
    if (localStorage.getItem('siid')) localStorage.removeItem('siid')
    if (localStorage.getItem('hppo')) localStorage.removeItem('hppo')
    if (localStorage.getItem('n_k')) localStorage.removeItem('n_k')
    window.location.href = window.location.origin //"http://localhost:3000/";
  }
  render() {
    return (
      <button
        id="logout_btn"
        onClick={this.odjava}
        style={{
          position: 'absolute',

          top: '15px',
          marginBottom: '30px',
          zIndex: '9999',
          border: 'none',
          outline: 'none',
          backgroundColor: 'transparent',
        }}
      >
        <img src={LogoutIcon} alt="odjava" width="20px" />
      </button>
    )
  }
}
