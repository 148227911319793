import { REQUEST_HPPE_A } from "../../../const";

export default function HPPEAReducer(state = [], action) {
  switch (action.type) {
    case REQUEST_HPPE_A:
      return action.hppe_a;
    default:
      return state;
  }
}
