import { NOTALLOWED, REQUEST_NOT_ALLOWED_USERS } from "../../const";
import axios from "axios";

export const fetchNotAllowedData = (notallowed) => {
  return {
    type: REQUEST_NOT_ALLOWED_USERS,
    notallowed,
  };
};

export const fetchNotAllowedUsersData = () => {
  return (dispatch) => {
    return axios
      .get(NOTALLOWED)
      .then((response) => {
        dispatch(fetchNotAllowedData(response.data));
        //// // // // console.log(response.data)
      })
      .catch((error) => {
        //// // // // console.log("err")
        throw error;
      });
  };
};
