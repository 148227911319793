var CryptoJS = require('crypto-js')

const asyncLocalStorage = {
  setItem: function (key, value) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value)
    })
  },
  getItem: function (key) {
    return Promise.resolve().then(function () {
      return localStorage.getItem(key)
    })
  },
}

export const tokenization = async (tkn, id, admin, siid, message) => {
  asyncLocalStorage
    .setItem('token', tkn)
    .then(function () {
      return asyncLocalStorage.getItem('token')
    })
    .then(function (value) {
      if (admin) localStorage.setItem('a', tkn)
      admin
        ? (window.location.href = window.location.origin + '/#/adm')
        : (window.location.href = window.location.origin + '/#/user')

      localStorage.setItem(
        'nmx',
        CryptoJS.AES.encrypt(JSON.stringify(id), 'Adna1234').toString()
      )
      // šifra korisnika u si tabeli ( ako takva postoji )
      if (siid !== '')
        localStorage.setItem(
          'sk',
          CryptoJS.AES.encrypt(JSON.stringify(siid), 'I1v4is5').toString()
        )
    })
}
