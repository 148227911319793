import { REQUEST_NK } from "../../const";

export default function NkReducer(state = [], action) {
  switch (action.type) {
    case REQUEST_NK:
      return action.nk;
    default:
      return state;
  }
}
