import React from 'react'
import axios from 'axios'
import {
  NumberBox,
  Button,
  CheckBox,
  SelectBox,
  TextBox,
  Popup,
} from 'devextreme-react'
import { Tooltip } from 'devextreme-react/tooltip'
import { connect } from 'react-redux'
import 'devextreme-react/autocomplete'
import { getEUROuKM, getKMuEURO } from '../Cijena/constCijena'
import ReportPom from '../../Download/ReportPom'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

import ReactDOMServer from 'react-dom/server'
import { confirmAlert } from 'react-confirm-alert'
import '../../../Styles/confirmStyle.css'
import { API } from '../../../const'
const CryptoJS = require('crypto-js')

var pdf
const valute = ['KM', 'EUR']
class Narudzba extends React.Component {
  constructor(props) {
    super(props)
    ////// // // // console.log(this.props)
    this.state = {
      kolicina: this.props.kolicina,
      sifra: this.props.sifra,
      popupVisible: false,
      popup_Shown: 0,
      km_u_e: 1,
      e_u_km: 1,
      cijena: this.props.cijena,
      pdf_ukljucen: false,
      valuta: this.props.valuta,
      toggle_n: false,
    }

    this.childRef = React.createRef()
    this.onVValueChanged = this.onVValueChanged.bind(this)
    this.onCValueChanged = this.onCValueChanged.bind(this)
    this.onPDFValueChanged = this.onPDFValueChanged.bind(this)
    this.hideInfo = this.hideInfo.bind(this)
    this.toggleN = this.toggleN.bind(this)
  }

  toggleN(e) {
    this.setState({ toggle_n: !this.state.toggle_n })
  }
  naruci = async () => {
    let prevod = this.props.prevod
    let oz_j = this.props.oz_j
    await axios
      .request({
        method: 'get',
        url: API + '/provjera_cijene/' + this.props.id + '/' + this.props.bk,
      })
      .then((resp) => {
        if (resp && resp.data && resp.data != -100) {
          confirmAlert({
            title:
              prevod &&
              prevod.length !== 0 &&
              prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  335
              ]
                ? prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      335
                  ].item2
                : 'Promijenjena cijena',
            message: (
              <>
                <p>
                  {' '}
                  {prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      336
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          336
                      ].item2
                    : 'Jeste li sigurni da želite naručiti artikal po novoj cijeni?'}
                </p>
                <p>
                  {' '}
                  {prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      339
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          339
                      ].item2
                    : 'Stara cijena artikla za dati broj komada: '}
                  {parseFloat(this.props.cijena).toFixed(2)}
                </p>
                <p>
                  {' '}
                  {prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      340
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          340
                      ].item2
                    : 'Nova cijena artikla za dati broj komada: '}
                  {parseFloat(resp.data).toFixed(2)}{' '}
                </p>{' '}
              </>
            ),
            buttons: [
              {
                label:
                  prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      199
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          199
                      ].item2
                    : 'Da',
                onClick: () => {
                  this.setState({ cijena: resp.data })
                  // console.log(this.props.cijena)
                  //this.props.cijena = resp.data
                  //console.log(this.props.cijena)
                  const u = this.props.upit
                  if (
                    parseFloat(
                      parseFloat(resp.data).toFixed(2) * this.props.bk
                    ) < 20
                  ) {
                    this.setState({ popup: true })
                  } else {
                    setTimeout(() => {
                      axios
                        .post(
                          'https://api.gat-crm.com:1121/updateNarudzba/' +
                            u.id +
                            '/' +
                            this.props.bk +
                            '/' +
                            true
                        )
                        .then((resp) => {
                          alert(
                            prevod &&
                              prevod.length !== 0 &&
                              prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  252
                              ]
                              ? prevod[
                                  oz_j.indexOf(localStorage.getItem('lng')) *
                                    (prevod.length / oz_j.length) +
                                    252
                                ].item2
                              : 'Narudžba poslana'
                          )
                          if (this.state.pdf_ukljucen) {
                            this.childRef.current.style.visibility = 'visible'

                            const input = this.childRef.current
                            window.html2canvas = html2canvas

                            const pdf = new jsPDF({
                              orientation: 'p',
                              unit: 'px',
                              format: [960, 648],
                            })

                            pdf
                              .html(input, {
                                html2canvas: {
                                  scale: 1,
                                  scrollY:
                                    window.pageYOffset /
                                    Math.pow(
                                      10,
                                      window.pageYOffset.toString().length > 0
                                        ? window.pageYOffset.toString().length -
                                            1
                                        : 0
                                    ),
                                  scrollX:
                                    window.pageXOffset /
                                    Math.pow(
                                      10,
                                      window.pageXOffset.toString().length > 0
                                        ? window.pageXOffset.toString().length -
                                            1
                                        : 0
                                    ),
                                  width: 459,
                                },
                                callback: (doc) => {
                                  doc.save(
                                    `${u.siid.toString().substr(3)}-${new Date()
                                      .getFullYear()
                                      .toString()
                                      .substr(-2)}` + '.pdf'
                                  )
                                },
                              })
                              .then(() => {
                                this.childRef.current.style.visibility =
                                  'hidden'
                              })
                          }
                        })
                        .catch((err) => console.log(err))
                    }, 1000)
                  }
                },
              },
              {
                label: prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    200
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        200
                    ].item2
                  : 'Ne',
              },
            ],
          })
        } else {
          const u = this.props.upit
          this.setState({ cijena: this.props.cijena })
          setTimeout(() => {
            if (
              parseFloat(
                parseFloat(this.state.cijena).toFixed(2) * this.props.bk
              ) < 20
            ) {
              this.setState({ popup: true })
            } else {
              let prevod = this.props.prevod
              let oz_j = this.props.oz_j

              axios
                .post(
                  'https://api.gat-crm.com:1121/updateNarudzba/' +
                    u.id +
                    '/' +
                    this.props.bk +
                    '/' +
                    false
                )
                .then((resp) => {
                  alert(
                    prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          252
                      ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            252
                        ].item2
                      : 'Narudžba poslana'
                  )
                  if (this.state.pdf_ukljucen) {
                    this.childRef.current.style.visibility = 'visible'

                    const input = this.childRef.current
                    window.html2canvas = html2canvas

                    const pdf = new jsPDF({
                      orientation: 'p',
                      unit: 'px',
                      format: [960, 648],
                    })

                    pdf
                      .html(input, {
                        html2canvas: {
                          scale: 1,
                          scrollY:
                            window.pageYOffset /
                            Math.pow(
                              10,
                              window.pageYOffset.toString().length > 0
                                ? window.pageYOffset.toString().length - 1
                                : 0
                            ),
                          scrollX:
                            window.pageXOffset /
                            Math.pow(
                              10,
                              window.pageXOffset.toString().length > 0
                                ? window.pageXOffset.toString().length - 1
                                : 0
                            ),
                          width: 459,
                        },
                        callback: (doc) => {
                          doc.save(
                            `${u.siid.toString().substr(3)}-${new Date()
                              .getFullYear()
                              .toString()
                              .substr(-2)}` + '.pdf'
                          )
                        },
                      })
                      .then(() => {
                        this.childRef.current.style.visibility = 'hidden'
                      })
                  }
                })
                .catch((err) => console.log(err))
            }
          }, 1000)
        }
      })
  }
  /*
  Foo = (
    <div>
      <ReportPom
        adresa_komitenta={this.props.adresa_komitenta}
        br_komitenta={this.props.br_komitenta}
        // ref={this.childRef}
        prevod={this.props.prevod}
        oz_j={this.props.oz_j}
        lng={localStorage.getItem('lng')}
        podaci={{
          tabela: [
            {
              Rb: 1,
              vašaOznaka: this.props.bc,
              bc: this.props.nc.toUpperCase(),
              naziv: {
                na: this.props.na,

                sifra_recepta: this.props.sifra_recepta,
              },
              JM: 'KOM',
              bk: this.props.bk.toString() + ',00',
              cijena: parseFloat(this.state.cijena)
                .toFixed(2)
                .toString()
                .replace('.', ','),
              ukupnaCijena: parseFloat(
                parseFloat(this.state.cijena).toFixed(2) * this.props.bk
              )
                .toFixed(2)
                .toString()
                .replace('.', ','),
            },
          ],
          valuta: this.props.valuta,
          brKom: parseInt(
            JSON.parse(
              CryptoJS.AES.decrypt(
                localStorage.getItem('nmx'),
                'Adna1234'
              ).toString(CryptoJS.enc.Utf8)
            )
          ),
          kusid: `${this.props.id.toString().substr(3)}-${new Date()
            .getFullYear()
            .toString()
            .substr(-2)}`,
        }}
      />
    </div>
  )*/
  onCValueChanged(e) {
    this.setState({ cijena: e.value })
    //	//// // // // console.log('cijena', this.state.cijena)
  }
  onVValueChanged(e) {
    if (e.value === 'EUR' && this.state.valuta === 'KM') {
      this.setState({
        valuta: e.value,
        cijena: (
          parseFloat(this.state.cijena) * parseFloat(this.state.e_u_km)
        ).toString(),
      })
    } else {
      this.setState({
        valuta: e.value,
        cijena: (
          parseFloat(this.state.cijena) * parseFloat(this.state.km_u_e)
        ).toString(),
      })
    }
  }
  onPDFValueChanged(e) {
    this.setState({ pdf_ukljucen: e.value })
  }
  componentDidMount = async () => {
    try {
      const [km_u_e, e_u_km] = await axios.all([getKMuEURO(), getEUROuKM()])
      //	//// // // // console.log("kursevi",km_u_e.data) //,km_u_d.data,e_u_km.data,d_u_km.data
      this.setState({
        km_u_e: km_u_e.data[0],
        e_u_km: e_u_km.data[0],
        cijena: this.props.cijena,
      })
    } catch (err) {
      ////// // // // console.log("ERROR",err)
    }
  }
  render() {
    //let { cijena } = this.state
    const { prevod, oz_j } = this.props
    return (
      <div id="cijena_footer">
        <div className="dx-fieldset">
          <div
            className="dx-field"
            id="naruci"
            onMouseEnter={this.toggleN}
            onMouseLeave={this.toggleN}
          >
            <Popup
              shadingColor="rgba(0, 0, 0, 0.5)"
              container=".dx-viewport"
              visible={this.state.popup}
              onHiding={() => this.setState({ popup: false })}
              dragEnabled={false}
              closeOnOutsideClick={true}
              width={500}
              height={200}
              showTitle={true}
              title={
                this.props.cijena == '0' &&
                this.props.prevod &&
                this.props.prevod.length !== 0 &&
                this.props.prevod[
                  this.props.oz_j.indexOf(localStorage.getItem('lng')) *
                    (this.props.prevod.length / this.props.oz_j.length) +
                    249
                ]
                  ? this.props.prevod[
                      this.props.oz_j.indexOf(localStorage.getItem('lng')) *
                        (this.props.prevod.length / this.props.oz_j.length) +
                        249
                    ].item2
                  : this.props.prevod &&
                    this.props.prevod.length !== 0 &&
                    this.props.prevod[
                      this.props.oz_j.indexOf(localStorage.getItem('lng')) *
                        (this.props.prevod.length / this.props.oz_j.length) +
                        227
                    ]
                  ? this.props.prevod[
                      this.props.oz_j.indexOf(localStorage.getItem('lng')) *
                        (this.props.prevod.length / this.props.oz_j.length) +
                        227
                    ].item2
                  : 'Nemoguće naručiti za ovu količinu proizvoda!'
              }
            />
            <Button
              text={
                prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    76
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        76
                    ].item2
                  : 'Naruči'
              }
              type="default"
              useSubmitBehavior={true}
              onClick={this.naruci}
              style={{ marginRight: '1vw' }}
            />

            <CheckBox
              text={
                prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    74
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        74
                    ].item2
                  : 'Spremiti kao pdf'
              }
              value={this.state.pdf_ukljucen}
              onValueChanged={this.onPDFValueChanged}
            />
            <Tooltip
              target="#naruci"
              position="left"
              visible={this.state.toggle_n}
              closeOnOutsideClick={false}
            >
              <div>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    240
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        240
                    ].item2
                  : 'Naruči ili spremi kao PDF dati upit'}
              </div>
            </Tooltip>
          </div>
        </div>
        <div
          id="a"
          style={{ position: 'absolute', top: '0 !important', left: '-3000px' }}
        >
          <div
            id="abc"
            style={{ marginTop: '0 !important', paddingTop: '0 !important' }}
          >
            <ReportPom
              adresa_komitenta={this.props.adresa_komitenta}
              br_komitenta={this.props.br_komitenta}
              ref={this.childRef}
              prevod={this.props.prevod}
              oz_j={this.props.oz_j}
              lng={localStorage.getItem('lng')}
              podaci={{
                tabela: [
                  {
                    Rb: 1,
                    vašaOznaka: this.props.bc,
                    bc: this.props.nc.toUpperCase(),
                    naziv: {
                      na: this.props.na,

                      sifra_recepta: this.props.sifra_recepta,
                    },
                    JM: 'KOM',
                    bk: this.props.bk.toString() + ',00',
                    cijena: parseFloat(this.state.cijena)
                      .toFixed(2)
                      .toString()
                      .replace('.', ','),
                    ukupnaCijena: parseFloat(
                      parseFloat(this.state.cijena).toFixed(2) * this.props.bk
                    )
                      .toFixed(2)
                      .toString()
                      .replace('.', ','),
                  },
                ],
                valuta: this.props.valuta,
                brKom: parseInt(
                  JSON.parse(
                    CryptoJS.AES.decrypt(
                      localStorage.getItem('nmx'),
                      'Adna1234'
                    ).toString(CryptoJS.enc.Utf8)
                  )
                ),
                kusid: `${this.props.id.toString().substr(3)}-${new Date()
                  .getFullYear()
                  .toString()
                  .substr(-2)}`,
              }}
            />
          </div>
        </div>
      </div>
    )
  }
  exportPDFWithComponent = async (e) => {
    pdf.save()
  }
  hideInfo() {
    this.setState({
      popupVisible: false,
    })
  }
}

const mapStateToProps = (state) => {
  return {
    prevod: state.prevod,
    jezik: state.jezik,
    oz_j: state.oz_j,
  }
}

export default connect(mapStateToProps, null)(Narudzba)
/*
          let gmail = 'mubina.ibrisagic@gat.com.ba'
          let component = ` Poštovani,%0D%0A

            Molimo vas za ponudu za površinsku za: %0D%0A %0D%0A
            Podaci o proizvodu:  %0D%0A %0D%0A
        Broj artikla: ${u.ba}, %0D%0A %0D%0A
        Broj crteža: ${u.bc}, %0D%0A %0D%0A
        Materijal: EN AW ${u.m}, %0D%0A %0D%0A
        Površina jednog komada: ${u.p}, %0D%0A %0D%0A
        Dimenzioni kriterij: ${u.t},  %0D%0A %0D%0A
        Slijepe rupe/navoji manje od Φ5: ${u.sr ? 'DA' : 'NE'},  %0D%0A %0D%0A
        Način kačenja: ${u.nk},  %0D%0A %0D%0A
        Broj komada: ${u.bk},  %0D%0A %0D%0A
        Način površinske zaštite: ${u.npz},   %0D%0A %0D%0A
        Hemijska predobrada prije eloksiranja: ${u.hppo}, %0D%0A %0D%0A
        ${u.hMin ? `Minute: ${u.hMin}, %0D%0A %0D%0A` : ''}
        Dodatno hemijsko poliranje( E7 ): ${
          u.hppoD ? 'DA' : 'NE'
        }, %0D%0A %0D%0A
        Debljina oksidnog sloja: ${u.dos}, %0D%0A %0D%0A
        Boja: ${u.b}, %0D%0A %0D%0A %0D%0A %0D%0A 
        
        `
          setTimeout(
            () =>
              (window.location.href = `mailto:${gmail}?subject=Upit&body=${component}`),
            2000
          )
        })*/
