import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import FadeIn from 'react-fade-in'
import '../../Styles/registerWaitStyle.css'
import RegisterWait1 from '../../Images/RegisterWait1.JPG'
import RegisterWait2 from '../../Images/RegisterWait2.JPG'
import RegisterWait3 from '../../Images/RegisterWait3.JPG'
const RegisterDonePage = (props) => {
  const [picture, setPicture] = useState('')
  useEffect((props) => {
    return () => {
      props.history.push({ pathname: '/' })
    }
  }, [])
  const handleMouseEnter = (e) => {
    setPicture(e.target.id)
  }
  const handleMouseLeave = (e) => {
    setPicture('')
  }
  return (
    <div id="register_wait">
      <div id="register_wait_images_wrapper">
        <div id="register_wait_image_container_1">
          <img
            src={RegisterWait2}
            width="400px"
            id="image2"
            alt="eloksiranje_slika2"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={
              picture === 'image2'
                ? {
                    opacity: '0.6',
                  }
                : { opacity: '1' }
            }
          />
        </div>
        <div id="register_wait_image_container_2">
          <div>
            <img
              src={RegisterWait1}
              width="400px"
              id="image1"
              alt="eloksiranje_slika1"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={
                picture === 'image1'
                  ? {
                      opacity: '0.6',
                    }
                  : { opacity: '1' }
              }
            />
            <img
              src={RegisterWait3}
              width="400px"
              id="image3"
              alt="eloksiranje_slika3"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={
                picture === 'image3'
                  ? {
                      opacity: '0.6',
                    }
                  : { opacity: '1' }
              }
            />
          </div>
        </div>
      </div>
      <div id="register_wait_text_container">
        <FadeIn>
          {' '}
          <h1>
            {props.prevod &&
            props.prevod.length !== 0 &&
            props.prevod[
              props.oz_j.indexOf(localStorage.getItem('lng')) *
                (props.prevod.length / props.oz_j.length) +
                150
            ]
              ? props.prevod[
                  props.oz_j.indexOf(localStorage.getItem('lng')) *
                    (props.prevod.length / props.oz_j.length) +
                    150
                ].item2
              : 'Hvala Vam što koristite GAT kalkulator za uslugu eloksiranja!'}
          </h1>
        </FadeIn>

        <div id="register_wait_text">
          {props.prevod &&
          props.prevod.length !== 0 &&
          props.prevod[
            props.oz_j.indexOf(localStorage.getItem('lng')) *
              (props.prevod.length / props.oz_j.length) +
              149
          ]
            ? props.prevod[
                props.oz_j.indexOf(localStorage.getItem('lng')) *
                  (props.prevod.length / props.oz_j.length) +
                  149
              ].item2
            : 'Molimo Vas da sačekate odobrenje registracije. Odobrenje će Vam stići na Vašu email adresu, nakon toga ćete imati pristup. Dok čekate potvrdu, možete posjetiti našu oficijelnu stranicu!'}
          <br />
          <a href="https://www.gat-cnc.com" target="_blank" rel="noreferrer">
            {props.prevod &&
            props.prevod.length !== 0 &&
            props.prevod[
              props.oz_j.indexOf(localStorage.getItem('lng')) *
                (props.prevod.length / props.oz_j.length) +
                131
            ]
              ? props.prevod[
                  props.oz_j.indexOf(localStorage.getItem('lng')) *
                    (props.prevod.length / props.oz_j.length) +
                    131
                ].item2
              : 'Idi na oficijelnu stranicu GAT-a'}
          </a>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    prevod: state.prevod,
    jezik: state.jezik,
    oz_j: state.oz_j,
  }
}

export default connect(mapStateToProps, null)(RegisterDonePage)
