import React from 'react'
import TextBox from 'devextreme-react/text-box'
import jednostavno from '../../../Images/jednostavno.JPG'
import srednje from '../../../Images/srednje.JPG'
import slozeno from '../../../Images/slozeno.JPG'
import axios from 'axios'
import { useState } from 'react'
import { useEffect } from 'react'
import { OZNAKE_JEZIKA, PREVOD } from '../../../const'
function ImageFieldF({ data }) {
  const [prevod, setPrevod] = useState([])
  const [oz_j, setOzJ] = useState([])

  useEffect(() => {
    axios.get(PREVOD).then((resp) => setPrevod(resp.data))
    axios.get(OZNAKE_JEZIKA).then((resp) => setOzJ(resp.data))
  }, [])
  return data && data.item2 === 'Jednostavno' ? (
    <div className=" image-field">
      <img src={jednostavno} className=" images" alt="jednostavno" />
      <TextBox
        className="product-name"
        value={
          prevod &&
          prevod.length !== 0 &&
          prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              67
          ]
            ? prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  67
              ].item2
            : 'Jednostavno '
        }
      />
    </div>
  ) : data && data.item2 === 'Srednje' ? (
    <div className=" image-field">
      <img src={srednje} className=" images" alt="srednje" />
      <TextBox
        className="product-name"
        value={
          prevod &&
          prevod.length !== 0 &&
          prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              68
          ]
            ? prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  68
              ].item2
            : 'Srednje'
        }
      />
    </div>
  ) : (
    <div className=" image-field">
      <img src={slozeno} className=" images" alt="slozeno" />
      <TextBox
        className="product-name"
        value={
          prevod &&
          prevod.length !== 0 &&
          prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              69
          ]
            ? prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  69
              ].item2
            : 'Složeno'
        }
      />
    </div>
  )
}
const ImageField = (data) => <ImageFieldF data={data} />
/*= (data)=> {
  console.log(data.item2)
  return (
    <div className=" image-field">
      {data && data.item2 === 'Jednostavno' ? (
        <img src={jednostavno} className=" images" alt="jednostavno" />

      ) : data && data.item2 === 'Srednje' ? (
        <img src={srednje} className=" images" alt="srednje" />
      ) : (
        <img src={slozeno} className=" images" alt="slozeno" />
      )}
      <TextBox className="product-name" defaultValue={data && data.item2} />
    </div>
  )
}
*/
export default ImageField
