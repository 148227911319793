import axios from 'axios'

const KMuEURO = 'https://api.gat-crm.com:1121/kurs/KMuEuro'
const KMuUSD = 'https://api.gat-crm.com:1121/kurs/KMuUSD'
const EUROuKM = 'https://api.gat-crm.com:1121/kurs/EUROuKM'
const USDuKM = 'https://api.gat-crm.com:1121/kurs/USDuKM'

export const getKMuEURO = () => axios.get(KMuEURO)

export const getKMuUSD = () => axios.get(KMuUSD)

export const getEUROuKM = () => axios.get(EUROuKM)

export const getUSDuKM = () => axios.get(USDuKM)
