import React, { useEffect } from 'react'
import NotFoundErrorPage from '../DodatneStranice/NotFoundErrorPage'
import TreeView from 'devextreme-react/tree-view'
import '../../Styles/adminStyle.css'
import OdjavaButton from '../../Components/DevExtremeComponents/OdjavaButton.js'
import KorisnikLista from '../../Components/DevExtremeComponents/AdminPanel/KorisnikLista/KorisnikLista'
import PregledJezika from '../../Components/DevExtremeComponents/AdminPanel/PregledJezika/PregledJezika'
import AdminProfilePage from './AdminProfilePage'
import Crtez from '../../Components/DevExtremeComponents/AdminPanel/PrikazCrteza/Crtez'
import ListaUpitiINarudzbe from '../../Components/DevExtremeComponents/AdminPanel/ListaUpitiINarudzbe/ListaUpitiINarudzbe'
import AdminPanelUpitPage from './AdminPanelUpitPage'
import { useState } from 'react'
import { OZNAKE_JEZIKA, PREVOD } from '../../const'
import axios from 'axios'

export default function Admin() {
  const [prevod, setPrevod] = useState([])
  const [oz_j, setOzJ] = useState([])
  useEffect(() => {
    axios.get(PREVOD).then((resp) => setPrevod(resp.data))
    axios.get(OZNAKE_JEZIKA).then((resp) => setOzJ(resp.data))
  }, [])
  const [adminData, setAdminData] = useState({
    id: '1',
    text: 'Slanje upita',
    component: <KorisnikLista />,
    expanded: true,
    selected: true,
  })

  return (
    <div>
      {localStorage.getItem('a') ? (
        <div id="admin_panel">
          <div id="admin_panel_header">
            <OdjavaButton />
          </div>
          <div className="container">
            <div className="left-content">
              <TreeView
                dataSource={[
                  {
                    id: '1',
                    text:
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          119
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              119
                          ].item2
                        : 'Korisnici',
                    component: <KorisnikLista />,
                    expanded: true,
                    selected: true,
                  },
                  {
                    id: '2',
                    text:
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          120
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              120
                          ].item2
                        : 'Jezici',
                    component: <PregledJezika />,
                  },
                  {
                    id: '3',
                    text:
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          121
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              121
                          ].item2
                        : 'Profil',
                    component: <AdminProfilePage />,
                  },
                  {
                    id: '4',
                    text:
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          253
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              253
                          ].item2
                        : 'Crtež',
                    component: <Crtez />,
                  },
                  {
                    id: '5',
                    text:
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          259
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              259
                          ].item2 +
                          '/' +
                          prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              225
                          ].item2
                        : 'Upiti/Narudzbe',
                    component: (
                      <ListaUpitiINarudzbe prevod={prevod} oz_j={oz_j} />
                    ),
                  },
                  {
                    id: '6',
                    text:
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          225
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              225
                          ].item2
                        : 'Upiti',
                    component: <AdminPanelUpitPage />,
                  },
                ]}
                selectionMode="single"
                selectByClick={true}
                onItemSelectionChanged={(e) => {
                  setAdminData(e.itemData)
                }}
              />
            </div>
            <div className="right-content"> {adminData.component} </div>
          </div>
        </div>
      ) : (
        <NotFoundErrorPage />
      )}
    </div>
  )
}
/*
  handleTreeViewSelectionChange(e) {
    this.setState({
      tabPanelIndex: 0,
      adminData: e.itemData,
    })

    ////// // // // console.log(e)
  }

  handleTabPanelSelectionChange(e) {
    this.setState({
      tabPanelIndex: e.value,
    })
  }*/
