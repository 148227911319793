import { REQUEST_M, M } from "../../const";
import axios from "axios";

export const fetchMData = (m_list) => {
  return {
    type: REQUEST_M,
    m_list,
  };
};

export const fetchMATData = () => {
  return (dispatch) => {
    return axios
      .get(M)
      .then((response) => {
        dispatch(fetchMData(response.data));
        //// // // // console.log("TU SAM" ,response.data)
      })
      .catch((error) => {
        //// // // // console.log("err")
        throw error;
      });
  };
};
