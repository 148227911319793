import { REQUEST_UPITI, UPITI } from "../../const";
import axios from "axios";
var CryptoJS = require("crypto-js");
export const fetchUpitData = (upiti) => {
  return {
    type: REQUEST_UPITI,
    upiti,
  };
};

export const fetchUpitiData = (nmx) => {
  return (dispatch) => {
    return axios
      .get(
        UPITI +
          parseInt(
            JSON.parse(
              CryptoJS.AES.decrypt(nmx, "Adna1234").toString(CryptoJS.enc.Utf8)
            )
          )
      )
      .then((response) => {
        dispatch(fetchUpitData(response.data));
        //// // // // console.log("UPITI",response.data)
      })
      .catch((error) => {
        //// // // // console.log("err")
        throw error;
      });
  };
};
