import React from 'react'
import axios from 'axios'
import {
  NumberBox,
  Button,
  CheckBox,
  SelectBox,
  TextBox,
} from 'devextreme-react'
import { connect } from 'react-redux'
import 'devextreme-react/autocomplete'
import { getEUROuKM, getKMuEURO } from '../Cijena/constCijena'
import ReportPom from '../../Download/ReportPom'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

import ReactDOMServer from 'react-dom/server'
const CryptoJS = require('crypto-js')

var pdf
const valute = ['KM', 'EUR']
class Footer extends React.Component {
  constructor(props) {
    super(props)
    ////// // // // console.log(this.props)
    this.state = {
      kolicina: this.props.kolicina,
      sifra: this.props.sifra,
      popupVisible: false,
      popup_Shown: 0,
      km_u_e: 1,
      e_u_km: 1,
      cijena: this.props.cijena,
      pdf_ukljucen: false,
      valuta: this.props.valuta,
    }

    this.childRef = React.createRef()
    this.onVValueChanged = this.onVValueChanged.bind(this)
    this.onCValueChanged = this.onCValueChanged.bind(this)
    this.onPDFValueChanged = this.onPDFValueChanged.bind(this)
    this.hideInfo = this.hideInfo.bind(this)
  }

  naruci = async () => {
    const u = this.props.upit
    // na 4 decimale zaokruziti cijenu i kad se narucuje
    if (this.state.pdf_ukljucen) {
      //pdf.save();

      this.childRef.current.style.visibility = 'visible'

      //let a = e.currentTarget.parentNode.getAttribute("key");
      //const input = document.getElementById("abc");
      //document.getElementById("abc").style.left = "auto";
      const input = this.childRef.current
      window.html2canvas = html2canvas
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'pt',
        format: [window.screen.width * 1.3, window.screen.height * 0.7],
      }) //({ unit: "px", format: "a4", userUnit: "px" });
      // // console.log(window);
      //pdf.deletePage(1);
      //pdf.setFontType("bold");
      pdf
        .html(input, {
          html2canvas: { scale: 1 },
          callback: (doc) => {
            // // console.log("Tu sam", doc.internal.getNumberOfPages());
            // doc.movePage(doc.internal.getNumberOfPages(), 1);
            for (let i = doc.internal.getNumberOfPages(); i > 1; i--) {
              //doc.deletePage(i);
            }
            doc.save(
              `${u.siid.toString().substr(3)}-${new Date()
                .getFullYear()
                .toString()
                .substr(-2)}` + '.pdf'
            )
          },
        })
        .then(() => {
          //// // console.log(pdf.getFont());

          this.childRef.current.style.visibility = 'hidden'
        })
    } else {
      let gmail = 'mubina.ibrisagic@gat.com.ba'
      let component = `Podaci o proizvodu:  %0D%0A %0D%0A
      Broj artikla: ${u.ba}, %0D%0A %0D%0A
      Broj crteža: ${u.bc}, %0D%0A %0D%0A
      Materijal: EN AW ${u.m}, %0D%0A %0D%0A
      Površina jednog komada: ${u.p}, %0D%0A %0D%0A
      Dimenzioni kriterij: ${u.t},  %0D%0A %0D%0A
      Slijepe rupe/navoji manje od Φ5: ${u.sr ? 'DA' : 'NE'},  %0D%0A %0D%0A
      Način kačenja: ${u.nk},  %0D%0A %0D%0A
      Broj komada: ${u.bk},  %0D%0A %0D%0A
      Način površinske zaštite: ${u.npz},   %0D%0A %0D%0A
      Hemijska predobrada prije eloksiranja: ${u.hppo}, %0D%0A %0D%0A
      ${u.hMin ? `Minute: ${u.hMin}, %0D%0A %0D%0A` : ''}
      Dodatno hemijsko poliranje( E7 ): ${u.hppoD ? 'DA' : 'NE'}, %0D%0A %0D%0A
      Debljina oksidnog sloja: ${u.dos}, %0D%0A %0D%0A
      Boja: ${u.b}, %0D%0A %0D%0A %0D%0A %0D%0A 
      
      ` // doraditi, naslijediti komponentu iz upit komponente
      window.location.href = `mailto:${gmail}?subject=Upit&body=${component}`
    }
  }
  Foo = (
    <div>
      <ReportPom
        // ref={this.childRef}
        prevod={this.props.prevod}
        oz_j={this.props.oz_j}
        lng={localStorage.getItem('lng')}
        podaci={{
          tabela: [
            {
              Rb: 1,
              vašaOznaka: this.props.bc,
              bc: this.props.nc.toUpperCase(),
              naziv: {
                na: this.props.na,

                sifra_recepta: this.props.sifra_recepta,
              },
              JM: 'KOM',
              bk: this.props.bk.toString() + ',00',
              cijena: parseFloat(this.props.cijena)
                .toFixed(2)
                .toString()
                .replace('.', ','),
              ukupnaCijena: parseFloat(
                parseFloat(this.props.cijena).toFixed(2) * this.props.bk
              )
                .toFixed(2)
                .toString()
                .replace('.', ','),
            },
          ],
          valuta: this.props.valuta,
          brKom: parseInt(
            JSON.parse(
              CryptoJS.AES.decrypt(
                localStorage.getItem('nmx'),
                'Adna1234'
              ).toString(CryptoJS.enc.Utf8)
            )
          ),
          kusid: `${this.props.id.toString().substr(3)}-${new Date()
            .getFullYear()
            .toString()
            .substr(-2)}`,
        }}
      />
    </div>
  )
  onCValueChanged(e) {
    this.setState({ cijena: e.value })
    //	//// // // // console.log('cijena', this.state.cijena)
  }
  onVValueChanged(e) {
    if (e.value === 'EUR' && this.state.valuta === 'KM') {
      this.setState({
        valuta: e.value,
        cijena: (
          parseFloat(this.state.cijena) * parseFloat(this.state.e_u_km)
        ).toString(),
      })
    } else {
      this.setState({
        valuta: e.value,
        cijena: (
          parseFloat(this.state.cijena) * parseFloat(this.state.km_u_e)
        ).toString(),
      })
    }
  }
  onPDFValueChanged(e) {
    this.setState({ pdf_ukljucen: e.value })
  }
  componentDidMount = async () => {
    try {
      const [km_u_e, e_u_km] = await axios.all([getKMuEURO(), getEUROuKM()])
      //	//// // // // console.log("kursevi",km_u_e.data) //,km_u_d.data,e_u_km.data,d_u_km.data
      this.setState({
        km_u_e: km_u_e.data[0],
        e_u_km: e_u_km.data[0],
      })
      //	//// // // // console.log("kursevi",this.state.km_u_e,this.state.km_u_d,this.state.e_u_km,this.state.d_u_km)
      this.setState({ cijena: this.props.cijena })
    } catch (err) {
      ////// // // // console.log("ERROR",err)
    }
  }
  render() {
    let { cijena } = this.state
    const { prevod, oz_j } = this.props
    return (
      <div id="cijena_footer">
        <div className="dx-fieldset">
          <div className="dx-field">
            <div className="dx-field-label" id="napomena">
              {prevod &&
              prevod.length !== 0 &&
              prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  73
              ]
                ? prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      73
                  ].item2
                : 'Napomena: Izračunata cijena nije obavezujuća'}
            </div>
          </div>
        </div>
        <div className="dx-fieldset">
          <div className="dx-field">
            <div className="dx-data dobijenaCijena">
              <TextBox
                name="cijena"
                value={cijena}
                // format=" 0.####"
                width="50%"
                readOnly={true}
              />

              <SelectBox
                items={valute}
                value={this.state.valuta}
                onValueChanged={this.onVValueChanged}
                // dropDownButtonRender={ImageIcon}
                placeholder={
                  prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      62
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          62
                      ].item2
                    : 'Izaberi ...'
                }
                width="50%"
              />
            </div>
          </div>
        </div>
        <br />
        <div className="dx-fieldset">
          <div className="dx-field">
            <Button
              text={
                prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    76
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        76
                    ].item2
                  : 'Naruči'
              }
              type="default"
              width="100%"
              useSubmitBehavior={true}
              onClick={this.naruci}
            />
            <div className="dx-field">
              <div className="dx-field-label">
                {' '}
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    74
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        74
                    ].item2
                  : 'Spremiti kao pdf'}
              </div>
              <div className="dx-field-value">
                <CheckBox
                  style={{
                    border: '1px solid ',
                  }}
                  value={this.state.pdf_ukljucen}
                  onValueChanged={this.onPDFValueChanged}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          id="a"
          style={{ position: 'absolute', top: '0px', left: '-3000px' }}
        >
          <div id="abc">
            <ReportPom
              ref={this.childRef}
              prevod={this.props.prevod}
              oz_j={this.props.oz_j}
              lng={localStorage.getItem('lng')}
              podaci={{
                tabela: [
                  {
                    Rb: 1,
                    vašaOznaka: this.props.bc,
                    bc: this.props.nc.toUpperCase(),
                    naziv: {
                      na: this.props.na,

                      sifra_recepta: this.props.sifra_recepta,
                    },
                    JM: 'KOM',
                    bk: this.props.bk.toString() + ',00',
                    cijena: parseFloat(cijena)
                      .toFixed(2)
                      .toString()
                      .replace('.', ','),
                    ukupnaCijena: parseFloat(
                      parseFloat(cijena).toFixed(2) * this.props.bk
                    )
                      .toFixed(2)
                      .toString()
                      .replace('.', ','),
                  },
                ],
                valuta: this.props.valuta,
                brKom: parseInt(
                  JSON.parse(
                    CryptoJS.AES.decrypt(
                      localStorage.getItem('nmx'),
                      'Adna1234'
                    ).toString(CryptoJS.enc.Utf8)
                  )
                ),
                kusid: `${this.props.id.toString().substr(3)}-${new Date()
                  .getFullYear()
                  .toString()
                  .substr(-2)}`,
              }}
            />
          </div>
          {/* 
          <ReportPom props={{ siid: this.props.upit.siid }} key="report" />
          <PDFExport
            ref={(component) => (pdf = component)}
            paperSize="auto"
            margin={40}
            fileName={`${`${this.props.id.toString().substr(3)}-${new Date()
              .getFullYear()
              .toString()
              .substr(-2)}`}`}
            author="Ivis software, B2B d.o.o. Sarajevo, ul. Branilaca Sarajeva 27, 71000 Sarajevo"
          >
            <Report
              podaci={{
                tabela: [
                  {
                    Rb: 1,
                    vašaOznaka: this.props.bc,
                    bc: this.props.nc.toUpperCase(),
                    naziv: {
                      na: this.props.na,

                      sifra_recepta: this.props.sifra_recepta,
                    },
                    JM: "KOM",
                    bk: this.props.bk.toString() + ",00",
                    cijena: cijena.toFixed(2).toString().replace(".", ","),
                    ukupnaCijena: parseFloat(cijena.toFixed(2) * this.props.bk)
                      .toFixed(2)
                      .toString()
                      .replace(".", ","),
                  },
                ],
                valuta: this.props.valuta,
                brKom: parseInt(
                  JSON.parse(
                    CryptoJS.AES.decrypt(
                      localStorage.getItem("nmx"),
                      "Adna1234"
                    ).toString(CryptoJS.enc.Utf8)
                  )
                ),
                kusid: `${this.props.id.toString().substr(3)}-${new Date()
                  .getFullYear()
                  .toString()
                  .substr(-2)}`,
              }}
            />
          </PDFExport>*/}
        </div>
      </div>
    )
  }
  exportPDFWithComponent = async (e) => {
    pdf.save()
  }
  hideInfo() {
    this.setState({
      popupVisible: false,
    })
  }
}

const mapStateToProps = (state) => {
  return {
    prevod: state.prevod,
    jezik: state.jezik,
    oz_j: state.oz_j,
  }
}

export default connect(mapStateToProps, null)(Footer)
