import axios, { post } from "axios";
import { post_upit } from "./constUpit";
import { API } from "../../../const";
// update

export const putUpit = (
  ba,
  bc,
  m,
  p,
  t,
  s_r,
  n_k,
  b_k,
  n_p_z,
  hppo,
  dos,
  itdos,
  b,
  s,
  min
) => {
  axios.put(post_upit, {
    usr: localStorage.getItem("nmx").toString(),
    ba: ba,
    bc: bc,
    m: m,
    p: p,
    t: t,
    sR: s_r,
    nK: n_k,
    bK: b_k,
    nPZ: n_p_z,
    hppo: hppo,
    dos: dos,
    itdos: itdos,
    b: b,
    s: s,
    hMin: min,
  });
};

// novi post
export const postSiKuzKusT0T1S4 = (na, sk, bc, bk) => {
  // // // // console.log("Si");
  const url = API + "/si/kuz/kus";
  const formData = new FormData();
  formData.append("na", na);
  formData.append("sk", sk);
  formData.append("bc", bc);
  formData.append("bk", bk);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return post(url, formData, config);
};

export const postArrayT2 = (dk, siid, sr, nk_min) => {
  const url = API + "/lista/t2";
  const formData = new FormData();
  formData.append("dk", dk);
  formData.append("siid", siid);
  formData.append("sr", sr);
  formData.append("nk_min", nk_min);

  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  return post(url, formData, config); //.then((resp) => // // // // console.log(resp));
};
// pomocne funkcije za post
/*export const postT0 = (id) =>
  axios.post(post_t0, {
    t0id: id,
    t0ts: "IZ",
    t0el: 0,
    t0el: 0,
    t0er: 0,
    t0em: 0,
    t0eo: 0,
    t0sl: 0,
    t0sr: 0,
    t0sm: 0,
    t0so: 0,
    t0ma: 0,
    t0tm: "F",
    t0kl: 1,
    t0oc: "010302",
    t0dr: date,
    t0ro: "",
    t0rk: "",
  }); //.then(alert("Uspjelo")).catch(err=>{//// // // // console.log("Error",err)})

export const postT2 = (id, bo, vo, nop, gm, vr, bk, oo, oznaka_recepta) => {
  axios.post(post_t2, {
    t2idm: id,
    t2tsm: "IZ",
    t2bo: bo,
    t2vo: vo,
    t2nop: nop,
    t2gm: gm,
    t2tp: 10,
    t2ti: 1,
    t2vr: vr,
    t2bk: bk,
    t2br: 1,
    t2nv: 0,
    t2oo: oo,
    t2or: oznaka_recepta,
    t2eo: 0,
    t2tph: "",
    t2tih: "",
    t2nb: "1",
    t2votpz: "",
    t2vrtpz: "",
    t2vokon: "",
    t2vrkon: "",
    t2uckon: "",
    t2tk: 0,
    t2tkh: "",
    t2bkoz: "",
    t2obkon: 0,
    t2agm: "",
  });
};

export const postT1 = (siid) => {
  axios.post(post_t1, {
    t1idm: siid,
    t1tsm: "IZ",
    t1rb: 5,
    t1id: "-",
    t1ts: "MT",
    t1kl: 1,
    t1ud: "",
    t1po: "",
    t1ma: 1,
    t1te: "R",
    t1bo: 0,
    t1m: "D",
    t1kls: "",
  });
};

export const postS4 = (siid, p) => {
  axios.post(post_s4, {
    S4kp: "MS",
    S4id: siid,
    S4ok: "FP",
    S4vk: p.toLocaleString("cs-CZ"),
    S4ks: "GPE",
  });
};

export const postUpit = (
  ba,
  bc,
  m,
  p,
  t,
  s_r,
  n_k,
  b_k,
  n_p_z,
  npzN,
  hppo,
  hppoN,
  hppoD,
  dos,
  itdos,
  b,
  bN,
  s,
  min,
  na
) => {
  axios
    .post(post_upit, {
      usr: parseInt(
        JSON.parse(
          CryptoJS.AES.decrypt(
            localStorage.getItem("nmx"),
            "Adna1234"
          ).toString(CryptoJS.enc.Utf8)
        )
      ),
      ba: ba,
      bc: bc,
      m: m,
      p: p,
      t: t,
      sR: s_r,
      nK: n_k,
      bK: b_k,
      nPZ: n_p_z,
      npzN: npzN,
      hppo: hppo,
      hppoN: hppoN,
      hppoD: hppoD,
      dos: dos,
      itdos: itdos,
      b: b,
      bN: bN,
      s: s,
      hMin: min,
      naziv: na,
    })
    .then((resp) => {
      // // // // console.log("RESPONSE", resp.data);
    })
    .catch((err) => // // // // console.log(err));
  // // // // console.log(
    ba,
    bc,
    m,
    p,
    t,
    s_r,
    n_k,
    b_k,
    n_p_z,
    npzN,
    hppo,
    hppoN,
    hppoD,
    dos,
    itdos,
    b,
    bN,
    s,
    min
  );
};
*/
