import React from 'react'
import { connect } from 'react-redux'
// dodatna komponenta potrebna za ucitavanje podataka poslanih kao upit složenog artikla, za kojeg se nije mogla izracunati cijena
class UpitDetalji extends React.Component {
  render() {
    const { upit, sifra_recepta, prevod, oz_j } = this.props
    if (sifra_recepta !== 'Kromatiranje SurTec 650')
      return (
        <div id="upit_detalji">
          <div className="lista_podataka card">
            <div>
              <p>
                {' '}
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    48
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        48
                    ].item2
                  : 'Naziv artikla'}
                /
              </p>
              <p>{upit.na}</p>
            </div>
            <div>
              <p>
                {' '}
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    50
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        50
                    ].item2
                  : 'Broj artikla'}
                /
              </p>
              <p>{upit.ba}</p>
            </div>

            <div>
              <p>
                {' '}
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    51
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        51
                    ].item2
                  : 'Broj crteža'}
                /
              </p>
              <p>{upit.bc}</p>
            </div>
          </div>
          <div className="lista_podataka card">
            <div>
              <p>
                {' '}
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    52
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        52
                    ].item2
                  : 'Materijal'}
                /
              </p>
              <p>{upit.m}</p>
            </div>

            <div>
              <p>
                {' '}
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    155
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        155
                    ].item2
                  : 'Površina'}
                /
              </p>
              <p>{upit.p}</p>
            </div>

            <div>
              <p>
                {' '}
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    54
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        54
                    ].item2
                  : 'Dimenzioni kriterij'}
                /
              </p>
              <p>{upit.t}</p>
            </div>

            <div>
              <p>
                {' '}
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    55
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        55
                    ].item2
                  : 'Slijepe rupe - navoji manje od Φ5'}
                /
              </p>
              <p>{upit.s_r ? 'DA' : 'NE'}</p>
            </div>

            <div>
              <p>
                {' '}
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    56
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        56
                    ].item2
                  : 'Način kačenja'}
                /
              </p>
              <p>{upit.nk}</p>
            </div>

            <div>
              <p>
                {' '}
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    57
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        57
                    ].item2
                  : 'Broj komada'}
                /
              </p>
              <p>{upit.bk}</p>
            </div>
          </div>
          <div className="lista_podataka card">
            <div>
              <p>
                {' '}
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    59
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        59
                    ].item2
                  : 'Način površinske zaštite'}
                /
              </p>
              <p>{upit.npz}</p>
            </div>

            <div>
              <p>
                {' '}
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    60
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        60
                    ].item2
                  : 'Hemijska predobrada prije eloksiranja'}
                /
              </p>
              <p>{upit.hppo + '  ' + upit.hppoN}</p>
            </div>

            <div>
              <p>
                {' '}
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    93
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        93
                    ].item2
                  : 'Dodatno hemijsko poliranje( E7 )'}
                /
              </p>
              <p>{upit.hppo_d ? 'DA' : 'NE'}</p>
            </div>
            {upit.hppoN === 'Mat eloksiranje' ? (
              <div>
                <p>
                  {' '}
                  {prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      94
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          94
                      ].item2
                    : 'Vrijeme( u minutama)'}
                  /
                </p>
                <p>{upit.min}</p>
              </div>
            ) : (
              ''
            )}
            <div>
              <p>
                {' '}
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    63
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        63
                    ].item2
                  : 'Debljina oksidnog sloja'}
                /
              </p>
              <p>{upit.dos}</p>
            </div>

            {/* <div >
          <p>Interval tolerancije debljine oksidnog sloja/</p>
          <p>{upit.itdos}</p>
        </div>
    */}
            <div>
              <p>
                {' '}
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    156
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        156
                    ].item2
                  : 'Boja'}
                /
              </p>
              <p>{upit.b}</p>
            </div>
          </div>
        </div>
      )
    else
      return (
        <div>
          <div>
            {' '}
            {prevod &&
            prevod.length !== 0 &&
            prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                157
            ]
              ? prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    157
                ].item2
              : 'Šifra recepta'}
            / {sifra_recepta}
          </div>
        </div>
      )
  }
}
const mapStateToProps = (state) => {
  return {
    prevod: state.prevod,
    jezik: state.jezik,
    oz_j: state.oz_j,
  }
}

export default connect(mapStateToProps, null)(UpitDetalji)
