import React from 'react'
import FadeIn from 'react-fade-in'
import { connect } from 'react-redux'

const DOS_2017_B_Opis = (props) => {
  return (
    <FadeIn>
      <div style={{ zIndex: '1000' }}>
        <ul className="dos_list">
          <li>10µm</li>
          <li>20µm</li>
          <li>30µm</li>
          <li>40µm</li>
        </ul>
        <p className="list-opis-hppe">
          {props.prevod &&
          props.prevod.length !== 0 &&
          props.prevod[
            props.oz_j.indexOf(localStorage.getItem('lng')) *
              (props.prevod.length / props.oz_j.length) +
              197
          ]
            ? props.prevod[
                props.oz_j.indexOf(localStorage.getItem('lng')) *
                  (props.prevod.length / props.oz_j.length) +
                  197
              ].item2
            : 'Sa zahtjevom većim od 50+/-10 mikrona, poslati upit na mail.'}
        </p>
      </div>
    </FadeIn>
  )
}

const mapStateToProps = (state) => {
  return {
    jezik: state.jezik,
    prevod: state.prevod,
    oz_j: state.oz_j,
  }
}

export default connect(mapStateToProps, null)(DOS_2017_B_Opis)
