import axios from 'axios'
import React, { Component, useEffect, useState } from 'react'
import Pagination from '@mui/material/Pagination'
import PaginationItem from '@mui/material/PaginationItem'
import Stack from '@mui/material/Stack'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import { OZNAKE_JEZIKA, PREVOD } from '../../const'

export default function UsloviAplikacije() {
  const [prevod, setPrevod] = useState([])
  const [oz_j, setOzJ] = useState([])

  const [page, setPage] = useState(1)
  useEffect(() => {
    axios.get(PREVOD).then((resp) => setPrevod(resp.data))
    axios.get(OZNAKE_JEZIKA).then((resp) => setOzJ(resp.data))
  }, [])

  return (
    <div id="uslovi_app">
      <div
        //id="uslovi_app"
        style={{
          padding: '10vh 10vw',
          height: '82vh',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
          transition: '0.3s',
          borderRadius: '10px',
          backgroundColor: 'rgba(200,200,200,0.5)',
          margin: '10vh 10vw',
        }}
      >
        <h1>
          {prevod &&
          prevod.length !== 0 &&
          prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              302
          ]
            ? prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  302
              ].item2
            : 'Opći uvjeti korištenja'}
        </h1>{' '}
        {page == '1' ? (
          <>
            <b>
              <h3>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    303
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        303
                    ].item2
                  : 'I Uvodne odredbe'}
              </h3>
            </b>
            <p>
              {' '}
              {prevod &&
              prevod.length !== 0 &&
              prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  304
              ]
                ? prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      304
                  ].item2
                : 'Ovim Općim uvjetima preduzeća GAT d.o.o. (dalje u tekstu: Opći uvjeti) utvrđuju se sadržaj i uvjeti korištenja internetske aplikacije Web Kalkulator (dalje u tekstu: Aplikacija) te prava i obveze Korisnika Aplikacije. Podnošenjem zahtjeva za aktivacijom aplikacije, tako što se korisnik registruje putem aplikacije Web kalkulator, korisnik prihvaća uvjete korištenja usluga Web kalkulatora utvrđene ovim Općim uvjetima i potvrđuje da su mu isti bili poznati i dostupni tokom registracije te se takav početak korištenja Aplikacije ima smatrati potvrdom sklopljenog ugovora. Ovi Opći uvjeti sastavni su dio ugovora između preduzeća GAT d.o.o. i Korisnika, a objavljeni su na internetskoj stranici www.gat-crm.com te su vidljivi Korisniku nakon prijave korisničkim imenom i lozinkom.'}
            </p>
            <b>
              <h3>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    305
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        305
                    ].item2
                  : 'II Pojmovi i definicije'}
              </h3>
            </b>
            <p>
              {' '}
              {prevod &&
              prevod.length !== 0 &&
              prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  306
              ]
                ? prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      306
                  ].item2
                : 'Pružatelj usluge je preduzeće GAT d.o.o., Šejkovača bb. 79260 Sanski Most.Korisnik je pravna ili fizička osoba s kojom pružatelj usluge sklapa ugovor za korištenje Aplikacije za poslovne svrhe, sukladno važećim propisima.Odgovorna osoba je ovlaštena osoba Korisnika koja se registrirala za korištenje Aplikacije i koja je registracijom prihvatila Opće uvjete.'}{' '}
            </p>
          </>
        ) : page == 2 ? (
          <>
            <b>
              <h3>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    307
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        307
                    ].item2
                  : 'III Tehnički uvjeti za korištenje Aplikacije'}
              </h3>
            </b>
            <p>
              {' '}
              {prevod &&
              prevod.length !== 0 &&
              prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  308
              ]
                ? prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      308
                  ].item2
                    .split('(')
                    .map((v, i) => (
                      <>
                        {v} <br />
                      </>
                    ))
                : 'Za korištenje Aplikacije Korisnik o svom trošku i na vlastitu odgovornost mora osigurati sljedeće tehničke uvjete: ...'}{' '}
            </p>
            <b>
              <h3>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    309
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        309
                    ].item2
                  : 'IV Postupak registracije i sklapanje ugovora'}
              </h3>
            </b>
            <p>
              {' '}
              {prevod &&
              prevod.length !== 0 &&
              prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  310
              ]
                ? prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      310
                  ].item2
                    .split('(')
                    .map((v, i) => (
                      <>
                        {v} <br />
                      </>
                    ))
                : 'Prijava se vrši na internetskoj stranici www.gat-crm.com prihvaćanjem ovih Općih uvjeta'}{' '}
            </p>{' '}
          </>
        ) : page == 3 ? (
          <>
            {' '}
            <b>
              <h3>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    311
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        311
                    ].item2
                  : 'V Korisnički račun aplikacije'}
              </h3>
            </b>
            <p>
              {' '}
              {prevod &&
              prevod.length !== 0 &&
              prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  312
              ]
                ? prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      312
                  ].item2
                : 'Pružatelj usluge daje na upotrebu aplikaciju Korisniku putem Korisničkog računa, a Korisnik sam unosi podatke u aplikaciju.'}{' '}
            </p>
          </>
        ) : page == 4 ? (
          <>
            {' '}
            <b>
              <h3>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    313
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        313
                    ].item2
                  : 'VI Baza podataka i povjerljivost informacija'}
              </h3>
            </b>
            <p>
              {' '}
              {prevod &&
              prevod.length !== 0 &&
              prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  314
              ]
                ? prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      314
                  ].item2
                : 'Korisnik Aplikacije je vlasnik baze podataka.'}{' '}
            </p>
          </>
        ) : page == 5 ? (
          <>
            {' '}
            <b>
              <h3>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    315
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        315
                    ].item2
                  : 'VII Odgovornost za korištenje Aplikacije'}
              </h3>
            </b>
            <p>
              {' '}
              {prevod &&
              prevod.length !== 0 &&
              prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  316
              ]
                ? prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      316
                  ].item2
                    .split('(')
                    .map((v, i) => (
                      <>
                        {v} <br />
                      </>
                    ))
                : 'Korisnik se obavezuje koristiti Aplikaciju u skladu sa tehnološkim postavkama utvrđenima Općim uvjetima.'}{' '}
            </p>
          </>
        ) : page == 6 ? (
          <>
            {' '}
            <b>
              <h3>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    317
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        317
                    ].item2
                  : 'VIII Intelektualno vlasništvo'}
              </h3>
            </b>
            <p>
              {' '}
              {prevod &&
              prevod.length !== 0 &&
              prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  318
              ]
                ? prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      318
                  ].item2
                : 'Cjelokupno intelektualno vlasništvo (program, struktura, edukacija, web podrška, slike, fotografije, logotipi i dr.) isključivo je vlasništvo pružatelja usluge.'}{' '}
            </p>
          </>
        ) : page == 7 ? (
          <>
            <b>
              <h3>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    319
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        319
                    ].item2
                  : 'IX Posebne obaveze pružatelja usluge'}
              </h3>
            </b>
            <p
              style={{
                textJustify: 'inter-word',
              }}
            >
              {' '}
              {prevod &&
              prevod.length !== 0 &&
              prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  320
              ]
                ? prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      320
                  ].item2
                    .split('(')
                    .map((v, i) => (
                      <>
                        {v} <br />
                      </>
                    ))
                : 'Pružatelj usluge posebno se obavezuje:'}{' '}
            </p>{' '}
          </>
        ) : page == 8 ? (
          <>
            <b>
              <h3>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    321
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        321
                    ].item2
                  : 'X Funkcionalnost i osnovne značajke Aplikacije'}
              </h3>
            </b>
            <p>
              {' '}
              {prevod &&
              prevod.length !== 0 &&
              prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  322
              ]
                ? prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      322
                  ].item2
                : 'Aplikacija korisniku pruža uvid u cijenu usluge površinske obrade u obliku ponude unosom svih neophodnih (traženih) podataka u Web kalkulatoru i mogućnost izdavanje narudžbe u GAT d.o.o.'}{' '}
            </p>
          </>
        ) : (
          <>
            <b>
              <h3>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    323
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        323
                    ].item2
                  : 'XI Otkaz i raskid'}
              </h3>
            </b>
            <p>
              {' '}
              {prevod &&
              prevod.length !== 0 &&
              prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  324
              ]
                ? prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      324
                  ].item2
                : 'Ako se Korisnik ne pridržava Općih uvjeta pružatelj zadržava pravo onemogućiti Korisniku korištenje Aplikacije bez prethodne najave, s učinkom trenutnog raskida ugovora na štetu Korisnika. '}{' '}
            </p>{' '}
            <b>
              <h3>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    325
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        325
                    ].item2
                  : 'XII Saglasnost s izmjenama Općih uvjeta'}
              </h3>
            </b>
            <p>
              {' '}
              {prevod &&
              prevod.length !== 0 &&
              prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  326
              ]
                ? prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      326
                  ].item2
                : 'Pružatelj usluge zadržava pravo naknadne izmjene i/ili dopune Općih uvjeta o čemu je dužan obavijestiti Korisnika email-om, putem obavijesti pri korištenju Aplikacije ili na drugi prikladan način.'}{' '}
            </p>{' '}
            <b>
              <h3>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    327
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        327
                    ].item2
                  : 'XIII Rješavanje sporova'}
              </h3>
            </b>
            <p>
              {' '}
              {prevod &&
              prevod.length !== 0 &&
              prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  328
              ]
                ? prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      328
                  ].item2
                    .split(' . ')
                    .map((v, i) => (
                      <>
                        {i <
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            328
                        ].item2.split(' . ').length -
                          1
                          ? v + '.'
                          : v}{' '}
                        <br /> <br />
                      </>
                    ))
                : 'Pružatelj usluge i Korisnik su saglasni da će mirno riješiti sve sporove proizašle iz ugovora, a ako to nije moguće, odgovoran je nadležni sud u Sanskom Mostu.Ovi Opći uvjeti poslovanja primjenjuju se od 01.10.2022. godine'}{' '}
            </p>
          </>
        )}
        <div style={{ position: 'absolute', bottom: '9vh' }}>
          <Stack style={{ marginLeft: '15vw' }}>
            <Pagination
              size="large"
              onChange={(e, p) => setPage(p)}
              count={9}
              renderItem={(item) => (
                <PaginationItem
                  components={{
                    previous: ArrowBackIcon,
                    next: ArrowForwardIcon,
                  }}
                  {...item}
                />
              )}
            />
          </Stack>
        </div>
      </div>
    </div>
  )
}
