import axios from "axios";
import {
  REQUEST_HPPE_A,
  HPPE_A,
  REQUEST_HPPE_B,
  HPPE_B,
  REQUEST_HPPE_C,
  HPPE_C,
} from "../../const";

export const fetchHAData = (hppe_a) => {
  return {
    type: REQUEST_HPPE_A,
    hppe_a,
  };
};

export const fetchHPPEAData = () => {
  return (dispatch) => {
    return axios
      .get(HPPE_A)
      .then((response) => {
        dispatch(fetchHAData(response.data));
        //// // // // console.log("TU SAM" ,response.data)
      })
      .catch((error) => {
        //// // // // console.log("err")
        throw error;
      });
  };
};

export const fetchHBData = (hppe_b) => {
  return {
    type: REQUEST_HPPE_B,
    hppe_b,
  };
};

export const fetchHPPEBData = () => {
  return (dispatch) => {
    return axios
      .get(HPPE_B)
      .then((response) => {
        dispatch(fetchHBData(response.data));
        //// // // // console.log("TU SAM" ,response.data)
      })
      .catch((error) => {
        //// // // // console.log("err")
        throw error;
      });
  };
};

export const fetchHCData = (hppe_c) => {
  return {
    type: REQUEST_HPPE_C,
    hppe_c,
  };
};

export const fetchHPPECData = () => {
  return (dispatch) => {
    return axios
      .get(HPPE_C)
      .then((response) => {
        dispatch(fetchHCData(response.data));
        //// // // // console.log("TU SAM" ,response.data)
      })
      .catch((error) => {
        //// // // // console.log("err")
        throw error;
      });
  };
};
