import axios from "axios";
import {
  ITDOS_A,
  REQUEST_ITDOS_A,
  REQUEST_ITDOS_B,
  ITDOS_B,
} from "../../const";

export const fetchITDAData = (itdos_a) => {
  return {
    type: REQUEST_ITDOS_A,
    itdos_a,
  };
};

export const fetchITDOSAData = () => {
  return (dispatch) => {
    return axios
      .get(ITDOS_A)
      .then((response) => {
        dispatch(fetchITDAData(response.data));
        //// // // // console.log("TU SAM" ,response.data)
      })
      .catch((error) => {
        //// // // // console.log("err")
        throw error;
      });
  };
};

export const fetchITDBData = (itdos_b) => {
  return {
    type: REQUEST_ITDOS_B,
    itdos_b,
  };
};

export const fetchITDOSBData = () => {
  return (dispatch) => {
    return axios
      .get(ITDOS_B)
      .then((response) => {
        dispatch(fetchITDBData(response.data));
        //// // // // console.log("TU SAM" ,response.data)
      })
      .catch((error) => {
        //// // // // console.log("err")
        throw error;
      });
  };
};
