import axios from "axios";
import { REQUEST_E6_ME, REQUEST_E6_DHP, E6_ME, E6_DHP } from "../../const";

export const fetchMEData = (e6_me) => {
  return {
    type: REQUEST_E6_ME,
    e6_me,
  };
};

export const fetchE6MEData = () => {
  return (dispatch) => {
    return axios
      .get(E6_ME)
      .then((response) => {
        dispatch(fetchMEData(response.data));
        //// // // // console.log("TU SAM" ,response.data)
      })
      .catch((error) => {
        //// // // // console.log("err")
        throw error;
      });
  };
};

export const fetchDHPData = (e6_dhp) => {
  return {
    type: REQUEST_E6_DHP,
    e6_dhp,
  };
};

export const fetchE6DHPData = () => {
  return (dispatch) => {
    return axios
      .get(E6_DHP)
      .then((response) => {
        dispatch(fetchDHPData(response.data));
        //// // // // console.log("TU SAM" ,response.data)
      })
      .catch((error) => {
        //// // // // console.log("err")
        throw error;
      });
  };
};
