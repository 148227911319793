import React from "react";
import "../../Styles/korisnikStyle.css";
import ListaUpitaAdmin from "../../Components/DevExtremeComponents/Upit/UpitAdmin/ListaUpitaAdmin";
const AdminPanelUpitPage = () => {
  return (
    <div id="admin_upit_panel">
      <div>
        <ListaUpitaAdmin />
      </div>
    </div>
  );
};

export default AdminPanelUpitPage;
