import { REQUEST_2017_DOS_B } from "../../../const";

export default function DOS2017BReducer(state = [], action) {
  switch (action.type) {
    case REQUEST_2017_DOS_B:
      return action.dos_2017_b;

    default:
      return state;
  }
}
