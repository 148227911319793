import { DataGrid } from 'devextreme-react'
import React from 'react'
import header from '../../Images/header.png'
import footer from '../../Images/footer.png'
import { Column } from 'devextreme-react/data-grid'
import '../../Styles/reportStyle.css'
const CryptoJS = require('crypto-js')

var moment = require('moment')

const ReportPom = React.forwardRef((props, ref) => (
  <div
    className="sgfsgd"
    id="sgfsgd"
    ref={ref}
    style={{ fontFamily: 'Arial', position: 'absolute', top: '0 !important' }}
  >
    <img src={header} id="header" alt="gat_header" />

    <br />
    <div>
      <div id="adr">
        <div id="adresa">
          <div id="adresa-1">
            <h3>{props.adresa_komitenta}</h3>
          </div>
          <div id="adresa-2">
            <div className="adresa-2-1">
              <span>
                {props.prevod &&
                props.prevod.length !== 0 &&
                props.prevod[
                  props.oz_j.indexOf(props.lng) *
                    (props.prevod.length / props.oz_j.length) +
                    159
                ]
                  ? props.prevod[
                      props.oz_j.indexOf(props.lng) *
                        (props.prevod.length / props.oz_j.length) +
                        159
                    ].item2
                  : 'Broj komitenta'}
                :{' '}
                {
                  /*JSON.parse(
                  CryptoJS.AES.decrypt(
                    localStorage.getItem('sk'),
                    'I1v4is5'
                  ).toString(CryptoJS.enc.Utf8)
                  )*/ props.br_komitenta
                }
              </span>
            </div>
          </div>
        </div>
        <div id="adresa-3">
          <div id="adresa-3-1">
            <div id="adresa-3-1-1">
              <p id="adresa-3-1-1-1">
                {' '}
                {props.prevod &&
                props.prevod.length !== 0 &&
                props.prevod[
                  props.oz_j.indexOf(props.lng) *
                    (props.prevod.length / props.oz_j.length) +
                    160
                ]
                  ? props.prevod[
                      props.oz_j.indexOf(props.lng) *
                        (props.prevod.length / props.oz_j.length) +
                        160
                    ].item2
                  : 'PONUDA'}
              </p>
              <p id="adresa-3-1-1-2">
                {props.prevod &&
                props.prevod.length !== 0 &&
                props.prevod[
                  props.oz_j.indexOf(props.lng) *
                    (props.prevod.length / props.oz_j.length) +
                    161
                ]
                  ? props.prevod[
                      props.oz_j.indexOf(props.lng) *
                        (props.prevod.length / props.oz_j.length) +
                        161
                    ].item2
                  : 'neobavezujuca'}
              </p>
            </div>
            <div id="adresa-3-1-2">
              <p id="adresa-3-1-2-1">
                {props.prevod &&
                props.prevod.length !== 0 &&
                props.prevod[
                  props.oz_j.indexOf(props.lng) *
                    (props.prevod.length / props.oz_j.length) +
                    163
                ]
                  ? props.prevod[
                      props.oz_j.indexOf(props.lng) *
                        (props.prevod.length / props.oz_j.length) +
                        163
                    ].item2
                  : 'Br.'}{' '}
                <br />
                <p style={{ marginTop: '10px' }}>{props.podaci.kusid}</p>
              </p>
            </div>
          </div>
          <div id="adresa-3-2">
            <div id="adresa-3-2-1">
              <p id="adresa-3-2-1-1">
                {props.prevod &&
                props.prevod.length !== 0 &&
                props.prevod[
                  props.oz_j.indexOf(props.lng) *
                    (props.prevod.length / props.oz_j.length) +
                    162
                ]
                  ? props.prevod[
                      props.oz_j.indexOf(props.lng) *
                        (props.prevod.length / props.oz_j.length) +
                        162
                    ].item2
                  : 'Referent'}
                :
              </p>
              <p id="adresa-3-2-1-2"> </p>
              <p id="adresa-3-2-1-3"></p>
            </div>
            <div id="adresa-3-2-2">
              {moment(new Date()).format('DD.MM.YYYY')}
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
    <div id="narudzba">
      <b>
        {props.prevod &&
        props.prevod.length !== 0 &&
        props.prevod[
          props.oz_j.indexOf(props.lng) *
            (props.prevod.length / props.oz_j.length) +
            164
        ]
          ? props.prevod[
              props.oz_j.indexOf(props.lng) *
                (props.prevod.length / props.oz_j.length) +
                164
            ].item2
          : 'Uslovi'}
      </b>

      <div id="narudžba-1">
        <div id="narudzba-1-1">
          <p>
            {props.prevod &&
            props.prevod.length !== 0 &&
            props.prevod[
              props.oz_j.indexOf(props.lng) *
                (props.prevod.length / props.oz_j.length) +
                165
            ]
              ? props.prevod[
                  props.oz_j.indexOf(props.lng) *
                    (props.prevod.length / props.oz_j.length) +
                    165
                ].item2
              : 'Nacin isporuke'}
            :
          </p>
          <p>
            {props.prevod &&
            props.prevod.length !== 0 &&
            props.prevod[
              props.oz_j.indexOf(props.lng) *
                (props.prevod.length / props.oz_j.length) +
                166
            ]
              ? props.prevod[
                  props.oz_j.indexOf(props.lng) *
                    (props.prevod.length / props.oz_j.length) +
                    166
                ].item2
              : 'Rok placanja'}
            :
          </p>
        </div>
        <div id="narudzba-1-2">
          <p>
            {props.prevod &&
            props.prevod.length !== 0 &&
            props.prevod[
              props.oz_j.indexOf(props.lng) *
                (props.prevod.length / props.oz_j.length) +
                167
            ]
              ? props.prevod[
                  props.oz_j.indexOf(props.lng) *
                    (props.prevod.length / props.oz_j.length) +
                    167
                ].item2
              : 'Preuzimanje, EXW SANSKI MOST'}
          </p>
          <p>
            {props.prevod &&
            props.prevod.length !== 0 &&
            props.prevod[
              props.oz_j.indexOf(props.lng) *
                (props.prevod.length / props.oz_j.length) +
                168
            ]
              ? props.prevod[
                  props.oz_j.indexOf(props.lng) *
                    (props.prevod.length / props.oz_j.length) +
                    168
                ].item2
              : '30 dana, Virmanski'}
          </p>
          <p>
            <br />
          </p>
        </div>
      </div>
    </div>
    <br />
    <div id="tabela-report">
      <DataGrid
        dataSource={props.podaci.tabela} // pomocna - t
        showBorders={true}
        height={100}
      >
        <Column
          dataField="Rb"
          caption={
            props.prevod &&
            props.prevod.length !== 0 &&
            props.prevod[
              props.oz_j.indexOf(props.lng) *
                (props.prevod.length / props.oz_j.length) +
                169
            ]
              ? props.prevod[
                  props.oz_j.indexOf(props.lng) *
                    (props.prevod.length / props.oz_j.length) +
                    169
                ].item2
              : 'Rb.'
          }
          allowEditing={false}
          width={25}
          alignment="center"
        />
        <Column
          dataField="vašaOznaka"
          caption={
            props.prevod &&
            props.prevod.length !== 0 &&
            props.prevod[
              props.oz_j.indexOf(props.lng) *
                (props.prevod.length / props.oz_j.length) +
                170
            ]
              ? props.prevod[
                  props.oz_j.indexOf(props.lng) *
                    (props.prevod.length / props.oz_j.length) +
                    170
                ].item2
              : 'Vasa oznaka'
          }
          allowEditing={false}
          width={49}
          alignment="center"
        />
        <Column
          dataField="bc"
          caption={
            props.prevod &&
            props.prevod.length !== 0 &&
            props.prevod[
              props.oz_j.indexOf(props.lng) *
                (props.prevod.length / props.oz_j.length) +
                171
            ]
              ? props.prevod[
                  props.oz_j.indexOf(props.lng) *
                    (props.prevod.length / props.oz_j.length) +
                    171
                ].item2
              : 'Br. crteza'
          }
          allowEditing={false}
          alignment="center"
          width={49}
        />
        <Column
          dataField="naziv"
          caption={
            props.prevod &&
            props.prevod.length !== 0 &&
            props.prevod[
              props.oz_j.indexOf(props.lng) *
                (props.prevod.length / props.oz_j.length) +
                96
            ]
              ? props.prevod[
                  props.oz_j.indexOf(props.lng) *
                    (props.prevod.length / props.oz_j.length) +
                    96
                ].item2
              : 'Naziv'
          }
          allowEditing={false}
          alignment="center"
          cellRender={ReportCellRender}
          width={89}
        />
        <Column
          dataField="JM"
          caption={
            props.prevod &&
            props.prevod.length !== 0 &&
            props.prevod[
              props.oz_j.indexOf(props.lng) *
                (props.prevod.length / props.oz_j.length) +
                172
            ]
              ? props.prevod[
                  props.oz_j.indexOf(props.lng) *
                    (props.prevod.length / props.oz_j.length) +
                    172
                ].item2
              : 'JM'
          }
          allowEditing={false}
          width={29}
          alignment="center"
        />
        <Column
          dataField="bk"
          caption={
            props.prevod &&
            props.prevod.length !== 0 &&
            props.prevod[
              props.oz_j.indexOf(props.lng) *
                (props.prevod.length / props.oz_j.length) +
                173
            ]
              ? props.prevod[
                  props.oz_j.indexOf(props.lng) *
                    (props.prevod.length / props.oz_j.length) +
                    173
                ].item2
              : 'Kolicina'
          }
          allowEditing={false}
          width={54}
          alignment="center"
        />
        <Column
          dataField="cijena"
          caption={
            (props.prevod &&
            props.prevod.length !== 0 &&
            props.prevod[
              props.oz_j.indexOf(props.lng) *
                (props.prevod.length / props.oz_j.length) +
                97
            ]
              ? props.prevod[
                  props.oz_j.indexOf(props.lng) *
                    (props.prevod.length / props.oz_j.length) +
                    97
                ].item2
              : 'Cijena') + ` ${props.podaci.valuta}`
          }
          allowEditing={false}
          width={68}
          alignment="center"
        />
        <Column
          dataField="ukupnaCijena"
          caption={
            (props.prevod &&
            props.prevod.length !== 0 &&
            props.prevod[
              props.oz_j.indexOf(props.lng) *
                (props.prevod.length / props.oz_j.length) +
                174
            ]
              ? props.prevod[
                  props.oz_j.indexOf(props.lng) *
                    (props.prevod.length / props.oz_j.length) +
                    174
                ].item2
              : 'Ukupna cijena') + ` ${props.podaci.valuta}`
          }
          allowEditing={false}
          alignment="center"
          width={89}
        />
      </DataGrid>
    </div>
    <br />
    <div id="pozdrav">
      <p>
        {props.prevod &&
        props.prevod.length !== 0 &&
        props.prevod[
          props.oz_j.indexOf(props.lng) *
            (props.prevod.length / props.oz_j.length) +
            175
        ]
          ? props.prevod[
              props.oz_j.indexOf(props.lng) *
                (props.prevod.length / props.oz_j.length) +
                175
            ].item2
          : 'Rok isporuke ponudenih pozicija odreduje se evidencijom Vase narudzbe.'}
      </p>

      <p>Srdacan pozdrav / Mit freundlichen Grüßen / Best regards</p>

      <p>GAT d.o.o.</p>
    </div>
    <img src={footer} id="footer" alt="gat_footer" />
  </div>
))

const ReportCellRender = (data) => {
  return (
    <div>
      <p>Eloksiranje - {data.value.na}</p>
      <p>
        Zahtjev eloksiranja : <br />
      </p>
      <p>{data.value.sifra_recepta}</p>
    </div>
  )
}

export default ReportPom
