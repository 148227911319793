const dictionary = {
  en: {
    Yes: 'Da',
    No: 'Ne',
    'dxDataGrid-editingConfirmDeleteMessage':
      'Želite li izbrisati ovu površinu?',
  },
  'dxDataGrid-editingSaveRowChanges': 'Spremi',
  'dxDataGrid-editingCancelRowChanges': 'Odustani',
  'dxFileManager-dialogRenameItemButtonText': 'Spremi',
  'dxFileManager-dialogButtonCancel': 'Odustani',
}
export default {
  getDictionary() {
    return dictionary
  },
}
