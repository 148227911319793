import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import SelectBox from 'devextreme-react/select-box'
import ConditionalIcon from '../../Icons/conditionalIcon'
import { Tooltip } from 'devextreme-react/tooltip'
import ImageItem from '../../Items/ImageItem.js'
import ColorItem from '../../Items/ColorItem.js'
import MaterijalOpis from '../../Opisi/MaterijalOpis'
import TolerancijeOpis from '../../Opisi/TolerancijeOpis'
import Login from '../../Login/Login'
import { tolerancije } from '../../../../data'
import calculator from '../../../../Images/calculator.png'
import add from '../../../../Images/add.png'
import back from '../../../../Images/arrow-92-24.png'
import '../../../../Styles/upitStyle.css'
import {
  NumberBox,
  Popup,
  Button,
  TextBox,
  Validator,
  Template,
} from 'devextreme-react'
import Box, { Item } from 'devextreme-react/box'
import Footer from './../Footer'
import NacinKacenjaOpis from '../../Opisi/NacinKacenjaOpis'
import NPZOpis from '../../Opisi/NPZOpis'
import DOS_A_OPIS from '../../Opisi/A/DOS_A_Opis'
import DOS_2017_A_OPIS from '../../Opisi/A/DOS_2017_A_Opis'
import DOS_B_OPIS from '../../Opisi/B/DOS_B_Opis'
import DOS_2017_B_OPIS from '../../Opisi/B/DOS_2017_B'
import { CustomRule } from 'devextreme-react/form'
import ServerErrorPage from '../../../../Pages/DodatneStranice/ServerErrorPage'
import ColorField from '../../Items/ColorField'
import ImageField from '../../Items/ImageField'
import Modal from '../../Modal/Modal'
import Loading from '../../Loading/Loading'
import GatLogo from '../../../../Images/gat.png'
import OdjavaButtonWhite from '../../OdjavaButtonWhite'
import { postArrayT2, postSiKuzKusT0T1S4 } from './../PostUpit'
import Narudzba from './../Narudzba'
import NPOpis from '../../Opisi/NPOpis'
import TextItemHppe from '../../Items/TextItemHppe'
import TextItemNpo from '../../Items/TextItemNpo'
import TextItemDK from '../../Items/TextItemDK'
import { Resource } from 'devextreme-react/scheduler'
import NPZField from '../../Items/NPZField'
var CryptoJS = require('crypto-js')

var e6Lista = [
  'Luženje na mjeru',
  'Kratko luženje',
  'Dugo luženje',
  'Kratko luženje sa hemijskim poliranjem',
]
class Upit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      adresa_komitenta: '',
      br_komitenta: '',
      isLoaded: true,
      hover: false,
      ba: '',
      bc: '',
      m: '',
      m_list: [],
      povrsina: 0,
      p: 0,
      pStr: '0',
      popupVisible: false,
      slijepe_rupe: false,
      acex: '',
      t: tolerancije[0],
      nk: { item1: 'Jednostavno', item2: 'Jednostavno' },
      nk_min: 0.25,
      bk: 0,
      npz_value: 'Standardno eloksiranje - S',
      e: 'S',
      hppo: '',
      dos: '',
      //itdos_a: [],
      //itdos_b: [],
      //itdos: "",
      boja_a: [],
      boja_b: [],
      b: { item1: 'SI', item2: 'natur' },
      s_c: [],
      s_value: '',
      isLoading: true,
      isError: false,
      num_of_kuzs: 0,
      num_of_kuses: 0,
      num_of_sis: 0,
      siid: '',
      sind: '',
      sijm: '',
      kuzbd: '',
      sifra_recepta: '-',
      min: '', // minute
      h_p: false, // hemijsko poliranje - E7 nadovezano na E6
      hppo_temp: '',
      hppoN: '',
      cijena: 0,
      izracunata_cijena: false,
      popup_Shown: 0,
      e6: '',
      opis_m: false,
      opis_t: false,
      opis_nk: false,
      opis_npz: false,
      opis_hppo: false,
      opis_dos: false,
      opis_itdos: false,
      opis_b: false,
      item1: '',
      item2: '',
      selectedBItem: { item1: 'SI', item2: 'natur' },
      selectedNKItem: { item1: 'Jednostavno', item2: 'Jednostavno' },
      na: '',
      valuta: 'KM',
      cijena_loading: false,
      new_item: false,
      modal_shown: false,
      upit: null,
      added_draw: false, // crtež mora obavezno biti dodan
      crtez: null,
      added: false,
      inputLista: [
        { k9kcbkrb: 1, k9kcbkbk: 0, k9kcbkc: Math.floor(Math.random() * 1000) },
      ], // lista - broj komada i cijena
      cijenaVisible: [false],
      nc: '',
      toggle_kc: false,
      toggle_b: false,
      toggle_kol: false,
      shownQ: false,
      blur: false,
      src: '',
      shownImg: false,
      user: {},
      disabledChange: false,
      //sifra recepta:  legura-materijal-eloksiranje-dos-acex(ako postoje slijepe rupe / navoji)-boja
    }

    this.nacin_povrsinske_zastite = []
    this.podaci = { sifra: '', kolicina: 5, cijena: 23 }
    this.baChanged = this.baChanged.bind(this)
    this.bcChanged = this.bcChanged.bind(this)
    this.pChanged = this.pChanged.bind(this)
    this.bkChanged = this.bkChanged.bind(this)
    this.showInfo = this.showInfo.bind(this)
    this.hideInfo = this.hideInfo.bind(this)
    this.checked = this.checked.bind(this)
    this.renderConditionalIcon = this.renderConditionalIcon.bind(this)
    this.onMValueChanged = this.onMValueChanged.bind(this)
    this.onTValueChanged = this.onTValueChanged.bind(this)
    this.onNKValueChanged = this.onNKValueChanged.bind(this)
    this.onNPOValueChanged = this.onNPOValueChanged.bind(this)
    this.onHPPOValueChanged = this.onHPPOValueChanged.bind(this)
    this.onDOSValueChanged = this.onDOSValueChanged.bind(this)
    // this.onITDOSValueChanged = this.onITDOSValueChanged.bind(this);
    this.onBValueChanged = this.onBValueChanged.bind(this)
    this.setSifraRecepta = this.setSifraRecepta.bind(this)
    this.setNacinEloksiranja = this.setNacinEloksiranja.bind(this)
    this.minChanged = this.minChanged.bind(this)
    this.onE6ValueChanged = this.onE6ValueChanged.bind(this)
    this.onMinValueChanged = this.onMinValueChanged.bind(this)
    this.onMFocusIn = this.onMFocusIn.bind(this)
    this.onMFocusOut = this.onMFocusOut.bind(this)
    this.onTFocusIn = this.onTFocusIn.bind(this)
    this.onTFocusOut = this.onTFocusOut.bind(this)
    this.onNKFocusIn = this.onNKFocusIn.bind(this)
    this.onNKFocusOut = this.onNKFocusOut.bind(this)
    this.onNPZFocusIn = this.onNPZFocusIn.bind(this)
    this.onNPZFocusOut = this.onNPZFocusOut.bind(this)
    this.onHPPOFocusIn = this.onHPPOFocusIn.bind(this)
    this.onHPPOFocusOut = this.onHPPOFocusOut.bind(this)
    this.onDOSFocusIn = this.onDOSFocusIn.bind(this)
    this.onDOSFocusOut = this.onDOSFocusOut.bind(this)
    //this.onITDOSFocusIn = this.onITDOSFocusIn.bind(this);
    //this.onITDOSFocusOut = this.onITDOSFocusOut.bind(this);
    this.onBFocusIn = this.onBFocusIn.bind(this)
    this.onBFocusOut = this.onBFocusOut.bind(this)
    this.selectionChanged = this.selectionChanged.bind(this)
    this.selectionBChanged = this.selectionBChanged.bind(this)
    this.selectionNKChanged = this.selectionNKChanged.bind(this)
    this.naChanged = this.naChanged.bind(this)
    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.hideQ = this.hideQ.bind(this)
    this.showQ = this.showQ.bind(this)
    this.showImg = this.showImg.bind(this)
    this.toggleKc = this.toggleKc.bind(this)
    this.toggleB = this.toggleB.bind(this)
    this.toggleKol = this.toggleKol.bind(this)
    this.makeTimer()
  }
  componentWillMount = () => {
    window.addEventListener('keydown', (event) => {
      if (event.key == 'Escape' && this.state.modal_shown)
        this.setState({ modal_shown: false })
    })
  }
  makeTimer = async () => {
    setInterval(() => {
      if (this.state.upit && localStorage.getItem('nmx') && this.state.siid) {
        // // // console.log("update upita", this.state.upit);
        axios.put(
          `https://api.gat-crm.com:1121/upit/${this.state.siid}`,
          this.state.upit
        )
      }
      if (this.state.modal_shown && this.state.siid) {
        axios
          .get(`https://api.gat-crm.com:1121/s_p/${this.state.siid}`)
          .then((resp) => {
            this.setState({
              p: parseFloat(parseFloat(resp.data).toFixed(4)),
              pStr: parseFloat(resp.data).toFixed(4).toString(),
            })
            this.updateP()
          })
      }
    }, 1000)
  }
  // update u bazi kad se promijeni neki podatak u ovoj komponenti
  updateNa = async () => {
    const formDataSi = new FormData()
    formDataSi.append('na', this.state.na)
    formDataSi.append('n_b', 'n')

    const formDataKus = new FormData()
    formDataKus.append('na', this.state.na)
    formDataKus.append('n_b', 'n')

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    axios.all([
      axios.put(
        `https://api.gat-crm.com:1121/si/MS/${this.state.siid}`,
        formDataSi,
        config
      ),
      axios.put(
        `https://api.gat-crm.com:1121/kus/${
          this.state.siid.substr(3) +
          '-' +
          new Date().getFullYear().toString().substr(2, 2)
        }`,
        formDataKus,
        config
      ),
    ])
    //.then((resp) =>  // // console.log(resp, "UPDATE"));
  }

  updateBC = async () => {
    const formDataSi = new FormData()
    formDataSi.append('bc', this.state.bc)
    formDataSi.append('na', this.state.na)
    formDataSi.append('n_b', 'b')

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    axios.put(
      `https://api.gat-crm.com:1121/si/MS/${this.state.siid}`,
      formDataSi,
      config
    )
  }

  updateP = async () => {
    this.setState({
      upit: { ...this.state.upit, p: this.state.p },
      izracunata_cijena: false,

      cijenaVisible: new Array(this.state.cijenaVisible.length).fill(false),
    })
    const formDataS4 = new FormData()
    formDataS4.append('p', this.state.pStr)

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    axios.put(
      `https://api.gat-crm.com:1121/s4/${this.state.siid}`,
      formDataS4,
      config
    )
  }

  // dimenzioni kriterij ( tolerancije )
  updateDK = async (dk) => {
    // // // // console.log("Dimenzioni kriterij - update", dk);
    const formDataT2 = new FormData()
    formDataT2.append('dk', dk)
    formDataT2.append('dk_sr', 'dk')
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    axios.put(
      `https://api.gat-crm.com:1121/t2/dk/sr/${this.state.siid}`,
      formDataT2,
      config
    )
  }
  updateNK = async () => {
    //let listaNKMin = [0.25, 0.75, 2];
    //let listaNK = ["Jednostavno", "Srednje", "Složeno"];
    // // // // console.log("Nacin kacenja - update", this.state.nk_min);
    const formDataT2 = new FormData()
    formDataT2.append('nk', this.state.nk_min)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    axios.put(
      `https://api.gat-crm.com:1121/t2/nk/${this.state.siid}`,
      formDataT2,
      config
    )
  }

  updateBK = async () => {
    const formDataKus = new FormData()
    formDataKus.append('bk', this.state.bk)
    formDataKus.append('n_b', 'b')
    // // // // console.log("update kus");
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    axios.put(
      `https://api.gat-crm.com:1121/kus/${this.state.siid.substr(3) + '-21'}`,
      formDataKus,
      config
    )
  }
  // kad se dodaju podaci u T2 tabelu za trenutnu ponudu  - ( izračunata cijena )
  updateUpitT2 = async () => {
    const formDataUpitT2 = new FormData()
    formDataUpitT2.append('t2Dodan', true)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    axios.put(
      `https://api.gat-crm.com:1121/upit/t2/${this.state.siid}`,
      formDataUpitT2,
      config
    )
  }
  //props - from child to parent component
  handleCallback = (added_draw, nc) => {
    this.setState({ added_draw: added_draw, nc: nc })
  }

  //event handleri
  naChanged(e) {
    this.setState({
      na: e.value,
      upit: { ...this.state.upit, na: e.value },
      izracunata_cijena: false,
    })
    this.updateNa()
    // // // console.log("UPIT", this.state.upit);
  }

  baChanged(e) {
    this.setState({
      ba: e.value,
      upit: { ...this.state.upit, ba: e.value },
      izracunata_cijena: false,
    })
    this.setSifraRecepta()
    // // // console.log("UPIT", this.state.upit);
  }

  bcChanged(e) {
    this.setState({
      bc: e.value,
      upit: { ...this.state.upit, bc: e.value },
      izracunata_cijena: false,
    })
    this.setSifraRecepta()
    this.updateBC()
  }

  pChanged(e) {
    this.setState({
      p: Math.abs(e.value),
      pStr: Math.abs(e.value).toString().replace('.', ','),
      upit: { ...this.state.upit, p: e.value },
      izracunata_cijena: false,
      cijenaVisible: new Array(this.state.cijenaVisible.length).fill(false),
    })
    this.setSifraRecepta()
    this.updateP()
  }

  bkChanged(e) {
    this.setState({
      bk: Math.abs(e.value),
      upit: { ...this.state.upit, bk: e.value },
      izracunata_cijena: false,
    })
    this.setSifraRecepta()
    this.updateBK()
  }

  showModal() {
    this.setState({
      modal_shown: !this.state.modal_shown,
      izracunata_cijena: false,
    })

    // // // // console.log("Show", this.state.siid);
  }
  hideModal() {
    this.setState({
      modal_shown: false,
    })
  }
  hideInfo() {
    this.setState({
      popupVisible: false,
    })
  }
  hideQ() {
    this.setState({ shownQ: false })
  }
  showQ() {
    this.setState({ shownQ: true })
  }
  async showImg(e) {
    try {
      axios
        .get(
          `https://api.gat-crm.com:1121/base64/${this.props.location.state.upit.siid}`
        )
        .then((resp) => {
          this.setState({ src: resp.data.item2 })
          //console.log(resp.data)
          resp.data.item1.split('.')[1] === 'pdf'
            ? this.setState({ ext: 'pdf' })
            : this.setState({ ext: 'jpeg' })

          setTimeout(() => this.setState({ shownImg: true }), 1000)
        })

        .catch((err) => this.setState({ popup_1: true }))
    } catch (err) {
      this.setState({ popup_1: true })
    }
  }
  checked(e) {
    this.setState({
      slijepe_rupe: e.value,
      acex: e.value ? 'ACEX-' : '',
      upit: { ...this.state.upit, sr: e.value },
      izracunata_cijena: false,
    })
    this.setSifraRecepta()
  }

  onMValueChanged(e) {
    //// // // console.log("crtez", this.state.added);
    ////// // // // console.log(this.state.sifra_recepta)
    if (
      (e.value === 'EN AW 2017' || e.value === 'EN AW 7075') &&
      this.state.hppo === 'E7'
    )
      this.setState({
        hppo: 'E0',
        hppoN: 'sjajna površina',
        upit: { ...this.state.upit, hppo: 'E0', hppoN: 'sjajna površina' },
      })
    this.setState({
      m: e.value,
      upit: { ...this.state.upit, m: e.value.substr(-4) },
      izracunata_cijena: false,
    })
    this.setSifraRecepta()
    // // // // console.log("M", e.value);
  }
  onMFocusIn(e) {
    this.setState({ opis_m: true })
  }

  onMFocusOut(e) {
    this.setState({ opis_m: false })
  }

  onTValueChanged(e) {
    this.setState({
      t: e.value,
      upit: { ...this.state.upit, t: e.value },
      izracunata_cijena: false,
    })
    if (this.state.upit.isT2added) this.updateDK(e.value)
    this.setSifraRecepta()
  }

  onTFocusIn(e) {
    this.setState({ opis_t: true })
  }

  onTFocusOut(e) {
    this.setState({ opis_t: false })
  }

  onNKValueChanged(e) {
    this.setState({
      nk: e.value,
      upit: { ...this.state.upit, nk: e.value.item2 },
      izracunata_cijena: false,
    })

    this.setSifraRecepta()
    ////// // // // console.log('nk', this.state.nk)
  }

  onNKFocusIn(e) {
    this.setState({ opis_nk: true })
  }

  onNKFocusOut(e) {
    this.setState({ opis_nk: false })
  }

  onNPOValueChanged(e) {
    /*// // // console.log(
      e.value.split("-")[0].trim(),
      "\n",
      e.value.split("-")[1].trim()
    );*/
    this.setState({
      npz_value: e.value,
      min: '0',
      upit: {
        ...this.state.upit,
        npz: e.value.split('-')[1].trim(),
        npzN: e.value.split('-')[0].trim(),
      },
      izracunata_cijena: false,
    })
    if (e.value === 'Standardno eloksiranje - S') {
      this.setState({
        hppo: 'E0',
        dos: '5µm',
        hppoN: 'sjajna površina',
        //itdos: "+/-3µm",
        b: { item1: 'SI', item2: 'natur' },
        upit: {
          ...this.state.upit,
          hppo: 'E0',
          hppoN: 'sjajna površina',
          dos: '5µm',
          //itdos: "+/-3µm",
          b: 'SI',
          bn: 'natur',
        },
      })
    } else if (e.value === 'Tvrdo eloksiranje - XH4') {
      this.setState({
        hppo: 'E0',
        dos: '10µm',
        //itdos: "+/-5µm",
        b: { item1: 'SI', item2: 'natur' },
        upit: {
          ...this.state.upit,
          hppo: 'E0',
          hppoN: 'sjajna površina',
          dos: '10µm',
          //itdos: "+/-5µm",
          b: 'SI',
          bn: 'natur',
          hMin: '0',
        },
      })
    }
    ////// // // // console.log('NPZ', this.state.npz_value)
    //// // // console.log(this.state.upit);
    this.setNacinEloksiranja()
    this.setSifraRecepta()
  }

  onNPZFocusIn(e) {
    this.setState({ opis_npz: true })
  }

  onNPZFocusOut(e) {
    setTimeout(() => this.setState({ opis_npz: false }), 200)
  }

  onHPPOValueChanged(e) {
    this.setState({
      hppo: e.value,
      min: e.value === 'E6' ? '1' : e.value === 'E7' ? '3' : '0',
      hppoN:
        e.value === 'E0'
          ? 'Sjajna površina'
          : e.value === 'E7'
          ? 'Svilenkasto sjajna površina'
          : 'Kratko luženje',
      hppo_temp: e.value.substr(0, 2),
      upit: {
        ...this.state.upit,
        hppo: e.value,
        hppoN:
          e.value === 'E0'
            ? 'Sjajna površina'
            : e.value === 'E7'
            ? 'Svilenkasto sjajna površina'
            : this.state.e6
            ? this.state.e6
            : 'Kratko luženje',
      },
      hMin: e.value === 'E6' ? '1' : e.value === 'E7' ? '3' : '0',
      izracunata_cijena: false,
    })
    this.setSifraRecepta()
  }

  onHPPOFocusIn(e) {
    this.setState({ opis_hppo: true })
  }

  onHPPOFocusOut(e) {
    this.setState({ opis_hppo: false })
  }

  onE6ValueChanged(e) {
    let prevod = this.props.prevod
    let oz_j = this.props.oz_j
    let pom_niz = [
      prevod &&
      prevod.length !== 0 &&
      prevod[
        oz_j.indexOf(localStorage.getItem('lng')) *
          (prevod.length / oz_j.length) +
          278
      ]
        ? prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              278
          ].item2
        : 'Luženje na mjeru',
      prevod &&
      prevod.length !== 0 &&
      prevod[
        oz_j.indexOf(localStorage.getItem('lng')) *
          (prevod.length / oz_j.length) +
          279
      ]
        ? prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              279
          ].item2
        : 'Kratko luženje',
      prevod &&
      prevod.length !== 0 &&
      prevod[
        oz_j.indexOf(localStorage.getItem('lng')) *
          (prevod.length / oz_j.length) +
          280
      ]
        ? prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              280
          ].item2
        : 'Dugo luženje',
      prevod &&
      prevod.length !== 0 &&
      prevod[
        oz_j.indexOf(localStorage.getItem('lng')) *
          (prevod.length / oz_j.length) +
          281
      ]
        ? prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              281
          ].item2
        : 'Kratko luženje sa hemijskim poliranjem',
    ]
    if (e.value === pom_niz[0])
      this.setState({ min: '1', upit: { ...this.state.upit, hMin: '1' } })
    if (e.value === pom_niz[1])
      this.setState({ min: '2', upit: { ...this.state.upit, hMin: '2' } })
    if (e.value === pom_niz[3])
      this.setState({
        min: '1',
        upit: { ...this.state.upit, hMin: '1' },
        hppo_d: true,
      })
    if (e.value === pom_niz[2])
      this.setState({ min: '10', upit: { ...this.state.upit, hMin: '10' } })
    this.setState({
      e6: e.value,
      hppoN: e.value,
      upit: { ...this.state.upit, hppoN: e.value },
      izracunata_cijena: false,
    })
    this.setSifraRecepta()
    // // // // console.log(e.value);
  }
  onMinValueChanged(e) {
    if (this.state.e6 === 'Kratko luženje sa hemijskim poliranjem')
      this.setState({ hppo_temp: 'E6(' + e.value + ')/E7(3)' })
    this.setState({
      min: e.value,
      upit: { ...this.state.upit, hMin: e.value },
      izracunata_cijena: false,
    })
    this.setSifraRecepta()
    // // // // console.log("sifra", this.state.sifra_recepta, "MINUTE " + e.value);
  }
  onDOSValueChanged(e) {
    this.setState({
      dos: e.value,
      upit: { ...this.state.upit, dos: e.value },
      izracunata_cijena: false,
    })
    this.setSifraRecepta()
  }

  onDOSFocusIn(e) {
    this.setState({ opis_dos: true })
  }

  onDOSFocusOut(e) {
    this.setState({ opis_dos: false })
  }

  /* onITDOSValueChanged(e) {
    this.setState({
      //itdos: e.value,
      //upit: { ...this.state.upit, itdos: e.value },
      izracunata_cijena: false,
    });
    this.setSifraRecepta();
    // // // // console.log(this.props);
  }

  onITDOSFocusIn(e) {
    this.setState({ opis_itdos: true });
  }

  onITDOSFocusOut(e) {
    this.setState({ opis_itdos: false });
  }
*/
  onBValueChanged(e) {
    // // // // console.log(e.value.item1);
    this.setState({
      b: e.value,
      upit: { ...this.state.upit, b: e.value.item1, bn: e.value.item2 },
      izracunata_cijena: false,
    })
    this.setSifraRecepta()
    ////// // // // console.log('boja', this.state.b.s)
  }

  onBFocusIn(e) {
    this.setState({ opis_b: true })
  }

  onBFocusOut(e) {
    this.setState({ opis_b: false })
  }

  minChanged(e) {
    this.setState({
      min: e.value,
      upit: { ...this.state.upit, hMin: e.value },
      izracunata_cijena: false,
    })
    this.setSifraRecepta()
  }

  toggleKc(e) {
    this.setState({ toggle_kc: !this.state.toggle_kc })
  }
  toggleB(e) {
    this.setState({ toggle_b: !this.state.toggle_b })
  }
  toggleKol(e) {
    this.setState({ toggle_kol: !this.state.toggle_kol })
  }
  setSifraRecepta() {
    //// console.log(this.state.acex);
    const m_temp = this.state.m.substr(this.state.m.length - 4)
    let hppo_temp =
      this.state.hppo === 'E7'
        ? 'E7(3)'
        : this.state.e6 === 'Kratko luženje sa hemijskim poliranjem'
        ? `E6(${this.state.min})/E7(3)`
        : this.state.hppo === 'E6'
        ? `E6(${this.state.min})`
        : 'E0'
    hppo_temp =
      this.state.npz_value === 'Tvrdo eloksiranje - XH4' ? 'E0' : hppo_temp
    this.setState({
      sifra_recepta:
        this.state.dos !== null
          ? m_temp +
            '-' +
            hppo_temp +
            '-' +
            this.state.e +
            '-' +
            this.state.dos.toString().match(/\d+/) +
            '-' +
            this.state.acex +
            this.state.selectedBItem.item1
          : m_temp +
            '-' +
            hppo_temp +
            '-' +
            this.state.e +
            '-' +
            this.state.dos +
            '-' +
            this.state.acex +
            this.state.selectedBItem.item1,
    })
    if (this.state.npz_value === 'Kromatiranje Surtec 650 - CH') {
      this.setState({
        sifra_recepta: 'Kromatiranje SurTec 650',
      })
    }
    //setTimeout(() => console.log('SR', this.state.sifra_recepta), 1000)
    this.setState({
      popup_Shown: 0,
    })

    /* // // // console.log(
      "Sifra",
      this.state.sifra_recepta,
      "Kolicina",
      this.state.bk,
      "E6",
      this.state.e6 + " " + this.state.min
    );*/
    // // // // console.log("HPPE naziv : ", this.state.hppoN);
  }
  getData = async (uid) => {
    try {
      axios
        .get(`https://api.gat-crm.com:1121/adresa_km/${uid}`)
        .then((resp) => {
          this.setState({ adresa_komitenta: resp.data })
        })
      axios.get(`https://api.gat-crm.com:1121/id_km/${uid}`).then((resp) => {
        this.setState({ br_komitenta: resp.data })
      })
    } catch (err) {
      console.log(err)
    }
  }

  setNacinEloksiranja() {
    let n = this.state.npz_value
    this.setState({
      e:
        n === 'Standardno eloksiranje - S'
          ? 'S'
          : n === 'Tvrdo eloksiranje - XH4'
          ? 'XH4'
          : '',
    })
    //setTimeout(() => // // // // console.log("SR", this.state.sifra_recepta), 1000);
  }

  // handleri za input polja ( broj komada )
  handleAddClick = async () => {
    // // // console.log(this.state.inputLista);
    axios
      .put(
        `https://api.gat-crm.com:1121/newKus/${this.state.siid.substr(
          3
        )}-${this.state.upit.datum.substr(2, 2)}/${this.state.na}`
      ) // broj dokumenta/ naziv artikla
      .then((resp) => {
        this.setState((prevState) => ({
          inputLista: [...prevState.inputLista, resp.data], //random cijena
          cijenaVisible: [...prevState.cijenaVisible, false],
        }))
      })
    // .catch((err) =>  // console.log("Error"));
  }

  // brisanje
  handleDeleteClick = async (index, e) => {
    if (index === 0) this.setState({ popup_3: true })
    else {
      axios
        .delete(
          `https://api.gat-crm.com:1121/bk/${this.state.siid}/${this.state.inputLista[index].k9kcbkrb}`
        )
        .then((resp) => {
          let inputListaKopija = this.state.inputLista.slice()
          inputListaKopija.splice(index, 1)
          this.setState({ inputLista: inputListaKopija })
          // // // console.log(resp.data);
        })
      //  .catch((err) => // console.log("Error"));
    }
  }

  handleInputChange = async (index, e) => {
    if (e.value == this.state.inputLista)
      console.log(e.value, this.state.inputLista[index])
    const { value } = e
    const inputListaKopija = this.state.inputLista.slice() // kopija liste
    inputListaKopija[index].k9kcbkbk = Math.abs(value)
    this.setState({ inputLista: inputListaKopija })
    // // // console.log("INPUT LISTA", e, index);
    const formDataKus = new FormData()
    formDataKus.append('bk', Math.abs(value))
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }

    axios
      .put(
        `https://api.gat-crm.com:1121/bk/${this.state.siid}/${this.state.inputLista[index].k9kcbkrb}`,
        formDataKus,
        config
      )
      .then((resp) => {
        // // // console.log(resp);
        let cijenaVisibleKopija = this.state.cijenaVisible.slice()
        cijenaVisibleKopija[index] = false
        this.setState({ cijenaVisible: cijenaVisibleKopija })
      })
    //.catch((err) =>  // console.log("Error"));
  }

  racunajCijenuPoBK = async (i, e) => {
    if (this.state.modal_shown) {
      this.setState({ popup_p_modal: true })
    } else {
      if (!this.state.cijenaVisible[i]) {
        try {
          this.setState({ cijena_loading: true })
          const formData = new FormData()
          formData.append('sifra_recepta', this.state.sifra_recepta)
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          }

          await axios
            .post('https://api.gat-crm.com:1121/s_r/', formData, config)
            .then((resp) => {
              if (resp.data === '') {
                // ne postoji sifra u bazi za dati recept
                this.slozeniArtikal()
              } else {
                this.setState({ disabledChange: true })
                if (!this.state.upit.isT2added) {
                  // ukoliko već nisu dodane operacije u t2, dodati ih
                  axios
                    .all([
                      postArrayT2(
                        this.state.t,
                        this.state.siid,
                        resp.data, // sifra recepta
                        this.state.nk_min
                      ),
                      axios
                        .post(
                          `https://api.gat-crm.com:1121/cijena/${parseInt(
                            // racunaj cijenu
                            JSON.parse(
                              CryptoJS.AES.decrypt(
                                localStorage.getItem('nmx'),
                                'Adna1234'
                              ).toString(CryptoJS.enc.Utf8)
                            )
                          )}`,
                          {
                            sifra: this.state.siid,
                            kolicina: this.state.inputLista[i].k9kcbkbk,
                            povrsina: this.state.p.toString().replace('.', ','),
                          }
                        )
                        .then((resp) => {
                          if (resp.data.item1 === '-') {
                            this.updateUpitT2()
                            axios.put(
                              `https://api.gat-crm.com:1121/bk/c/${this.state.siid}/${this.state.inputLista[i].k9kcbkrb}`,
                              {
                                k9kcbkc: '-',
                              }
                            )

                            let inputListaKopija = this.state.inputLista.slice()
                            inputListaKopija[i].k9kcbkc = parseFloat(
                              resp.data.item1
                            ).toFixed(4)
                            this.setState({
                              cijena: '-',
                              valuta: resp.data.item2,
                              bk: inputListaKopija[i].k9kcbkbk,
                              izracunata_cijena: true,
                              upit: {
                                ...this.state.upit,
                                cijena: '-',
                                bk: inputListaKopija[i].k9kcbkbk,
                                isT2added: true,
                              },
                              cijena_loading: false,

                              inputLista: inputListaKopija,
                            })
                          } else {
                            this.updateUpitT2()
                            axios.put(
                              `https://api.gat-crm.com:1121/bk/c/${this.state.siid}/${this.state.inputLista[i].k9kcbkrb}`,
                              {
                                k9kcbkc: parseFloat(resp.data.item1)
                                  .toFixed(4)
                                  .toString(),
                              }
                            )

                            let inputListaKopija = this.state.inputLista.slice()
                            inputListaKopija[i].k9kcbkc = parseFloat(
                              resp.data.item1
                            ).toFixed(4)
                            this.setState({
                              cijena: parseFloat(resp.data.item1)
                                .toFixed(4)
                                .toString(),
                              valuta: resp.data.item2,
                              bk: inputListaKopija[i].k9kcbkbk,
                              izracunata_cijena: true,
                              upit: {
                                ...this.state.upit,
                                cijena: parseFloat(resp.data.item1)
                                  .toFixed(4)
                                  .toString(),
                                bk: inputListaKopija[i].k9kcbkbk,
                                isT2added: true,
                              },
                              cijena_loading: false,

                              inputLista: inputListaKopija,
                            })
                          }
                        }),
                    ])
                    .catch((err) => {
                      this.setState({ cijena_loading: false })
                      this.slozeniArtikal()
                    })
                } else {
                  // operacije postoje u bazi već, potrebno samo izračunati cijenu
                  axios
                    .post(
                      `https://api.gat-crm.com:1121/cijena/${parseInt(
                        JSON.parse(
                          CryptoJS.AES.decrypt(
                            localStorage.getItem('nmx'),
                            'Adna1234'
                          ).toString(CryptoJS.enc.Utf8)
                        )
                      )}`,
                      {
                        sifra: this.state.siid,
                        kolicina: this.state.inputLista[i].k9kcbkbk,
                        povrsina: this.state.p.toString().replace('.', ','),
                      }
                    )
                    .then((resp) => {
                      if (resp.data.item1 === '-') {
                        this.updateUpitT2()
                        axios.put(
                          `https://api.gat-crm.com:1121/bk/c/${this.state.siid}/${this.state.inputLista[i].k9kcbkrb}`,
                          { k9kcbkc: '-' }
                        )
                        /*   .then((resp) =>
                          // // console.log("UPDATE U BK TABELI: ", resp.data)
                        );*/
                        let inputListaKopija = this.state.inputLista.slice()
                        inputListaKopija[i].k9kcbkc = '-'
                        this.setState({
                          cijena: '-',
                          valuta: resp.data.item2,
                          bk: inputListaKopija[i].k9kcbkbk,
                          izracunata_cijena: true,
                          upit: {
                            ...this.state.upit,
                            cijena: '-',
                            bk: inputListaKopija[i].k9kcbkbk,
                            isT2added: true,
                          },
                          cijena_loading: false,
                          inputLista: inputListaKopija,
                        })
                        //  // // console.log("Cijena: ", resp.data);
                      } else {
                        this.updateUpitT2()
                        axios.put(
                          `https://api.gat-crm.com:1121/bk/c/${this.state.siid}/${this.state.inputLista[i].k9kcbkrb}`,
                          { k9kcbkc: parseFloat(resp.data.item1).toFixed(4) }
                        )
                        /*   .then((resp) =>
                          // // console.log("UPDATE U BK TABELI: ", resp.data)
                        );*/
                        let inputListaKopija = this.state.inputLista.slice()
                        inputListaKopija[i].k9kcbkc = parseFloat(
                          resp.data.item1
                        ).toFixed(4)
                        this.setState({
                          cijena: parseFloat(resp.data.item1)
                            .toFixed(4)
                            .toString(),
                          bk: inputListaKopija[i].k9kcbkbk,
                          valuta: resp.data.item2,

                          izracunata_cijena: true,
                          upit: {
                            ...this.state.upit,
                            cijena: parseFloat(resp.data.item1)
                              .toFixed(4)
                              .toString(),
                            bk: inputListaKopija[i].k9kcbkbk,
                            isT2added: true,
                          },
                          cijena_loading: false,
                          inputLista: inputListaKopija,
                          blur: false,
                        })
                      }
                    })
                    .catch((err) => {
                      this.setState({ cijena_loading: false })
                      this.slozeniArtikal()
                    })
                }
              }
            })
        } catch (err) {
          this.setState({ cijena_loading: false })

          this.slozeniArtikal()
        }
        const cijenaVisibleKopija = this.state.cijenaVisible.slice() // kopija liste
        cijenaVisibleKopija[i] = true
        this.setState({ cijenaVisible: cijenaVisibleKopija })
      }
    }
  }

  slozeniArtikal = () => {
    this.setState({ isT2added: false, cijena: 0 })
    this.setState({
      upit: { ...this.state.upit, isT2added: false, cijena: '0' },
    })

    this.props.history.push({
      pathname: '/s_a',
      state: {
        upit: {
          id: this.state.siid,
          ba: this.state.ba,
          na: this.state.na,
          bc: this.state.bc,
          m: this.state.m.substr(this.state.m.length - 4),
          p: this.state.p ? this.state.p : 0,
          t: this.state.t,
          s_r: this.state.slijepe_rupe ? this.state.slijepe_rupe : false,
          nk: this.state.selectedNKItem.item2,
          bk: this.state.bk,
          npz: this.state.npz_value ? this.state.npz_value : '',
          hppo: this.state.hppo,
          hppoN: this.state.hppoN,
          hppo_d: this.state.hppo_d,
          min: this.state.min,
          dos: this.state.dos,
          // itdos: this.state.itdos,

          //b_n: this.state.b_n ? this.state.b.item2 : "",

          b:
            this.state.selectedBItem.item1 +
            ' ( ' +
            this.state.selectedBItem.item2 +
            ' )',
          sifra_recepta: this.state.sifra_recepta,
          upit: this.state.upit,
        },
        user: this.state.user,
      },
    })
  }
  racunajCijenu = async (e) => {
    const { prevod, oz_j } = this.props
    if (!this.state.added_draw) {
      this.setState({ popup_2: true })
    } else {
      // // // // console.log(this.state.siid);
      try {
        //console.log(this.state.sifra_recepta)
        const formData = new FormData()
        formData.append('sifra_recepta', this.state.sifra_recepta)
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
        this.setState({ cijena_loading: true })
        await axios
          .post('https://api.gat-crm.com:1121/s_r/', formData, config)
          .then((resp) => {
            this.setState({ cijena_loading: false })
            if (resp.data === '') {
              // ne postoji sifra u bazi za dati recept
              this.slozeniArtikal()
            } else {
              this.setState({ disabledChange: true })
              if (!this.state.upit.isT2added) {
                // ukoliko već nisu dodane operacije u t2, dodati ih
                axios
                  .all([
                    postArrayT2(
                      this.state.t,
                      this.state.siid,
                      resp.data, // sifra recepta
                      this.state.nk_min
                    ),
                    axios
                      .post(
                        `https://api.gat-crm.com:1121/cijena/${parseInt(
                          // racunaj cijenu
                          JSON.parse(
                            CryptoJS.AES.decrypt(
                              localStorage.getItem('nmx'),
                              'Adna1234'
                            ).toString(CryptoJS.enc.Utf8)
                          )
                        )}`,
                        {
                          sifra: this.state.siid,
                          kolicina: this.state.bk,
                          povrsina: this.state.p.toString().replace('.', ','),
                        }
                      )
                      .then((resp) => {
                        //  // // console.log(resp.data);
                        if (resp.data.item1 === '-') {
                          this.showInfo()
                          this.updateUpitT2()
                          axios.put(
                            `https://api.gat-crm.com:1121/${this.state.siid}`
                          )
                          //.then(/*(resp) =>  // console.log(resp)*/);
                          this.setState({
                            cijena: '-',
                            valuta: resp.data.item2,
                            upit: {
                              ...this.state.upit,
                              cijena: '-',
                              isT2added: true,
                            },
                            izracunata_cijena: true,
                            cijena_loading: false,
                          })
                        } else {
                          this.showInfo()
                          this.updateUpitT2()
                          axios.put(
                            `https://api.gat-crm.com:1121/${this.state.siid}`
                          )
                          // .then((resp) =>  // console.log(resp));
                          this.setState({
                            cijena: parseFloat(resp.data.item1)
                              .toFixed(4)
                              .toString(),
                            valuta: resp.data.item2,
                            upit: {
                              ...this.state.upit,
                              cijena: parseFloat(resp.data.item1)
                                .toFixed(4)
                                .toString(),
                              isT2added: true,
                            },
                            izracunata_cijena: true,
                            cijena_loading: false,
                          })
                        }
                      }),
                  ])
                  .catch((err) => this.slozeniArtikal())
              } else {
                // operacije postoje u bazi već, potrebno samo izračunati cijenu
                axios
                  .post(
                    `https://api.gat-crm.com:1121/cijena/${parseInt(
                      JSON.parse(
                        CryptoJS.AES.decrypt(
                          localStorage.getItem('nmx'),
                          'Adna1234'
                        ).toString(CryptoJS.enc.Utf8)
                      )
                    )}`,
                    {
                      sifra: this.state.siid,
                      kolicina: this.state.bk,
                      povrsina: this.state.p.toString().replace('.', ','),
                    }
                  )
                  .then((resp) => {
                    //  // // console.log(resp.data);
                    if (resp.data.item1 === '-') {
                      this.showInfo()
                      axios.put(
                        `https://api.gat-crm.com:1121/bk_k/${this.state.siid}/${this.state.bk}/-`
                      )
                      this.updateUpitT2()
                      this.setState({
                        cijena: '-',
                        valuta: resp.data.item2,
                        upit: {
                          ...this.state.upit,
                          cijena: '-',
                          isT2added: true,
                        },
                        izracunata_cijena: true,
                        cijena_loading: false,
                      })
                    } else {
                      this.showInfo()
                      axios.put(
                        `https://api.gat-crm.com:1121/bk_k/${this.state.siid}/${
                          this.state.bk
                        }/${parseFloat(resp.data.item1).toFixed(4).toString()}` //  cijena = resp.data.item1
                      )

                      this.updateUpitT2()
                      this.setState({
                        cijena: parseFloat(resp.data.item1)
                          .toFixed(4)
                          .toString(),
                        valuta: resp.data.item2,
                        upit: {
                          ...this.state.upit,
                          cijena: parseFloat(resp.data.item1)
                            .toFixed(4)
                            .toString(),
                          isT2added: true,
                        },
                        izracunata_cijena: true,
                        cijena_loading: false,
                      })
                    }
                  })
                  .catch((err) => this.slozeniArtikal())
              }
            }
          })
      } catch (err) {
        // // // // console.log(err);
        this.setState({ cijena_loading: false })
        this.slozeniArtikal()
      }
    }
  }

  // učitavanje komponente
  componentDidMount = async () => {
    let uid = JSON.parse(
      CryptoJS.AES.decrypt(localStorage.getItem('nmx'), 'Adna1234').toString(
        CryptoJS.enc.Utf8
      )
    )
    this.getData(uid)
    try {
      if (localStorage.getItem('n_k') && localStorage.getItem('n_k') <= 1) {
        this.setState({ shownQ: true })
      } //novi korisnik
      if (this.props.location.state) {
        // update postojeceg upita
        const u = this.props.location.state.upit

        axios
          .get(`https://api.gat-crm.com:1121/crtez/${u.siid}`)
          .then((resp) => {
            //// // console.log(resp);
            this.setState({
              added_draw: true,
              crtez: resp.data,
              nc: resp.data.k9kccnc.includes('_')
                ? resp.data.k9kccnc.replace(
                    resp.data.k9kccnc.substring(resp.data.k9kccnc.indexOf('_')),
                    ''
                  )
                : resp.data.k9kccnc.replace(
                    resp.data.k9kccnc.substring(resp.data.k9kccnc.indexOf('.')),
                    ''
                  ),
            })
          })
          .catch((err) =>
            this.setState({ added_draw: false, crtez: null, nc: '' })
          )

        axios
          .get(`https://api.gat-crm.com:1121/kuses/bk/${u.siid}`)
          .then((resp) => {
            let pom = []
            if (resp.data) {
              for (var i = 0; i < resp.data.length; i++) {
                pom.push(resp.data[i].k9kcbkc > 0)
              }
              this.setState({
                inputLista: resp.data,
                cijenaVisible: pom,
                disabledChange:
                  resp.data &&
                  resp.data.length > 0 &&
                  pom.filter((p) => p == true).length > 0
                    ? true
                    : false,
              })
            }
          })

        this.setState({
          siid: u.siid,

          ba: u.ba,
          na: u.na,
          bc: u.bc,
          m: 'EN AW ' + u.m,
          p: u.p,
          t: u.t,
          slijepe_rupe: u.sr,
          nk: { item1: u.nk, item2: u.nk },
          bk: u.bk,
          npz_value: u.npzN + ' - ' + u.npz,
          hppo: u.hppo,
          hppoN: u.hppoN,
          e6: u.hppo === 'E6' ? u.hppoN : '',
          dos: u.dos,
          //itdos: u.itdos,
          min: u.hMin.toString(),
          cijena: u.cijena,
          e: u.npz === 'CH' ? '' : u.npz,
          h_p: u.hppoD ? true : false,
          hppo_temp: u.hppo,
          acex: u.sr ? 'ACEX-' : '',
          b: {
            item1: u.b,
            item2: u.bn,
          },

          upit: u,
        })
        setTimeout(() => this.setSifraRecepta(), 1000)
      } else if (this.props.history.location.state) {
        // dorada slozenog artikla
        const u = this.props.history.location.state.upit // pomocna konstanta radi jednostavnijeg ispisa

        axios
          .get(`https://api.gat-crm.com:1121/crtez/${u.siid}`)
          .then((resp) => {
            this.setState({
              added_draw: true,
              crtez: resp.data,
              nc: resp.data.k9kccnc,
            })
          })
          .catch((err) =>
            this.setState({ added_draw: false, crtez: null, nc: '' })
          )
        axios
          .get(`https://api.gat-crm.com:1121/kuses/bk/${u.siid}`)
          .then((resp) => {
            let pom = []
            //console.log(resp.data)
            if (resp && resp.data) {
              for (var i = 0; i < resp.data.length; i++) {
                let a = resp.data[i].k9kcbkc != '0'
                pom.push(a)
              }
              this.setState({
                inputLista: resp.data,
                cijenaVisible: pom,
                disabledChange:
                  resp.data &&
                  resp.data.length > 0 &&
                  pom.filter((p) => p == true).length > 0
                    ? true
                    : false,
              })
            }
          })

        /* // // // console.log(
          "update slozenog artikla",
          "A " + this.props.history.location.state.upit.hppo,
          this.props.history.location.state.upit.hppoN
        );*/
        this.setState({
          siid: u.siid,
          na: u.na,
          ba: u.ba,
          bc: u.bc,
          bk: u.bk,
          dos: u.dos,
          //itdos: u.itdos,
          m: u.m,
          min: u.min,
          h_p: u.h_p,
          hppoN: u.hppoN,
          selectedBItem: u.selectedBItem,
          npz_value: u.npz_value,
          selectedNKItem: { item1: u.nk, item2: u.nk },
          nk: { item1: u.nk, item2: u.nk },
          t: u.t,
          p: u.p,
          hppo: u.hppo,
          sifra_recepta: u.sifra_recepta,
          b: u.selectedBItem,
          slijepe_rupe: u.slijepe_rupe,
          acex: u.slijepe_rupe ? 'ACEX-' : '',
          upit: u.upit,
          e6: u.e6,
        })
      } else {
        this.setState({
          new_item: true,
          min: '0',
          m: 'EN AW 2017',
          hppo: 'E0',
          npz_value: 'Standardno eloksiranje - S',
          dos: '5µm',
          //itdos: "+/-3µm",
          b: { item1: 'SI', item2: 'natur' },
          sifra_recepta: '2017-E0-S-5-SI-V',
          upit: { ...this.state.upit, isT2added: false },
        })
        postSiKuzKusT0T1S4(
          '-',
          JSON.parse(
            CryptoJS.AES.decrypt(
              localStorage.getItem('nmx'),
              'Adna1234'
            ).toString(CryptoJS.enc.Utf8)
          ),
          '0000',
          0
        ).then((resp) => {
          this.setState({
            siid: resp.data.item1.siid,
            upit: resp.data.item1,
            upitPom: resp.data.item1,
            inputLista: [resp.data.item2],
          })
          // // // // console.log("Si");
          //// // // console.log(resp.data.item2);
        })
      }
    } catch (err) {
      // // // // console.log(err);
    }
    if (localStorage.getItem('nmx')) {
      try {
        axios
          .get(
            'https://api.gat-crm.com:1121/user/' +
              JSON.parse(
                CryptoJS.AES.decrypt(
                  localStorage.getItem('nmx'),
                  'Adna1234'
                ).toString(CryptoJS.enc.Utf8)
              )
          )
          .then((resp) => {
            this.setState({ user: resp.data })
          })
      } catch (err) {
        console.log(err)
      }
    }
  }

  render() {
    if (this.state.isError) return <ServerErrorPage />
    if (!localStorage.getItem('token')) return <Login />
    // if (!localStorage.getItem("token")) window.location.href("/user/login");
    const { prevod, oz_j } = this.props
    let { ba, bc, bk, cijena, na, inputLista } = this.state
    return (
      <div id="upit" className={this.state.modal_shown ? 'modal_shown' : ''}>
        {' '}
        <div
          id="q_div"
          style={{
            position: 'fixed',
            top: '50px',
            right: '20vw',
            zIndex: '10000000',
          }}
        >
          {' '}
          <span onClick={this.showQ} id="q">
            {' '}
            ?
          </span>
        </div>
        <div
          style={{
            color: 'white !important',
            backgroundColor: 'black !important',
          }}
        >
          <Popup
            shadingColor="rgba(0, 0, 0, 0.5)"
            container=".dx-viewport"
            visible={this.state.shownQ}
            onHiding={this.hideQ}
            dragEnabled={false}
            closeOnOutsideClick={true}
            showTitle={true}
            title={'Upute za upit'}
            style={{ width: '80vw', height: '80vh' }}
          >
            <div style={{ overflowY: 'scroll', height: '100% ' }}>
              <b>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    47
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        47
                    ].item2
                  : 'Podaci o proizvodu'}
              </b>
              <u>
                <p>
                  {prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      50
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          50
                      ].item2
                    : 'Broj artikla'}{' '}
                  {','}
                  {prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      48
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          48
                      ].item2
                    : 'Naziv artikla'}{' '}
                  {','}
                  {prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      51
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          51
                      ].item2
                    : 'Broj crteža'}
                </p>
              </u>
              <p>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    285
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        285
                    ].item2
                  : 'U polje „Podaci o proizvodu“ neophodno je unijeti podatke navedene na crtežu: broj artikla, naziv artikla i broj crteža. Sa navedenim podacima artiklu se dodjeljuje šifra koja služi za prepoznavanje i pretraživanje artikla.'}
              </p>
              <p>
                {' '}
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    286
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        286
                    ].item2
                  : 'Neophodno je učitati crtež artikla klikom na polje „spremite crtež“, koji može biti u formatu pdf, png, jpg. Klikom na polje „crtež“ vrši se pregled učitanog crteža i po potrebi se isti može preuzeti ili isprintati.'}
              </p>
              <u>
                <p>
                  {prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      52
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          52
                      ].item2
                    : 'Materijal'}
                </p>
              </u>
              <p>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    287
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        287
                    ].item2
                  : 'Iz padajuće liste odabrati leguru aluminijuma od kojeg se artikl izrađuje. Ukoliko ponuđene legure ne odgovaraju traženoj bira se legura iz iste serije aluminijum legure, tako npr. ako se artikl izrađuje iz legure EN AW 6060 (AlMgSi0,5) birati leguru EN AW 6082 (AlMgSi1).'}
              </p>
              <u>
                <p>
                  {prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      53
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          53
                      ].item2
                    : 'Površina jednog komada (dm2)'}
                </p>
              </u>
              <p>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    288
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        288
                    ].item2
                  : 'Unos površine komada se vrši:'}
              </p>
              <p>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    289
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        289
                    ].item2
                  : '1.	Ručno – unosom broja u predviđeno polje (poznatu površinu smo dobili iz step prikaza komada)'}
              </p>{' '}
              <p>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    290
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        290
                    ].item2
                  : '2.	Računanje površine komada pomoću kalkulatora površine. Klikom na polje „računaj površinu“ otvori se prozor za kalkulaciju površine komada. U desnom dijelu bira se geometrijsko tijelo koje odgovara artiklu. Klikom na jedno od 4 geometrijska tijela prikažu se polja za unos dimenzija komada za odabrano tijelo. Klikom na oznaku „+“ računamo površinu i dobivena vrijednost se prikaže u tabeli pored. Za isti artikl, ukoliko je složenog oblika, gdje se na istom komadu računa površina iz dva ili tri dijela, moguće i nejednakih oblika, biramo opciju „+“ i odabiremo isto ili drugo geometrijsko tijelo za račun površine. Zbir vrijednosti svih izračunatih površina jednog komada iz tabele prikazuje se u polju „površina jednog komada“.  Nakon izračunate površine zatvoriti kalkulator površine klikom na polje „zatvorite kalkulator“.'}
              </p>
              <b>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    58
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        58
                    ].item2
                  : 'Podaci o površinskoj zaštiti'}
              </b>
              <p>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    291
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        291
                    ].item2
                  : 'Unosom svih podataka u dio „Podaci o površinskoj zaštititi“ kreiramo recept eloksiranja. Neophodno je da su odabrani podaci u web kalkulatoru navedeni i na crtežu artikla, kao što su način površinske obrade, debljina oksidnog sloja i boja.'}{' '}
              </p>
              <u>
                <p>
                  {prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      54
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          54
                      ].item2
                    : 'Dimenzioni kriterij'}
                </p>
              </u>
              <p>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    292
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        292
                    ].item2
                  : 'Odabrati jedan od tri ponuđena dimenziona kriterija sa padajuće liste koji odgovara kotama na učitanom crtežu.'}
              </p>
              <u>
                <p>
                  {prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      56
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          56
                      ].item2
                    : 'Način kačenja'}
                </p>
              </u>
              <p>
                {' '}
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    293
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        293
                    ].item2
                  : 'Odabrati jedan od tri ponuđena načina kačenja sa padajuće liste. Kratki opis načina kačenja prikaže se automatski klikom na padajuću listu. Klikom na sliku otvori se galerija sa primjerima načina kačenja.'}
              </p>
              <u>
                <p>
                  {prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      233
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          233
                      ].item2
                    : 'Slijepe rupe manje ili jednake promjeru'}{' '}
                  {prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      234
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          234
                      ].item2
                    : ' 5 mm i/ili Navoji  manji ili jednaki M5'}
                </p>
              </u>
              <p>
                {' '}
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    294
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        294
                    ].item2
                  : 'Ako na komadu postoji slijepa/neprolazna rupa manja ili jednaka promjeru 5mm ili navoj manji ili jednak M5 odabrati „DA“, ako ne postoji odabrati „NE“.'}
              </p>
              <u>
                {' '}
                <p>
                  {' '}
                  {prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      59
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          59
                      ].item2
                    : 'Način površinske obrade'}
                </p>
              </u>
              <p>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    295
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        295
                    ].item2
                  : 'Odabrati jedno od tri ponuđena načina površinske obrade sa padajuće liste. Kratki opis površinske obrade prikaže se automatski klikom na padajuću listu.'}
              </p>
              <u>
                <p>
                  {prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      60
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          60
                      ].item2
                    : 'Hemijska predobrada'}{' '}
                  {prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      89
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          89
                      ].item2
                    : 'prije eloksiranja'}
                </p>
              </u>
              <p>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    296
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        296
                    ].item2
                  : 'Odabrati jedno od tri ponuđena načina hemijske predobrade prije eloksiranja sa padajuće liste. Kratki opis hemijske predobrade prikaže se automatski klikom na padajuću listu.'}
              </p>
              <u>
                <p>
                  {prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      63
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          63
                      ].item2
                    : 'Debljina oksidnog sloja'}
                </p>
              </u>
              <p>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    297
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        297
                    ].item2
                  : 'Odabrati jedno od ponuđenih debljina oksidnog sloja sa padajuće liste. '}
              </p>
              <u>
                <p>
                  {prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      156
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          156
                      ].item2
                    : 'Boja'}
                </p>
              </u>
              <p>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    298
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        298
                    ].item2
                  : 'Odabrati jedno od ponuđenih boja sa padajuće liste.'}
              </p>
              <b>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    198
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        198
                    ].item2
                  : 'Proizvod se ne može opisati datim podacima? Idite na složeni artikl'}
              </b>
              <p>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    299
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        299
                    ].item2
                  : 'Kada zahtjev površinske obrade nije moguće definisati datim podacima kliknuti strelicu u kvadratiću pored rečenice „ Proizvod se ne može opisati datim podacima? Idite na složeni artikl.“ '}
              </p>{' '}
              <p>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    300
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        300
                    ].item2
                  : 'Klikom na strelicu otvori se prozor sa podacima evidentiranim u upitu web kalkulatora za koji ne postoji recept površinske obrade. Klikom na „pošalji upit“ kreira se mail sa navedenim podacima površinske obrade koji se šalje administratoru za uslugu web kalkulatora.'}{' '}
              </p>
              <b>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    211
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        211
                    ].item2
                  : 'Računanje cijene za količine'}
              </b>{' '}
              <p>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    301
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        301
                    ].item2
                  : 'Za izračun cijene unijeti u označeno polje željeni broj komada i kliknuti tipku „kalkulator“. Prikaže se cijena artikla/komad. Klikom na polje „dodaj novu količinu“ za isti artikl je moguće izračunati cijenu i za drugu količinu.  Klikom na polje „naruči“ automatski se kreira narudžba za odabrani broj komada u GAT bazi i za istu u roku od 24h dobićete mail-om potvrdu narudžbe od administratora.'}{' '}
              </p>
              {/** KRAJ UPUTA */}
            </div>
          </Popup>
        </div>
        <div id="k_p_h_u">
          <div id="korisnik_panel_header_u">
            <div
              style={{
                position: 'fixed',
                left: '25px',
                top: '17px',
                padding: '3px 5px',
                color: 'white',
              }}
              onClick={() => this.props.history.goBack()}
            >
              <img
                src={back}
                height="24px"
                width="24px"
                style={{ margin: '0', padding: '0' }}
              />
            </div>
            <div id="k_p_logo">
              <img src={GatLogo} alt="gat_logo" width="60px" />
            </div>
            <div id="number">
              <p>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    42
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        42
                    ].item2 + ':'
                  : 'Naziv firme:'}{' '}
                {this.state.user.companyName} {'   '}{' '}
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    248
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        248
                    ].item2 + ':'
                  : 'Korisnik:'}{' '}
                {this.state.user.firstName + '   ' + this.state.user.lastName}
              </p>
              <p>+387 37 689 113</p>
            </div>
            <OdjavaButtonWhite />
          </div>
        </div>
        <div style={{ height: '3px' }}></div>
        <div className="upit-component">
          <div className="form">
            <div className="dx-fieldset">
              <div className="dx-field">
                <div className="dx-field-label h1">
                  <h1 style={{ marginTop: '70px' }}>
                    {' '}
                    {prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        47
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            47
                        ].item2
                      : 'Podaci o proizvodu'}
                  </h1>
                </div>
              </div>
            </div>

            <Popup
              shadingColor="rgba(0, 0, 0, 0.5)"
              container=".dx-viewport"
              visible={this.state.popup_3}
              width={500}
              height={200}
              onHiding={() => this.setState({ popup_3: false })}
              dragEnabled={false}
              closeOnOutsideClick={true}
              showTitle={true}
              title={
                prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    246
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        246
                    ].item2
                  : 'Zabranjeno brisanje ovog input polja!'
              }
            ></Popup>
            <Popup
              shadingColor="rgba(0, 0, 0, 0.5)"
              container=".dx-viewport"
              visible={this.state.popup_p_modal}
              width={1000}
              height={200}
              onHiding={() => this.setState({ popup_p_modal: false })}
              dragEnabled={false}
              closeOnOutsideClick={true}
              showTitle={true}
              title={
                prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    247
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        247
                    ].item2
                  : 'Molimo Vas da zatvorite kalkulator površine prije računanja cijene upita'
              }
            ></Popup>
            <div className="card">
              <Box height={50}>
                <Item ratio={1}>
                  <div className="dx-fieldset">
                    <div className="dx-field">
                      <div className="dx-field-label label">
                        {prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            50
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                50
                            ].item2
                          : 'Broj artikla'}
                        <span className="star">*</span>
                      </div>
                      <div className="dx-field-value">
                        <TextBox
                          name="broj-artikla"
                          value={ba}
                          onValueChanged={this.baChanged}
                          showSpinButtons={true}
                          disabled={this.state.disabledChange}
                        >
                          <Validator>
                            <CustomRule
                              type="required"
                              message={
                                prevod &&
                                prevod.length !== 0 &&
                                prevod[
                                  oz_j.indexOf(localStorage.getItem('lng')) *
                                    (prevod.length / oz_j.length) +
                                    49
                                ]
                                  ? prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        49
                                    ].item2
                                  : 'Obavezno polje'
                              }
                            />
                          </Validator>
                        </TextBox>
                      </div>
                    </div>
                  </div>
                </Item>
                <Item ratio={1}></Item>
              </Box>
              <Box height={50}>
                <Item ratio={1}>
                  <div className="dx-fieldset">
                    <div className="dx-field n_a">
                      <div className="dx-field-label label">
                        {prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            48
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                48
                            ].item2
                          : 'Naziv artikla'}{' '}
                        <span className="star">*</span>
                      </div>
                      <div className="dx-field-value ">
                        <TextBox
                          name="naziv-artikla"
                          value={na}
                          onValueChanged={this.naChanged}
                          showSpinButtons={true}
                          disabled={this.state.disabledChange}
                        >
                          <Validator>
                            <CustomRule
                              type="required"
                              message={
                                prevod &&
                                prevod.length !== 0 &&
                                prevod[
                                  oz_j.indexOf(localStorage.getItem('lng')) *
                                    (prevod.length / oz_j.length) +
                                    49
                                ]
                                  ? prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        49
                                    ].item2
                                  : 'Obavezno polje'
                              }
                            />
                          </Validator>
                        </TextBox>
                      </div>
                    </div>
                  </div>
                </Item>
                <Item ratio={1}></Item>
              </Box>

              <Box height={50} className={this.state.opis_m ? 'materijal' : ''}>
                <Item ratio={1}>
                  <div className="dx-fieldset">
                    <div className="dx-field">
                      <div className="dx-field-label label">
                        {prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            52
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                52
                            ].item2
                          : 'Materijal'}{' '}
                        <span className="star">*</span>
                      </div>
                      <div className="dx-field-value">
                        <SelectBox
                          items={this.props.m_list}
                          value={this.state.m}
                          onValueChanged={this.onMValueChanged}
                          onFocusIn={this.onMFocusIn}
                          onFocusOut={this.onMFocusOut}
                          placeholder="Izaberi ..."
                          disabled={this.state.disabledChange}
                          // dropDownButtonRender={ImageIcon}
                        >
                          <Template
                            name="conditionalIcon"
                            render={this.renderConditionalIcon}
                          />
                          <Validator>
                            <CustomRule
                              type="required"
                              message={
                                prevod &&
                                prevod.length !== 0 &&
                                prevod[
                                  oz_j.indexOf(localStorage.getItem('lng')) *
                                    (prevod.length / oz_j.length) +
                                    49
                                ]
                                  ? prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        49
                                    ].item2
                                  : 'Obavezno polje'
                              }
                            />
                          </Validator>
                        </SelectBox>
                      </div>
                    </div>
                  </div>
                </Item>
                <Item
                  ratio={1}
                  className={this.state.opis_m ? 'materijal_opis' : ''}
                >
                  <div className="dx-fieldset">
                    <div
                      className={
                        this.state.opis_m
                          ? 'dx-field materijal_opis'
                          : 'dx-field'
                      }
                    >
                      {this.state.opis_m ? <MaterijalOpis /> : ''}
                    </div>
                  </div>
                </Item>
              </Box>
            </div>
            <div className="card card_p">
              <Box height={40}>
                <Item ratio={1}>
                  <div className="dx-fieldset">
                    <div className="dx-field">
                      <div className="dx-field-label label label_p ">
                        {prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            53
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                53
                            ].item2
                          : 'Površina jednog komada (dm2)'}
                        <span className="star">*</span>
                      </div>
                      <div className="dx-field-value">
                        <NumberBox
                          name="povrsina"
                          value={this.state.p}
                          onValueChanged={this.pChanged}
                          showSpinButtons={true}
                          disabled={this.state.disabledChange}
                        >
                          <Validator>
                            <CustomRule
                              type="required"
                              message={
                                prevod &&
                                prevod.length !== 0 &&
                                prevod[
                                  oz_j.indexOf(localStorage.getItem('lng')) *
                                    (prevod.length / oz_j.length) +
                                    49
                                ]
                                  ? prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        49
                                    ].item2
                                  : 'Obavezno polje'
                              }
                            />
                          </Validator>
                        </NumberBox>
                      </div>
                    </div>
                  </div>
                  <br />
                </Item>
                <Item ratio={1}>
                  <div className="r_p_btn kalk_pov">
                    <Button
                      style={{
                        width: '120px',

                        padding: 'auto 0 !important',
                        textAlign: 'center',
                      }}
                      useSubmitBehavior={true}
                      onClick={this.showModal}
                      alignment="center"
                      text={
                        this.state.modal_shown
                          ? prevod &&
                            prevod.length !== 0 &&
                            prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                262
                            ]
                            ? prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  262
                              ].item2
                            : 'Zatvorite kalkulator'
                          : prevod &&
                            prevod.length !== 0 &&
                            prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                189
                            ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                189
                            ].item2
                          : 'Računaj površinu'
                      }
                      disabled={this.state.disabledChange}
                      type="default"
                    />
                  </div>
                </Item>
              </Box>
            </div>
            {this.state.modal_shown ? (
              <div className="card">
                <Modal props={{ id: this.state.siid }} />
              </div>
            ) : (
              ''
            )}
          </div>{' '}
          <div style={{ height: '3px' }}></div>
          <div className="form">
            <div className="dx-fieldset p_o_p_z">
              <div className="dx-field">
                <div className="dx-field-label  label h1">
                  <h1>
                    {prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        58
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            58
                        ].item2
                      : 'Podaci o površinskoj zaštiti'}
                  </h1>
                </div>
              </div>
            </div>

            <div className="card card-smaller-dk-nk-sr">
              <Box
                height={50}
                className={this.state.opis_t ? 'dimenzioni_kriterij' : ''}
              >
                <Item ratio={1}>
                  <div className="dx-fieldset">
                    <div className="dx-field">
                      <div className="dx-field-label label">
                        {prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            54
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                54
                            ].item2
                          : 'Dimenzioni kriterij'}{' '}
                        <span className="star">*</span>
                      </div>
                      <div className="dx-field-value">
                        <SelectBox
                          items={tolerancije}
                          value={this.state.t}
                          itemRender={TextItemDK}
                          onValueChanged={this.onTValueChanged}
                          onFocusIn={this.onTFocusIn}
                          onFocusOut={this.onTFocusOut}
                          disabled={this.state.disabledChange}
                          placeholder={
                            prevod &&
                            prevod.length !== 0 &&
                            prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                62
                            ]
                              ? prevod[
                                  oz_j.indexOf(localStorage.getItem('lng')) *
                                    (prevod.length / oz_j.length) +
                                    62
                                ].item2
                              : 'Izaberi ...'
                          }
                          // dropDownButtonRender={ImageIcon}
                        >
                          <Validator>
                            <CustomRule
                              type="required"
                              message={
                                prevod &&
                                prevod.length !== 0 &&
                                prevod[
                                  oz_j.indexOf(localStorage.getItem('lng')) *
                                    (prevod.length / oz_j.length) +
                                    49
                                ]
                                  ? prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        49
                                    ].item2
                                  : 'Obavezno polje'
                              }
                            />
                          </Validator>
                        </SelectBox>
                      </div>
                    </div>
                  </div>
                </Item>
                <Item ratio={1}>
                  <div
                    className={
                      this.state.opis_t
                        ? 'dx-fieldset dimenzioni_kriterij_opis'
                        : 'dx-fieldset'
                    }
                  >
                    {this.state.opis_t ? <TolerancijeOpis /> : ''}
                  </div>
                </Item>
              </Box>
              <Box
                height={50}
                className={this.state.opis_nk ? 'nacin_kacenja' : ''}
              >
                <Item ratio={1}>
                  <div className="dx-fieldset">
                    <div className="dx-field">
                      <div className="dx-field-label label">
                        {prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            56
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                56
                            ].item2
                          : 'Način kačenja'}{' '}
                        <span className="star">*</span>
                      </div>
                      <div className="dx-field-value">
                        <SelectBox
                          dataSource={this.props.nk}
                          disabled={this.state.disabledChange}
                          defaultValue={this.state.nk.item2}
                          selectedItem={this.state.selectedNKItem}
                          displayExpr="item2"
                          valueExpr="item2"
                          itemRender={ImageItem}
                          fieldRender={ImageField}
                          onFocusIn={this.onNKFocusIn}
                          onFocusOut={this.onNKFocusOut}
                          //dropDownButtonTemplate="conditionalIcon"
                          onSelectionChanged={this.selectionNKChanged}
                          // dropDownButtonRender={ImageIcon}
                        ></SelectBox>
                      </div>
                    </div>
                  </div>
                </Item>
                <Item ratio={1}>
                  <div
                    className={this.state.opis_nk ? 'nacin_kacenja_opis' : ''}
                  >
                    {this.state.opis_nk ? (
                      <NacinKacenjaOpis nk={this.state.selectedNKItem} />
                    ) : (
                      ''
                    )}
                  </div>
                </Item>
              </Box>{' '}
              <Box height={50}>
                <Item ratio={1}>
                  <div className="dx-fieldset">
                    <div className="dx-field">
                      <div className="dx-field-label label label_p slijepe_rupe">
                        {prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            233
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                233
                            ].item2
                          : 'Slijepe rupe manje ili jednake promjeru'}
                        <br />
                        {prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            234
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                234
                            ].item2
                          : ' 5 mm i/ili Navoji  manji ili jednaki M5'}
                        <span className="star">*</span>
                      </div>
                      <div className="dx-field-value">
                        <SelectBox
                          items={[
                            {
                              label:
                                prevod &&
                                prevod.length !== 0 &&
                                prevod[
                                  oz_j.indexOf(localStorage.getItem('lng')) *
                                    (prevod.length / oz_j.length) +
                                    199
                                ]
                                  ? prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        199
                                    ].item2
                                  : 'DA',
                              value: true,
                            },
                            {
                              label:
                                prevod &&
                                prevod.length !== 0 &&
                                prevod[
                                  oz_j.indexOf(localStorage.getItem('lng')) *
                                    (prevod.length / oz_j.length) +
                                    200
                                ]
                                  ? prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        200
                                    ].item2
                                  : 'NE',
                              value: false,
                            },
                          ]}
                          defaultValue={this.state.slijepe_rupe}
                          disabled={this.state.disabledChange}
                          onValueChanged={this.checked}
                          displayExpr="label"
                          valueExpr="value"
                          //width={80}
                        >
                          {' '}
                        </SelectBox>
                      </div>
                    </div>
                  </div>
                </Item>
                <Item ratio={1}>
                  <div className="dx-fieldset"></div>
                </Item>
              </Box>
              <Box
                height={50}
                className={this.state.opis_npz ? 'nacin_povr_zast' : ''}
              >
                <Item ratio={1}>
                  <div className="dx-fieldset">
                    <div className="dx-field">
                      <div className="dx-field-label label">
                        {prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            59
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                59
                            ].item2
                          : 'Način površinske obrade'}{' '}
                        <span className="star">*</span>
                      </div>
                      <div className="dx-field-value">
                        <SelectBox
                          id="ABC"
                          fieldRender={NPZField}
                          items={this.props.npz}
                          disabled={this.state.disabledChange}
                          value={this.state.npz_value}
                          onValueChanged={this.onNPOValueChanged}
                          itemRender={TextItemNpo}
                          onFocusIn={this.onNPZFocusIn}
                          onFocusOut={this.onNPZFocusOut}
                          placeholder={
                            prevod &&
                            prevod.length !== 0 &&
                            prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                62
                            ]
                              ? prevod[
                                  oz_j.indexOf(localStorage.getItem('lng')) *
                                    (prevod.length / oz_j.length) +
                                    62
                                ].item2
                              : 'Izaberi ...'
                          }
                          // dropDownButtonRender={ImageIcon}
                        >
                          <Validator>
                            <CustomRule
                              type="required"
                              message={
                                prevod &&
                                prevod.length !== 0 &&
                                prevod[
                                  oz_j.indexOf(localStorage.getItem('lng')) *
                                    (prevod.length / oz_j.length) +
                                    49
                                ]
                                  ? prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        49
                                    ].item2
                                  : 'Obavezno polje'
                              }
                            />
                          </Validator>
                        </SelectBox>
                      </div>
                    </div>
                  </div>
                </Item>
                <Item ratio={1}>
                  <div className="dx-fieldset">
                    <div
                      className={
                        this.state.opis_npz
                          ? 'dx-field nacin_povr_zast_opis'
                          : 'dx-field'
                      }
                    >
                      {this.state.opis_npz ? (
                        <NPZOpis npz={this.state.npz_value} />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </Item>
              </Box>
              {this.state.npz_value === 'Standardno eloksiranje - S' ? (
                <div>
                  {this.state.m.substr(-4) !== '2017' &&
                  this.state.m.substr(-4) !== '7075' ? (
                    <Box
                      height={50}
                      className={this.state.opis_hppo ? 'nacin_povr_obr' : ''}
                    >
                      <Item ratio={1}>
                        <div className="dx-fieldset">
                          <div className="dx-field">
                            <div className="dx-field-label  label label_p">
                              {prevod &&
                              prevod.length !== 0 &&
                              prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  60
                              ]
                                ? prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      60
                                  ].item2
                                : 'Hemijska predobrada'}{' '}
                              <br />
                              {prevod &&
                              prevod.length !== 0 &&
                              prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  89
                              ]
                                ? prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      89
                                  ].item2
                                : 'prije eloksiranja'}
                              <span className="star">*</span>
                            </div>
                            <div className="dx-field-value">
                              <SelectBox
                                id="HPPE"
                                items={this.props.hppe_a}
                                value={this.state.hppo}
                                //displayExpr="item2"
                                itemRender={TextItemHppe}
                                disabled={this.state.disabledChange}
                                onValueChanged={this.onHPPOValueChanged}
                                onFocusIn={this.onHPPOFocusIn}
                                onFocusOut={this.onHPPOFocusOut}
                                placeholder={
                                  prevod &&
                                  prevod.length !== 0 &&
                                  prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      62
                                  ]
                                    ? prevod[
                                        oz_j.indexOf(
                                          localStorage.getItem('lng')
                                        ) *
                                          (prevod.length / oz_j.length) +
                                          62
                                      ].item2
                                    : 'Izaberi ...'
                                }
                                // dropDownButtonRender={ImageIcon}
                              >
                                <Validator>
                                  <CustomRule
                                    type="required"
                                    message={
                                      prevod &&
                                      prevod.length !== 0 &&
                                      prevod[
                                        oz_j.indexOf(
                                          localStorage.getItem('lng')
                                        ) *
                                          (prevod.length / oz_j.length) +
                                          49
                                      ]
                                        ? prevod[
                                            oz_j.indexOf(
                                              localStorage.getItem('lng')
                                            ) *
                                              (prevod.length / oz_j.length) +
                                              49
                                          ].item2
                                        : 'Obavezno polje'
                                    }
                                  />
                                </Validator>
                              </SelectBox>
                            </div>
                          </div>
                        </div>
                      </Item>
                      <Item ratio={1}>
                        <div
                          className={
                            this.state.opis_hppo
                              ? 'dx-fieldset nacin_povr_obr_opis'
                              : 'dx-fieldset'
                          }
                        >
                          {this.state.opis_hppo ? (
                            <NPOpis np={this.state.hppo} />
                          ) : (
                            ''
                          )}
                        </div>
                      </Item>
                    </Box>
                  ) : (
                    <Box
                      height={50}
                      className={this.state.opis_hppo ? 'nacin_povr_obr' : ''}
                    >
                      <Item ratio={1}>
                        <div className="dx-fieldset">
                          <div className="dx-field">
                            <div className="dx-field-label label label_p">
                              {prevod &&
                              prevod.length !== 0 &&
                              prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  60
                              ]
                                ? prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      60
                                  ].item2
                                : 'Hemijska predobrada'}{' '}
                              <br />
                              {prevod &&
                              prevod.length !== 0 &&
                              prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  89
                              ]
                                ? prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      89
                                  ].item2
                                : 'prije eloksiranja'}
                              <span className="star">*</span>
                            </div>
                            <div className="dx-field-value">
                              <SelectBox
                                id="HPPE"
                                items={this.props.hppe_b}
                                value={this.state.hppo}
                                itemRender={TextItemHppe}
                                disabled={this.state.disabledChange}
                                //displayExpr="item2"
                                onValueChanged={this.onHPPOValueChanged}
                                onFocusIn={this.onHPPOFocusIn}
                                onFocusOut={this.onHPPOFocusOut}
                                placeholder={
                                  prevod &&
                                  prevod.length !== 0 &&
                                  prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      62
                                  ]
                                    ? prevod[
                                        oz_j.indexOf(
                                          localStorage.getItem('lng')
                                        ) *
                                          (prevod.length / oz_j.length) +
                                          62
                                      ].item2
                                    : 'Izaberi ...'
                                }
                                // dropDownButtonRender={ImageIcon}
                              >
                                <Validator>
                                  <CustomRule
                                    type="required"
                                    message={
                                      prevod &&
                                      prevod.length !== 0 &&
                                      prevod[
                                        oz_j.indexOf(
                                          localStorage.getItem('lng')
                                        ) *
                                          (prevod.length / oz_j.length) +
                                          49
                                      ]
                                        ? prevod[
                                            oz_j.indexOf(
                                              localStorage.getItem('lng')
                                            ) *
                                              (prevod.length / oz_j.length) +
                                              49
                                          ].item2
                                        : 'Obavezno polje'
                                    }
                                  />
                                </Validator>
                              </SelectBox>
                            </div>
                          </div>
                        </div>
                      </Item>
                      <Item ratio={1}>
                        <div
                          className={
                            this.state.opis_hppo
                              ? 'dx-fieldset nacin_povr_obr_opis'
                              : 'dx-fieldset'
                          }
                        >
                          {this.state.opis_hppo ? (
                            <NPOpis np={this.state.hppo} />
                          ) : (
                            ''
                          )}
                        </div>
                      </Item>
                    </Box>
                  )}
                  {this.state.npz_value !== 'C Kromatiranje Surtec 650' &&
                  this.state.hppo === 'E6' ? (
                    <Box height={50}>
                      <Item ratio={1}>
                        <div className="dx-fieldset">
                          <div className="dx-field">
                            <div className="dx-field-label label">
                              {prevod &&
                              prevod.length !== 0 &&
                              prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  201
                              ]
                                ? prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      201
                                  ].item2
                                : 'Način predobrade'}{' '}
                              <span className="star">*</span>
                            </div>
                            <div className="dx-field-value">
                              <SelectBox
                                id="E6"
                                items={
                                  [
                                    prevod &&
                                    prevod.length !== 0 &&
                                    prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        278
                                    ]
                                      ? prevod[
                                          oz_j.indexOf(
                                            localStorage.getItem('lng')
                                          ) *
                                            (prevod.length / oz_j.length) +
                                            278
                                        ].item2
                                      : 'Luženje na mjeru',
                                    prevod &&
                                    prevod.length !== 0 &&
                                    prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        279
                                    ]
                                      ? prevod[
                                          oz_j.indexOf(
                                            localStorage.getItem('lng')
                                          ) *
                                            (prevod.length / oz_j.length) +
                                            279
                                        ].item2
                                      : 'Kratko luženje',
                                    prevod &&
                                    prevod.length !== 0 &&
                                    prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        280
                                    ]
                                      ? prevod[
                                          oz_j.indexOf(
                                            localStorage.getItem('lng')
                                          ) *
                                            (prevod.length / oz_j.length) +
                                            280
                                        ].item2
                                      : 'Dugo luženje',
                                    prevod &&
                                    prevod.length !== 0 &&
                                    prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        281
                                    ]
                                      ? prevod[
                                          oz_j.indexOf(
                                            localStorage.getItem('lng')
                                          ) *
                                            (prevod.length / oz_j.length) +
                                            281
                                        ].item2
                                      : 'Kratko luženje sa hemijskim poliranjem',
                                  ] /**e6Lista */
                                }
                                value={this.state.e6}
                                //displayExpr="item2"

                                disabled={this.state.disabledChange}
                                onValueChanged={this.onE6ValueChanged}
                                placeholder={
                                  prevod &&
                                  prevod.length !== 0 &&
                                  prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      62
                                  ]
                                    ? prevod[
                                        oz_j.indexOf(
                                          localStorage.getItem('lng')
                                        ) *
                                          (prevod.length / oz_j.length) +
                                          62
                                      ].item2
                                    : 'Izaberi ...'
                                }
                                // dropDownButtonRender={ImageIcon}
                              >
                                <Validator>
                                  <CustomRule
                                    type="required"
                                    message={
                                      prevod &&
                                      prevod.length !== 0 &&
                                      prevod[
                                        oz_j.indexOf(
                                          localStorage.getItem('lng')
                                        ) *
                                          (prevod.length / oz_j.length) +
                                          49
                                      ]
                                        ? prevod[
                                            oz_j.indexOf(
                                              localStorage.getItem('lng')
                                            ) *
                                              (prevod.length / oz_j.length) +
                                              49
                                          ].item2
                                        : 'Obavezno polje'
                                    }
                                  />
                                </Validator>
                              </SelectBox>
                            </div>
                          </div>
                        </div>
                      </Item>
                      <Item ratio={1}>
                        <div className="dx-fieldset">
                          <div className="dx-field"></div>
                        </div>
                      </Item>
                    </Box>
                  ) : (
                    ''
                  )}
                  {this.state.hppo !== 'E6' ||
                  !this.state.hppo ||
                  this.state.e6 !== 'Dugo luženje' ||
                  this.state.e6 === '' ||
                  !this.state.e6
                    ? ''
                    : /*<Box height={50}>
                      <Item ratio={1}>
                        <div className="dx-fieldset">
                          <div className="dx-field">
                            <div className="dx-field-label">
                              {prevod &&
                              prevod.length !== 0 &&
                              prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  191
                              ]
                                ? prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      191
                                  ].item2
                                : 'Minute'}{' '}
                              <span className="star">*</span>
                            </div>
                            <div className="dx-field-value">
                              {this.state.e6 === 'Dugo luženje' ? (
                                <SelectBox
                                  id="E6"
                                  items={this.props.e6_me}
                                  value={this.state.min}
                                  onValueChanged={this.onMinValueChanged}
                                  placeholder={
                                    prevod &&
                                    prevod.length !== 0 &&
                                    prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        62
                                    ]
                                      ? prevod[
                                          oz_j.indexOf(
                                            localStorage.getItem('lng')
                                          ) *
                                            (prevod.length / oz_j.length) +
                                            62
                                        ].item2
                                      : 'Izaberi ...'
                                  }
                                  // dropDownButtonRender={ImageIcon}
                                >
                                  <Validator>
                                    <CustomRule
                                      type="required"
                                      message={
                                        prevod &&
                                        prevod.length !== 0 &&
                                        prevod[
                                          oz_j.indexOf(
                                            localStorage.getItem('lng')
                                          ) *
                                            (prevod.length / oz_j.length) +
                                            49
                                        ]
                                          ? prevod[
                                              oz_j.indexOf(
                                                localStorage.getItem('lng')
                                              ) *
                                                (prevod.length / oz_j.length) +
                                                49
                                            ].item2
                                          : 'Obavezno polje'
                                      }
                                    />
                                  </Validator>
                                </SelectBox>
                              ) : (
                                <SelectBox
                                  id="E6"
                                  items={this.props.e6_dhp}
                                  value={this.state.min}
                                  onValueChanged={this.onMinValueChanged}
                                  placeholder={
                                    prevod &&
                                    prevod.length !== 0 &&
                                    prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        62
                                    ]
                                      ? prevod[
                                          oz_j.indexOf(
                                            localStorage.getItem('lng')
                                          ) *
                                            (prevod.length / oz_j.length) +
                                            62
                                        ].item2
                                      : 'Izaberi ...'
                                  }
                                  // dropDownButtonRender={ImageIcon}
                                >
                                  <Validator>
                                    <CustomRule
                                      type="required"
                                      message={
                                        prevod &&
                                        prevod.length !== 0 &&
                                        prevod[
                                          oz_j.indexOf(
                                            localStorage.getItem('lng')
                                          ) *
                                            (prevod.length / oz_j.length) +
                                            49
                                        ]
                                          ? prevod[
                                              oz_j.indexOf(
                                                localStorage.getItem('lng')
                                              ) *
                                                (prevod.length / oz_j.length) +
                                                49
                                            ].item2
                                          : 'Obavezno polje'
                                      }
                                    />
                                  </Validator>
                                </SelectBox>
                              )}
                            </div>
                          </div>
                        </div>
                      </Item>
                      <Item ratio={1}>
                        <div className="dx-fieldset">
                          <div className="dx-field"></div>
                        </div>
                      </Item>
                    </Box>*/
                      ''}
                  {this.state.m.substr(-4) === '2017' ? (
                    <Box height={50}>
                      <Item ratio={1}>
                        <div className="dx-fieldset">
                          <div className="dx-field">
                            <div className="dx-field-label label">
                              {prevod &&
                              prevod.length !== 0 &&
                              prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  63
                              ]
                                ? prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      63
                                  ].item2
                                : 'Debljina oksidnog sloja'}
                              <span className="star">*</span>
                            </div>
                            <div className="dx-field-value">
                              <SelectBox
                                items={this.props.dos_2017_a}
                                disabled={this.state.disabledChange}
                                value={this.state.dos}
                                //displayExpr="item2"
                                onValueChanged={this.onDOSValueChanged}
                                onFocusIn={this.onDOSFocusIn}
                                onFocusOut={this.onDOSFocusOut}
                                placeholder={
                                  prevod &&
                                  prevod.length !== 0 &&
                                  prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      62
                                  ]
                                    ? prevod[
                                        oz_j.indexOf(
                                          localStorage.getItem('lng')
                                        ) *
                                          (prevod.length / oz_j.length) +
                                          62
                                      ].item2
                                    : 'Izaberi ...'
                                }
                                // dropDownButtonRender={ImageIcon}
                              >
                                <Validator>
                                  <CustomRule
                                    type="required"
                                    message={
                                      prevod &&
                                      prevod.length !== 0 &&
                                      prevod[
                                        oz_j.indexOf(
                                          localStorage.getItem('lng')
                                        ) *
                                          (prevod.length / oz_j.length) +
                                          49
                                      ]
                                        ? prevod[
                                            oz_j.indexOf(
                                              localStorage.getItem('lng')
                                            ) *
                                              (prevod.length / oz_j.length) +
                                              49
                                          ].item2
                                        : 'Obavezno polje'
                                    }
                                  />
                                </Validator>
                              </SelectBox>
                            </div>
                          </div>
                        </div>
                      </Item>
                      <Item ratio={1}>
                        <div className="dx-fieldset">
                          {/*this.state.opis_dos ? <DOS_2017_A_OPIS /> : ''*/}
                        </div>
                      </Item>
                    </Box>
                  ) : (
                    <Box height={50}>
                      <Item ratio={1}>
                        <div className="dx-fieldset">
                          <div className="dx-field">
                            <div className="dx-field-label label">
                              {prevod &&
                              prevod.length !== 0 &&
                              prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  63
                              ]
                                ? prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      63
                                  ].item2
                                : 'Debljina oksidnog sloja'}
                              <span className="star">*</span>
                            </div>
                            <div className="dx-field-value">
                              <SelectBox
                                items={this.props.dos_a}
                                value={this.state.dos}
                                //displayExpr="item2"
                                disabled={this.state.disabledChange}
                                onValueChanged={this.onDOSValueChanged}
                                onFocusIn={this.onDOSFocusIn}
                                onFocusOut={this.onDOSFocusOut}
                                placeholder={
                                  prevod &&
                                  prevod.length !== 0 &&
                                  prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      62
                                  ]
                                    ? prevod[
                                        oz_j.indexOf(
                                          localStorage.getItem('lng')
                                        ) *
                                          (prevod.length / oz_j.length) +
                                          62
                                      ].item2
                                    : 'Izaberi ...'
                                }
                                // dropDownButtonRender={ImageIcon}
                              >
                                <Validator>
                                  <CustomRule
                                    type="required"
                                    message={
                                      prevod &&
                                      prevod.length !== 0 &&
                                      prevod[
                                        oz_j.indexOf(
                                          localStorage.getItem('lng')
                                        ) *
                                          (prevod.length / oz_j.length) +
                                          49
                                      ]
                                        ? prevod[
                                            oz_j.indexOf(
                                              localStorage.getItem('lng')
                                            ) *
                                              (prevod.length / oz_j.length) +
                                              49
                                          ].item2
                                        : 'Obavezno polje'
                                    }
                                  />
                                </Validator>
                              </SelectBox>
                            </div>
                          </div>
                        </div>
                      </Item>
                      <Item ratio={1}>
                        <div className="dx-fieldset">
                          {/*this.state.opis_dos ? <DOS_A_OPIS /> : ''*/}
                        </div>
                      </Item>
                    </Box>
                  )}
                  {/* <Box height={90}>
                  <Item baseSize={400} ratio={7}>
                    <div className="dx-fieldset">
                      <div className="dx-field">
                        <div className="dx-field-label">
                          Interval tolerancije debljine oksidnog sloja
                        </div>
                        <div className="dx-field-value">
                          <SelectBox
                            items={this.props.itdos_a}
                            value={this.state.itdos}
                            onValueChanged={this.onITDOSValueChanged}
                            onFocusIn={this.onITDOSFocusIn}
                            onFocusOut={this.onITDOSFocusOut}
                            placeholder="Izaberi ..."
                           // dropDownButtonRender={ImageIcon}  
                          >
                            <Validator>
                              <CustomRule
                                type="required"
                                message="Obavezno polje"
                              />
                            </Validator>
                          </SelectBox>
                        </div>
                      </div>
                    </div>
                  </Item>
                  <Item baseSize={600} ratio={2}>
                    <div className="dx-fieldset">
                      {this.state.opis_itdos ? <ITDOS_A_OPIS /> : ""}
                    </div>
                  </Item>
                            </Box>*/}
                  <Box height={50}>
                    <Item ratio={1}>
                      <div className="dx-fieldset">
                        <div className="dx-field">
                          <div className="dx-field-label label">
                            {prevod &&
                            prevod.length !== 0 &&
                            prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                156
                            ]
                              ? prevod[
                                  oz_j.indexOf(localStorage.getItem('lng')) *
                                    (prevod.length / oz_j.length) +
                                    156
                                ].item2
                              : 'Boja'}
                            <span className="star">*</span>
                          </div>
                          <div className="dx-field-value">
                            <SelectBox
                              dataSource={this.props.boja_a}
                              onFocusIn={this.onBFocusIn}
                              disabled={this.state.disabledChange}
                              onFocusOut={this.onBFocusOut}
                              defaultValue={this.state.b.item2}
                              selectedItem={this.state.selectedBItem}
                              displayExpr="item2"
                              valueExpr="item2"
                              itemRender={ColorItem}
                              fieldRender={ColorField}
                              // dropDownButtonTemplate="conditionalIcon"
                              onSelectionChanged={this.selectionChanged}
                              // dropDownButtonRender={ImageIcon}
                            ></SelectBox>
                          </div>
                        </div>
                      </div>
                    </Item>
                    <Item ratio={1}>
                      <div className="dx-fieldset">
                        {/*this.state.opis_b ? <BOJA_A_OPIS /> : ""*/}
                      </div>
                    </Item>
                  </Box>
                </div>
              ) : this.state.npz_value === 'Tvrdo eloksiranje - XH4' ? (
                <div>
                  <div></div>
                  {/*<Box height={90}>
                  <Item baseSize={400} ratio={7}>
                    <div className="dx-fieldset">
                      <div className="dx-field">
                        <div className="dx-field-label">
                          Hemijska predobrada prije eloksiranja
                        </div>
                        <div className="dx-field-value">
                          <SelectBox
                            items={this.props.hppe_b}
                            value={this.state.hppo}
                            onFocusIn={this.onHPPOFocusIn}
                            onFocusOut={this.onHPPOFocusOut}
                            //displayExpr="item2"
                            onValueChanged={this.onHPPOValueChanged}
                            placeholder="Izaberi ..."
                           // dropDownButtonRender={ImageIcon}  
                          >
                            <Validator>
                              <CustomRule
                                type="required"
                                message="Obavezno polje"
                              />
                            </Validator>
                          </SelectBox>
                        </div>
                      </div>
                    </div>
                  </Item>
                  <Item baseSize={600} ratio={2}>
                    <div className="dx-fieldset">
                      {this.state.opis_hppo ? <HPPE_B_Opis /> : ""}
                    </div>
                  </Item>
                </Box>*/}

                  {this.state.npz_value !== 'C Kromatiranje Surtec 650' &&
                  this.state.hppo === 'E6' ? (
                    <Box height={50}>
                      <Item ratio={1}>
                        <div className="dx-fieldset">
                          <div className="dx-field">
                            <div className="dx-field-label label">
                              {prevod &&
                              prevod.length !== 0 &&
                              prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  61
                              ]
                                ? prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      61
                                  ].item2
                                : 'Način eloksiranja'}{' '}
                              <span className="star">*</span>
                            </div>
                            <div className="dx-field-value">
                              <SelectBox
                                id="E6"
                                items={
                                  [
                                    prevod &&
                                    prevod.length !== 0 &&
                                    prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        278
                                    ]
                                      ? prevod[
                                          oz_j.indexOf(
                                            localStorage.getItem('lng')
                                          ) *
                                            (prevod.length / oz_j.length) +
                                            278
                                        ].item2
                                      : 'Luženje na mjeru',
                                    prevod &&
                                    prevod.length !== 0 &&
                                    prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        279
                                    ]
                                      ? prevod[
                                          oz_j.indexOf(
                                            localStorage.getItem('lng')
                                          ) *
                                            (prevod.length / oz_j.length) +
                                            279
                                        ].item2
                                      : 'Kratko luženje',
                                    prevod &&
                                    prevod.length !== 0 &&
                                    prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        280
                                    ]
                                      ? prevod[
                                          oz_j.indexOf(
                                            localStorage.getItem('lng')
                                          ) *
                                            (prevod.length / oz_j.length) +
                                            280
                                        ].item2
                                      : 'Dugo luženje',
                                    prevod &&
                                    prevod.length !== 0 &&
                                    prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        281
                                    ]
                                      ? prevod[
                                          oz_j.indexOf(
                                            localStorage.getItem('lng')
                                          ) *
                                            (prevod.length / oz_j.length) +
                                            281
                                        ].item2
                                      : 'Kratko luženje sa hemijskim poliranjem',
                                  ] /*e6Lista*/
                                }
                                value={this.state.e6}
                                disabled={this.state.disabledChange}
                                //displayExpr="item2"
                                onValueChanged={this.onE6ValueChanged}
                                placeholder={
                                  prevod &&
                                  prevod.length !== 0 &&
                                  prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      62
                                  ]
                                    ? prevod[
                                        oz_j.indexOf(
                                          localStorage.getItem('lng')
                                        ) *
                                          (prevod.length / oz_j.length) +
                                          62
                                      ].item2
                                    : 'Izaberi ...'
                                }
                                // dropDownButtonRender={ImageIcon}
                              >
                                <Validator>
                                  <CustomRule
                                    type="required"
                                    message={
                                      prevod &&
                                      prevod.length !== 0 &&
                                      prevod[
                                        oz_j.indexOf(
                                          localStorage.getItem('lng')
                                        ) *
                                          (prevod.length / oz_j.length) +
                                          49
                                      ]
                                        ? prevod[
                                            oz_j.indexOf(
                                              localStorage.getItem('lng')
                                            ) *
                                              (prevod.length / oz_j.length) +
                                              49
                                          ].item2
                                        : 'Obavezno polje'
                                    }
                                  />
                                </Validator>
                              </SelectBox>
                            </div>
                          </div>
                        </div>
                      </Item>
                      <Item ratio={1}>
                        <div className="dx-fieldset">
                          <div className="dx-field"></div>
                        </div>
                      </Item>
                    </Box>
                  ) : (
                    ''
                  )}
                  {
                    this.state.hppo !== 'E6' ||
                    !this.state.hppo ||
                    this.state.e6 !== 'Dugo luženje' ||
                    this.state.e6 === '' ||
                    !this.state.e6
                      ? ''
                      : ''
                    /*<Box height={50}>
                      <Item ratio={1}>
                        <div className="dx-fieldset">
                          <div className="dx-field">
                            <div className="dx-field-label">
                              {prevod &&
                              prevod.length !== 0 &&
                              prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  191
                              ]
                                ? prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      191
                                  ].item2
                                : 'Minute'}{' '}
                              <span className="star">*</span>
                            </div>
                            <div className="dx-field-value">
                              {this.state.e6 === 'Dugo luženje' ? (
                                <SelectBox
                                  id="E6"
                                  items={this.props.e6_me}
                                  value={this.state.min}
                                  onValueChanged={this.onMinValueChanged}
                                  placeholder={
                                    prevod &&
                                    prevod.length !== 0 &&
                                    prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        62
                                    ]
                                      ? prevod[
                                          oz_j.indexOf(
                                            localStorage.getItem('lng')
                                          ) *
                                            (prevod.length / oz_j.length) +
                                            62
                                        ].item2
                                      : 'Izaberi ...'
                                  }
                                  // dropDownButtonRender={ImageIcon}
                                >
                                  <Validator>
                                    <CustomRule
                                      type="required"
                                      message={
                                        prevod &&
                                        prevod.length !== 0 &&
                                        prevod[
                                          oz_j.indexOf(
                                            localStorage.getItem('lng')
                                          ) *
                                            (prevod.length / oz_j.length) +
                                            49
                                        ]
                                          ? prevod[
                                              oz_j.indexOf(
                                                localStorage.getItem('lng')
                                              ) *
                                                (prevod.length / oz_j.length) +
                                                49
                                            ].item2
                                          : 'Obavezno polje'
                                      }
                                    />
                                  </Validator>
                                </SelectBox>
                              ) : (
                                <SelectBox
                                  id="E6"
                                  items={this.props.e6_dhp}
                                  value={this.state.min}
                                  onValueChanged={this.onMinValueChanged}
                                  placeholder={
                                    prevod &&
                                    prevod.length !== 0 &&
                                    prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        62
                                    ]
                                      ? prevod[
                                          oz_j.indexOf(
                                            localStorage.getItem('lng')
                                          ) *
                                            (prevod.length / oz_j.length) +
                                            62
                                        ].item2
                                      : 'Izaberi ...'
                                  }
                                  // dropDownButtonRender={ImageIcon}
                                >
                                  <Validator>
                                    <CustomRule
                                      type="required"
                                      message={
                                        prevod &&
                                        prevod.length !== 0 &&
                                        prevod[
                                          oz_j.indexOf(
                                            localStorage.getItem('lng')
                                          ) *
                                            (prevod.length / oz_j.length) +
                                            49
                                        ]
                                          ? prevod[
                                              oz_j.indexOf(
                                                localStorage.getItem('lng')
                                              ) *
                                                (prevod.length / oz_j.length) +
                                                49
                                            ].item2
                                          : 'Obavezno polje'
                                      }
                                    />
                                  </Validator>
                                </SelectBox>
                              )}
                            </div>
                          </div>
                        </div>
                      </Item>
                      <Item ratio={1}>
                        <div className="dx-fieldset">
                          <div className="dx-field"></div>
                        </div>
                      </Item>
                    </Box>
                                    )}*/
                  }
                  {this.state.m.substr(-4) === '2017' ? (
                    <Box height={50}>
                      <Item ratio={1}>
                        <div className="dx-fieldset">
                          <div className="dx-field">
                            <div className="dx-field-label label">
                              {prevod &&
                              prevod.length !== 0 &&
                              prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  63
                              ]
                                ? prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      63
                                  ].item2
                                : 'Debljina oksidnog sloja'}
                              <span className="star">*</span>
                            </div>
                            <div className="dx-field-value">
                              <SelectBox
                                items={this.props.dos_2017_b}
                                value={this.state.dos}
                                disabled={this.state.disabledChange}
                                //displayExpr="item2"
                                onValueChanged={this.onDOSValueChanged}
                                onFocusIn={this.onDOSFocusIn}
                                onFocusOut={this.onDOSFocusOut}
                                placeholder={
                                  prevod &&
                                  prevod.length !== 0 &&
                                  prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      62
                                  ]
                                    ? prevod[
                                        oz_j.indexOf(
                                          localStorage.getItem('lng')
                                        ) *
                                          (prevod.length / oz_j.length) +
                                          62
                                      ].item2
                                    : 'Izaberi ...'
                                }
                                // dropDownButtonRender={ImageIcon}
                              >
                                <Validator>
                                  <CustomRule
                                    type="required"
                                    message={
                                      prevod &&
                                      prevod.length !== 0 &&
                                      prevod[
                                        oz_j.indexOf(
                                          localStorage.getItem('lng')
                                        ) *
                                          (prevod.length / oz_j.length) +
                                          49
                                      ]
                                        ? prevod[
                                            oz_j.indexOf(
                                              localStorage.getItem('lng')
                                            ) *
                                              (prevod.length / oz_j.length) +
                                              49
                                          ].item2
                                        : 'Obavezno polje'
                                    }
                                  />
                                </Validator>
                              </SelectBox>
                            </div>
                          </div>
                        </div>
                      </Item>
                      <Item ratio={1}>
                        <div className="dx-fieldset">
                          {/*this.state.opis_dos ? <DOS_2017_B_OPIS /> : ''*/}
                        </div>
                      </Item>
                    </Box>
                  ) : (
                    <Box
                      height={50}
                      className={
                        this.state.opis_dos ? 'debljina_oks_sloja' : ''
                      }
                    >
                      <Item ratio={1}>
                        <div className="dx-fieldset">
                          <div className="dx-field">
                            <div className="dx-field-label label">
                              {prevod &&
                              prevod.length !== 0 &&
                              prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  63
                              ]
                                ? prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      63
                                  ].item2
                                : 'Debljina oksidnog sloja'}
                              <span className="star">*</span>
                            </div>
                            <div className="dx-field-value">
                              <SelectBox
                                items={this.props.dos_b}
                                value={this.state.dos}
                                disabled={this.state.disabledChange}
                                //displayExpr="item2"
                                onValueChanged={this.onDOSValueChanged}
                                onFocusIn={this.onDOSFocusIn}
                                onFocusOut={this.onDOSFocusOut}
                                placeholder={
                                  prevod &&
                                  prevod.length !== 0 &&
                                  prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      62
                                  ]
                                    ? prevod[
                                        oz_j.indexOf(
                                          localStorage.getItem('lng')
                                        ) *
                                          (prevod.length / oz_j.length) +
                                          62
                                      ].item2
                                    : 'Izaberi ...'
                                }
                                // dropDownButtonRender={ImageIcon}
                              >
                                <Validator>
                                  <CustomRule
                                    type="required"
                                    message={
                                      prevod &&
                                      prevod.length !== 0 &&
                                      prevod[
                                        oz_j.indexOf(
                                          localStorage.getItem('lng')
                                        ) *
                                          (prevod.length / oz_j.length) +
                                          49
                                      ]
                                        ? prevod[
                                            oz_j.indexOf(
                                              localStorage.getItem('lng')
                                            ) *
                                              (prevod.length / oz_j.length) +
                                              49
                                          ].item2
                                        : 'Obavezno polje'
                                    }
                                  />
                                </Validator>
                              </SelectBox>
                            </div>
                          </div>
                        </div>
                      </Item>
                      <Item ratio={1}>
                        <div
                          className={
                            this.state.opis_dos
                              ? 'dx-fieldset debljina_oks_sloja_opis'
                              : 'dx-fieldset'
                          }
                        >
                          {this.state.opis_dos ? <DOS_B_OPIS /> : ''}
                        </div>
                      </Item>
                    </Box>
                  )}
                  {/* <Box height={90}>
                  <Item baseSize={400} ratio={7}>
                    <div className="dx-fieldset">
                      <div className="dx-field">
                        <div className="dx-field-label">
                          Interval tolerancije debljine oksidnog sloja
                        </div>
                        <div className="dx-field-value">
                          <SelectBox
                            items={this.props.itdos_b}
                            value={this.state.itdos}
                            onValueChanged={this.onITDOSValueChanged}
                            onFocusIn={this.onITDOSFocusIn}
                            onFocusOut={this.onITDOSFocusOut}
                            placeholder="Izaberi ..."
                           // dropDownButtonRender={ImageIcon}  
                          >
                            <Validator>
                              <CustomRule
                                type="required"
                                message="Obavezno polje"
                              />
                            </Validator>
                          </SelectBox>
                        </div>
                      </div>
                    </div>
                  </Item>
                  <Item baseSize={600} ratio={2}>
                    <div className="dx-fieldset">
                      {this.state.opis_itdos ? <ITDOS_B_OPIS /> : ""}
                    </div>
                  </Item>
                            </Box>*/}
                  <Box height={50}>
                    <Item ratio={1}>
                      <div className="dx-fieldset" id="pom">
                        <div className="dx-field">
                          <div className="dx-field-label label">
                            {prevod &&
                            prevod.length !== 0 &&
                            prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                156
                            ]
                              ? prevod[
                                  oz_j.indexOf(localStorage.getItem('lng')) *
                                    (prevod.length / oz_j.length) +
                                    156
                                ].item2
                              : 'Boja'}{' '}
                            <span className="star">*</span>
                          </div>
                          <div className="dx-field-value">
                            <SelectBox
                              dataSource={this.props.boja_b}
                              onFocusIn={this.onBFocusIn}
                              onFocusOut={this.onBFocusOut}
                              defaultValue={this.state.b.item2}
                              disabled={this.state.disabledChange}
                              selectedItem={this.state.selectedBItem}
                              displayExpr="item2"
                              valueExpr="item2"
                              itemRender={ColorItem}
                              fieldRender={ColorField}
                              onSelectionChanged={this.selectionBChanged}
                              // dropDownButtonRender={ImageIcon}
                            ></SelectBox>
                          </div>
                        </div>
                      </div>
                    </Item>
                    <Item ratio={1}>
                      <div className="dx-fieldset">
                        {/*this.state.opis_b ? <BOJA_B_OPIS /> : ""*/}
                      </div>
                    </Item>
                  </Box>
                </div>
              ) : (
                <br />
              )}
            </div>
            <div className="card card_bk card_sa">
              <Box height={40}>
                <Item ratio={1}>
                  <div className="dx-fieldset s_a">
                    <div className="dx-field ">
                      <div className="dx-field-value sl_ar">
                        <div>
                          {prevod &&
                          prevod.length !== 0 &&
                          prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              198
                          ]
                            ? prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  198
                              ].item2
                            : 'Proizvod se ne može opisati datim podacima? Idite na složeni artikal'}{' '}
                        </div>
                      </div>
                    </div>
                  </div>
                </Item>
                <Item ratio={1}>
                  <div id="s_a_btn">
                    <div>
                      {' '}
                      <Button
                        icon="arrowright"
                        type="default"
                        index={1}
                        onClick={this.slozeniArtikal}
                      />
                    </div>
                  </div>
                </Item>{' '}
              </Box>{' '}
              <br />
              <div style={{ marginLeft: '7px' }}>
                {this.state.disabledChange
                  ? 'Kreirani upit je zaključan, nisu moguće izmjene istog. Ukoliko želite izračunati cijenu za neki drugi artikal, molimo Vas da kreirate novi upit.'
                  : ''}
              </div>
            </div>
            {/* lista input-a za broj komada i cijene */}
            <div style={{ height: '3px' }}></div>
            <div>
              <div className=" r_c_z_k">
                <div className="dx-field">
                  <div className="dx-field-label h1 ">
                    <h1>
                      {prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          211
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              211
                          ].item2
                        : 'Računanje cijene za količine'}
                    </h1>
                  </div>
                </div>
                <div className="card card_bk card_bk_rc">
                  {inputLista.map((x, i) => {
                    return (
                      <div key={i} id="bk">
                        <Box height={50}>
                          <Item ratio={1}>
                            <div id="r_s_bk">
                              <div className="dx-field-label">
                                <div className="i_b_bk">
                                  {' '}
                                  <p>
                                    {prevod &&
                                    prevod.length !== 0 &&
                                    prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        57
                                    ]
                                      ? prevod[
                                          oz_j.indexOf(
                                            localStorage.getItem('lng')
                                          ) *
                                            (prevod.length / oz_j.length) +
                                            57
                                        ].item2
                                      : 'Broj komada'}
                                  </p>
                                  <div>
                                    <NumberBox
                                      name="bk"
                                      placeholder={
                                        prevod &&
                                        prevod.length !== 0 &&
                                        prevod[
                                          oz_j.indexOf(
                                            localStorage.getItem('lng')
                                          ) *
                                            (prevod.length / oz_j.length) +
                                            193
                                        ]
                                          ? prevod[
                                              oz_j.indexOf(
                                                localStorage.getItem('lng')
                                              ) *
                                                (prevod.length / oz_j.length) +
                                                193
                                            ].item2
                                          : 'broj komada...'
                                      }
                                      valueChangeEvent="keyup"
                                      value={parseFloat(x.k9kcbkbk)}
                                      onValueChanged={(e) =>
                                        this.handleInputChange(i, e)
                                      }
                                    />
                                  </div>
                                </div>
                                {inputLista.length >= 1 && (
                                  <div className="i_b_bk ">
                                    <p style={{ visibility: 'hidden' }}>
                                      Button
                                    </p>
                                    <div
                                      id="calc"
                                      onMouseEnter={(e) =>
                                        this.setState({ toggle_kc: true })
                                      }
                                      onMouseLeave={(e) =>
                                        this.setState({ toggle_kc: false })
                                      }
                                    >
                                      {' '}
                                      <Button
                                        type="default"
                                        disabled={
                                          x.k9kcbkbk == '0' || this.state.p == 0
                                        }
                                        icon={calculator}
                                        className="mr10"
                                        onClick={(e) =>
                                          this.racunajCijenuPoBK(i, e)
                                        }
                                      />
                                    </div>
                                    <Tooltip
                                      target="#calc"
                                      position="right"
                                      visible={this.state.toggle_kc}
                                      closeOnOutsideClick={false}
                                    >
                                      <div>
                                        {prevod &&
                                        prevod.length !== 0 &&
                                        prevod[
                                          oz_j.indexOf(
                                            localStorage.getItem('lng')
                                          ) *
                                            (prevod.length / oz_j.length) +
                                            249
                                        ]
                                          ? prevod[
                                              oz_j.indexOf(
                                                localStorage.getItem('lng')
                                              ) *
                                                (prevod.length / oz_j.length) +
                                                249
                                            ].item2
                                          : 'Izračunaj cijenu'}
                                      </div>
                                    </Tooltip>
                                  </div>
                                )}
                              </div>
                              <div className="dx-field-value">
                                <div className="i_b_bk cijena">
                                  <p>
                                    {prevod &&
                                    prevod.length !== 0 &&
                                    prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        97
                                    ]
                                      ? prevod[
                                          oz_j.indexOf(
                                            localStorage.getItem('lng')
                                          ) *
                                            (prevod.length / oz_j.length) +
                                            97
                                        ].item2
                                      : 'Cijena'}
                                  </p>
                                  <div>
                                    <TextBox
                                      name="c"
                                      placeholder={
                                        prevod &&
                                        prevod.length !== 0 &&
                                        prevod[
                                          oz_j.indexOf(
                                            localStorage.getItem('lng')
                                          ) *
                                            (prevod.length / oz_j.length) +
                                            194
                                        ]
                                          ? prevod[
                                              oz_j.indexOf(
                                                localStorage.getItem('lng')
                                              ) *
                                                (prevod.length / oz_j.length) +
                                                194
                                            ].item2
                                          : 'cijena...'
                                      }
                                      readOnly={true}
                                      value={
                                        x.k9kcbkc.toString() != '0'
                                          ? new Intl.NumberFormat('de-DE', {
                                              minimumFractionDigits: 4,
                                            }).format(x.k9kcbkc.toString()) +
                                            ' ' +
                                            this.state.valuta
                                          : '0' + ' ' + this.state.valuta
                                      }
                                      style={{
                                        visibility:
                                          this.state.cijenaVisible[i] &&
                                          !this.state.cijena_loading
                                            ? 'visible'
                                            : 'hidden',
                                        right: '0',
                                        width: '15vw !important',
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Item>
                          <Item ratio={1}>
                            <div id="r_s_bk" className="l_s_bk">
                              <div className="i_b_bk">
                                <p style={{ visibility: 'hidden' }}>Button</p>
                                <div
                                  id="close"
                                  onMouseEnter={(e) =>
                                    this.setState({ toggle_b: true })
                                  }
                                  onMouseLeave={(e) =>
                                    this.setState({ toggle_b: false })
                                  }
                                >
                                  <Button
                                    icon="close"
                                    type="default"
                                    className="mr10"
                                    onClick={(e) =>
                                      this.handleDeleteClick(i, e)
                                    }
                                  />
                                </div>{' '}
                                <Tooltip
                                  target="#close"
                                  visible={this.state.toggle_b}
                                  closeOnOutsideClick={false}
                                >
                                  <div>
                                    {prevod &&
                                    prevod.length !== 0 &&
                                    prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        250
                                    ]
                                      ? prevod[
                                          oz_j.indexOf(
                                            localStorage.getItem('lng')
                                          ) *
                                            (prevod.length / oz_j.length) +
                                            250
                                        ].item2
                                      : 'Izbriši datu količinu'}
                                  </div>
                                </Tooltip>
                              </div>
                              <div id="n">
                                <p style={{ visibility: 'hidden' }}>Narudzba</p>{' '}
                                <Narudzba
                                  adresa_komitenta={this.state.adresa_komitenta}
                                  br_komitenta={this.state.br_komitenta}
                                  bc={this.state.bc}
                                  na={this.state.na}
                                  nc={this.state.nc}
                                  prevod={prevod}
                                  oz_j={oz_j}
                                  sifra_recepta={this.state.sifra_recepta}
                                  bk={x.k9kcbkbk}
                                  id={this.state.siid}
                                  cijena={x.k9kcbkc}
                                  upit={this.state.upit}
                                  valuta={this.state.valuta}
                                />
                              </div>
                            </div>
                          </Item>
                        </Box>
                        {inputLista.length - 1 === i && (
                          <div
                            id="bk_n_kol"
                            style={{
                              marginTop: '50px !important',
                            }}
                          >
                            <Box height={60}>
                              <Item ratio={1}>
                                <div className="dx-fieldset">
                                  <div className="dx-field add_button_field">
                                    <div
                                      className="dx-field-value d_n_k"
                                      id="add_btn"
                                      onMouseEnter={() =>
                                        this.setState({ toggle_kol: true })
                                      }
                                      onMouseLeave={() =>
                                        this.setState({ toggle_kol: false })
                                      }
                                    >
                                      <Button
                                        icon={add}
                                        text={
                                          prevod &&
                                          prevod.length !== 0 &&
                                          prevod[
                                            oz_j.indexOf(
                                              localStorage.getItem('lng')
                                            ) *
                                              (prevod.length / oz_j.length) +
                                              235
                                          ]
                                            ? prevod[
                                                oz_j.indexOf(
                                                  localStorage.getItem('lng')
                                                ) *
                                                  (prevod.length /
                                                    oz_j.length) +
                                                  235
                                              ].item2
                                            : 'Dodaj novu količinu'
                                        }
                                        type="default"
                                        style={{
                                          marginTop: '5px !important',
                                        }}
                                        onClick={this.handleAddClick}
                                      />
                                    </div>{' '}
                                    <Tooltip
                                      target="#add_btn"
                                      visible={this.state.toggle_kol}
                                      closeOnOutsideClick={false}
                                    >
                                      <div>
                                        {prevod &&
                                        prevod.length !== 0 &&
                                        prevod[
                                          oz_j.indexOf(
                                            localStorage.getItem('lng')
                                          ) *
                                            (prevod.length / oz_j.length) +
                                            254
                                        ]
                                          ? prevod[
                                              oz_j.indexOf(
                                                localStorage.getItem('lng')
                                              ) *
                                                (prevod.length / oz_j.length) +
                                                254
                                            ].item2
                                          : 'Dodaj novu količinu za kalkulaciju cijene'}
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                              </Item>
                              <Item ratio={1}>
                                <div className="dx-fieldset"></div>
                              </Item>
                            </Box>
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.cijena_loading ? <Loading /> : ''}
        {/* <Popup
          visible={this.state.popupVisible}
          onHiding={this.hideInfo}
          dragEnabled={false}
          closeOnOutsideClick={true}
          showTitle={true}
          title={
            prevod &&
            prevod.length !== 0 &&
            prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                196
            ]
              ? prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    196
                ].item2
              : 'Izračunata cijena!'
          }
          width={300}
          height={250}
        >
          <h1>
            {cijena} {this.state.valuta}
          </h1>
        </Popup>*/}
        {/*
        <div className="cijena-component">
          <div className="form">
            <div className="dx-fieldset">
              {" "}
              <div className="dx-field">
                <div className="dx-field-label">
                  <h1>
                    {prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem("lng")) *
                        (prevod.length / oz_j.length) +
                        195
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem("lng")) *
                            (prevod.length / oz_j.length) +
                            195
                        ].item2
                      : "Odabrani broj komada"}{" "}
                    <span className="star">*</span>
                  </h1>
                  <br />
                </div>
              </div>
            </div>
            <Popup
              visible={this.state.popupVisible}
              onHiding={this.hideInfo}
              dragEnabled={false}
              closeOnOutsideClick={true}
              showTitle={true}
              title={
                prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem("lng")) *
                    (prevod.length / oz_j.length) +
                    196
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem("lng")) *
                        (prevod.length / oz_j.length) +
                        196
                    ].item2
                  : "Izračunata cijena!"
              }
              width={300}
              height={250}
            >
              <h1>
                {cijena} {this.state.valuta}
              </h1>
            </Popup>

           
            <div className="card">
              <div className="dx-fieldset">
                <div className="dx-field">
                  <NumberBox
                    value={bk}
                    onValueChanged={this.bkChanged}
                    showSpinButtons={true}
                  >
                    <Validator>
                      <CustomRule
                        type="required"
                        message={
                          prevod &&
                          prevod.length !== 0 &&
                          prevod[
                            oz_j.indexOf(localStorage.getItem("lng")) *
                              (prevod.length / oz_j.length) +
                              49
                          ]
                            ? prevod[
                                oz_j.indexOf(localStorage.getItem("lng")) *
                                  (prevod.length / oz_j.length) +
                                  49
                              ].item2
                            : "Obavezno polje"
                        }
                      />
                    </Validator>
                  </NumberBox>
                </div>
                <br /> <br />
                <div className="dx-field">
                  <Button
                    text={
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem("lng")) *
                          (prevod.length / oz_j.length) +
                          197
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem("lng")) *
                              (prevod.length / oz_j.length) +
                              197
                          ].item2
                        : "Računaj cijenu"
                    }
                    type="default"
                    width="100%"
                    useSubmitBehavior={true}
                    onClick={this.racunajCijenu}
                  />
                </div>
              </div>
            </div>
            {this.state.izracunata_cijena ? (
              <div className="card ">
                <Narudzba
                  bc={this.state.bc}
                  na={this.state.na}
                  nc={this.state.nc}
                  sifra_recepta={this.state.sifra_recepta}
                  bk={this.state.bk}
                  id={this.state.siid}
                  cijena={this.state.cijena}
                  upit={this.state.upit}
                  valuta={this.state.valuta}
                />
              </div>
            ) : (
              <div className="cijena-prepare"></div>
            )}
          </div>
        </div>*/}
      </div>
    )
  }
  selectionChanged({ selectedItem }) {
    this.setState({
      selectedBItem: selectedItem,
      b: selectedItem,
      upit: {
        ...this.state.upit,
        b: selectedItem.item1,
        bn: selectedItem.item2,
      },
      izracunata_cijena: false,
    })
    this.setSifraRecepta()
    // // // // console.log(selectedItem);
  }
  selectionBChanged({ selectedItem }) {
    // pomocna za boje ( ne treba )
    this.setState({
      selectedBItem: selectedItem,
      upit: {
        ...this.state.upit,
        b: selectedItem.item1,
        bn: selectedItem.item2,
      },
      izracunata_cijena: false,
    })
    this.setSifraRecepta()
  }
  selectionNKChanged({ selectedItem }) {
    this.setState({
      selectedNKItem: selectedItem,

      upit: { ...this.state.upit, nk: selectedItem.item1 },
    })
    if (selectedItem.item1 === 'Jednostavno') this.setState({ nk_min: 0.25 })
    else if (selectedItem.item2 === 'Srednje') this.setState({ nk_min: 0.75 })
    else this.setState({ nk_min: 2 })
    if (this.state.upit && this.state.upit.isT2added == true) this.updateNK()
  }
  renderConditionalIcon() {
    return <ConditionalIcon value={null} />
  }
  showInfo() {
    this.state.popup_Shown === 0
      ? this.setState({ popupVisible: true, popup_Shown: 1 })
      : this.setState({
          popup_Shown: 1,
        })
  }
}

const mapStateToProps = (state) => {
  return {
    m_list: state.m_list,
    npz: state.npz,
    hppe_a: state.hppe_a,
    hppe_b: state.hppe_b,
    hppe_c: state.hppe_c,
    dos_a: state.dos_a,
    dos_b: state.dos_b,
    dos_2017_a: state.dos_2017_a,
    dos_2017_b: state.dos_2017_b,
    // itdos_a: state.itdos_a,
    //itdos_b: state.itdos_b,
    boja_a: state.boja_a,
    boja_b: state.boja_b,
    s_c: state.s_c,
    num_of_kuzs: state.num_of_kuzs,
    num_of_kuses: state.num_of_kuses,
    num_of_sis: state.num_of_sis,
    nk: state.nk,
    e6_me: state.e6_me,
    e6_dhp: state.e6_dhp,
    prevod: state.prevod,
    jezik: state.jezik,
    oz_j: state.oz_j,
  }
}

export default connect(mapStateToProps, null)(Upit)
