import React, { useState, useEffect } from 'react'
import FadeIn from 'react-fade-in'
import { connect } from 'react-redux'

const TolerancijeOpis = (props) => {
  const [dk, setDk] = useState('x')

  useEffect(() => {
    setInterval(() => {
      if (localStorage.getItem('dk')) setDk(localStorage.getItem('dk'))
    }, 100)
  })
  return (
    <FadeIn>
      <div className="opis" style={{ zIndex: '1000' }}>
        <ul className="list-opis ">
          {dk == 'D1' || dk == 'x' ? (
            <li>
              {' '}
              {props.prevod &&
              props.prevod.length !== 0 &&
              props.prevod[
                props.oz_j.indexOf(localStorage.getItem('lng')) *
                  (props.prevod.length / props.oz_j.length) +
                  116
              ]
                ? props.prevod[
                    props.oz_j.indexOf(localStorage.getItem('lng')) *
                      (props.prevod.length / props.oz_j.length) +
                      116
                  ].item2
                : 'D1 - na komadu ima minimalno jedna kota sa tolerancijskim poljem manje ili jednako 0,02.'}
            </li>
          ) : (
            ''
          )}
          {dk == 'D2' || dk == 'x' ? (
            <li>
              {props.prevod &&
              props.prevod.length !== 0 &&
              props.prevod[
                props.oz_j.indexOf(localStorage.getItem('lng')) *
                  (props.prevod.length / props.oz_j.length) +
                  117
              ]
                ? props.prevod[
                    props.oz_j.indexOf(localStorage.getItem('lng')) *
                      (props.prevod.length / props.oz_j.length) +
                      117
                  ].item2
                : 'D2  - na komadu ima minimalno jedna kota sa tolerancijskim poljem veće od 0,02 a manje ili jednako 0,05.'}
            </li>
          ) : (
            ''
          )}
          {dk == 'D3' || dk == 'x' ? (
            <li>
              {props.prevod &&
              props.prevod.length !== 0 &&
              props.prevod[
                props.oz_j.indexOf(localStorage.getItem('lng')) *
                  (props.prevod.length / props.oz_j.length) +
                  118
              ]
                ? props.prevod[
                    props.oz_j.indexOf(localStorage.getItem('lng')) *
                      (props.prevod.length / props.oz_j.length) +
                      118
                  ].item2
                : 'D3 - na komadu ima kota sa tolerancijiskim poljem veće od 0,05.'}
            </li>
          ) : (
            ''
          )}
        </ul>
      </div>
    </FadeIn>
  )
}

const mapStateToProps = (state) => {
  return {
    jezik: state.jezik,
    prevod: state.prevod,
    oz_j: state.oz_j,
  }
}

export default connect(mapStateToProps, null)(TolerancijeOpis)
