import axios from "axios";
import { JEZIK, REQUEST_JEZIK } from "../../const";

export const fetchJData = (jezik) => {
  return {
    type: REQUEST_JEZIK,
    jezik,
  };
};

export const fetchJezikData = () => {
  return (dispatch) => {
    return axios
      .get(JEZIK)
      .then((response) => {
        dispatch(fetchJData(response.data));
      })
      .catch((error) => {
        //// // // // console.log("err")
        throw error;
      });
  };
};
