import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'

import './App.css'

import React, { useState, useEffect } from 'react'
import {
  HashRouter as Router,
  Switch as Sw,
  Route,
  useParams,
  useLocation,
} from 'react-router-dom'
//import i18n from "./i18n";
import Switch from 'react-switch'
import Login from './Components/DevExtremeComponents/Login/Login'
import Upit from './Components/DevExtremeComponents/Upit/Upit'

import Register from './Components/DevExtremeComponents/Register/Register'
import Loading from './Components/DevExtremeComponents/Loading/Loading'
import RegisterDonePage from './Pages/DodatneStranice/RegisterDonePage'
import NotFoundErrorPage from './Pages/DodatneStranice/NotFoundErrorPage'
import OdobrenjeKorisnika from './Components/DevExtremeComponents/AdminPanel/OdobrenjeKorisnika/OdobrenjeKorisnika'
import SlozeniArtikal from './Components/DevExtremeComponents/SlozeniArtikal/SlozeniArtikal'
import KorisnikPanelPage from './Pages/Korisnik/KorisnikPanelPage'
import ServerErrorPage from './Pages/DodatneStranice/ServerErrorPage'
import EmailButton from './Components/Mail/Mail'
import Modal from './Components/DevExtremeComponents/Modal/Modal'
import axios from 'axios'
import ReportPom from './Components/Download/ReportPom'
import UploadCrteza from './Components/DevExtremeComponents/Upit/Crtez/UploadCrteza'
import UpitAdmin from './Components/DevExtremeComponents/Upit/UpitAdmin/UpitAdmin'
import SlozeniArtikalAdmin from './Components/DevExtremeComponents/Upit/UpitAdmin/SlozeniArtikalAdmin'
import OdobrenjeRegistracije from './Pages/DodatneStranice/OdobrenjeRegistracije'
import UsloviAplikacije from './Pages/DodatneStranice/UsloviAplikacije'
import Galerija from './Components/DevExtremeComponents/Galerija'
import { JEZIK, OZNAKE_JEZIKA, PREVOD } from './const'
import ResetPassword from './Components/DevExtremeComponents/Login/ResetPassword'
import ResetModal from './Components/DevExtremeComponents/Login/ResetModal'
import Admin from './Pages/Admin/Admin'
import Komitent from './Pages/Admin/Komitent'

const CryptoJS = require('crypto-js')

function App() {
  const [lng_class, set_lng_class] = useState('#' + window.location.pathname)
  const [error, setError] = useState(false)

  const [nfError, setNFError] = useState(false)
  const [languages, setLanguages] = useState([])
  const [loading, setLoading] = useState(false)
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem('l_m') ? true : false
  )

  const location = useLocation()
  useEffect(() => fetchData(), [])
  useEffect(() => set_lng_class(location.hash), [location])
  //const [ucitano, setUcitano] = useState(fetchData(languages));
  async function fetchData() {
    setLoading(true)
    await fetch('https://api.gat-crm.com:1121/jezici')
      .then((res) => {
        setLoading(false)
        if (res.status >= 400) setError(true)
        res.json().then((res) => {
          //  console.log(res)
          setLanguages(res)
        })
      })
      .catch((err) => {
        setError(true)
        //// // console.log("Error", err);
        setLoading(false)
      })
  }

  const changeLanguage = async (lng) => {
    let expires = new Date()
    expires.setTime(expires.getTime() + new Date() * 1000)
    var jwtCookie =
      'lng' +
      '=' +
      lng +
      ';' +
      ', expires=' +
      expires +
      ', path=' +
      '/' +
      ', domain=' +
      window.location.origin +
      ', httpOnly=' +
      false +
      ', secure=' +
      false
    document.cookie = jwtCookie

    //i18n.changeLanguage(lng);
    localStorage.setItem('lng', lng)
    //// // console.log(lng, localStorage.getItem("nmx"));
    const formData = new FormData()
    formData.append('lng', lng)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    if (localStorage.getItem('nmx') !== null)
      axios.put(
        `https://api.gat-crm.com:1121/usr/lng/${parseInt(
          JSON.parse(
            CryptoJS.AES.decrypt(
              localStorage.getItem('nmx'),
              'Adna1234'
            ).toString(CryptoJS.enc.Utf8)
          )
        )}`,
        formData,
        config
      )
    window.location.href = window.location.origin + '/#/'

    //.then((resp) => // // console.log("USER UPDATE: ", resp.data));
  }
  if (loading) return <Loading />
  else if (error) return <ServerErrorPage />
  return (
    <Router>
      <div className={localStorage.getItem('l_m') ? 'light-mode' : 'dark-mode'}>
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            right: '5px',
            zIndex: '10000000',
          }}
        >
          <label>
            {' '}
            <Switch
              onChange={() => {
                setDarkMode(!darkMode)
                darkMode
                  ? localStorage.setItem('d_m', 'darkMode')
                  : localStorage.removeItem('d_m')
                !darkMode
                  ? localStorage.setItem('l_m', 'lightMode')
                  : localStorage.removeItem('l_m')
              }}
              checked={darkMode}
              uncheckedIcon={<div> </div>}
              checkedIcon={<div></div>}
              uncheckedHandleIcon={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    color: 'black',
                    fontSize: 20,
                  }}
                >
                  ☾
                </div>
              }
              checkedHandleIcon={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    backgroundColor: 'white',
                    borderRadius: '50px',
                    fontSize: 18,
                  }}
                >
                  ☀
                </div>
              }
            />
          </label>
        </div>

        <div>
          <nav>
            <div className="nav-lng">
              <ul
                id="ul_lng"
                className={
                  lng_class == '#/'
                    ? 'languages abc'
                    : lng_class == '#/register'
                    ? 'languages def'
                    : lng_class == '#/upit'
                    ? 'languages ghi'
                    : lng_class == '#/user'
                    ? 'languages jkl'
                    : lng_class == '#/s_a'
                    ? 'languages mno'
                    : 'languages sdf'
                }
              >
                {languages
                  ? languages.map((v, i) => (
                      <li key={i}>
                        {' '}
                        <img
                          onClick={() => {
                            changeLanguage(v.item1)
                          }}
                          src={v.item2}
                          alt="lng"
                        />
                      </li>
                    ))
                  : ''}
              </ul>
            </div>
          </nav>
          <Sw>
            <Route
              exact
              path="/"
              component={() => <Login error={error} darkMode={darkMode} />}
            />

            {/*render={(props) => <HomePage {...props} lng={languages} />} -> pomocna metoda, ukoliko bi se podaci prenosili iz App.js  */}

            <Route exact path="/register" component={Register} />

            <Route exact path="/user" component={KorisnikPanelPage} />

            <Route exact path="/upit" component={Upit} />

            <Route exact path="/upit/a" component={UpitAdmin} />
            <Route exact path="/resetPwd" component={ResetModal} />

            <Route exact path="/adm" component={() => <Admin />} />

            <Route exact path="/o/k" component={OdobrenjeKorisnika} />

            <Route exact path="/s_a" component={SlozeniArtikal} />

            <Route exact path="/s_a/a" component={SlozeniArtikalAdmin} />

            <Route exact path="/register_wait" component={RegisterDonePage} />

            <Route exact path="/upload" component={UploadCrteza} />

            <Route exact path="/mail" component={EmailButton} />

            <Route
              exact
              path="/modal"
              component={() => <Modal darkMode={darkMode} />}
            />
            <Route exact path="/galerija" component={Galerija} />

            <Route exact path="/pdf/pom" component={ReportPom} />
            <Route
              exact
              path="/uslovi_aplikacije"
              component={UsloviAplikacije}
            />
            <Route path="/rgstr_vrf/:id" children={<Child />} />
            <Route path="/re_pwd/:id" children={<ChildPwd />} />

            <Route exact path="/a/KM/:km" children={<ChildKM />} />
            <Route exact path="*" component={NotFoundErrorPage} />

            {/*  <Route exact path="/user/login" component={Login} />
          <Route exact path="/pom" component={PomocnaKomponenta} />


            <Route exact path="/report" component={PomocnaKomp} />
          <Route exact path="/pomocna" component={AutoFocus} />
          
          <Route exact path="/loading" component={Loading} />*/}
          </Sw>
        </div>
      </div>
    </Router>
  )
}
function Child() {
  let { id } = useParams()

  return <OdobrenjeRegistracije id={id} />
}
function ChildPwd() {
  let { id } = useParams()

  return <ResetPassword id={id} />
}

function ChildKM() {
  let { km } = useParams()

  return <Komitent km={km} />
}
window.onbeforeunload = function (e) {
  var dialogText = 'Želite li se odjaviti?'
  e.returnValue = dialogText
  localStorage.removeItem('token')
  localStorage.removeItem('nmx')
  if (localStorage.getItem('a')) localStorage.removeItem('a')
  if (localStorage.getItem('hppo')) localStorage.removeItem('hppo')
  if (localStorage.getItem('sk')) localStorage.removeItem('sk')
  if (localStorage.getItem('siid')) localStorage.removeItem('siid')
  return dialogText
}

export default App
