import React, { useState, useEffect } from 'react'
import FadeIn from 'react-fade-in'
import { connect } from 'react-redux'

const NacinKacenjaOpis = (props) => {
  const [nk, setNk] = useState('x')

  useEffect(() => {
    setInterval(() => {
      if (localStorage.getItem('nk')) {
        setNk(localStorage.getItem('nk'))
      }
    }, 100)
  })
  return (
    <FadeIn>
      <div
        className="opis nk_opis"
        style={{
          marginTop: '-45px',
          marginLeft: '7px',
          fontSize: '13px',
        }}
      >
        <ul className="list-opis">
          <li style={{ listStyleType: 'none' }}>
            {' '}
            {props.prevod &&
            props.prevod.length !== 0 &&
            props.prevod[
              props.oz_j.indexOf(localStorage.getItem('lng')) *
                (props.prevod.length / props.oz_j.length) +
                212
            ]
              ? props.prevod[
                  props.oz_j.indexOf(localStorage.getItem('lng')) *
                    (props.prevod.length / props.oz_j.length) +
                    212
                ].item2
              : 'Kačenje je metoda postavljanja  (vješanja) komada na alat koji drži komade u procesu eloksiranja. Kačenjem se obezbjeđuje stabilno držanje komada na alatu i osigurava električni kontakt. Veličina, težina i geometrija dijelova određuje način kačenja komada.'}{' '}
          </li>
          {nk == 'jednostavno' || nk == 'x' ? (
            <li>
              {' '}
              {props.prevod &&
              props.prevod.length !== 0 &&
              props.prevod[
                props.oz_j.indexOf(localStorage.getItem('lng')) *
                  (props.prevod.length / props.oz_j.length) +
                  213
              ]
                ? ' - ' +
                  props.prevod[
                    props.oz_j.indexOf(localStorage.getItem('lng')) *
                      (props.prevod.length / props.oz_j.length) +
                      213
                  ].item2
                : '  - Jednostavno kačenje: stabilno držanje komada na alatu postiže se jednim kontaktom alata na jednom od otvora na komadu.'}
            </li>
          ) : (
            ''
          )}
          {nk == 'srednje' || nk == 'x' ? (
            <li>
              {' '}
              {props.prevod &&
              props.prevod.length !== 0 &&
              props.prevod[
                props.oz_j.indexOf(localStorage.getItem('lng')) *
                  (props.prevod.length / props.oz_j.length) +
                  214
              ]
                ? ' - ' +
                  props.prevod[
                    props.oz_j.indexOf(localStorage.getItem('lng')) *
                      (props.prevod.length / props.oz_j.length) +
                      214
                  ].item2
                : '  - Srednje složeno kačenje: stabilno držanje komada na alatu potiže se sa dva kontakta alata na dva mjesta na komadu, u otvorima ili na krajevima komada.'}
            </li>
          ) : (
            ''
          )}
          {nk == 'slozeno' || nk == 'x' ? (
            <li>
              {' '}
              {props.prevod &&
              props.prevod.length !== 0 &&
              props.prevod[
                props.oz_j.indexOf(localStorage.getItem('lng')) *
                  (props.prevod.length / props.oz_j.length) +
                  215
              ]
                ? ' - ' +
                  props.prevod[
                    props.oz_j.indexOf(localStorage.getItem('lng')) *
                      (props.prevod.length / props.oz_j.length) +
                      215
                  ].item2
                : ' - Složeno kačenje: stabilno držanje komada na alatu postiže se sa više od dva potrebna kontakta alata na komadu.'}
            </li>
          ) : (
            ''
          )}
        </ul>
      </div>
    </FadeIn>
  )
}

const mapStateToProps = (state) => {
  return {
    jezik: state.jezik,
    prevod: state.prevod,
    oz_j: state.oz_j,
  }
}

export default connect(mapStateToProps, null)(NacinKacenjaOpis)
