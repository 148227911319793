import React from 'react'
import Form, {
  ButtonItem,
  GroupItem,
  SimpleItem,
  Label,
  CompareRule,
  EmailRule,
  PatternRule,
  RequiredRule,
  StringLengthRule,
  AsyncRule,
  Item,
} from 'devextreme-react/form'
import '../../../Styles/registerStyle.css'
import 'devextreme-react/autocomplete'
import eye from '../../../Images/eye.png'
import white_eye from '../../../Images/white_eye.png'
import RegisterBackground from '../../../Images/RegisterBackground.JPG'
import { Popup, SelectBox, TextBox, Validator } from 'devextreme-react'
import { Button as TextBoxButton } from 'devextreme-react/text-box'
import { useEffect } from 'react'
import axios from 'axios'
import { API, OZNAKE_JEZIKA, PREVOD } from '../../../const'
import { useState } from 'react'
import notify from 'devextreme/ui/notify'
export default function ResetModal() {
  const [email, setEmail] = useState('')
  const [prevod, setPrevod] = useState([])
  const [oz_j, setOzJ] = useState([])

  useEffect(() => {
    axios.get(PREVOD).then((resp) => setPrevod(resp.data))
    axios.get(OZNAKE_JEZIKA).then((resp) => setOzJ(resp.data))
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    axios
      .request({ method: 'get', url: API + '/mailRePWd/' + email })
      .then((resp) =>
        notify(
          {
            message:
              prevod &&
              prevod.length !== 0 &&
              prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  147
              ]
                ? prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      147
                  ].item2
                : 'Podaci su poslani!',
            position: {
              my: 'center top',
              at: 'center top',
            },
          },
          'success',
          3000
        )
      )
    setTimeout(
      () => (window.location.href = window.location.origin + '/#/'),
      3000
    )
  }
  return (
    <div>
      <div id="register">
        <div id="register-image">
          <div id="register-image-absolute">
            <img
              src={RegisterBackground}
              alt="register"
              width="100%"
              height="120%"
            />
          </div>
        </div>
        <div id="register-form">
          <React.Fragment>
            <form action="your-action" onSubmit={handleSubmit}>
              <Form
                readOnly={false}
                showColonAfterLabel={true}
                showValidationSummary={true}
                validationGroup="customerData"
              >
                <GroupItem>
                  <div id="mobile-screen-layout-img-register"></div>
                </GroupItem>
                <GroupItem
                  className="prijava"
                  caption={
                    prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        122
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            122
                        ].item2
                      : 'Podaci'
                  }
                >
                  <SimpleItem dataField="Email" editorType="dxTextBox">
                    <div className="dx-field">
                      <TextBox
                        placeholder="Email..."
                        onValueChanged={(e) => setEmail(e.value)}
                        value={email}
                      >
                        <Validator>
                          <RequiredRule message="Email je obavezan" />
                          <EmailRule message="Email je neispravan" />
                        </Validator>
                      </TextBox>
                    </div>
                  </SimpleItem>
                </GroupItem>{' '}
                <ButtonItem
                  horizontalAlignment="left"
                  buttonOptions={{
                    text:
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          185
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              185
                          ].item2
                        : 'Izmjena lozinke',
                    elementAttr: { id: 'registerButton' },
                    useSubmitBehavior: true,
                  }}
                />
              </Form>
            </form>
          </React.Fragment>
        </div>
      </div>
    </div>
  )
}
