import React from 'react'

const Error = () => {
  return (
    <div
      id="error"
      style={{
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        top: '0',
        left: '0',
        zIndex: '100',
      }}
    >
      <h1>500 - Internal Server Error</h1>
      {/*<Link href="/" >Idi na početnu stranicu</Link>*/}
    </div>
  )
}

export default Error
