import React from 'react'
import FadeIn from 'react-fade-in'
import { connect } from 'react-redux'

const MaterijalOpis = (props) => {
  return (
    <FadeIn>
      <div className="opis m_opis" style={{ zIndex: '1000' }}>
        {props.prevod &&
        props.prevod.length !== 0 &&
        props.prevod[
          props.oz_j.indexOf(localStorage.getItem('lng')) *
            (props.prevod.length / props.oz_j.length) +
            111
        ]
          ? props.prevod[
              props.oz_j.indexOf(localStorage.getItem('lng')) *
                (props.prevod.length / props.oz_j.length) +
                111
            ].item2
          : 'Ukoliko niste pronašli odgovarajuću leguru odabrati leguru iz iste serije.'}
      </div>
    </FadeIn>
  )
}

const mapStateToProps = (state) => {
  return {
    jezik: state.jezik,
    prevod: state.prevod,
    oz_j: state.oz_j,
  }
}

export default connect(mapStateToProps, null)(MaterijalOpis)
