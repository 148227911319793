import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DataGrid, { Column, SearchPanel } from 'devextreme-react/data-grid'

import { Button } from 'devextreme-react'
import { fetchUpitiData } from '../../../../store/actions/UpitAction'
import '../../../../Styles/adminUpitStyle.css'
import UrediUpit from '../../Items/UrediUpit'

class ListaUpita extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lng: 'ba',
    }

    this.mounted = false
    this.makeTimer = setInterval(() => {
      if (this.mounted)
        this.setState({
          lng: localStorage.getItem('lng')
            ? localStorage.getItem('lng')
            : this.state.lng,
        })
    })
  }
  componentDidMount() {
    this.mounted = false
    //  // // console.log(this.props.prevod);
    this.makeTimer = setInterval(() => {
      if (this.mounted)
        this.setState({
          lng: localStorage.getItem('lng')
            ? localStorage.getItem('lng')
            : this.state.lng,
        })
    })
  }

  componentWillMount() {
    this.mounted = true
  }
  componentWillUnmount() {
    this.mounted = false
    clearInterval(this.makeTimer)
  }
  render() {
    const { prevod, oz_j } = this.props
    if (!this.props.upiti) {
      return (
        <div>
          <h1>
            {prevod &&
            prevod.length !== 0 &&
            prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                46
            ]
              ? prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    46
                ].item2
              : 'Nema podataka'}
          </h1>
        </div>
      )
    }
    if (localStorage.getItem('nmx')) {
      return (
        <div id="admin_panel_lu">
          <div id="lista_upita">
            <div>
              {' '}
              <h1 id="l_u_header">
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    151
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        151
                    ].item2
                  : 'LISTA VAŠIH UPITA'}
              </h1>
              <DataGrid
                id="gridContainer"
                className="admin_l_u_datagrid"
                dataSource={this.props.upiti}
                keyExpr="id"
                showBorders={true}
                noDataText={
                  prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      99
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          99
                      ].item2
                    : 'Trenutno ne postoji nijedan kreiran upit'
                }
              >
                <SearchPanel
                  visible={true}
                  placeholder={
                    prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        41
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            41
                        ].item2
                      : 'Pretraži...'
                  }
                />
                <Column
                  dataField="datum"
                  caption={
                    prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        95
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            95
                        ].item2
                      : 'Datum'
                  }
                  dataType="date"
                  format="dd-MM-yyyy"
                />
                <Column
                  dataField="ba"
                  caption={
                    prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        50
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            50
                        ].item2
                      : 'Broj artikla'
                  }
                  allowEditing={false}
                />
                <Column
                  dataField="na"
                  caption={
                    prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        96
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            96
                        ].item2
                      : 'Naziv'
                  }
                  allowEditing={false}
                />
                <Column
                  dataField="bc"
                  caption={
                    prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        51
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            51
                        ].item2
                      : 'Broj crteža'
                  }
                  allowEditing={false}
                />
                <Column
                  dataField="cijena"
                  caption={
                    prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        97
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            97
                        ].item2
                      : 'Cijena'
                  }
                  allowEditing={false}
                />
                <Column
                  dataField="id"
                  caption=" "
                  width={100}
                  cellRender={cellRender}
                />
              </DataGrid>
            </div>
          </div>
          <br /> <br /> <br />
          <div id="lista_upita_btn">
            <Button
              text={
                prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    152
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        152
                    ].item2
                  : 'Novi upit'
              }
              onClick={() =>
                (window.location.href = window.location.origin + '/#/upit/a')
              }
              type="default"
              stylingMode="contained"
            />
          </div>
          <br />
          <br />
        </div>
      )
    } else
      return (
        <div>
          {prevod &&
          prevod.length !== 0 &&
          prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              46
          ]
            ? prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  46
              ].item2
            : 'Nema podataka'}
        </div>
      )
  }
}

function cellRender(data) {
  return (
    <Link to={{ pathname: '/upit/a', state: { upit: data.data } }}>
      <UrediUpit />
    </Link>
  )
}

const mapStateToProps = (state) => {
  return {
    upiti: state.upiti,
    prevod: state.prevod,
    jezik: state.jezik,
    oz_j: state.oz_j,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchUpitiData: dispatch(fetchUpitiData(localStorage.getItem('nmx'))),
})

export default connect(mapStateToProps, mapDispatchToProps)(ListaUpita)
