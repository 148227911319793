import { REQUEST_USERS, USERS } from "../../const";
import axios from "axios";

export const fetchData = (users) => {
  return {
    type: REQUEST_USERS,
    users,
  };
};

export const fetchUsersData = () => {
  return (dispatch) => {
    return axios
      .get(USERS)
      .then((response) => {
        dispatch(fetchData(response.data));
        //// // // // console.log(response.data)
      })
      .catch((error) => {
        //// // // // console.log("err")
        throw error;
      });
  };
};
