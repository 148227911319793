import React from 'react'
import FadeIn from 'react-fade-in'

const DOS_A_Opis = () => {
  return (
    <FadeIn>
      <div style={{ zIndex: '1000' }}>
        <ul className="dos_list">
          <li>5µm</li>
          <li>10µm</li>
          <li>15µm</li>
          <li>20µm</li>
          <li>25µm</li>
        </ul>
      </div>
    </FadeIn>
  )
}

export default DOS_A_Opis
