import { PROPERTY_TYPES } from '@babel/types'
import React, { useState } from 'react'
import Lightbox from 'react-lightbox-component'
import 'react-lightbox-component/build/css/index.css'
import j1 from '../../Images/Jednostavno/j1.JPG'
import j2 from '../../Images/Jednostavno/j2.JPG'
import j3 from '../../Images/Jednostavno/j3.JPG'
import j4 from '../../Images/Jednostavno/j4.JPG'
import j5 from '../../Images/Jednostavno/j5.JPG'

import ss1 from '../../Images/Srednje/ss1.JPG'
import ss2 from '../../Images/Srednje/ss2.JPG'
import ss3 from '../../Images/Srednje/ss3.JPG'
import ss4 from '../../Images/Srednje/ss4.JPG'
import ss5 from '../../Images/Srednje/ss5.JPG'

import s1 from '../../Images/Slozeno/s1.JPG'
import s2 from '../../Images/Slozeno/s2.JPG'
import s3 from '../../Images/Slozeno/s3.JPG'
import s4 from '../../Images/Slozeno/s4.JPG'
import s5 from '../../Images/Slozeno/s5.JPG'
import { useEffect } from 'react'
import axios from 'axios'
import { OZNAKE_JEZIKA, PREVOD } from '../../const'

export default function Galerija(props) {
  const [text1, setText1] = useState('')
  const [text2, setText2] = useState('')
  const [text3, setText3] = useState('')

  const [prevod, setPrevod] = useState([])
  const [oz_j, setOzJ] = useState([])

  useEffect(() => {
    axios.get(PREVOD).then((resp) => setPrevod(resp.data))
    axios.get(OZNAKE_JEZIKA).then((resp) => setOzJ(resp.data))
  }, [])

  const images_j = [
    {
      src: j1,
      title:
        prevod &&
        prevod.length !== 0 &&
        prevod[
          oz_j.indexOf(localStorage.getItem('lng')) *
            (prevod.length / oz_j.length) +
            67
        ]
          ? prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                67
            ].item2
          : 'Jednostavno',
      description: ' ',
    },
    {
      src: j2,
      title:
        prevod &&
        prevod.length !== 0 &&
        prevod[
          oz_j.indexOf(localStorage.getItem('lng')) *
            (prevod.length / oz_j.length) +
            67
        ]
          ? prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                67
            ].item2
          : 'Jednostavno',
      description: ' ',
    },
    {
      src: j3,
      title:
        prevod &&
        prevod.length !== 0 &&
        prevod[
          oz_j.indexOf(localStorage.getItem('lng')) *
            (prevod.length / oz_j.length) +
            67
        ]
          ? prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                67
            ].item2
          : 'Jednostavno',
      description: ' ',
    },
    {
      src: j4,
      title:
        prevod &&
        prevod.length !== 0 &&
        prevod[
          oz_j.indexOf(localStorage.getItem('lng')) *
            (prevod.length / oz_j.length) +
            67
        ]
          ? prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                67
            ].item2
          : 'Jednostavno',
      description: ' ',
    },
    {
      src: j5,
      title:
        prevod &&
        prevod.length !== 0 &&
        prevod[
          oz_j.indexOf(localStorage.getItem('lng')) *
            (prevod.length / oz_j.length) +
            67
        ]
          ? prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                67
            ].item2
          : 'Jednostavno',
      description: ' ',
    },
  ]
  const images_s_s = [
    {
      src: ss1,
      title:
        prevod &&
        prevod.length !== 0 &&
        prevod[
          oz_j.indexOf(localStorage.getItem('lng')) *
            (prevod.length / oz_j.length) +
            68
        ]
          ? prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                68
            ].item2
          : 'Srednje',
      description: ' ',
    },
    {
      src: ss2,
      title:
        prevod &&
        prevod.length !== 0 &&
        prevod[
          oz_j.indexOf(localStorage.getItem('lng')) *
            (prevod.length / oz_j.length) +
            68
        ]
          ? prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                68
            ].item2
          : 'Srednje',
      description: ' ',
    },
    {
      src: ss3,
      title:
        prevod &&
        prevod.length !== 0 &&
        prevod[
          oz_j.indexOf(localStorage.getItem('lng')) *
            (prevod.length / oz_j.length) +
            68
        ]
          ? prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                68
            ].item2
          : 'Srednje',
      description: ' ',
    },
    {
      src: ss4,
      title:
        prevod &&
        prevod.length !== 0 &&
        prevod[
          oz_j.indexOf(localStorage.getItem('lng')) *
            (prevod.length / oz_j.length) +
            68
        ]
          ? prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                68
            ].item2
          : 'Srednje',
      description: ' ',
    },
    {
      src: ss5,
      title:
        prevod &&
        prevod.length !== 0 &&
        prevod[
          oz_j.indexOf(localStorage.getItem('lng')) *
            (prevod.length / oz_j.length) +
            68
        ]
          ? prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                68
            ].item2
          : 'Srednje',
      description: ' ',
    },
  ]
  const images_s = [
    {
      src: s1,
      title:
        prevod &&
        prevod.length !== 0 &&
        prevod[
          oz_j.indexOf(localStorage.getItem('lng')) *
            (prevod.length / oz_j.length) +
            69
        ]
          ? prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                69
            ].item2
          : 'Složeno',
      description: ' ',
    },
    {
      src: s2,
      title:
        prevod &&
        prevod.length !== 0 &&
        prevod[
          oz_j.indexOf(localStorage.getItem('lng')) *
            (prevod.length / oz_j.length) +
            69
        ]
          ? prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                69
            ].item2
          : 'Složeno',
      description: ' ',
    },
    {
      src: s3,
      title:
        prevod &&
        prevod.length !== 0 &&
        prevod[
          oz_j.indexOf(localStorage.getItem('lng')) *
            (prevod.length / oz_j.length) +
            69
        ]
          ? prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                69
            ].item2
          : 'Složeno',
      description: ' ',
    },
    {
      src: s4,
      title:
        prevod &&
        prevod.length !== 0 &&
        prevod[
          oz_j.indexOf(localStorage.getItem('lng')) *
            (prevod.length / oz_j.length) +
            69
        ]
          ? prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                69
            ].item2
          : 'Složeno',
      description: ' ',
    },
    {
      src: s5,
      title:
        prevod &&
        prevod.length !== 0 &&
        prevod[
          oz_j.indexOf(localStorage.getItem('lng')) *
            (prevod.length / oz_j.length) +
            69
        ]
          ? prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                69
            ].item2
          : 'Složeno',
      description: ' ',
    },
  ]

  if (props.props.props.nk == 'jednostavno')
    return (
      <div>
        <Lightbox
          images={images_j}
          renderImageFunc={(idx, image, toggleLightbox, width, height) => {
            if (idx == '0')
              return (
                <div
                  style={{
                    width: '30%',
                    paddingLeft: '5px',
                    marginLeft: '-5px',
                    paddingBottom: '10px',
                  }}
                  onMouseEnter={() =>
                    setText1(
                      prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            263
                        ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              263
                          ].item2
                        : 'Klikom na sliku otvorite galeriju slika za kačenje!'
                    )
                  }
                  onMouseLeave={() => setText1('')}
                >
                  {' '}
                  <img
                    key={idx}
                    src={image.src}
                    className="img-circle"
                    style={{ width: width, height: height }}
                    onClick={toggleLightbox.bind(true, idx)}
                  />
                  <p>
                    {prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        67
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            67
                        ].item2
                      : 'Jednostavno'}
                  </p>
                  <p>{text1}</p>
                </div>
              )
            return <div></div>
          }}
          thumbnailWidth="50px"
          thumbnailHeight="50px"
          clickOutsideToClose={true}
          showImageModifiers={false}
        />
      </div>
    )
  if (props.props.props.nk == 'srednje')
    return (
      <div>
        <Lightbox
          images={images_s_s}
          renderImageFunc={(idx, image, toggleLightbox, width, height) => {
            if (idx == '0')
              return (
                <div
                  style={{
                    width: '30%',
                    paddingLeft: '5px',
                    marginLeft: '-5px',
                    paddingBottom: '10px',
                  }}
                  onMouseEnter={() =>
                    setText2(
                      prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            263
                        ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              263
                          ].item2
                        : 'Klikom na sliku otvorite galeriju slika za kačenje!'
                    )
                  }
                  onMouseLeave={() => setText2('')}
                >
                  {' '}
                  <img
                    key={idx}
                    src={image.src}
                    className="img-circle"
                    style={{ width: width, height: height }}
                    onClick={toggleLightbox.bind(false, idx)}
                  />
                  <p>
                    {prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        68
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            68
                        ].item2
                      : 'Srednje'}
                  </p>
                  <p>{text2}</p>
                </div>
              )
            return <div></div>
          }}
          thumbnailWidth="50px"
          thumbnailHeight="50px"
          showImageModifiers={false}
        />
      </div>
    )
  if (props.props.props.nk == 'slozeno')
    return (
      <div>
        {' '}
        <Lightbox
          images={images_s}
          renderImageFunc={(idx, image, toggleLightbox, width, height) => {
            if (idx == '0')
              return (
                <div
                  style={{
                    width: '30%',
                    paddingLeft: '5px',
                    marginLeft: '-5px',
                    paddingBottom: '10px',
                  }}
                  onMouseEnter={() =>
                    setText3(
                      prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            263
                        ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              263
                          ].item2
                        : 'Klikom na sliku otvorite galeriju slika za kačenje!'
                    )
                  }
                  onMouseLeave={() => setText3('')}
                >
                  {' '}
                  <img
                    key={idx}
                    src={image.src}
                    className="img-circle"
                    style={{ width: width, height: height }}
                    onClick={toggleLightbox.bind(null, idx)}
                  />
                  <p>
                    {prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        69
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            69
                        ].item2
                      : 'Složeno'}
                  </p>
                  <p>{text3}</p>
                </div>
              )
            return <div></div>
          }}
          thumbnailWidth="50px"
          thumbnailHeight="50px"
          showImageModifiers={false}
        />
      </div>
    )
}
