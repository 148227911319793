import axios from "axios";
import {
  REQUEST_NKUZS,
  NKUZS,
  REQUEST_NKUSES,
  NSIS,
  NKUSES,
  REQUEST_NSIS,
} from "../../const";

export const fetchKUZData = (num_of_kuzs) => {
  return {
    type: REQUEST_NKUZS,
    num_of_kuzs,
  };
};

export const fetchNKUZData = () => {
  return (dispatch) => {
    return axios
      .get(NKUZS)
      .then((response) => {
        dispatch(fetchKUZData(response.data));
        //// // // // console.log("TU SAM" ,response.data)
      })
      .catch((error) => {
        //// // // // console.log("err")
        throw error;
      });
  };
};

export const fetchKUSData = (num_of_kuses) => {
  return {
    type: REQUEST_NKUSES,
    num_of_kuses,
  };
};

export const fetchNKUSESData = () => {
  return (dispatch) => {
    return axios
      .get(NKUSES)
      .then((response) => {
        dispatch(fetchKUSData(response.data));
        //// // // // console.log("TU SAM" ,response.data)
      })
      .catch((error) => {
        //// // // // console.log("err")
        throw error;
      });
  };
};

export const fetchSISData = (num_of_sis) => {
  return {
    type: REQUEST_NSIS,
    num_of_sis,
  };
};

export const fetchNSISData = () => {
  return (dispatch) => {
    return axios
      .get(NSIS)
      .then((response) => {
        dispatch(fetchSISData(response.data));
        //// // // // console.log("TU SAM" ,response.data)
      })
      .catch((error) => {
        //// // // // console.log("err")
        throw error;
      });
  };
};
