import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { Button, Validator } from 'devextreme-react'
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box'
import { CompareRule, RequiredRule } from 'devextreme-react/form'
import '../../Styles/adminProfilStyle.css'
import eye from '../../Images/eye.png'
var CryptoJS = require('crypto-js')

class AdminProfilePage extends Component {
  constructor(props) {
    super(props)
    this.passwordButton1 = {
      icon: eye,
      stylingMode: 'text',
      onClick: () => {
        this.setState({
          passwordMode1:
            this.state.passwordMode1 === 'text' ? 'password' : 'text',
        })
      },
    }
    this.passwordButton2 = {
      icon: eye,
      stylingMode: 'text',
      onClick: () => {
        this.setState({
          passwordMode2:
            this.state.passwordMode2 === 'text' ? 'password' : 'text',
        })
      },
    }
    this.passwordButton3 = {
      icon: eye,
      stylingMode: 'text',
      onClick: () => {
        this.setState({
          passwordMode3:
            this.state.passwordMode3 === 'text' ? 'password' : 'text',
        })
      },
    }

    this.state = {
      user: {
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        country: '',
        city: '',
      },
      old_pwd: '',
      new_pwd: '',
      re_pwd: '',
      visibleForm: false,
      passwordMode1: 'password',
      passwordMode2: 'password',
      passwordMode3: 'password',
    }
    this.onOldPwdChanged = this.onOldPwdChanged.bind(this)
    this.onNewPwdChanged = this.onNewPwdChanged.bind(this)
    this.onRePwdChanged = this.onRePwdChanged.bind(this)
    this.passwordComparison = this.passwordComparison.bind(this)
  }

  onOldPwdChanged(e) {
    this.setState({ old_pwd: e.value })
  }
  onNewPwdChanged(e) {
    this.setState({ new_pwd: e.value })
  }
  onRePwdChanged(e) {
    this.setState({ re_pwd: e.value })
  }
  izmijeniPwd = async (e) => {
    const formData = new FormData()
    formData.append('old_pwd', this.state.old_pwd)
    formData.append('pwd', this.state.new_pwd)
    try {
      await axios
        .put(
          `https://api.gat-crm.com:1121/u_pwd/${this.state.user.id}`,
          formData,
          {
            // popraviti id - kad ga nema u state-u
            headers: {
              'content-type': 'multipart/form-data',
            },
          }
        )
        .then((resp) => {
          // // // // console.log(resp);

          if (resp.data === 'Neispravna sifra') {
            this.props.prevod &&
            this.props.prevod.length !== 0 &&
            this.props.prevod[
              this.props.oz_j.indexOf(localStorage.getItem('lng')) *
                (this.props.prevod.length / this.props.oz_j.length) +
                182
            ]
              ? alert(
                  this.props.prevod[
                    this.props.oz_j.indexOf(localStorage.getItem('lng')) *
                      (this.props.prevod.length / this.props.oz_j.length) +
                      182
                  ].item2
                )
              : alert('Neispravna stara lozinka!')
          } else if (resp.data === 'Iste sifre') {
            this.props.prevod &&
            this.props.prevod.length !== 0 &&
            this.props.prevod[
              this.props.oz_j.indexOf(localStorage.getItem('lng')) *
                (this.props.prevod.length / this.props.oz_j.length) +
                183
            ]
              ? alert(
                  this.props.prevod[
                    this.props.oz_j.indexOf(localStorage.getItem('lng')) *
                      (this.props.prevod.length / this.props.oz_j.length) +
                      183
                  ].item2
                )
              : alert('Nova šifra ne može biti ista kao i stara!')
          } else {
            this.props.prevod &&
            this.props.prevod.length !== 0 &&
            this.props.prevod[
              this.props.oz_j.indexOf(localStorage.getItem('lng')) *
                (this.props.prevod.length / this.props.oz_j.length) +
                184
            ]
              ? alert(
                  this.props.prevod[
                    this.props.oz_j.indexOf(localStorage.getItem('lng')) *
                      (this.props.prevod.length / this.props.oz_j.length) +
                      184
                  ].item2
                )
              : alert('Izmijenjen password')
            // // // // console.log("Izmijenjena šifra");
            this.setState({ visibleForm: !this.state.visibleForm })
          }
        })
    } catch (err) {
      // // // // console.log(err);
      this.setState({ visibleForm: !this.state.visibleForm })
    }

    /*then - postaviti old_pwd,new_pwd,re_pwd = "" nakon uspjesnog update-a i visibleForm prebaciti u ovaj dio*/
  }
  componentDidMount = async () => {
    let ID = window.localStorage.getItem('nmx')
      ? parseInt(
          JSON.parse(
            CryptoJS.AES.decrypt(
              localStorage.getItem('nmx'),
              'Adna1234'
            ).toString(CryptoJS.enc.Utf8)
          )
        )
      : 0
    axios.get(`https://api.gat-crm.com:1121/user/${ID}`).then((resp) => {
      //id
      // // // // console.log(resp.data);
      this.setState({ user: resp.data })
      this.user = resp.data
    })
  }
  render() {
    let { user } = this.state
    const { prevod, oz_j } = this.props
    return (
      <div id="admin_profil">
        <br />
        <h1
          style={{
            marginTop: 0,
            paddingTop: 0,
            fontSize: '28px',
          }}
        >
          {prevod &&
          prevod.length !== 0 &&
          prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              122
          ]
            ? prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  122
              ].item2
            : 'Podaci'}
          :
        </h1>
        <div id="profil_podaci">
          <div className="lista_podataka">
            <div>
              <p>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    42
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        42
                    ].item2
                  : 'Ime'}
                /
              </p>
              <p>{user.firstName}</p>
            </div>

            <div>
              <p>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    43
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        43
                    ].item2
                  : 'Prezime'}
                /
              </p>
              <p>{user.lastName}</p>
            </div>
            <div>
              <p>Email/</p>
              <p>{user.email}</p>
            </div>
            <div>
              <p>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    123
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        123
                    ].item2
                  : 'Ime kompanije'}
                /
              </p>
              <p>{user.companyName}</p>
            </div>
            <div>
              <p>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    145
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        145
                    ].item2
                  : 'Adresa'}
                /
              </p>
              <p>{user.address}</p>
            </div>
            <div>
              <p>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    19
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        19
                    ].item2
                  : 'Grad'}
                /
              </p>
              <p>{user.city}</p>
            </div>
            <div>
              <p>
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    17
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        17
                    ].item2
                  : 'Država'}
                /
              </p>
              <p>{user.country}</p>
            </div>
          </div>
        </div>
        <div id="repassword">
          <div
            className="chng_pwd_btn"
            style={{
              visibility: this.state.visibleForm ? 'hidden' : 'visible',
            }}
          >
            <Button
              onClick={() =>
                this.setState({ visibleForm: !this.state.visibleForm })
              }
              text={
                prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    185
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        185
                    ].item2
                  : 'Izmjena lozinke'
              }
              options={{ className: 'chng_pwd_btn' }}
            />
          </div>
          <form
            id="card"
            style={{
              visibility: this.state.visibleForm ? 'visible' : 'hidden',
            }}
          >
            <div className="dx-field">
              <div className="dx-field-label">
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    125
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        125
                    ].item2
                  : 'Stara lozinka'}
              </div>
              <div className="dx-field-value">
                <TextBox
                  type="password"
                  name="odlpwd"
                  value={this.state.old_pwd}
                  onValueChanged={this.onOldPwdChanged}
                  mode={this.state.passwordMode1}
                >
                  <TextBoxButton
                    name="password"
                    location="after"
                    options={this.passwordButton1}
                  />
                </TextBox>
              </div>
            </div>
            <div className="dx-field">
              <div className="dx-field-label">
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    126
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        126
                    ].item2
                  : 'Nova lozinka'}
              </div>
              <div className="dx-field-value">
                <TextBox
                  type="password"
                  name="password"
                  value={this.state.new_pwd}
                  onValueChanged={this.onNewPwdChanged}
                  mode={this.state.passwordMode2}
                >
                  <TextBoxButton
                    name="password"
                    location="after"
                    options={this.passwordButton2}
                  />
                </TextBox>
              </div>
            </div>
            <div className="dx-field">
              <div className="dx-field-label">
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    127
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        127
                    ].item2
                  : 'Ponovljena lozinka'}
              </div>
              <div className="dx-field-value">
                <TextBox
                  type="password"
                  name="re-password"
                  value={this.state.re_pwd}
                  onValueChanged={this.onRePwdChanged}
                  mode={this.state.passwordMode3}
                >
                  <Validator>
                    <RequiredRule
                      message={
                        prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            9
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                9
                            ].item2
                          : 'Ponovna lozinka je obavezna'
                      }
                    />
                    <CompareRule
                      message={
                        prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            10
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                10
                            ].item2
                          : 'Lozinka i ponovna lozinka nisu iste. Probajte ponovo!'
                      }
                      comparisonTarget={this.passwordComparison}
                    />
                  </Validator>
                  <TextBoxButton
                    name="password"
                    location="after"
                    options={this.passwordButton3}
                  />
                </TextBox>
              </div>
            </div>
            <br /> <br />
            <div className="chng_pwd_btn">
              <Button
                text={
                  prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      128
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          128
                      ].item2
                    : 'Izmijeni password'
                }
                onClick={this.izmijeniPwd}
              />
            </div>
            <br /> <br />
          </form>
        </div>
      </div>
    )
  }
  passwordComparison() {
    return this.state.new_pwd
  }
}

const mapStateToProps = (state) => {
  return {
    jezik: state.jezik,
    prevod: state.prevod,
    oz_j: state.oz_j,
  }
}

export default connect(mapStateToProps, null)(AdminProfilePage)
