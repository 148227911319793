import React from 'react'
import Form, {
  ButtonItem,
  GroupItem,
  SimpleItem,
  Label,
  CompareRule,
  EmailRule,
  PatternRule,
  RequiredRule,
  StringLengthRule,
  AsyncRule,
  Item,
} from 'devextreme-react/form'
import { connect } from 'react-redux'
import notify from 'devextreme/ui/notify'
import '../../../Styles/registerStyle.css'
import 'devextreme-react/autocomplete'
import { countries } from '../../../data'
import eye from '../../../Images/eye.png'
import white_eye from '../../../Images/white_eye.png'
import RegisterBackground from '../../../Images/RegisterBackground.JPG'
import { Popup, SelectBox, TextBox, Validator } from 'devextreme-react'
import { Button as TextBoxButton } from 'devextreme-react/text-box'
import Homepage from '../../../Pages/HomePage'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { thisTypeAnnotation } from '@babel/types'

class Register extends React.Component {
  constructor(props) {
    super(props)

    this.buttonOptions = {
      text:
        this.props.prevod &&
        this.props.prevod.length !== 0 &&
        this.props.prevod[
          this.props.oz_j.indexOf(localStorage.getItem('lng')) *
            (this.props.prevod.length / this.props.oz_j.length) +
            138
        ]
          ? this.props.prevod[
              this.props.oz_j.indexOf(localStorage.getItem('lng')) *
                (this.props.prevod.length / this.props.oz_j.length) +
                138
            ].item2
          : 'Registrujte se',
      elementAttr: { id: 'registerButton' },
      useSubmitBehavior: true,
    }
    this.checkBoxOptions = {
      text:
        this.props.prevod &&
        this.props.prevod.length !== 0 &&
        this.props.prevod[
          this.props.oz_j.indexOf(localStorage.getItem('lng')) *
            (this.props.prevod.length / this.props.oz_j.length) +
            143
        ]
          ? this.props.prevod[
              this.props.oz_j.indexOf(localStorage.getItem('lng')) *
                (this.props.prevod.length / this.props.oz_j.length) +
                143
            ].item2
          : 'Slažem se s uslovima aplikacije',
      value: false,
    }
    this.cityEditorOptions = {
      //dataSource: service.getCities(),
      minSearchLength: 2,
    }

    this.passwordOptions = {
      mode: 'password',
    }

    this.maxDate = new Date().setYear(new Date().getYear() - 21)

    this.phoneEditorOptions = {
      maskRules: {
        X: /[02-9]/,
      },

      maskInvalidMessage:
        this.props.prevod &&
        this.props.prevod.length !== 0 &&
        this.props.prevod[
          this.props.oz_j.indexOf(localStorage.getItem('lng')) *
            (this.props.prevod.length / this.props.oz_j.length) +
            144
        ]
          ? this.props.prevod[
              this.props.oz_j.indexOf(localStorage.getItem('lng')) *
                (this.props.prevod.length / this.props.oz_j.length) +
                144
            ].item2
          : 'Neispravan format broja',
    }
    this.state = {
      email: '',
      pwd: '',
      passwordMode: 'password',
      re_pwd: '',
      rePasswordMode: 'password',
      first_name: '',
      last_name: '',
      country: 'Bosnia and Herzegovina',
      city: '',
      address: '',
      company_name: '',
      postal_code: '',
      tel: '',
      lng: 'ba',
      po: '',
      bk: '',
      vp: '',
      neispravni_podaci_visible: false,
      e_u_u_popup: false,
    }

    this.mounted = false
    this.passwordChanged = this.passwordChanged.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.passwordComparison = this.passwordComparison.bind(this)
    this.onEmailChanged = this.onEmailChanged.bind(this)
    this.onPWDChanged = this.onPWDChanged.bind(this)
    this.onRePWDChanged = this.onRePWDChanged.bind(this)
    this.onFirstNameChanged = this.onFirstNameChanged.bind(this)
    this.onLastNameChanged = this.onLastNameChanged.bind(this)
    this.onCountryChanged = this.onCountryChanged.bind(this)
    this.onCityChanged = this.onCityChanged.bind(this)
    this.onAddressChanged = this.onAddressChanged.bind(this)
    this.onCompanyNameChanged = this.onCompanyNameChanged.bind(this)
    this.onPostalCodeChanged = this.onPostalCodeChanged.bind(this)
    this.onTelChanged = this.onTelChanged.bind(this)
    this.onPOChanged = this.onPOChanged.bind(this)
    this.onBKChanged = this.onBKChanged.bind(this)
    this.onVPChanged = this.onVPChanged.bind(this)
    this.makeTimer = setInterval(() => {
      if (this.mounted)
        this.setState({
          lng: localStorage.getItem('lng')
            ? localStorage.getItem('lng')
            : this.state.lng,
        })
    })
  }
  onEmailChanged(e) {
    this.setState({
      email: e.value,
    })
  }
  onPWDChanged(e) {
    this.setState({
      pwd: e.value,
    })
  }
  onRePWDChanged(e) {
    this.setState({
      re_pwd: e.value,
    })
  }
  onFirstNameChanged(e) {
    this.setState({
      first_name: e.value,
    })
  }
  onLastNameChanged(e) {
    this.setState({
      last_name: e.value,
    })
  }
  onCountryChanged(e) {
    this.setState({
      country: e.value,
    })
  }
  onCityChanged(e) {
    this.setState({
      city: e.value,
    })
  }
  onAddressChanged(e) {
    this.setState({
      address: e.value,
    })
  }
  onCompanyNameChanged(e) {
    this.setState({
      company_name: e.value,
    })
  }
  onPostalCodeChanged(e) {
    this.setState({
      postal_code: e.value,
    })
  }
  onTelChanged(e) {
    this.setState({
      tel: e.value,
    })
  }
  onPOChanged(e) {
    this.setState({ po: e.value })
  }
  onBKChanged(e) {
    this.setState({ bk: e.value })
  }
  onVPChanged(e) {
    this.setState({ vp: e.value })
  }
  register = (
    f_n,
    l_n,
    email,
    pwd,
    c_n,
    tel,
    country,
    city,
    addr,
    usertx,
    postal_code
  ) => {
    const { prevod, oz_j } = this.props
    const rgstr = 'https://api.gat-crm.com:1121/register'
    if (pwd.length >= 6) {
      axios
        .post(rgstr, {
          firstName: f_n,
          lastName: l_n,
          email: email,
          pwd: pwd,
          companyName: c_n,
          isAdmin: false,
          isAllowed: false,
          tel: tel,
          country: country,
          postalCode: postal_code,
          address: addr,
          city: city,
          siid: '',
          jezik: localStorage.getItem('lng')
            ? localStorage.getItem('lng')
            : 'ba',
          usertx: usertx,
        })
        .then((resp) => {
          axios.get('https://api.gat-crm.com:1121/mailValidate/' + email)

          notify(
            {
              message:
                prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    147
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        147
                    ].item2
                  : 'Podaci su poslani!',
              position: {
                my: 'center top',
                at: 'center top',
              },
            },
            'success',
            3000
          )
          setTimeout(
            () =>
              (window.location.href =
                window.location.origin + '/#/register_wait'),
            3000
          )
        })
        .catch((err) => {
          if (err.toString() === 'Error: Request failed with status code 400')
            this.setState({ e_u_u_popup: true })
          else this.setState({ p_u_p_popup: true })

          // // console.log(err);
        })
    } else this.setState({ n_pwd_popup: true })
  }

  componentDidMount() {
    this.mounted = false
    // // // console.log(this.props.prevod);
    this.makeTimer = setInterval(() => {
      if (this.mounted)
        this.setState({
          lng: localStorage.getItem('lng')
            ? localStorage.getItem('lng')
            : this.state.lng,
        })
    })
  }

  componentWillMount() {
    this.mounted = true
  }
  componentWillUnmount() {
    this.mounted = false
    clearInterval(this.makeTimer)
  }
  render() {
    let {
      email,
      pwd,
      re_pwd,
      first_name,
      last_name,
      country,
      city,
      address,
      company_name,
      tel,
      po,
      bk,
      vp,
      postal_code,
    } = this.state
    const { prevod, oz_j } = this.props
    if (localStorage.getItem('token')) return <Homepage />
    return (
      <div>
        <div id="register">
          <div id="register-image">
            <div id="register-image-absolute">
              <img
                src={RegisterBackground}
                alt="register"
                width="100%"
                height="120%"
              />
            </div>
          </div>
          <div id="register-form">
            <React.Fragment>
              <form action="your-action" onSubmit={this.handleSubmit}>
                <Form
                  readOnly={false}
                  showColonAfterLabel={true}
                  showValidationSummary={true}
                  validationGroup="customerData"
                >
                  <GroupItem>
                    <div id="mobile-screen-layout-img-register"></div>
                  </GroupItem>

                  <GroupItem
                    className="prijava"
                    caption={
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          2
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              2
                          ].item2
                        : 'Prijava'
                    }
                  >
                    <SimpleItem dataField="Email" editorType="dxTextBox">
                      <div className="dx-field">
                        <TextBox
                          placeholder="Email..."
                          onValueChanged={this.onEmailChanged}
                          value={email}
                        >
                          <Validator>
                            <AsyncRule
                              message="Email adresa već u upotrebi"
                              validationCallback={asyncValidation}
                            />
                            <RequiredRule
                              message={
                                prevod &&
                                prevod.length !== 0 &&
                                prevod[
                                  oz_j.indexOf(localStorage.getItem('lng')) *
                                    (prevod.length / oz_j.length) +
                                    4
                                ]
                                  ? prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        4
                                    ].item2
                                  : 'Email je obavezan'
                              }
                            />
                            <EmailRule
                              message={
                                prevod &&
                                prevod.length !== 0 &&
                                prevod[
                                  oz_j.indexOf(localStorage.getItem('lng')) *
                                    (prevod.length / oz_j.length) +
                                    5
                                ]
                                  ? prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        5
                                    ].item2
                                  : 'Email je neispravan'
                              }
                            />
                          </Validator>
                        </TextBox>
                      </div>
                    </SimpleItem>
                    <SimpleItem
                      dataField={
                        prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            6
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                6
                            ].item2
                          : 'Lozinka'
                      }
                      editorType="dxTextBox"
                    >
                      <div className="dx-field">
                        <TextBox
                          placeholder="password ..."
                          stylingMode="filled"
                          onValueChanged={this.onPWDChanged}
                          value={pwd}
                          mode={this.state.passwordMode}
                        >
                          <Validator>
                            <AsyncRule
                              message={
                                prevod &&
                                prevod.length !== 0 &&
                                prevod[
                                  oz_j.indexOf(localStorage.getItem('lng')) *
                                    (prevod.length / oz_j.length) +
                                    3
                                ]
                                  ? prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        3
                                    ].item2
                                  : 'Email adresa već u upotrebi'
                              }
                              validationCallback={asyncValidation}
                            />
                            <RequiredRule
                              message={
                                prevod &&
                                prevod.length !== 0 &&
                                prevod[
                                  oz_j.indexOf(localStorage.getItem('lng')) *
                                    (prevod.length / oz_j.length) +
                                    7
                                ]
                                  ? prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        7
                                    ].item2
                                  : 'Lozinka je obavezna'
                              }
                            />
                          </Validator>
                          <TextBoxButton
                            name="password"
                            location="after"
                            id="TextBoxButton"
                            options={{
                              //text: '👁',

                              elementAttr: { id: 'eyeicon' },
                              icon: localStorage.getItem('l_m')
                                ? eye
                                : white_eye,
                              stylingMode: 'text',
                              onClick: () => {
                                this.setState({
                                  passwordMode:
                                    this.state.passwordMode === 'text'
                                      ? 'password'
                                      : 'text',
                                })
                              },
                            }}
                          />
                        </TextBox>
                      </div>
                    </SimpleItem>
                    <SimpleItem
                      dataField={
                        prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            8
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                8
                            ].item2
                          : 'Ponovna lozinka'
                      }
                      editorType="dxTextBox"
                      editorOptions={this.passwordOptions}
                    >
                      <div className="dx-field">
                        <TextBox
                          placeholder="Re password ..."
                          stylingMode="filled"
                          onValueChanged={this.onRePWDChanged}
                          value={re_pwd}
                          mode={this.state.rePasswordMode}
                        >
                          <Validator>
                            <RequiredRule
                              message={
                                prevod &&
                                prevod.length !== 0 &&
                                prevod[
                                  oz_j.indexOf(localStorage.getItem('lng')) *
                                    (prevod.length / oz_j.length) +
                                    9
                                ]
                                  ? prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        9
                                    ].item2
                                  : 'Ponovna lozinka je obavezna'
                              }
                            />
                            <CompareRule
                              message={
                                prevod &&
                                prevod.length !== 0 &&
                                prevod[
                                  oz_j.indexOf(localStorage.getItem('lng')) *
                                    (prevod.length / oz_j.length) +
                                    10
                                ]
                                  ? prevod[
                                      oz_j.indexOf(
                                        localStorage.getItem('lng')
                                      ) *
                                        (prevod.length / oz_j.length) +
                                        10
                                    ].item2
                                  : 'Lozinka i ponovna lozinka nisu iste. Probajte ponovo!'
                              }
                              comparisonTarget={this.passwordComparison}
                            />
                          </Validator>
                          <TextBoxButton
                            id="TextBoxButton"
                            name="password"
                            location="after"
                            options={{
                              //text: '👁',
                              icon: localStorage.getItem('l_m')
                                ? eye
                                : white_eye,

                              elementAttr: { id: 'eyeicon' },
                              stylingMode: 'text',
                              onClick: () => {
                                this.setState({
                                  rePasswordMode:
                                    this.state.rePasswordMode === 'text'
                                      ? 'password'
                                      : 'text',
                                })
                              },
                            }}
                          />
                        </TextBox>
                      </div>
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem
                    caption={
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          11
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              11
                          ].item2
                        : 'Kontakt osoba'
                    }
                  >
                    <SimpleItem
                      dataField={
                        prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            44
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                44
                            ].item2
                          : 'Ime'
                      }
                    >
                      <TextBox
                        onValueChanged={this.onFirstNameChanged}
                        value={first_name}
                      >
                        <Validator>
                          <RequiredRule
                            message={
                              prevod &&
                              prevod.length !== 0 &&
                              prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  12
                              ]
                                ? prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      12
                                  ].item2
                                : 'Ime je obavezno'
                            }
                          />
                          <PatternRule
                            pattern="^[A-Za-z]{1}[a-z]{1,}$"
                            message={
                              prevod &&
                              prevod.length !== 0 &&
                              prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  13
                              ]
                                ? prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      13
                                  ].item2
                                : 'Neispravno ste unijeli ime'
                            }
                          />
                        </Validator>
                      </TextBox>
                    </SimpleItem>
                    <SimpleItem
                      dataField={
                        prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            43
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                43
                            ].item2
                          : 'Prezime'
                      }
                    >
                      <TextBox
                        onValueChanged={this.onLastNameChanged}
                        value={last_name}
                      >
                        <Validator>
                          <RequiredRule
                            message={
                              prevod &&
                              prevod.length !== 0 &&
                              prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  15
                              ]
                                ? prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      15
                                  ].item2
                                : 'Prezime je obavezno'
                            }
                          />
                          <PatternRule
                            pattern="^[A-Za-z]{1}[a-z]{1,}$"
                            message={
                              prevod &&
                              prevod.length !== 0 &&
                              prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  14
                              ]
                                ? prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      14
                                  ].item2
                                : 'Neispravno ste unijeli prezime'
                            }
                          />
                        </Validator>
                      </TextBox>
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem
                    caption={
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          16
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              16
                          ].item2
                        : 'Podaci o firmi'
                    }
                  >
                    <SimpleItem
                      dataField={
                        prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            42
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                42
                            ].item2
                          : 'Naziv firme'
                      }
                    >
                      <TextBox
                        value={company_name}
                        onValueChanged={this.onCompanyNameChanged}
                      >
                        <Validator>
                          <RequiredRule
                            message={
                              prevod &&
                              prevod.length !== 0 &&
                              prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  23
                              ]
                                ? prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      23
                                  ].item2
                                : 'Naziv firme je obavezan'
                            }
                          />
                        </Validator>
                      </TextBox>
                    </SimpleItem>
                    <SimpleItem
                      dataField={
                        prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            145
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                145
                            ].item2
                          : 'Adresa'
                      }
                    >
                      <TextBox
                        value={address}
                        onValueChanged={this.onAddressChanged}
                      >
                        <Validator>
                          <RequiredRule
                            message={
                              prevod &&
                              prevod.length !== 0 &&
                              prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  146
                              ]
                                ? prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      146
                                  ].item2
                                : 'Adresa je obavezna'
                            }
                          />
                        </Validator>
                      </TextBox>
                    </SimpleItem>

                    <SimpleItem
                      dataField={
                        prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            19
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                19
                            ].item2
                          : 'Grad'
                      }
                      editorType="dxAutocomplete"
                      editorOptions={this.cityEditorOptions}
                    >
                      <TextBox value={city} onValueChanged={this.onCityChanged}>
                        <Validator>
                          <PatternRule
                            pattern={/^[^0-9]+$/}
                            message={
                              prevod &&
                              prevod.length !== 0 &&
                              prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  20
                              ]
                                ? prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      20
                                  ].item2
                                : 'Ne smijete koristiti brojeve u nazivu grada'
                            }
                          />
                          <StringLengthRule
                            min={2}
                            message={
                              prevod &&
                              prevod.length !== 0 &&
                              prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  21
                              ]
                                ? prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      21
                                  ].item2
                                : 'Grad mora sadržavati bar 2 slova u nazivu'
                            }
                          />
                          <RequiredRule
                            message={
                              prevod &&
                              prevod.length !== 0 &&
                              prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  22
                              ]
                                ? prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      22
                                  ].item2
                                : 'Grad je obavezan'
                            }
                          />
                        </Validator>
                      </TextBox>
                    </SimpleItem>
                    <SimpleItem
                      dataField={
                        prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            258
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                258
                            ].item2
                          : 'Poštanski broj'
                      }
                    >
                      <TextBox
                        value={postal_code}
                        onValueChanged={this.onPostalCodeChanged}
                      >
                        <Validator>
                          <RequiredRule
                            message={
                              prevod &&
                              prevod.length !== 0 &&
                              prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  49
                              ]
                                ? prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      49
                                  ].item2
                                : 'Obavezno polje'
                            }
                          />
                        </Validator>
                      </TextBox>
                    </SimpleItem>
                    <SimpleItem
                      dataField={
                        prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            17
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                17
                            ].item2
                          : 'Država'
                      }
                    >
                      <SelectBox
                        items={countries}
                        value={country}
                        onValueChanged={this.onCountryChanged}
                      >
                        <RequiredRule
                          message={
                            prevod &&
                            prevod.length !== 0 &&
                            prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                18
                            ]
                              ? prevod[
                                  oz_j.indexOf(localStorage.getItem('lng')) *
                                    (prevod.length / oz_j.length) +
                                    18
                                ].item2
                              : 'Država je obavezna'
                          }
                        />
                      </SelectBox>
                    </SimpleItem>

                    <SimpleItem
                      dataField={
                        prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            24
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                24
                            ].item2
                          : 'Broj telefona'
                      }
                      editorOptions={this.phoneEditorOptions}
                    >
                      <TextBox value={tel} onValueChanged={this.onTelChanged}>
                        <Validator>
                          <PatternRule
                            pattern={/^[^a-z,^A-Z]+$/}
                            message={
                              prevod &&
                              prevod.length !== 0 &&
                              prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  25
                              ]
                                ? prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      25
                                  ].item2
                                : 'Ne smijete koristiti simbole pri upisu broja telefona'
                            }
                          />
                          <RequiredRule
                            message={
                              prevod &&
                              prevod.length !== 0 &&
                              prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  26
                              ]
                                ? prevod[
                                    oz_j.indexOf(localStorage.getItem('lng')) *
                                      (prevod.length / oz_j.length) +
                                      26
                                  ].item2
                                : 'Telefon je obavezan'
                            }
                          />
                        </Validator>
                      </TextBox>
                    </SimpleItem>
                    <SimpleItem
                      dataField={
                        prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            27
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                27
                            ].item2
                          : 'Prihvaćeno'
                      }
                      editorType="dxCheckBox"
                      editorOptions={this.checkBoxOptions}
                    >
                      <Label visible={false} />

                      <CompareRule
                        message={
                          prevod &&
                          prevod.length !== 0 &&
                          prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              28
                          ]
                            ? prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  28
                              ].item2
                            : 'Nije potvrđeno da se slažete s uslovima aplikacije'
                        }
                        comparisonTarget={this.checkComparison}
                      />
                    </SimpleItem>
                    <SimpleItem>
                      <Link
                        to="/uslovi_aplikacije"
                        target="_blank"
                        style={{ marginLeft: '23px' }}
                      >
                        {prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            284
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                284
                            ].item2
                          : 'Idi na uslove aplikacije'}
                      </Link>
                    </SimpleItem>
                  </GroupItem>
                  <br />
                  <ButtonItem
                    horizontalAlignment="left"
                    buttonOptions={this.buttonOptions}
                  />
                </Form>
              </form>

              <div
                style={{
                  visibility: this.state.neispravni_podaci_visible
                    ? 'visible'
                    : 'hidden',
                  marginLeft: '20px',
                }}
              >
                {this.state.first_name === '' ? (
                  <p className="neispravni_podaci">
                    {prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        202
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            202
                        ].item2
                      : 'Nije popunjeno polje za ime'}
                  </p>
                ) : (
                  ''
                )}
                {this.state.last_name === '' ? (
                  <p className="neispravni_podaci">
                    {prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        203
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            203
                        ].item2
                      : 'Nije popunjeno polje za prezime'}
                  </p>
                ) : (
                  ''
                )}
                {this.state.email === '' ? (
                  <p className="neispravni_podaci">
                    {' '}
                    {prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        204
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            204
                        ].item2
                      : 'Nije popunjeno polje za E-mail adresu'}
                  </p>
                ) : (
                  ''
                )}
                {this.state.password === '' ? (
                  <p className="neispravni_podaci">
                    {prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        205
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            205
                        ].item2
                      : 'Nije popunjeno polje za lozinku'}
                  </p>
                ) : (
                  ''
                )}
                {this.state.company_name === '' ? (
                  <p className="neispravni_podaci">
                    {prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        206
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            206
                        ].item2
                      : 'Nije popunjeno polje za naziv firme'}
                  </p>
                ) : (
                  ''
                )}
                {this.state.address === '' ? (
                  <p className="neispravni_podaci">
                    {prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        207
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            207
                        ].item2
                      : 'Nije popunjeno polje za adresu'}
                  </p>
                ) : (
                  ''
                )}

                {this.state.city === '' ? (
                  <p className="neispravni_podaci">
                    {prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        208
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            208
                        ].item2
                      : 'Nije popunjeno polje za grad'}
                  </p>
                ) : (
                  ''
                )}
                {this.state.country === '' ? (
                  <p className="neispravni_podaci">
                    {prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        209
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            209
                        ].item2
                      : 'Nije popunjeno polje za državu'}
                  </p>
                ) : (
                  ''
                )}
                {this.state.tel === '' ? (
                  <p className="neispravni_podaci">
                    {prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        210
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            210
                        ].item2
                      : 'Nije popunjeno polje za broj telefona'}
                  </p>
                ) : (
                  ''
                )}
              </div>
              <div>
                <Popup
                  shadingColor="rgba(0, 0, 0, 0.5)"
                  container=".dx-viewport"
                  visible={this.state.n_p_popup}
                  onHiding={() => {
                    this.setState({ n_p_popup: false })
                  }}
                  dragEnabled={false}
                  width={500}
                  height={200}
                  closeOnOutsideClick={true}
                  style={{ textAlign: 'center' }}
                  title={
                    prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        29
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            29
                        ].item2
                      : 'Neispravno uneseni podaci!'
                  }
                ></Popup>{' '}
                <Popup
                  shadingColor="rgba(0, 0, 0, 0.5)"
                  container=".dx-viewport"
                  visible={this.state.e_u_u_popup}
                  onHiding={() => {
                    this.setState({ e_u_u_popup: false })
                  }}
                  dragEnabled={false}
                  width={500}
                  height={200}
                  closeOnOutsideClick={true}
                  style={{ textAlign: 'center' }}
                  title={
                    prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        3
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            3
                        ].item2
                      : 'Email adresa već u upotrebi!'
                  }
                ></Popup>
                <Popup
                  shadingColor="rgba(0, 0, 0, 0.5)"
                  container=".dx-viewport"
                  visible={this.state.p_u_p_popup}
                  onHiding={() => {
                    this.setState({ p_u_p_popup: false })
                  }}
                  dragEnabled={false}
                  width={500}
                  height={200}
                  closeOnOutsideClick={true}
                  style={{ textAlign: 'center' }}
                  title={
                    prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        148
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            148
                        ].item2
                      : 'Pogrešno uneseni podaci!'
                  }
                ></Popup>
                <Popup
                  shadingColor="rgba(0, 0, 0, 0.5)"
                  container=".dx-viewport"
                  visible={this.state.n_pwd_popup}
                  onHiding={() => {
                    this.setState({ n_pwd_popup: false })
                  }}
                  dragEnabled={false}
                  width={700}
                  height={200}
                  closeOnOutsideClick={true}
                  style={{ textAlign: 'center' }}
                  title={
                    prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        133
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            133
                        ].item2
                      : 'Nedovoljno duga lozinka, potrebno bar 6 karaktera!'
                  }
                ></Popup>
              </div>
            </React.Fragment>
          </div>
        </div>
      </div>
    )
  }

  passwordChanged(e) {
    ////// // // // console.log(e.value)
    this.setState({
      password: e.value,
    })
  }
  checkComparison() {
    return true
  }

  handleSubmit(e) {
    if (
      this.state.first_name !== '' &&
      this.state.last_name !== '' &&
      this.state.email !== '' &&
      this.state.pwd !== '' &&
      this.state.company_name !== '' &&
      this.state.tel !== '' &&
      this.state.country !== '' &&
      this.state.city !== '' &&
      this.state.address !== ''
    )
      this.register(
        this.state.first_name,
        this.state.last_name,
        this.state.email,
        this.state.pwd,
        this.state.company_name,
        this.state.tel,
        this.state.country,
        this.state.city,
        this.state.address,
        this.state.po + ';' + this.state.bk + ';' + this.state.vp,
        this.state.postal_code
      )
    else {
      /* const { prevod, oz_j } = this.props
      prevod &&
      prevod.length !== 0 &&
      prevod[
        oz_j.indexOf(localStorage.getItem('lng')) *
          (prevod.length / oz_j.length) +
          29
      ]
        ? alert(
            prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                29
            ].item2
          )
        : alert('Neispravno uneseni podaci!')*/
      this.setState({ neispravni_podaci_visible: 'true', n_p_popup: true })
    }
    e.preventDefault()
    //	window.location.href = window.location.origin+ "/#/register_wait"
  }
  passwordComparison() {
    ////// // // // console.log(this.state.pwd)
    return this.state.pwd
  }
}

function sendRequest(value) {
  const validEmail = 'test@dx-email.com'
  //// console.log(value);
  return new Promise((resolve) => {
    setTimeout(function () {
      resolve((value = validEmail))
    }, 1000)
  })
}

function asyncValidation(params) {
  return sendRequest(params.value)
}

const mapStateToProps = (state) => {
  return {
    prevod: state.prevod,
    jezik: state.jezik,
    oz_j: state.oz_j,
  }
}

export default connect(mapStateToProps, null)(Register)
/**<SimpleItem
                      caption={
                        prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem("lng")) *
                            (prevod.length / oz_j.length) +
                            226
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem("lng")) *
                                (prevod.length / oz_j.length) +
                                226
                            ].item2
                          : "Koja površinska obrada najviše odgovara Vašim zahtjevima?"
                      }
                      dataField={po}
                    />
                   <TextBox value={po} onValueChanged={this.onPOChanged} />
                    <SimpleItem
                      caption={
                        prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem("lng")) *
                            (prevod.length / oz_j.length) +
                            227
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem("lng")) *
                                (prevod.length / oz_j.length) +
                                227
                            ].item2
                          : "Koje su očekivane količine proizvoda za potrebe površinske obrade?"
                      }
                      dataField={bk}
                    />
                    <SimpleItem
                      caption={
                        prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem("lng")) *
                            (prevod.length / oz_j.length) +
                            228
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem("lng")) *
                                (prevod.length / oz_j.length) +
                                228
                            ].item2
                          : "Uslugu koju tražite je namjenjena za kakvu vrstu proizvoda?"
                      }
                      dataField={vp}
                    />  */
