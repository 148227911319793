import React from 'react'
import jednostavno from '../../../Images/jednostavno_p.png'
import srednje from '../../../Images/srednje_p.png'
import slozeno from '../../../Images/slozeno_p.png'
import Galerija from '../Galerija'
import { Tooltip } from 'devextreme-react'

export default function ImageItem(data) {
  return (
    <div className="custom-item">
      {' '}
      {data.item2 === 'Jednostavno' ? (
        <ImageItemToolTip props={{ nk: 'jednostavno', src: jednostavno }} />
      ) : data.item2 === 'Srednje' ? (
        <ImageItemToolTip props={{ nk: 'srednje', src: srednje }} />
      ) : data.item2 === 'Složeno' ? (
        <ImageItemToolTip props={{ nk: 'slozeno', src: slozeno }} />
      ) : (
        ''
      )}
    </div>
  )
}

class ImageItemToolTip extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }

    this.toggleSDefault = this.toggleSDefault.bind(this)
    this.toggleDDefault = this.toggleDDefault.bind(this)
  }

  toggleSDefault(e) {
    localStorage.setItem('nk', this.props.props.nk)
  }
  toggleDDefault(e) {
    if (localStorage.getItem('nk')) localStorage.setItem('nk', 'x')
  }

  render() {
    return (
      <div
        id={'product' + this.props.props}
        onMouseEnter={this.toggleSDefault}
        onMouseLeave={this.toggleDDefault}
      >
        <Galerija props={this.props} id="product1" />
      </div>
    )
  }
}
