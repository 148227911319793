import React from 'react'
import { Component } from 'react'
import { connect } from 'react-redux'
import KorisnikPanelPage from './Korisnik/KorisnikPanelPage'
import ServerErrorPage from './DodatneStranice/ServerErrorPage'
import Login from '../Components/DevExtremeComponents/Login/Login'
import Admin from './Admin/Admin'

class HomePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lng: 'ba',
    }
    this.makeTimer()
  }
  makeTimer() {
    setInterval(() => {
      this.setState({
        lng: localStorage.getItem('lng')
          ? localStorage.getItem('lng')
          : this.state.lng,
      })
    }, 1)
  }
  render() {
    if (localStorage.getItem('a')) return <Admin />
    if (localStorage.getItem('nmx')) return <KorisnikPanelPage />
    if (!this.props.jezik) return <ServerErrorPage />
    return <Login />
  }
}
const mapStateToProps = (state) => {
  return {
    prevod: state.prevod,
    jezik: state.jezik,
    oz_j: state.oz_j,
  }
}

export default connect(mapStateToProps, null)(HomePage)
