import React, { Component } from 'react'
import logoutIconWhite from '../../Images/logoutIconWhite.png'
export default class OdjavaButtonWhite extends Component {
  constructor(props) {
    super(props)
    this.odjava = this.odjava.bind(this)
  }
  odjava() {
    localStorage.removeItem('token')
    localStorage.removeItem('nmx')
    if (localStorage.getItem('a')) localStorage.removeItem('a')
    if (localStorage.getItem('sk')) localStorage.removeItem('sk')
    if (localStorage.getItem('siid')) localStorage.removeItem('siid')
    if (localStorage.getItem('hppo')) localStorage.removeItem('hppo')
    if (localStorage.getItem('nk')) localStorage.removeItem('nk')
    if (localStorage.getItem('npz')) localStorage.removeItem('npz')
    if (localStorage.getItem('n_k')) localStorage.removeItem('n_k')
    window.location.href = window.location.origin //"http://localhost:3000/";
  }
  render() {
    return (
      <button id="logout_btn" onClick={this.odjava}>
        <img src={logoutIconWhite} alt="odjava" width="20px" />
      </button>
    )
  }
}
