import { REQUEST_HPPE_C } from "../../../const";

export default function HPPECReducer(state = [], action) {
  switch (action.type) {
    case REQUEST_HPPE_C:
      return action.hppe_c;
    default:
      return state;
  }
}
