import { REQUEST_PREVOD_ADMIN } from "../../const";

export default function PrevodAdminReducer(state = [], action) {
  switch (action.type) {
    case REQUEST_PREVOD_ADMIN:
      return action.prevod_a;
    default:
      return state;
  }
}
