import React, { useState, useEffect } from 'react'
import ListaUpita from '../../Components/DevExtremeComponents/KorisnikPanel/ListaUpita'
import NotFoundErrorPage from '../DodatneStranice/NotFoundErrorPage'
import OdjavaButtonWhite from '../../Components/DevExtremeComponents/OdjavaButtonWhite'
import '../../Styles/korisnikStyle.css'
import GatLogo from '../../Images/gat.png'
//import Background from '../../Images/Background.png'
import Login from '../../Components/DevExtremeComponents/Login/Login'
import { Popup } from 'devextreme-react'
import axios from 'axios'
import { connect } from 'react-redux'
var CryptoJS = require('crypto-js')
const KorisnikPanelPage = (props) => {
  const [shownModal, setShownModal] = useState(false)
  const [user, setUser] = useState({})

  function showModal(e) {
    setShownModal(!shownModal)
  }
  useEffect(() => {
    if (localStorage.getItem('n_k') && localStorage.getItem('n_k') <= 1)
      setShownModal(true)
    if (localStorage.getItem('nmx')) {
      try {
        axios
          .get(
            'https://api.gat-crm.com:1121/user/' +
              JSON.parse(
                CryptoJS.AES.decrypt(
                  localStorage.getItem('nmx'),
                  'Adna1234'
                ).toString(CryptoJS.enc.Utf8)
              )
          )
          .then((resp) => {
            setUser(resp.data)
          })
      } catch (err) {
        console.log(err)
      }
    }
  }, [])
  return (
    <div id="korisnik_panel">
      {localStorage.getItem('a') ? (
        <NotFoundErrorPage />
      ) : localStorage.getItem('nmx') ? (
        <div>
          {' '}
          <div
            className="q"
            style={{
              position: 'fixed',
              top: '50px',
              right: '20vw',
              zIndex: '10000000',
            }}
          >
            {' '}
            <span onClick={showModal} id="q">
              {' '}
              ?
            </span>
          </div>{' '}
          <div>
            {' '}
            <Popup
              shadingColor="rgba(0, 0, 0, 0.5)"
              container=".dx-viewport"
              visible={shownModal}
              onHiding={() => setShownModal(false)}
              dragEnabled={false}
              closeOnOutsideClick={true}
              showTitle={true}
              title={
                props.prevod &&
                props.prevod.length !== 0 &&
                props.prevod[
                  props.oz_j.indexOf(localStorage.getItem('lng')) *
                    (props.prevod.length / props.oz_j.length) +
                    329
                ]
                  ? props.prevod[
                      props.oz_j.indexOf(localStorage.getItem('lng')) *
                        (props.prevod.length / props.oz_j.length) +
                        329
                    ].item2
                  : 'Upute za korištenje Web kalkulatora'
              }
              style={{ width: '80vw', height: '80vh' }}
            >
              <div>
                <p>
                  {props.prevod &&
                  props.prevod.length !== 0 &&
                  props.prevod[
                    props.oz_j.indexOf(localStorage.getItem('lng')) *
                      (props.prevod.length / props.oz_j.length) +
                      330
                  ]
                    ? props.prevod[
                        props.oz_j.indexOf(localStorage.getItem('lng')) *
                          (props.prevod.length / props.oz_j.length) +
                          330
                      ].item2
                    : 'Web kalkulator je aplikacija koja Korisniku omogućava brz i jednostavan način izračuna cijene usluge površinske obrade - anodizacija aluminijuma.'}
                </p>
                <p>
                  {props.prevod &&
                  props.prevod.length !== 0 &&
                  props.prevod[
                    props.oz_j.indexOf(localStorage.getItem('lng')) *
                      (props.prevod.length / props.oz_j.length) +
                      331
                  ]
                    ? props.prevod[
                        props.oz_j.indexOf(localStorage.getItem('lng')) *
                          (props.prevod.length / props.oz_j.length) +
                          331
                      ].item2
                    : '- Da bi se kreirala ponuda korisnik treba da ima definisan zahtjev površinske obrade eloksiranje, gdje je na crtežu navedeno sljedeće: postupak eloksiranja, debljina oksidnog sloja, boja.'}
                </p>
                <b>
                  <h3>
                    {props.prevod &&
                    props.prevod.length !== 0 &&
                    props.prevod[
                      props.oz_j.indexOf(localStorage.getItem('lng')) *
                        (props.prevod.length / props.oz_j.length) +
                        332
                    ]
                      ? props.prevod[
                          props.oz_j.indexOf(localStorage.getItem('lng')) *
                            (props.prevod.length / props.oz_j.length) +
                            332
                        ].item2
                      : 'Lista vaših upita'}
                  </h3>
                </b>
                <p>
                  {props.prevod &&
                  props.prevod.length !== 0 &&
                  props.prevod[
                    props.oz_j.indexOf(localStorage.getItem('lng')) *
                      (props.prevod.length / props.oz_j.length) +
                      333
                  ]
                    ? props.prevod[
                        props.oz_j.indexOf(localStorage.getItem('lng')) *
                          (props.prevod.length / props.oz_j.length) +
                          333
                      ].item2
                    : 'Za pregled evidentiranih upita i naručenih artikala Korisniku su prikazane dvije tabele.   Prva tabela prikazuje artikle za koje su evidentirani upiti. Druga tabela prikazuje artikle za koje je poslana narudžba iz Web kalkulatora.   Klikom na polje „Novi upit“ otvara se prozor za evidenciju upita jednog artikla. Upute za unos podataka u upitu otvore se klikom na „?“.   Pretraživanje evidentiranog artikla vrši se u polju „Pretraga“ iznad tabele, unosom broja ili naziva artikla ili broja crteža.  Klikom na polje „uredi upit“ iz prve tabele vrši se pregled evidentiranog upita. Isti nije moguće dorađivati, dozvoljeno je samo kalkulisati cijenu/komadu i za istu poslati narudžbu klikom na polje „Naruči“.   Naručeni artikl prelazi u drugu tabelu u kojoj je prikazan ID artikla, šifra recepta, broj artikla, naziv artikla, količina, cijena i datum narudžbe.  '}
                </p>
                <p>
                  {props.prevod &&
                  props.prevod.length !== 0 &&
                  props.prevod[
                    props.oz_j.indexOf(localStorage.getItem('lng')) *
                      (props.prevod.length / props.oz_j.length) +
                      334
                  ]
                    ? props.prevod[
                        props.oz_j.indexOf(localStorage.getItem('lng')) *
                          (props.prevod.length / props.oz_j.length) +
                          334
                      ].item2
                    : 'Potvrda narudžbe sa terminom isporuke Korisnik dobiva na mail u roku 1-2 dana.'}
                </p>
              </div>
            </Popup>
          </div>
          <div id="k_p_h">
            <div id="korisnik_panel_header">
              <div id="k_p_logo">
                <img src={GatLogo} alt="gat_logo" width="60px" />
              </div>
              <div id="number">
                {' '}
                <p>
                  {props.prevod &&
                  props.prevod.length !== 0 &&
                  props.prevod[
                    props.oz_j.indexOf(localStorage.getItem('lng')) *
                      (props.prevod.length / props.oz_j.length) +
                      42
                  ]
                    ? props.prevod[
                        props.oz_j.indexOf(localStorage.getItem('lng')) *
                          (props.prevod.length / props.oz_j.length) +
                          42
                      ].item2
                    : 'Naziv firme:'}{' '}
                  {user.companyName} {'   '}
                  {props.prevod &&
                  props.prevod.length !== 0 &&
                  props.prevod[
                    props.oz_j.indexOf(localStorage.getItem('lng')) *
                      (props.prevod.length / props.oz_j.length) +
                      248
                  ]
                    ? props.prevod[
                        props.oz_j.indexOf(localStorage.getItem('lng')) *
                          (props.prevod.length / props.oz_j.length) +
                          248
                      ].item2
                    : 'Korisnik:'}
                  {'  '}
                  {user.firstName + '   ' + user.lastName}
                </p>{' '}
                <p style={{ marginTop: '12px' }}>+387 37 689 113</p>
              </div>
              <OdjavaButtonWhite />
            </div>
          </div>
          <ListaUpita user={user} />
        </div>
      ) : (
        <Login />
      )}
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    prevod: state.prevod,
    jezik: state.jezik,
    oz_j: state.oz_j,
  }
}

export default connect(mapStateToProps, null)(KorisnikPanelPage)
