import { getDefaultNormalizer } from '@testing-library/react'
import axios from 'axios'
import { Button } from 'devextreme-react'
import React from 'react'
import letterPng from '../../Images/letterPng.jpg'
export default function OdobrenjeRegistracije({ id }) {
  async function validiraj() {
    const formData = new FormData()
    formData.append('korisnik', id)
    //console.log("window")
    try {
      await axios
        .request({
          method: 'post',
          url: `https://api.gat-crm.com:1121/mail/potvrda`,
          data: formData,

          headers: { 'Content-Type': 'application/json' },
        })

        .then((resp) => {
          // console.log(resp)
          alert('Potvrdili ste datu E-mail adresu!')
        })
        .catch((err) => console.log(err))
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <div style={{ textAlign: 'center' }} className="rgst_vldt">
      <div
        style={{
          backgroundColor: '#c9e7f2',
          position: 'absolute',
          zIndex: '-1000',
          top: '0',
          left: '0',
          width: '100vw',
          height: '32vh',
        }}
      >
        <img src={letterPng} />
      </div>
      <div
        className="rgst_vldt"
        style={{
          backgroundColor: 'white',
          //marginTop: "22vh",
          width: '100vw',
          position: 'absolute',
          top: '25vh',
          left: '0',
          borderRadius: ' 22% 22% 0 0',
        }}
      >
        <br /> <br />
        <h1>Potvrdite Vašu e-mail adresu</h1> <br /> <br />
        <Button text="Potvrdi" type="default" onClick={validiraj} />
        <br /> <br /> <br /> <br />
        <p>
          Klikom na dugme potvrđujete Vašu e-mail adresu za korisnički račun WEB
          Kalkulator aplikacije te se slažete sa svim uslovima aplikacije.
        </p>
      </div>
    </div>
  )
}
