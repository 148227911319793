import { REQUEST_OZNAKE_JEZIKA } from "../../const";

export default function OJReducer(state = [], action) {
  switch (action.type) {
    case REQUEST_OZNAKE_JEZIKA:
      return action.oz_j;
    default:
      return state;
  }
}
