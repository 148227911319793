import { Button } from 'devextreme-react/button'
import axios from 'axios'
import { DataGrid, SelectBox, TextBox } from 'devextreme-react'
import { Column } from 'devextreme-react/data-grid'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import '../../../../Styles/adminListeStyle.css'
import { OZNAKE_JEZIKA, PREVOD } from '../../../../const'

const formatDate = new Intl.DateTimeFormat('ru').format

function ListaUpitiINarudzbe(props) {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [emailOnClick, setEmailOnClick] = useState('')
  const [clicked, setClicked] = useState(false)
  const [listeUpita, setListeUpita] = useState([])
  const [listeNarudzbi, setListeNarudzbi] = useState([])
  const [email_lista, setEmailLista] = useState([])

  useEffect(() => {
    axios
      .request({
        method: 'get',
        url: 'https://api.gat-crm.com:1121/email_lista',
      })
      .then((resp) => setEmailLista(resp.data))
  }, [])

  const onClick = () => {
    setEmailOnClick(email)
    setClicked(true)
  }
  useEffect(() => {
    if (email != '')
      axios
        .all([
          axios.get(`https://api.gat-crm.com:1121/getUpiti/${emailOnClick}`),
          axios.get(`https://api.gat-crm.com:1121/getNarudzbe/${emailOnClick}`),
        ])
        .then((res) => {
          setListeUpita(res[0].data)
          setListeNarudzbi(res[1].data)
          /*  axios
            .get(`https://api.gat-crm.com:1121/getNarudzbe/${email}`)
            .then((res) => {
              console.log(res);
              setListeNarudzbi(res.data);
            });*/
        })
        .catch((err) => {
          console.log(err)
          setListeUpita([])
          setClicked(false)
        })
  }, [emailOnClick])
  const prevod = props.prevod
  const oz_j = props.oz_j
  return (
    <div>
      <br /> <br />
      <div
        style={{
          width: '50vw',
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        <label style={{ marginTop: '10px' }}>
          {
            // 226
            props.prevod &&
            props.prevod.length !== 0 &&
            props.prevod[
              props.oz_j.indexOf(localStorage.getItem('lng')) *
                (props.prevod.length / props.oz_j.length) +
                224
            ]
              ? props.prevod[
                  props.oz_j.indexOf(localStorage.getItem('lng')) *
                    (props.prevod.length / props.oz_j.length) +
                    224
                ].item2
              : 'Email korisnika:'
          }{' '}
        </label>
        <SelectBox
          dataSource={email_lista}
          searchEnabled={true}
          searchMode={'contains'}
          acceptCustomValue={true}
          onValueChanged={(e) => {
            setEmail(e.value)
            setClicked(false)
          }}
        />

        <Button
          id="draw_btn"
          type="default"
          onClick={onClick}
          useSubmitBehavior={true}
          text={
            props.prevod &&
            props.prevod.length !== 0 &&
            props.prevod[
              props.oz_j.indexOf(localStorage.getItem('lng')) *
                (props.prevod.length / props.oz_j.length) +
                261
            ]
              ? props.prevod[
                  props.oz_j.indexOf(localStorage.getItem('lng')) *
                    (props.prevod.length / props.oz_j.length) +
                    261
                ].item2
              : 'Pronađi'
          }
        ></Button>
      </div>
      {clicked && listeUpita ? (
        <div id="liste">
          <br />
          <h1>
            {props.prevod &&
            props.prevod.length !== 0 &&
            props.prevod[
              props.oz_j.indexOf(localStorage.getItem('lng')) *
                (props.prevod.length / props.oz_j.length) +
                259
            ]
              ? props.prevod[
                  props.oz_j.indexOf(localStorage.getItem('lng')) *
                    (props.prevod.length / props.oz_j.length) +
                    259
                ].item2
              : 'Narudžbe'}
          </h1>
          <br />
          <DataGrid
            id="gridContainer"
            className="admin_l_u_datagrid"
            dataSource={listeNarudzbi}
            showBorders={true}
            noDataText={
              prevod &&
              prevod.length !== 0 &&
              prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  260
              ]
                ? prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      260
                  ].item2
                : 'Nema narudžbi'
            }
            rowRender={DataRowNarudzbe}
          >
            <Column dataField="siid" caption="ID" width="120" />
            <Column
              dataField="sifra"
              caption={
                prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    157
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        157
                    ].item2
                  : 'Šifra recepta'
              }
              width={180}
              alignment="center"
            />
            <Column
              dataField="ba"
              caption={
                props.prevod &&
                props.prevod.length !== 0 &&
                props.prevod[
                  props.oz_j.indexOf(localStorage.getItem('lng')) *
                    (props.prevod.length / props.oz_j.length) +
                    50
                ]
                  ? props.prevod[
                      props.oz_j.indexOf(localStorage.getItem('lng')) *
                        (props.prevod.length / props.oz_j.length) +
                        50
                    ].item2
                  : 'Broj artikla'
              }
              //width="180"
            />
            <Column
              dataField="na"
              caption={
                props.prevod &&
                props.prevod.length !== 0 &&
                props.prevod[
                  props.oz_j.indexOf(localStorage.getItem('lng')) *
                    (props.prevod.length / props.oz_j.length) +
                    48
                ]
                  ? props.prevod[
                      props.oz_j.indexOf(localStorage.getItem('lng')) *
                        (props.prevod.length / props.oz_j.length) +
                        48
                    ].item2
                  : 'Naziv artikla'
              }
            />
            <Column
              dataField="bk"
              caption={
                props.prevod &&
                props.prevod.length !== 0 &&
                props.prevod[
                  props.oz_j.indexOf(localStorage.getItem('lng')) *
                    (props.prevod.length / props.oz_j.length) +
                    57
                ]
                  ? props.prevod[
                      props.oz_j.indexOf(localStorage.getItem('lng')) *
                        (props.prevod.length / props.oz_j.length) +
                        57
                    ].item2
                  : 'Broj komada'
              }
              width="100"
              alignment="center"
            />
            <Column
              dataField="cijena"
              caption={
                props.prevod &&
                props.prevod.length !== 0 &&
                props.prevod[
                  props.oz_j.indexOf(localStorage.getItem('lng')) *
                    (props.prevod.length / props.oz_j.length) +
                    97
                ]
                  ? props.prevod[
                      props.oz_j.indexOf(localStorage.getItem('lng')) *
                        (props.prevod.length / props.oz_j.length) +
                        97
                    ].item2
                  : 'Cijena'
              }
            />
            <Column
              dataField="datum"
              caption={
                props.prevod &&
                props.prevod.length !== 0 &&
                props.prevod[
                  props.oz_j.indexOf(localStorage.getItem('lng')) *
                    (props.prevod.length / props.oz_j.length) +
                    95
                ]
                  ? props.prevod[
                      props.oz_j.indexOf(localStorage.getItem('lng')) *
                        (props.prevod.length / props.oz_j.length) +
                        95
                    ].item2
                  : 'Datum'
              }
              dataType="date"
              alignment="center"
              width="150"
            />
          </DataGrid>
          <br />
          <br />
          <hr />
          <br />
          <h1>
            {props.prevod &&
            props.prevod.length !== 0 &&
            props.prevod[
              props.oz_j.indexOf(localStorage.getItem('lng')) *
                (props.prevod.length / props.oz_j.length) +
                225
            ]
              ? props.prevod[
                  props.oz_j.indexOf(localStorage.getItem('lng')) *
                    (props.prevod.length / props.oz_j.length) +
                    225
                ].item2
              : 'Upiti'}{' '}
          </h1>
          <br />
          <DataGrid
            className="admin_l_u_datagrid"
            dataSource={listeUpita}
            noDataText="Nema upita"
            rowRender={DataRowUpiti}
          >
            {' '}
            <Column dataField="siid" caption="ID" width="120" />{' '}
            <Column
              dataField="ba"
              alignment="center"
              width="180"
              caption={
                props.prevod &&
                props.prevod.length !== 0 &&
                props.prevod[
                  props.oz_j.indexOf(localStorage.getItem('lng')) *
                    (props.prevod.length / props.oz_j.length) +
                    50
                ]
                  ? props.prevod[
                      props.oz_j.indexOf(localStorage.getItem('lng')) *
                        (props.prevod.length / props.oz_j.length) +
                        50
                    ].item2
                  : 'Broj artikla'
              }
              allowEditing={false}
            />
            <Column
              dataField="na"
              className="na"
              alignment="center"
              caption={
                props.prevod &&
                props.prevod.length !== 0 &&
                props.prevod[
                  props.oz_j.indexOf(localStorage.getItem('lng')) *
                    (props.prevod.length / props.oz_j.length) +
                    48
                ]
                  ? props.prevod[
                      props.oz_j.indexOf(localStorage.getItem('lng')) *
                        (props.prevod.length / props.oz_j.length) +
                        48
                    ].item2
                  : 'Naziv artikla'
              }
              allowEditing={false}
            />
            <Column
              dataField="bk"
              alignment="center"
              caption={
                props.prevod &&
                props.prevod.length !== 0 &&
                props.prevod[
                  props.oz_j.indexOf(localStorage.getItem('lng')) *
                    (props.prevod.length / props.oz_j.length) +
                    57
                ]
                  ? props.prevod[
                      props.oz_j.indexOf(localStorage.getItem('lng')) *
                        (props.prevod.length / props.oz_j.length) +
                        57
                    ].item2
                  : 'Broj komada'
              }
              width="100"
              allowEditing={false}
            />
            <Column
              dataField="datum"
              alignment="center"
              caption={
                props.prevod &&
                props.prevod.length !== 0 &&
                props.prevod[
                  props.oz_j.indexOf(localStorage.getItem('lng')) *
                    (props.prevod.length / props.oz_j.length) +
                    95
                ]
                  ? props.prevod[
                      props.oz_j.indexOf(localStorage.getItem('lng')) *
                        (props.prevod.length / props.oz_j.length) +
                        95
                    ].item2
                  : 'Datum'
              }
              dataType="date"
              width="150"
            />
          </DataGrid>
        </div>
      ) : (
        ''
      )}
    </div>
  )
  function DataRowNarudzbe(rowInfo) {
    return (
      <tbody
        className={`employee dx-row ${
          rowInfo.rowIndex % 2 ? 'dx-row-alt' : ''
        }`}
      >
        <tr className="main-row">
          <td
            style={{
              borderRight: '1px solid #ddd',
            }}
          >
            {rowInfo.data.siid}
          </td>{' '}
          <td style={{ textAlign: 'center', borderRight: '1px solid #ddd' }}>
            {rowInfo.data.sifra}
          </td>
          <td
            style={{
              borderRight: '1px solid #ddd',
            }}
          >
            {rowInfo.data.ba}
          </td>
          <td
            style={{
              borderRight: '1px solid #ddd',
            }}
          >
            {rowInfo.data.na}
          </td>
          <td style={{ textAlign: 'center', borderRight: '1px solid #ddd' }}>
            {rowInfo.data.bk}
          </td>
          <td style={{ textAlign: 'end', borderRight: '1px solid #ddd' }}>
            {rowInfo.data.cijena}
          </td>{' '}
          <td style={{ textAlign: 'center', borderRight: '1px solid #ddd' }}>
            {formatDate(new Date(rowInfo.data.datum))}{' '}
          </td>
        </tr>
      </tbody>
    )
  }
  function DataRowUpiti(rowInfo) {
    return (
      <tbody
        className={`employee dx-row ${
          rowInfo.rowIndex % 2 ? 'dx-row-alt' : ''
        }`}
      >
        <tr className="main-row">
          <td
            style={{
              borderRight: '1px solid #ddd',
            }}
          >
            {rowInfo.data.siid}
          </td>{' '}
          <td
            style={{
              borderRight: '1px solid #ddd',
            }}
          >
            {rowInfo.data.ba}
          </td>
          <td
            style={{
              borderRight: '1px solid #ddd',
            }}
          >
            {rowInfo.data.na}
          </td>
          <td style={{ textAlign: 'center', borderRight: '1px solid #ddd' }}>
            {rowInfo.data.bk}
          </td>
          <td style={{ textAlign: 'center', borderRight: '1px solid #ddd' }}>
            {formatDate(new Date(rowInfo.data.datum))}{' '}
          </td>
        </tr>
      </tbody>
    )
  }
}

export default ListaUpitiINarudzbe
