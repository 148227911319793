export const REQUEST_UPITI = 'REQUEST_UPITI'
export const REQUEST_UPITI_SUCCESS = 'REQUEST_UPITI_SUCCESS'
export const REQUEST_UPITI_ERROR = 'REQUEST_UPITI_ERROR'

export const REQUEST_USERS = 'REQUEST_USERS'
export const REQUEST_USERS_SUCCESS = 'REQUEST_USERS_SUCCESS'
export const REQUEST_USERS_ERROR = 'REQUEST_USERS_ERROR'

export const REQUEST_NOT_ALLOWED_USERS = 'REQUESt_NOT_ALLOWED_USERS_SUCCESS'

export const REQUEST_M = 'REQUEST_M'

export const REQUEST_NPZ = 'REQUEST_NPZ'

export const REQUEST_HPPE_A = 'REQUEST_HPPO_A'
export const REQUEST_HPPE_B = 'REQUEST_HPPO_B'
export const REQUEST_HPPE_C = 'REQUEST_HPPO_C'

export const REQUEST_E6_ME = 'REQUEST_E6_ME'
export const REQUEST_E6_DHP = 'REQUEST_E6_DHP'

export const REQUEST_DOS_A = 'REQUEST_DOS_A'
export const REQUEST_DOS_B = 'REQUEST_DOS_B'

export const REQUEST_2017_DOS_A = 'REQUEST_2017_DOS_A'
export const REQUEST_2017_DOS_B = 'REQUEST_2017_DOS_B'

export const REQUEST_ITDOS_A = 'REQUEST_ITDOS_A'
export const REQUEST_ITDOS_B = 'REQUEST_ITDOS_B'

export const REQUEST_B_A = 'REQUEST_B_A'
export const REQUEST_B_B = 'REQUEST_B_B'

export const REQUEST_S_C = 'REQUEST_S_C'

export const REQUEST_NKUZS = 'REQUEST_NKUZS'
export const REQUEST_NKUSES = 'REQUEST_NKUSES'
export const REQUEST_NSIS = 'REQUEST_NSIS'

export const REQUEST_NK = 'REQUEST_NK'

export const REQUEST_POVRSINE = 'REQUEST_POVRSINE'
export const REQUEST_PREVOD = 'REQUEST_PREVOD'
export const REQUEST_PREVOD_ADMIN = 'REQUEST_PREVOD_ADMIN'
export const REQUEST_JEZIK = 'REQUEST_JEZIK'
export const REQUEST_OZNAKE_JEZIKA = 'REQUEST_OZNAKE_JEZIKA'

export const REQUEST_BK = 'REQUEST_BK'

export const REQUEST_LISTA_UPITA = 'REQUEST_LISTA_UPITA'
export const REQUEST_LISTA_NARUDZBI = 'REQUEST_LISTA_NARUDZBI'

export const API = 'https://api.gat-crm.com:1121' // 'https://api.gat-crm.com:1121' //  'https://api.gat-crm.com:1121' //

export const USERS = API + '/users'
export const NOTALLOWED = API + '/notallowed'
export const UPITI = API + '/upiti/' //fix id - localstorage(nmx)

export const M = API + '/materijali'

export const HPPE_A = API + '/hppe/a'
export const HPPE_B = API + '/hppe/b'
export const HPPE_C = API + '/hppe/c'

export const E6_ME = API + '/e6/me'
export const E6_DHP = API + '/e6/dhp'

export const NPZ = API + '/npz'

export const DOS_A = API + '/dos/a'
export const DOS_B = API + '/dos/b'

export const DOS_2017_A = API + '/dos2017/a'
export const DOS_2017_B = API + '/dos2017/b'

export const ITDOS_A = API + '/itdos/a'
export const ITDOS_B = API + '/itdos/b'

export const B_A = API + '/boja/a'
export const B_B = API + '/boja/b'

export const S_C = API + '/surtec/c'

export const NKUZS = API + '/num_of_kuzs'
export const NKUSES = API + '/num_of_kuses'
export const NSIS = API + '/num_of_sis'

export const NK = API + '/nk'

export const POVRSINE = API + '/povrsine/'

export const PREVOD = API + '/gotovi_prevodi'

export const PREVOD_ADMIN = API + '/prevodi'

export const JEZIK = API + '/jezici'

export const OZNAKE_JEZIKA = API + '/oz_g_j'

export const BK = API + '/kuses/bk/'

export const LISTA_UPITA = API + '/getUpiti/'
export const LISTA_NARUDZBI = API + '/getNarudzbe/'

export const KM_LISTA = API + '/km_lista'

export const KM = API + '/km/'
