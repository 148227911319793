import React, { useState, useEffect } from 'react'
import FadeIn from 'react-fade-in'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

const NPOpis = (props) => {
  const [hppo, setHppo] = useState('x')

  useEffect(() => {
    setInterval(() => {
      if (localStorage.getItem('hppo')) setHppo(localStorage.getItem('hppo'))
    }, 100)
  })
  return (
    <FadeIn>
      <div
        className="opis np_opis"
        style={{
          marginTop: '-193px',
          backgoundColor: 'white',
        }}
      >
        <ul
          className="list-opis"
          style={{
            fontSize: '13px',
          }}
        >
          {hppo == 'E0' || hppo == 'x' ? (
            <li style={{ listStyleType: 'none' }}>
              {props.prevod &&
              props.prevod.length !== 0 &&
              props.prevod[
                props.oz_j.indexOf(localStorage.getItem('lng')) *
                  (props.prevod.length / props.oz_j.length) +
                  218
              ]
                ? props.prevod[
                    props.oz_j.indexOf(localStorage.getItem('lng')) *
                      (props.prevod.length / props.oz_j.length) +
                      218
                  ].item2
                : 'E0: hemijska predobrada odmašćivanje, postiže se sjajna površina, gdje svi tragovi mašinske obrade ostaju vidljivi, kao i ogrebotine i drugi površinski nedostaci u koliko su prisutni.'}{' '}
            </li>
          ) : (
            ''
          )}
          {hppo == 'E6' || hppo == 'x' ? (
            <li>
              {' '}
              {props.prevod &&
              props.prevod.length !== 0 &&
              props.prevod[
                props.oz_j.indexOf(localStorage.getItem('lng')) *
                  (props.prevod.length / props.oz_j.length) +
                  220
              ]
                ? props.prevod[
                    props.oz_j.indexOf(localStorage.getItem('lng')) *
                      (props.prevod.length / props.oz_j.length) +
                      220
                  ].item2
                : 'E6: hemijska predobrada luženje, postiže se mat površina. Tragovi mašinske obrade, ogrebotine na površini komada su djelimično uklonjenje. Razlikujemo:'}{' '}
              <ul style={{ listStyleType: 'lower-alpha' }}>
                <li>
                  {' '}
                  {props.prevod &&
                  props.prevod.length !== 0 &&
                  props.prevod[
                    props.oz_j.indexOf(localStorage.getItem('lng')) *
                      (props.prevod.length / props.oz_j.length) +
                      236
                  ]
                    ? props.prevod[
                        props.oz_j.indexOf(localStorage.getItem('lng')) *
                          (props.prevod.length / props.oz_j.length) +
                          236
                      ].item2
                    : 'Luženje na mjeru: Komadi imaju usko tolerisane kote, gdje je zahtjev da se usko tolerisane kote zadrže u granicama tolerancije. Luženje je veoma kratko, komadi zadržavaju blago sjajan izgled.'}{' '}
                </li>
                <li>
                  {' '}
                  {props.prevod &&
                  props.prevod.length !== 0 &&
                  props.prevod[
                    props.oz_j.indexOf(localStorage.getItem('lng')) *
                      (props.prevod.length / props.oz_j.length) +
                      231
                  ]
                    ? props.prevod[
                        props.oz_j.indexOf(localStorage.getItem('lng')) *
                          (props.prevod.length / props.oz_j.length) +
                          231
                      ].item2
                    : 'Kratko luženje: Površina komada je blago mat izgleda sa vidnim tragovima mašinske obrade.'}{' '}
                </li>{' '}
                <li>
                  {' '}
                  {props.prevod &&
                  props.prevod.length !== 0 &&
                  props.prevod[
                    props.oz_j.indexOf(localStorage.getItem('lng')) *
                      (props.prevod.length / props.oz_j.length) +
                      232
                  ]
                    ? props.prevod[
                        props.oz_j.indexOf(localStorage.getItem('lng')) *
                          (props.prevod.length / props.oz_j.length) +
                          232
                      ].item2
                    : 'Dugo luženje: Za komade na kojima je potrebno površinskom obradom postići ravnomjernu, ujednačenu mat površinu. Najčešća primjena je na komadima koji imaju djelimično ili u potpunosti CNC mašinski neobrađene vidne površine, kao npr. profili, limovi.'}{' '}
                </li>
              </ul>
            </li>
          ) : (
            ''
          )}
          {hppo == 'E7' || hppo == 'x' ? (
            <li>
              {' '}
              {props.prevod &&
              props.prevod.length !== 0 &&
              props.prevod[
                props.oz_j.indexOf(localStorage.getItem('lng')) *
                  (props.prevod.length / props.oz_j.length) +
                  221
              ]
                ? props.prevod[
                    props.oz_j.indexOf(localStorage.getItem('lng')) *
                      (props.prevod.length / props.oz_j.length) +
                      221
                  ].item2
                : 'E7: hemijska predobrada poliranje, postiže sjajnu dekorativnu površinu. Pogodno za postizanje dodatnog sjaja na sačmarenim površinama. Materijali koji se hemijski poliraju spadaju u grupu aluminijum legure 6000x i 5000x serije, to su: EN AW 6082, EN AW 6060, EN AW 5754. Nije pogodno za legure serije 2000x.'}
            </li>
          ) : (
            ''
          )}
        </ul>
      </div>
    </FadeIn>
  )
}

const mapStateToProps = (state) => {
  return {
    jezik: state.jezik,
    prevod: state.prevod,
    oz_j: state.oz_j,
  }
}

export default connect(mapStateToProps, null)(NPOpis)
