import axios from "axios";
import { OZNAKE_JEZIKA, REQUEST_OZNAKE_JEZIKA } from "../../const";

export const fetchOJData = (oz_j) => {
  return {
    type: REQUEST_OZNAKE_JEZIKA,
    oz_j,
  };
};

export const fetchOzJezikData = () => {
  return (dispatch) => {
    return axios
      .get(OZNAKE_JEZIKA)
      .then((response) => {
        dispatch(fetchOJData(response.data));
      })
      .catch((error) => {
        //// // // // console.log("err")
        throw error;
      });
  };
};
