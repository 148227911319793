import { combineReducers } from "redux";
import users from "./UserReducer";
import upiti from "./UpitReducer";
import notallowed from "./NotAllowedUsersReducer";
import m_list from "./MReducer";
import hppe_a from "./HPPE/HPPEAReducer";
import hppe_b from "./HPPE/HPPEBReducer";
import hppe_c from "./HPPE/HPPECReducer";
import npz from "./NpzReducer";
import dos_a from "./DOS/DOSAReducer";
import dos_b from "./DOS/DOSBReducer";
import itdos_a from "./ITDOS/ITDOSAReducer";
import itdos_b from "./ITDOS/ITDOSBReducer";
import boja_a from "./B/BAReducer";
import boja_b from "./B/BBReducer";
import s_c from "./SCReducer";
import num_of_kuzs from "./NUM/NKUZSReducer";
import num_of_kuses from "./NUM/NKUSESReducer";
import num_of_sis from "./NUM/NSISReducer";
import nk from "./NKReducer";
import povrsine from "./PovrsinaReducer";
import prevod from "./PrevodReducer";
import prevod_a from "./PrevodAdminReducer";
import jezik from "./JezikReducer";
import oz_j from "./OJReducer";
import e6_me from "./E6Min/E6MEReducer";
import e6_dhp from "./E6Min/E6DHPReducer";
import dos_2017_a from "./DOS/DOS2017AReducer";
import dos_2017_b from "./DOS/DOS2017BReducer";
import bk from "./BKReducer";
import lista_upita from "./UiNReducer";
import lista_narudzbi from "./UiNReducer";
export default combineReducers({
  upiti: upiti,
  users: users,
  notallowed: notallowed,
  m_list: m_list,
  hppe_a: hppe_a,
  hppe_b: hppe_b,
  hppe_c: hppe_c,
  npz: npz,
  dos_a: dos_a,
  dos_b: dos_b,
  itdos_a: itdos_a,
  itdos_b: itdos_b,
  boja_a: boja_a,
  boja_b: boja_b,
  s_c: s_c,
  num_of_kuzs: num_of_kuzs,
  num_of_kuses: num_of_kuses,
  num_of_sis: num_of_sis,
  nk: nk,
  povrsine: povrsine,
  prevod: prevod,
  prevod_a: prevod_a,
  jezik: jezik,
  oz_j: oz_j,
  e6_me: e6_me,
  e6_dhp: e6_dhp,
  dos_2017_a: dos_2017_a,
  dos_2017_b: dos_2017_b,
  bk: bk,
  lista_upita: lista_upita,
  lista_narudzbi: lista_narudzbi,
});
