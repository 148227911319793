import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DataGrid, {
  Column,
  Paging,
  SearchPanel,
} from 'devextreme-react/data-grid'
import { Button } from 'devextreme-react'
import { fetchUpitiData } from '../../../store/actions/UpitAction'
import '../../../Styles/korisnikStyle.css'
import k_p_img1 from '../../../Images/k_p_img1.JPG'
import k_p_img2 from '../../../Images/k_p_img2.JPG'
import k_p_img3 from '../../../Images/k_p_img3.JPG'
import axios from 'axios'
import { useState } from 'react'
import { useEffect } from 'react'
import { OZNAKE_JEZIKA, PREVOD } from '../../../const'
import UrediUpit from '../Items/UrediUpit'

var CryptoJS = require('crypto-js')
const formatDate = new Intl.DateTimeFormat('ru').format

class ListaUpita extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lng: 'ba',
      user: {},
      listeNarudzbi: [],
    }

    this.mounted = false
    this.makeTimer = setInterval(() => {
      if (this.mounted)
        this.setState({
          lng: localStorage.getItem('lng')
            ? localStorage.getItem('lng')
            : this.state.lng,
        })
    })
  }
  componentDidMount = async () => {
    this.mounted = false
    //  // // console.log(this.props.prevod);
    this.makeTimer = setInterval(() => {
      if (this.mounted)
        this.setState({
          lng: localStorage.getItem('lng')
            ? localStorage.getItem('lng')
            : this.state.lng,
        })
    })
    if (localStorage.getItem('nmx')) {
      try {
        axios
          .get(
            'https://api.gat-crm.com:1121/user/' +
              JSON.parse(
                CryptoJS.AES.decrypt(
                  localStorage.getItem('nmx'),
                  'Adna1234'
                ).toString(CryptoJS.enc.Utf8)
              )
          )
          .then((resp) => {
            axios
              .get(
                `https://api.gat-crm.com:1121/getNarudzbe/${resp.data.email}`
              )
              .then((resp2) => this.setState({ listeNarudzbi: resp2.data }))
            this.setState({ user: resp.data })
          })
      } catch (err) {
        console.log(err)
      }
    }

    /*'id usera',
      JSON.parse(
        CryptoJS.AES.decrypt(localStorage.getItem('nmx'), 'Adna1234').toString(
          CryptoJS.enc.Utf8
        )
      )
    */
  }

  componentWillMount() {
    this.mounted = true
  }
  componentWillUnmount() {
    this.mounted = false
    clearInterval(this.makeTimer)
  }
  render() {
    const { prevod, oz_j } = this.props
    if (!this.props.upiti) {
      return (
        <div>
          <h1>
            {prevod &&
            prevod.length !== 0 &&
            prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                46
            ]
              ? prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    46
                ].item2
              : 'Nema podataka'}
          </h1>
        </div>
      )
    }
    if (localStorage.getItem('nmx')) {
      return (
        <div id="korisnik_panel_lu">
          <div id="lista_upita">
            <div>
              <h1 id="l_u_header">
                {prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    151
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        151
                    ].item2
                  : 'LISTA VAŠIH UPITA'}
              </h1>
              <br />
              <div id="lista_upita_btn">
                <Button
                  alignment="left"
                  text={
                    prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        152
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            152
                        ].item2
                      : 'Novi upit'
                  }
                  onClick={() =>
                    (window.location.href = window.location.origin + '/#/upit')
                  }
                  type="default"
                  stylingMode="contained"
                />
              </div>
              {window.screen.width > 801 ? (
                <DataGrid
                  id="gridContainer"
                  dataSource={this.props.upiti}
                  keyExpr="id"
                  rowRender={DataRow1}
                  allowColumnResizing={true}
                  columnResizingMode={'widget'}
                  columnMinWidth={50}
                  columnAutoWidth={true}
                  noDataText={
                    prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        99
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            99
                        ].item2
                      : 'Trenutno ne postoji nijedan kreiran upit'
                  }
                >
                  <SearchPanel
                    visible={true}
                    id="l_u_search_panel"
                    placeholder={
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          41
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              41
                          ].item2
                        : 'Pretraži...'
                    }
                  />
                  <Column
                    dataField="datum"
                    alignment="center"
                    width="90px"
                    caption={
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          95
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              95
                          ].item2
                        : 'Datum'
                    }
                    dataType="date"
                  />
                  <Column
                    dataField="ba"
                    width="85px"
                    alignment="center"
                    caption={
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          50
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              50
                          ].item2
                        : 'Broj artikla'
                    }
                    allowEditing={false}
                  />
                  <Column
                    id="kolona_na"
                    dataField="na"
                    className="na"
                    alignment="center"
                    caption={
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          48
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              48
                          ].item2
                        : 'Naziv artikla'
                    }
                    allowEditing={false}
                  />
                  <Column
                    dataField="bc"
                    id="kolona_bc"
                    alignment="center"
                    width="100px"
                    caption={
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          51
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              51
                          ].item2
                        : 'Broj crteža'
                    }
                    allowEditing={false}
                  />
                  <Column
                    dataField="cijena"
                    width="85px"
                    caption={
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          97
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              97
                          ].item2
                        : 'Cijena'
                    }
                    alignment="center"
                    allowEditing={false}
                  />
                  <Column
                    dataField="id"
                    width="100px"
                    caption=" "
                    alignment="center"
                    cellRender={cellRender}
                  />
                  <Paging defaultPageSize={15} />
                </DataGrid>
              ) : window.screen.width > 698 ? (
                <DataGrid
                  id="gridContainer"
                  dataSource={this.props.upiti}
                  keyExpr="id"
                  rowRender={DataRow2}
                  allowColumnResizing={true}
                  columnResizingMode={'widget'}
                  columnMinWidth={50}
                  columnAutoWidth={true}
                  noDataText={
                    prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        99
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            99
                        ].item2
                      : 'Trenutno ne postoji nijedan kreiran upit'
                  }
                >
                  <SearchPanel
                    visible={true}
                    id="l_u_search_panel"
                    placeholder={
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          41
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              41
                          ].item2
                        : 'Pretraži...'
                    }
                  />
                  <Column
                    dataField="datum"
                    alignment="center"
                    width="85px"
                    caption={
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          95
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              95
                          ].item2
                        : 'Datum'
                    }
                    dataType="date"
                  />
                  <Column
                    dataField="ba"
                    width="80px"
                    alignment="center"
                    caption={
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          50
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              50
                          ].item2
                        : 'Broj artikla'
                    }
                    allowEditing={false}
                  />

                  <Column
                    dataField="bc"
                    id="kolona_bc"
                    alignment="center"
                    width="95px"
                    caption={
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          51
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              51
                          ].item2
                        : 'Broj crteža'
                    }
                    allowEditing={false}
                  />
                  <Column
                    dataField="cijena"
                    width="95px"
                    caption={
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          97
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              97
                          ].item2
                        : 'Cijena'
                    }
                    alignment="center"
                    allowEditing={false}
                  />
                  <Column
                    dataField="id"
                    width="80px"
                    caption=" "
                    alignment="center"
                    cellRender={cellRender}
                  />
                  <Paging defaultPageSize={15} />
                </DataGrid>
              ) : (
                <DataGrid
                  id="gridContainer"
                  dataSource={this.props.upiti}
                  keyExpr="id"
                  rowRender={DataRow3}
                  allowColumnResizing={true}
                  columnResizingMode={'widget'}
                  columnMinWidth={50}
                  columnAutoWidth={true}
                  noDataText={
                    prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        99
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            99
                        ].item2
                      : 'Trenutno ne postoji nijedan kreiran upit'
                  }
                >
                  <SearchPanel
                    visible={true}
                    id="l_u_search_panel"
                    placeholder={
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          41
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              41
                          ].item2
                        : 'Pretraži...'
                    }
                  />
                  <Column
                    dataField="datum"
                    alignment="center"
                    width="70px"
                    caption={
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          95
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              95
                          ].item2
                        : 'Datum'
                    }
                    dataType="date"
                  />
                  <Column
                    dataField="ba"
                    alignment="center"
                    caption={
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          50
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              50
                          ].item2
                        : 'Broj artikla'
                    }
                    allowEditing={false}
                  />

                  <Column
                    dataField="cijena"
                    width="80px"
                    caption={
                      prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          97
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              97
                          ].item2
                        : 'Cijena'
                    }
                    alignment="center"
                    allowEditing={false}
                  />
                  <Column
                    dataField="id"
                    width="85px"
                    caption=" "
                    alignment="center"
                    cellRender={cellRender}
                  />
                  <Paging defaultPageSize={15} />
                </DataGrid>
              )}
            </div>
          </div>
          {/* <br /> <br />
          <div id="korisnik_panel_images">
            <div>
              <img
                src={k_p_img1}
                alt="korisnik_panel 1"
                height="350px"
                width="400px"
              />
            </div>

            <div>
              <img
                src={k_p_img2}
                alt="korisnik_panel 2"
                height="350px"
                width="400px"
              />
            </div>

            <div>
              <img
                src={k_p_img3}
                alt="korisnik_panel 3"
                height="350px"
                width="400px"
              />
            </div>
          </div>
          <br />*/}
          <hr />
          <h1 style={{ marginLeft: '22vw' }}>
            {prevod &&
            prevod.length !== 0 &&
            prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                259
            ]
              ? prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    259
                ].item2
              : 'Narudžbe'}
          </h1>
          <div id="lista_upita" style={{ paddingTop: '0' }}>
            {' '}
            <DataGrid
              id="gridContainer"
              className="admin_l_u_datagrid"
              dataSource={this.state.listeNarudzbi}
              showBorders={true}
              rowRender={DataRowNarudzbe}
              noDataText="Nema narudžbi"
            >
              <Column dataField="siid" caption="ID" width="120" />
              <Column
                width={180}
                dataField="sifra"
                caption={
                  prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      157
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          157
                      ].item2
                    : 'Šifra recepta'
                }
                alignment="center"
              />
              <Column
                dataField="ba"
                caption={
                  prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      50
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          50
                      ].item2
                    : 'Broj artikla'
                }
              />
              <Column
                dataField="na"
                caption={
                  prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      48
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          48
                      ].item2
                    : 'Naziv artikla'
                }
                width={170}
              />
              <Column
                dataField="bk"
                caption={
                  prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      57
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          57
                      ].item2
                    : 'Broj komada'
                }
                alignment="center"
              />
              <Column
                dataField="cijena"
                caption={
                  prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      97
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          97
                      ].item2
                    : 'Cijena'
                }
              />
              <Column
                dataField="datum"
                caption={
                  prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      95
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          95
                      ].item2
                    : 'Date'
                }
                dataType="date"
                alignment="center"
                width="150"
              />
            </DataGrid>
          </div>
        </div>
      )
    } else
      return (
        <div>
          {prevod &&
          prevod.length !== 0 &&
          prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              46
          ]
            ? prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  46
              ].item2
            : 'Nema podataka'}
        </div>
      )
  }
}

function cellRender(data) {
  return (
    <Link
      to={{
        pathname: '/upit',
        state: { upit: data.data },
      }}
    >
      <UrediUpit />
    </Link>
  )
}

function DataRow1(rowInfo) {
  return (
    <tbody
      className={`employee dx-row ${rowInfo.rowIndex % 2 ? 'dx-row-alt' : ''}`}
    >
      <tr className="main-row">
        <td
          style={{ textAlign: 'center', borderRight: '1px solid #ddd' }}
          id="tabela_da"
        >
          {formatDate(new Date(rowInfo.data.datum))}{' '}
        </td>
        <td
          style={{
            borderRight: '1px solid #ddd',
          }}
        >
          {rowInfo.data.ba}
        </td>
        <td
          id="tabela_na"
          style={{
            borderRight: '1px solid #ddd',
            width: '30vw',
          }}
        >
          {rowInfo.data.na}
        </td>
        <td style={{ borderRight: '1px solid #ddd' }} id="tabela_bc">
          {rowInfo.data.bc}
        </td>
        <td style={{ textAlign: 'end', borderRight: '1px solid #ddd' }}>
          {rowInfo.data.cijena == '0'
            ? '0'
            : new Intl.NumberFormat('de-DE', {
                minimumFractionDigits: 4,
              }).format(rowInfo.data.cijena)}
        </td>
        <td id="uredi_upit_td">
          <Link
            to={{
              pathname: '/upit',
              state: { upit: rowInfo.data },
            }}
          >
            <UrediUpit />
          </Link>
        </td>
      </tr>
    </tbody>
  )
}

function DataRow2(rowInfo) {
  return (
    <tbody
      className={`employee dx-row ${rowInfo.rowIndex % 2 ? 'dx-row-alt' : ''}`}
    >
      <tr className="main-row">
        <td
          style={{ textAlign: 'center', borderRight: '1px solid #ddd' }}
          id="tabela_da"
        >
          {formatDate(new Date(rowInfo.data.datum))}{' '}
        </td>
        <td
          style={{
            borderRight: '1px solid #ddd',
          }}
        >
          {rowInfo.data.ba}
        </td>

        <td style={{ borderRight: '1px solid #ddd' }} id="tabela_bc">
          {rowInfo.data.bc}
        </td>
        <td style={{ textAlign: 'end', borderRight: '1px solid #ddd' }}>
          {rowInfo.data.cijena == '0'
            ? '0'
            : new Intl.NumberFormat('de-DE', {
                minimumFractionDigits: 4,
              }).format(rowInfo.data.cijena)}
        </td>
        <td id="uredi_upit_td">
          <Link
            to={{
              pathname: '/upit',
              state: { upit: rowInfo.data },
            }}
          >
            <UrediUpit />
          </Link>
        </td>
      </tr>
    </tbody>
  )
}

function DataRow3(rowInfo) {
  return (
    <tbody
      className={`employee dx-row ${rowInfo.rowIndex % 2 ? 'dx-row-alt' : ''}`}
    >
      <tr className="main-row">
        <td
          style={{ textAlign: 'center', borderRight: '1px solid #ddd' }}
          id="tabela_da"
        >
          {formatDate(new Date(rowInfo.data.datum))}{' '}
        </td>
        <td
          style={{
            borderRight: '1px solid #ddd',
          }}
        >
          {rowInfo.data.ba}
        </td>

        <td style={{ textAlign: 'end', borderRight: '1px solid #ddd' }}>
          {rowInfo.data.cijena == '0'
            ? '0'
            : new Intl.NumberFormat('de-DE', {
                minimumFractionDigits: 4,
              }).format(rowInfo.data.cijena)}
        </td>
        <td id="uredi_upit_td">
          <Link
            to={{
              pathname: '/upit',
              state: { upit: rowInfo.data },
            }}
          >
            {' '}
            <UrediUpit />
          </Link>
        </td>
      </tr>
    </tbody>
  )
}

function DataRowNarudzbe(rowInfo) {
  return (
    <tbody
      className={`employee dx-row ${rowInfo.rowIndex % 2 ? 'dx-row-alt' : ''}`}
    >
      <tr className="main-row">
        <td
          style={{
            borderRight: '1px solid #ddd',
          }}
        >
          {rowInfo.data.siid}
        </td>{' '}
        <td style={{ textAlign: 'center', borderRight: '1px solid #ddd' }}>
          {rowInfo.data.sifra}
        </td>
        <td
          style={{
            borderRight: '1px solid #ddd',
          }}
        >
          {rowInfo.data.ba}
        </td>
        <td
          style={{
            borderRight: '1px solid #ddd',
          }}
        >
          {rowInfo.data.na}
        </td>
        <td style={{ textAlign: 'center', borderRight: '1px solid #ddd' }}>
          {rowInfo.data.bk}
        </td>
        <td style={{ textAlign: 'end', borderRight: '1px solid #ddd' }}>
          {rowInfo.data.cijena}
        </td>{' '}
        <td style={{ textAlign: 'center', borderRight: '1px solid #ddd' }}>
          {formatDate(new Date(rowInfo.data.datum))}{' '}
        </td>
      </tr>
    </tbody>
  )
}

const mapStateToProps = (state) => {
  return {
    upiti: state.upiti,
    prevod: state.prevod,
    jezik: state.jezik,
    oz_j: state.oz_j,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchUpitiData: dispatch(fetchUpitiData(localStorage.getItem('nmx'))),
})

export default connect(mapStateToProps, mapDispatchToProps)(ListaUpita)
