import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { OZNAKE_JEZIKA, PREVOD } from '../../../const'

export default function UrediUpit() {
  const [prevod, setPrevod] = useState([])
  const [oz_j, setOzJ] = useState([])
  useEffect(() => {
    axios.get(PREVOD).then((resp) => setPrevod(resp.data))
    axios.get(OZNAKE_JEZIKA).then((resp) => setOzJ(resp.data))
  }, [])

  return (
    <>
      {prevod &&
      prevod.length !== 0 &&
      prevod[
        oz_j.indexOf(localStorage.getItem('lng')) *
          (prevod.length / oz_j.length) +
          98
      ]
        ? prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              98
          ].item2
        : 'Uredi upit'}
    </>
  )
}
