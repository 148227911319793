import React, { useEffect, useState } from 'react'
import TextBox from 'devextreme-react/text-box'
import axios from 'axios'
import { OZNAKE_JEZIKA, PREVOD } from '../../../const'

function ColorFieldF({ data }) {
  const [prevod, setPrevod] = useState([])
  const [oz_j, setOzJ] = useState([])

  useEffect(() => {
    axios.get(PREVOD).then((resp) => setPrevod(resp.data))
    axios.get(OZNAKE_JEZIKA).then((resp) => setOzJ(resp.data))
  }, [])
  return data && data.item1 == 'SI' ? (
    <div className="custom-item select-with-color">
      <div className="custom-icon-field" id={data && data.item1}></div>
      <TextBox
        value={
          prevod &&
          prevod.length !== 0 &&
          prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              100
          ]
            ? prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  100
              ].item2
            : 'SI (natur)'
        }
      />
    </div>
  ) : data && data.item1 == 'ZL' ? (
    <div className="custom-item select-with-color">
      <div className="custom-icon-field" id={data && data.item1}></div>
      <TextBox
        value={
          prevod &&
          prevod.length !== 0 &&
          prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              101
          ]
            ? prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  101
              ].item2
            : 'ZL (zlatna)'
        }
      />
    </div>
  ) : data && data.item1 == 'TI' ? (
    <div className="custom-item select-with-color">
      <div className="custom-icon-field" id={data && data.item1}></div>
      <TextBox
        value={
          prevod &&
          prevod.length !== 0 &&
          prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              102
          ]
            ? prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  102
              ].item2
            : 'TI (titan)'
        }
      />
    </div>
  ) : data && data.item1 == 'CV' ? (
    <div className="custom-item select-with-color">
      <div className="custom-icon-field" id={data && data.item1}></div>
      <TextBox
        value={
          prevod &&
          prevod.length !== 0 &&
          prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              103
          ]
            ? prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  103
              ].item2
            : 'CV (crvena)'
        }
      />
    </div>
  ) : data && data.item1 == 'PL' ? (
    <div className="custom-item select-with-color">
      <div className="custom-icon-field" id={data && data.item1}></div>
      <TextBox
        value={
          prevod &&
          prevod.length !== 0 &&
          prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              104
          ]
            ? prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  104
              ].item2
            : 'PL (plava)'
        }
      />
    </div>
  ) : data && data.item1 == 'ZE' ? (
    <div className="custom-item select-with-color">
      <div className="custom-icon-field" id={data && data.item1}></div>
      <TextBox
        value={
          prevod &&
          prevod.length !== 0 &&
          prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              105
          ]
            ? prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  105
              ].item2
            : 'ZE (zelena)'
        }
      />
    </div>
  ) : data && data.item1 == 'NA' ? (
    <div className="custom-item select-with-color">
      <div className="custom-icon-field" id={data && data.item1}></div>
      <TextBox
        value={
          prevod &&
          prevod.length !== 0 &&
          prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              106
          ]
            ? prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  106
              ].item2
            : 'NA (naradžasta)'
        }
      />
    </div>
  ) : (
    <div className="custom-item select-with-color">
      <div className="custom-icon-field" id={data && data.item1}></div>
      <TextBox
        value={
          prevod &&
          prevod.length !== 0 &&
          prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              107
          ]
            ? prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  107
              ].item2
            : 'CR (crna)'
        }
      />
    </div>
  )
}

const ColorField = (data) => <ColorFieldF data={data} />

export default ColorField
