import axios from 'axios'
import { Button, SelectBox, TextBox } from 'devextreme-react'
import React, { useEffect, useState } from 'react'
import { API, KM, KM_LISTA, OZNAKE_JEZIKA, PREVOD } from '../../const'

export default function Komitent(props) {
  const [prevod, setPrevod] = useState([])
  const [oz_j, setOzJ] = useState([])

  const [nazivKM, setNazivKM] = useState('')
  const [km, setKM] = useState('')
  const [km_lista, setKMLista] = useState([])
  const postKM = async () => {
    axios
      .request({
        method: 'get',
        url: API + '/update_km/' + props.km + '/' + km,
      })
      .then((resp) => alert('Postavljen komitent!'))
  }
  useEffect(() => {
    axios.get(PREVOD).then((resp) => setPrevod(resp.data))
    axios.get(OZNAKE_JEZIKA).then((resp) => setOzJ(resp.data))
    axios.get(KM_LISTA).then((resp) => setKMLista(resp.data))
    axios.get(KM + props.km).then((resp) => setNazivKM(resp.data))
  }, [])
  return (
    <div style={{ margin: '10vh' }}>
      <div style={{ width: '50%' }}>
        <label>Naziv firme</label>
        <TextBox value={nazivKM}></TextBox>
      </div>
      <br />
      <div style={{ width: '50%' }}>
        <label>Lista komitenata</label>
        <SelectBox
          items={km_lista}
          onValueChanged={(e) => setKM(e.value)}
          searchEnabled={true}
          displayExpr="item2"
          valueExpr="item1"
          placeholder={
            prevod &&
            prevod.length !== 0 &&
            prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                41
            ]
              ? prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    41
                ].item2
              : 'Pretraži...'
          }
          noDataText={
            prevod &&
            prevod.length !== 0 &&
            prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                46
            ]
              ? prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    46
                ].item2
              : 'Nema podataka'
          }
        ></SelectBox>
      </div>
      <br />
      <div style={{ width: '50%' }}>
        <Button
          id="new_lng_btn"
          style={{ padding: '5px' }}
          text={'OK'}
          type="default"
          onClick={() => postKM()}
        >
          OK{' '}
        </Button>
      </div>
    </div>
  )
}
