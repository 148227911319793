import React, { Component } from 'react'
import DataGrid, { Column, Editing } from 'devextreme-react/data-grid'
import { locale, loadMessages } from 'devextreme/localization'
import '../../../Styles/modalStyle.css'
import { Button } from 'devextreme-react/button'
import kvadar from '../../../Images/kvadar.jpg'
import suplji_kvadar from '../../../Images/supljikvadar.jpg'
import valjak from '../../../Images/valjak.jpg'
import suplji_valjak from '../../../Images/supljivaljak.jpg'

import kvadar_d from '../../../Images/dark-mode-povrsine/kvadar.jpg'
import suplji_kvadar_d from '../../../Images/dark-mode-povrsine/supljikvadar.jpg'
import valjak_d from '../../../Images/dark-mode-povrsine/valjak.jpg'
import suplji_valjak_d from '../../../Images/dark-mode-povrsine/supljivaljak.jpg'

//import kupa from '../../../Images/kupa.png'
//import elipsoid from '../../../Images/elipsoid.png'
import { NumberBox } from 'devextreme-react'
import erase from '../../../Images/erase.png'
import add from '../../../Images/add.png'
import '../../../Styles/modalStyle.css'
import { connect } from 'react-redux'
import axios from 'axios'
import { postPovrsina, putPovrsina } from './postPovrsinaKck9pt'
import Loading from '../Loading/Loading'
import service from './dictionary'
import ModalImage from './ModalImage'
const Pi = Math.PI

// poslati siid kao prop iz upit komponente
class Modal extends Component {
  constructor(props) {
    super(props)

    this.geom_tijela = [
      ' ',
      'Puni kvadar',
      'Šuplji kvadar',
      'Puni valjak',
      'Šuplji valjak',
      'Kupa',
      'Elipsoid',
    ]
    // sva geometrijska tijela sa svojim povrsinama dodana na ukupnu povrsinu predmeta

    this.state = {
      g_tijelo: this.geom_tijela[0],
      g_t_show: '',
      p_k_a: 0,
      p_k_b: 0,
      p_k_c: 0,
      s_k_a: 0,
      s_k_b: 0,
      s_k_c: 0,
      s_k_d: 0,
      p_v_b: 0,
      p_v_a: 0,
      s_v_b: 0,
      s_v_a: 0,
      s_v_c: 0,
      k_r: 0,
      k_v: 0,
      e_a: 0,
      e_b: 0,
      e_c: 0,
      povrsina: 1, // ukupna povrsina
      povrsine: [],
      visibleButtons: false,
      visibleAdd: false,
      validate: false,
      geom_tijelo: { s: '', n: '' },
      selectedItemKeys: [],
      isLoading: false,
      editGT: {},
      br_PK: 0,
      br_SK: 0,
      br_PV: 0,
      br_SV: 0,
      br_K: 0,
      br_E: 0,
      edit: false, // ako je uključen edit kolone, timer zabraniti da ucitava listu
    }
    this.makeTimer()

    // povrsine geometrijskih tijela

    this.p_p_k = parseFloat(
      (2 *
        (this.state.p_k_a * this.state.p_k_b +
          this.state.p_k_a * this.state.p_k_c +
          this.state.p_k_b * this.state.p_k_c)) /
        10000
    )
    this.p_s_k = parseFloat(
      (4 *
        this.state.s_k_d *
        (2 * this.state.s_k_b +
          this.state.s_k_c +
          this.state.s_k_a -
          2 * this.state.s_k_d)) /
        10000
    )
    this.p_p_v = parseFloat(
      (2 * Pi * this.state.s_v_b * (this.state.s_v_b + this.state.s_v_a)) /
        10000
    )
    this.p_s_v = parseFloat(
      (2 *
        Pi *
        (2 * this.state.s_v_a * this.state.s_v_b -
          this.state.s_v_a * this.state.s_v_c +
          2 * this.state.s_v_b * this.state.s_v_c -
          this.state.s_v_c * this.state.s_v_c)) /
        10000
    )
    this.p_k = parseFloat(
      (Pi *
        this.state.k_r *
        (this.state.k_r +
          Math.abs(
            Math.sqrt(Math.pow(this.state.k_r, 2) + Math.pow(this.state.k_v, 2))
          ))) /
        10000
    )
    this.p_e = parseFloat(
      (4 *
        Pi *
        Math.pow(
          (Math.pow(this.state.e_a * this.state.e_b, 1.6) +
            Math.pow(this.state.e_a * this.state.e_c, 1.6) +
            Math.pow(this.state.e_b * this.state.e_c, 1.6)) /
            3,
          0.625
        )) /
        10000
    )
    // za floating buttons - za svako posebno geometrijsko tijelo
    this.add = this.add.bind(this)
    this.erase = this.erase.bind(this)
    this.edit = this.edit.bind(this)
    this.onEditingStart = this.onEditingStart.bind(this)
    this.onEditCanceled = this.onEditCanceled.bind(this)
    this.onSaved = this.onSaved.bind(this)

    // this.onRowUpdated = this.onRowUpdated.bind(this);
    this.onRowUpdating = this.onRowUpdating.bind(this)
    this.onRowRemoving = this.onRowRemoving.bind(this)
    this.onGTValueChanged = this.onGTValueChanged.bind(this)
    // puni kvadar

    this.on_p_k_a_value_changed = this.on_p_k_a_value_changed.bind(this)
    this.on_p_k_b_value_changed = this.on_p_k_b_value_changed.bind(this)
    this.on_p_k_c_value_changed = this.on_p_k_c_value_changed.bind(this)
    // suplji kvadar
    this.on_s_k_a_value_changed = this.on_s_k_a_value_changed.bind(this)
    this.on_s_k_b_value_changed = this.on_s_k_b_value_changed.bind(this)
    this.on_s_k_c_value_changed = this.on_s_k_c_value_changed.bind(this)
    this.on_s_k_d_value_changed = this.on_s_k_d_value_changed.bind(this)
    // puni valjak
    this.on_p_v_b_value_changed = this.on_p_v_b_value_changed.bind(this)
    this.on_p_v_a_value_changed = this.on_p_v_a_value_changed.bind(this)
    // suplji valjak
    this.on_s_v_b_value_changed = this.on_s_v_b_value_changed.bind(this)
    this.on_s_v_a_value_changed = this.on_s_v_a_value_changed.bind(this)
    this.on_s_v_c_value_changed = this.on_s_v_c_value_changed.bind(this)
    // kupa
    this.on_k_r_value_changed = this.on_k_r_value_changed.bind(this)
    this.on_k_v_value_changed = this.on_k_v_value_changed.bind(this)

    // elipsoid
    this.on_e_a_value_changed = this.on_e_a_value_changed.bind(this)
    this.on_e_b_value_changed = this.on_e_b_value_changed.bind(this)
    this.on_e_c_value_changed = this.on_e_c_value_changed.bind(this)

    // lokalizacija
    this.initMessages()
  }

  // puni kvadar

  on_p_k_a_value_changed(e) {
    this.setState({
      p_k_a: Math.abs(e.value),
    })
    this.p_p_k = parseFloat(
      (2 *
        (this.state.p_k_a * this.state.p_k_b +
          this.state.p_k_a * this.state.p_k_c +
          this.state.p_k_b * this.state.p_k_c)) /
        10000
    )
    if (this.state.p_k_a * this.state.p_k_b * this.state.p_k_c > 0)
      this.setState({ validate: true })
    else this.setState({ validate: false })
    // // // // // console.log("Povrsina", this.p_p_k);
  }
  on_p_k_b_value_changed(e) {
    this.setState({
      p_k_b: Math.abs(e.value),
    })
    this.p_p_k = parseFloat(
      (2 *
        (this.state.p_k_a * this.state.p_k_b +
          this.state.p_k_a * this.state.p_k_c +
          this.state.p_k_b * this.state.p_k_c)) /
        10000
    )
    if (this.state.p_k_a * this.state.p_k_b * this.state.p_k_c > 0)
      this.setState({ validate: true })
    else this.setState({ validate: false })
    // // // // console.log("Povrsina", this.p_p_k);
  }
  on_p_k_c_value_changed(e) {
    this.setState({
      p_k_c: Math.abs(e.value),
    })
    this.p_p_k = parseFloat(
      (2 *
        (this.state.p_k_a * this.state.p_k_b +
          this.state.p_k_a * this.state.p_k_c +
          this.state.p_k_b * this.state.p_k_c)) /
        10000
    )
    if (this.state.p_k_a * this.state.p_k_b * this.state.p_k_c > 0)
      this.setState({ validate: true })
    else this.setState({ validate: false })
    // // // // console.log("Povrsina", this.p_p_k);
  }

  onPKClicked = (e) => {
    // // // // console.log("Kvadar");
  }

  //suplji kvadar
  on_s_k_a_value_changed(e) {
    this.setState({
      s_k_a: Math.abs(e.value),
    })
    this.p_s_k = parseFloat(
      (4 *
        this.state.s_k_d *
        (2 * this.state.s_k_b +
          this.state.s_k_c +
          this.state.s_k_a -
          2 * this.state.s_k_d)) /
        10000
    )
    if (
      this.state.s_k_a *
        this.state.s_k_b *
        this.state.s_k_c *
        this.state.s_k_d >
        0 &&
      this.state.s_k_a >= 2 * this.state.s_k_d &&
      this.state.s_k_c >= 2 * this.state.s_k_d
    )
      this.setState({ validate: true })
    else this.setState({ validate: false })
    // // // // console.log("Povrsina", this.p_s_k);
  }

  on_s_k_b_value_changed(e) {
    this.setState({
      s_k_b: Math.abs(e.value),
    })
    this.p_s_k = parseFloat(
      (4 *
        this.state.s_k_d *
        (2 * this.state.s_k_b +
          this.state.s_k_c +
          this.state.s_k_a -
          2 * this.state.s_k_d)) /
        10000
    )
    if (
      this.state.s_k_a *
        this.state.s_k_b *
        this.state.s_k_c *
        this.state.s_k_d >
        0 &&
      this.state.s_k_a >= 2 * this.state.s_k_d &&
      this.state.s_k_c >= 2 * this.state.s_k_d
    )
      this.setState({ validate: true })
    else this.setState({ validate: false })
    // // // // console.log("Povrsina", this.p_s_k);
  }

  on_s_k_c_value_changed(e) {
    this.setState({
      s_k_c: Math.abs(e.value),
    })
    this.p_s_k = parseFloat(
      (4 *
        this.state.s_k_d *
        (2 * this.state.s_k_b +
          this.state.s_k_c +
          this.state.s_k_a -
          2 * this.state.s_k_d)) /
        10000
    )
    if (
      this.state.s_k_a *
        this.state.s_k_b *
        this.state.s_k_c *
        this.state.s_k_d >
        0 &&
      this.state.s_k_a >= 2 * this.state.s_k_d &&
      this.state.s_k_c >= 2 * this.state.s_k_d
    )
      this.setState({ validate: true })
    else this.setState({ validate: false })
    // // // // console.log("Povrsina", this.p_s_k);
  }

  on_s_k_d_value_changed(e) {
    this.setState({
      s_k_d: Math.abs(e.value),
    })
    this.p_s_k = parseFloat(
      (4 *
        this.state.s_k_d *
        (2 * this.state.s_k_b +
          this.state.s_k_c +
          this.state.s_k_a -
          2 * this.state.s_k_d)) /
        10000
    )
    if (
      this.state.s_k_a *
        this.state.s_k_b *
        this.state.s_k_c *
        this.state.s_k_d >
        0 &&
      this.state.s_k_a >= 2 * this.state.s_k_d &&
      this.state.s_k_c >= 2 * this.state.s_k_d
    )
      this.setState({ validate: true })
    else this.setState({ validate: false })
    // // // // console.log("Povrsina", this.p_s_k);
  }

  //puni valjak
  on_p_v_b_value_changed(e) {
    this.setState({
      p_v_b: Math.abs(e.value),
    })
    this.p_p_v = parseFloat(
      (2 * Pi * this.state.p_v_b * (this.state.p_v_b + this.state.p_v_a)) /
        10000
    )
    if (this.state.p_v_b * this.state.p_v_a > 0)
      this.setState({ validate: true })
    else this.setState({ validate: false })
    // // // // console.log("valjak", this.p_p_v);
  }

  on_p_v_a_value_changed(e) {
    this.setState({
      p_v_a: Math.abs(e.value),
    })
    this.p_p_v = parseFloat(
      (2 * Pi * this.state.p_v_b * (this.state.p_v_b + this.state.p_v_a)) /
        10000
    )
    if (this.state.p_v_b * this.state.p_v_a > 0)
      this.setState({ validate: true })
    else this.setState({ validate: false })
    // // // // console.log("valjak", this.p_p_v);
  }

  //suplji valjak
  on_s_v_b_value_changed(e) {
    this.setState({
      s_v_b: Math.abs(e.value),
    })
    this.p_s_v = parseFloat(
      (2 *
        Pi *
        (2 * this.state.s_v_a * this.state.s_v_b -
          this.state.s_v_a * this.state.s_v_c +
          2 * this.state.s_v_b * this.state.s_v_c -
          this.state.s_v_c * this.state.s_v_c)) /
        10000
    )
    if (
      this.state.s_v_b * this.state.s_v_a * this.state.s_v_c > 0 &&
      this.state.s_v_b > this.state.s_v_c
    )
      this.setState({ validate: true })
    else this.setState({ validate: false })
    // // // // console.log("Povrsina", this.p_s_v);
  }

  on_s_v_a_value_changed(e) {
    this.setState({
      s_v_a: Math.abs(e.value),
    })
    this.p_s_v = parseFloat(
      (2 *
        Pi *
        (2 * this.state.s_v_a * this.state.s_v_b -
          this.state.s_v_a * this.state.s_v_c +
          2 * this.state.s_v_b * this.state.s_v_c -
          this.state.s_v_c * this.state.s_v_c)) /
        10000
    )
    if (
      this.state.s_v_b * this.state.s_v_a * this.state.s_v_c > 0 &&
      this.state.s_v_b > this.state.s_v_c
    )
      this.setState({ validate: true })
    else this.setState({ validate: false })
    // // // // console.log("Povrsina", this.p_s_v);
  }

  on_s_v_c_value_changed(e) {
    this.setState({
      s_v_c: Math.abs(e.value),
    })
    this.p_s_v = parseFloat(
      (2 *
        Pi *
        (2 * this.state.s_v_a * this.state.s_v_b -
          this.state.s_v_a * this.state.s_v_c +
          2 * this.state.s_v_b * this.state.s_v_c -
          this.state.s_v_c * this.state.s_v_c)) /
        10000
    )
    if (
      this.state.s_v_b * this.state.s_v_a * this.state.s_v_c > 0 &&
      this.state.s_v_b > this.state.s_v_c
    )
      this.setState({ validate: true })
    else this.setState({ validate: false })
    // // // // console.log("Povrsina", this.p_s_v);
  }

  // kupa

  on_k_r_value_changed(e) {
    this.setState({
      k_r: Math.abs(e.value),
    })
    this.p_k = parseFloat(
      (Pi *
        this.state.k_r *
        (this.state.k_r +
          Math.abs(
            Math.sqrt(Math.pow(this.state.k_r, 2) + Math.pow(this.state.k_v, 2))
          ))) /
        10000
    )
    if (this.state.k_r * this.state.k_v > 0) this.setState({ validate: true })
    else this.setState({ validate: false })
    // // // // console.log("Povrsina", this.p_k);
  }

  on_k_v_value_changed(e) {
    this.setState({
      k_v: Math.abs(e.value),
    })
    this.p_k = parseFloat(
      (Pi *
        this.state.k_r *
        (this.state.k_r +
          Math.abs(
            Math.sqrt(Math.pow(this.state.k_r, 2) + Math.pow(this.state.k_v, 2))
          ))) /
        10000
    )
    if (this.state.k_r * this.state.k_v > 0) this.setState({ validate: true })
    else this.setState({ validate: false })
    // // // // console.log("Povrsina", this.p_k);
  }

  //elipsoid

  on_e_a_value_changed(e) {
    this.setState({
      e_a: Math.abs(e.value),
    })
    this.p_e = parseFloat(
      (4 *
        Pi *
        Math.pow(
          (Math.pow(this.state.e_a * this.state.e_b, 1.6) +
            Math.pow(this.state.e_a * this.state.e_c, 1.6) +
            Math.pow(this.state.e_b * this.state.e_c, 1.6)) /
            3,
          0.625
        )) /
        10000
    )
    if (this.state.e_a * this.state.e_b * this.state.e_c > 0)
      this.setState({ validate: true })
    else this.setState({ validate: false })
    // // // // console.log("Povrsina", this.p_e);
  }

  on_e_b_value_changed(e) {
    this.setState({
      e_b: Math.abs(e.value),
    })
    this.p_e = parseFloat(
      (4 *
        Pi *
        Math.pow(
          (Math.pow(this.state.e_a * this.state.e_b, 1.6) +
            Math.pow(this.state.e_a * this.state.e_c, 1.6) +
            Math.pow(this.state.e_b * this.state.e_c, 1.6)) /
            3,
          0.625
        )) /
        10000
    )
    if (this.state.e_a * this.state.e_b * this.state.e_c > 0)
      this.setState({ validate: true })
    else this.setState({ validate: false })
    // // // // console.log("Povrsina", this.p_e);
  }

  on_e_c_value_changed(e) {
    this.setState({
      e_c: Math.abs(e.value),
    })
    this.p_e = parseFloat(
      (4 *
        Pi *
        Math.pow(
          (Math.pow(this.state.e_a * this.state.e_b, 1.6) +
            Math.pow(this.state.e_a * this.state.e_c, 1.6) +
            Math.pow(this.state.e_b * this.state.e_c, 1.6)) /
            3,
          0.625
        )) /
        10000
    )
    if (this.state.e_a * this.state.e_b * this.state.e_c > 0)
      this.setState({ validate: true })
    else this.setState({ validate: false })
    // // // // console.log("Povrsina", this.p_e);
  }
  findGTP(e) {
    let pomNiz = [
      'Puni kvadar',
      'Šuplji kvadar',
      'Puni valjak',
      'Šuplji valjak',
      'Kupa',
      'Elipsoid',
    ]
    return pomNiz.indexOf(e)
  }
  // geometrijsko tijelo
  onGTValueChanged(e) {
    // // console.log(e);
    if (e.value !== ' ' && e.value !== undefined) {
      this.setState({
        g_t: e.value,
      })
      const pozicija = this.findGTP(e.value)
      const povrsine = [
        this.p_p_k,
        this.p_s_k,
        this.p_p_v,
        this.p_s_v,
        this.p_k,
        this.p_e,
      ]
      const simboli = ['PK', 'ŠK', 'PV', 'ŠV', 'K', 'E']
      this.dodajPovrsinu(povrsine[pozicija], simboli[pozicija], this.state.g_t)
    }
    if (e.value === ' ') {
      //// // // // console.log("RU", e);
      this.setState({ visibleButtons: false })
    }
    //// // // // console.log("E", e);
  }
  findGT(e) {
    // vrati niz koji predstavlja redom vrijednosti a,b,c,d,v,r
    if (e === 'PK')
      return [this.state.p_k_a, this.state.p_k_b, this.state.p_k_c, 0, 0, 0]
    else if (e === 'ŠK')
      return [
        this.state.s_k_a,
        this.state.s_k_b,
        this.state.s_k_c,
        this.state.s_k_d,
        0,
        0,
      ]
    else if (e === 'PV') return [0, 0, 0, 0, this.state.p_v_b, this.state.p_v_a]
    else if (e === 'ŠV')
      return [0, 0, 0, this.state.s_v_c, this.state.s_v_b, this.state.s_v_a]
    else if (e === 'K') return [0, 0, 0, 0, this.state.k_r, this.state.k_v]
    else return [this.state.e_a, this.state.e_b, this.state.e_c, 0, 0, 0]
  }

  setBrGT() {
    let pomocniNiz = ['PK', 'ŠK', 'PV', 'ŠV', 'K', 'E']
    let pomocniNiz2 = [0, 0, 0, 0, 0, 0]
    if (this.props.povrsine) {
      this.props.povrsine.map((v, i) => {
        pomocniNiz2[pomocniNiz.indexOf(v.k9kcptks)] += 1
      })
      this.setState({
        br_PK: pomocniNiz2[0],
        br_SK: pomocniNiz2[1],
        br_PV: pomocniNiz2[2],
        br_SV: pomocniNiz2[3],
        br_K: pomocniNiz2[4],
        br_E: pomocniNiz2[5],
      })
      // // // // console.log("A", pomocniNiz2);
    }
  }
  add = async (e) => {
    let pomocniNiz = this.findGT(this.state.geom_tijelo.s)
    let povrsina = this.findPovrsina(this.state.geom_tijelo.s)

    this.setBrGT()
    try {
      postPovrsina(
        this.props.props.id,
        pomocniNiz[0],
        pomocniNiz[1],
        pomocniNiz[2],
        pomocniNiz[3],
        pomocniNiz[4],
        pomocniNiz[5],
        povrsina,
        this.state.geom_tijelo.s,
        this.state.geom_tijelo.n
      )
      //  window.location.reload(false);
    } catch (err) {
      // // // // console.log(err);
    }

    this.setState({ visibleAdd: !this.state.visibleAdd })

    // // // // console.log("POMOCNI NIZ", pomocniNiz);
  }
  findInputToSet0(e) {
    if (e === 'PK') {
      this.setState({ p_k_a: 0, p_k_b: 0, p_k_c: 0, validate: false })
      this.p_p_k = 0
    } else if (e === 'ŠK') {
      this.setState({
        s_k_a: 0,
        s_k_b: 0,
        s_k_c: 0,
        s_k_d: 0,
        validate: false,
      })
      this.p_s_k = 0
    } else if (e === 'PV') {
      this.setState({ p_v_b: 0, p_v_a: 0, validate: false })
      this.p_p_v = 0
    } else if (e === 'ŠV') {
      this.setState({ s_v_b: 0, s_v_a: 0, s_v_c: 0, validate: false })
      this.p_s_v = 0
    } else if (e === 'K') {
      this.setState({ k_r: 0, k_v: 0, validate: false })
      this.p_k = 0
    } else {
      this.setState({ e_a: 0, e_b: 0, e_c: 0, validate: false })
      this.p_e = 0
    }
  } // kod edita već unesenog geom. tijela u tabelu
  setGT(e) {
    let s = e.k9kcptks
    if (s === 'PK') {
      this.setState({
        p_k_a: e.k9kcpta,
        p_k_b: e.k9kcptb,
        p_k_c: e.k9kcptc,
      })
      this.p_p_k = e.k9kcptp
    } else if (s === 'ŠK') {
      this.setState({
        s_k_a: e.k9kcpta,
        s_k_b: e.k9kcptb,
        s_k_c: e.k9kcptc,
        s_k_d: e.k9kcptd,
      })
      this.p_s_k = e.k9kcptp
    } else if (s === 'PV') {
      this.setState({
        p_v_b: e.k9kcptr,
        p_v_a: e.k9kcptv,
      })
      this.p_p_v = e.k9kcptp
    } else if (s === 'ŠV') {
      this.setState({
        s_v_b: e.k9kcptr,
        s_v_a: e.k9kcptv,
        s_v_c: e.k9kcptd,
      })
      this.p_s_v = e.k9kcptp
    } else if (s === 'K') {
      this.setState({
        k_r: e.k9kcptr,
        k_v: e.k9kcpt,
      })
      this.p_k = e.k9kcptp
    } else if (s === 'E') {
      this.setState({
        e_a: e.k9kcpta,
        e_b: e.k9kcptb,
        e_c: e.k9kcptc,
      })
      this.p_e = e.k9kcptp
    }
    this.setState({
      g_t: e.k9kcptksn,
      geom_tijelo: { s: e.k9kcptks, n: e.k9kcptksn },
      validate: false,
      visibleButtons: false,
      editGT: e,
      edit: true,
    })
  }
  onEditingStart = async (e) => {
    this.setGT(e.key)
    // // // // console.log(e.data);

    // // // // console.log("Editing");
  }
  onEditCanceled = (e) => {
    this.setState({
      g_t: ' ',
      geom_tijelo: { s: '', n: '' },
      p_k_a: 0,
      p_k_b: 0,
      p_k_c: 0,
      s_k_a: 0,
      s_k_b: 0,
      s_k_c: 0,
      s_k_d: 0,
      p_v_b: 0,
      p_v_a: 0,
      s_v_b: 0,
      s_v_a: 0,
      s_v_c: 0,
      k_r: 0,
      k_v: 0,
      e_a: 0,
      e_b: 0,
      e_c: 0,
      edit: false,
    })
  }
  makeTimer = async () => {
    setInterval(() => {
      if (!this.state.edit) this.getData()
    }, 1000)
  }
  onSaved = async (e) => {
    // // // // console.log("Saving", e);
    let pomocniNiz = this.findGT(this.state.editGT.k9kcptks)
    let povrsina = this.findPovrsina(this.state.editGT.k9kcptks)
    try {
      putPovrsina(
        this.state.editGT.k9kcptid,
        this.state.editGT.k9kcptbd,
        pomocniNiz[0],
        pomocniNiz[1],
        pomocniNiz[2],
        pomocniNiz[3],
        pomocniNiz[4],
        pomocniNiz[5],
        povrsina,
        this.state.geom_tijelo.s,
        this.state.geom_tijelo.n
      )
    } catch (err) {
      // // // // console.log(err);
    }
    this.setState({ edit: false })
  }
  onRowUpdating = async (e) => {
    // // // // console.log(e.key, "first");
  }
  onRowRemoving = async (e) => {
    // // // // console.log(e.data);
    axios.delete(
      `https://api.gat-crm.com:1121/povrsina/${e.data.k9kcptks}/${e.data.k9kcptid}/${e.data.k9kcptbd}`
    )
    // .then((resp) => // // // // console.log(resp))
    //   .catch((err) => // // // // console.log(err));
  }

  erase(e) {
    this.findInputToSet0(this.state.geom_tijelo.s)
    // let pomocniNiz = this.findGT(this.state.geom_tijelo.s);
    //let povrsina = this.findPovrsina(this.state.geom_tijelo.s);
    // // // // console.log("erase", this.p_p_k);
  }
  edit(e) {
    // // // // console.log("edit");
    // // // // console.log(this.props.povrsine);
  }
  findPovrsina(s) {
    const simboli = ['PK', 'ŠK', 'PV', 'ŠV', 'K', 'E']
    const povrsine = [
      this.p_p_k,
      this.p_s_k,
      this.p_p_v,
      this.p_s_v,
      this.p_k,
      this.p_e,
    ]
    let pozicija = simboli.indexOf(s)
    return povrsine[pozicija]
  }
  dodajPovrsinu = (i, s, n) => {
    // iznos povrsine, naziv geom tijela
    //// // // // console.log("TU sam");

    this.setState({
      visibleButtons: true,
      geom_tijelo: { s: s, n: n },
      validate: i > 0 ? true : false,
    })

    // // // // console.log("DODano", s, n);
  }
  getData = () => {
    axios
      .get(`https://api.gat-crm.com:1121/povrsine/${this.props.props.id}`)
      .then((resp) => {
        this.setState({ povrsine: resp.data })
        //this.props.povrsine = resp.data;
      })
  }
  initMessages() {
    loadMessages(service.getDictionary())
  }
  componentDidMount = async () => {
    let pomocniNiz = ['PK', 'ŠK', 'PV', 'ŠV', 'K', 'E']
    let pomocniNiz2 = [0, 0, 0, 0, 0, 0]
    try {
      this.getData()
    } catch (err) {
      // // // // console.log(err);
    }
    if (this.props.povrsine) {
      this.props.povrsine.map((v, i) => {
        pomocniNiz2[pomocniNiz.indexOf(v.k9kcptks)] += 1
      })
      this.setState({
        br_PK: pomocniNiz2[0],
        br_SK: pomocniNiz2[1],
        br_PV: pomocniNiz2[2],
        br_SV: pomocniNiz2[3],
        br_K: pomocniNiz2[4],
        br_E: pomocniNiz2[5],
      })
      // // // // console.log("A", pomocniNiz2);
    }
  }
  componentDidUpdate = () => {}
  render() {
    let {
      p_k_a,
      p_k_b,
      p_k_c,
      s_k_a,
      s_k_b,
      s_k_c,
      s_k_d,
      p_v_b,
      p_v_a,
      s_v_b,
      s_v_a,
      s_v_c,
      k_r,
      k_v,
      e_a,
      e_b,
      e_c,
    } = this.state
    const { prevod, oz_j } = this.props
    if (this.state.isLoading) return <Loading />
    return (
      <div id="modal">
        <h1>
          {' '}
          {prevod &&
          prevod.length !== 0 &&
          prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              90
          ]
            ? prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  90
              ].item2
            : 'Kalkulator površine'}
        </h1>

        <div id="modal-povrsina">
          <div id="tabela-povrsina">
            <div id="tabela-povrsina-1"></div>
            <div id="tabela-povrsina-2">
              <DataGrid
                //className="modalTabela"
                confirmDeleteMessage="Izbriši"
                allowColumnResizing={true}
                columnResizingMode={'widget'}
                //columnMinWidth={60}
                //columnAutoWidth={true}
                id="gridContainer"
                dataSource={this.state.povrsine} //this.props.povrsine}
                showBorders={true}
                allowColumnReordering={true}
                onRowRemoving={this.onRowRemoving}
                onRowUpdating={this.onRowUpdating}
                onRowUpdated={this.onRowUpdated}
                //selectedRowKeys={this.state.selectedItemKeys}
                //onSelectionChanged={this.selectionChanged}
                onEditingStart={this.onEditingStart}
                onEditCanceled={this.onEditCanceled}
                onSaved={this.onSaved}
                onSaving={this.onSaving}
                noDataText={
                  prevod &&
                  prevod.length !== 0 &&
                  prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      158
                  ]
                    ? prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          158
                      ].item2
                    : 'Nema izabranih geometrijskih tijela'
                }
                width={360}
                //allowColumnReordering="true"
              >
                {' '}
                <Editing
                  mode="row"
                  useIcons={true}
                  allowDeleting={true}
                  allowUpdating={true}
                />
                <Column
                  dataField="k9kcptksn"
                  caption={
                    prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        91
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            91
                        ].item2
                      : 'Tijelo'
                  }
                  allowEditing={false}
                />
                <Column
                  dataField="k9kcptp"
                  caption={
                    prevod &&
                    prevod.length !== 0 &&
                    prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        155
                    ]
                      ? prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            155
                        ].item2 + '(dm2)'
                      : 'Površina(dm2)'
                  }
                  allowEditing={false}
                  width={120}
                  alignment="center"
                />
                <Column type="buttons">
                  <Button name="delete" />
                  <Button name="update" />
                </Column>
              </DataGrid>
            </div>
          </div>
          <div className="popup-item">
            <div id="modal-left-container">
              <div id="modal-left-container-inputs">
                {/*<SelectBox
                  items={this.geom_tijela}
                  value={this.state.g_t}
                  onValueChanged={this.onGTValueChanged}
                  placeholder="Izaberi ..."
                />*/}
                <br />
                {
                  //<PoljaZaPovrsinu props={this.state.geom_tijelo} />
                }

                {this.state.geom_tijelo.n === 'Puni kvadar' ? (
                  <div>
                    <h3>
                      {' '}
                      {prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          84
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              84
                          ].item2 + '(mm)'
                        : 'Puni kvadar' + '(mm)'}
                    </h3>
                    <div className="povrsina">
                      <div>
                        <label>a</label>
                        <NumberBox
                          name="a"
                          value={p_k_a}
                          onValueChanged={this.on_p_k_a_value_changed}
                        />
                      </div>
                      <div>
                        <label>b</label>
                        <NumberBox
                          name="b"
                          value={p_k_b}
                          onValueChanged={this.on_p_k_b_value_changed}
                        />
                      </div>
                      <div>
                        <label>c</label>
                        <NumberBox
                          name="c"
                          value={p_k_c}
                          onValueChanged={this.on_p_k_c_value_changed}
                        />
                      </div>
                    </div>
                  </div>
                ) : this.state.geom_tijelo.n === 'Šuplji kvadar' ? (
                  <div>
                    <h3>
                      {prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          85
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              85
                          ].item2 + '(mm)'
                        : 'Šuplji kvadar' + '(mm)'}
                    </h3>
                    <div className="povrsina">
                      <div>
                        <label>a</label>
                        <NumberBox
                          name="s_k_a"
                          value={s_k_a}
                          onValueChanged={this.on_s_k_a_value_changed}
                        />
                      </div>
                      <div>
                        <label>b</label>
                        <NumberBox
                          name="s_k_b"
                          value={s_k_b}
                          onValueChanged={this.on_s_k_b_value_changed}
                        />{' '}
                      </div>
                      <div>
                        <label>c</label>
                        <NumberBox
                          name="s_k_c"
                          value={s_k_c}
                          onValueChanged={this.on_s_k_c_value_changed}
                        />
                      </div>
                      <div>
                        <label>d</label>
                        <NumberBox
                          name="s_k_d"
                          value={s_k_d}
                          onValueChanged={this.on_s_k_d_value_changed}
                        />
                      </div>
                    </div>
                  </div>
                ) : this.state.geom_tijelo.n === 'Puni valjak' ? (
                  <div>
                    <h3>
                      {prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          86
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              86
                          ].item2 + '(mm)'
                        : 'Puni valjak' + '(mm)'}
                    </h3>
                    <div className="povrsina">
                      <div>
                        {' '}
                        <div>
                          <label>a</label>
                          <NumberBox
                            name="p_v_a"
                            value={p_v_a}
                            onValueChanged={this.on_p_v_a_value_changed}
                          />
                        </div>
                        <label>b</label>
                        <NumberBox
                          name="p_v_b"
                          value={p_v_b}
                          onValueChanged={this.on_p_v_b_value_changed}
                        />
                      </div>
                    </div>
                  </div>
                ) : this.state.geom_tijelo.n === 'Šuplji valjak' ? (
                  <div>
                    {' '}
                    <h3>
                      {prevod &&
                      prevod.length !== 0 &&
                      prevod[
                        oz_j.indexOf(localStorage.getItem('lng')) *
                          (prevod.length / oz_j.length) +
                          87
                      ]
                        ? prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              87
                          ].item2 + '(mm)'
                        : 'Šuplji valjak' + '(mm)'}
                    </h3>
                    <div className="povrsina">
                      <div>
                        {' '}
                        <div>
                          <label>a</label>
                          <NumberBox
                            name="s_v_a"
                            value={s_v_a}
                            onValueChanged={this.on_s_v_a_value_changed}
                          />
                        </div>
                        <label>b</label>
                        <NumberBox
                          name="s_v_b"
                          value={s_v_b}
                          onValueChanged={this.on_s_v_b_value_changed}
                        />
                      </div>

                      <div>
                        <label>c</label>
                        <NumberBox
                          name="s_v_c"
                          value={s_v_c}
                          onValueChanged={this.on_s_v_c_value_changed}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  /*this.state.geom_tijelo.n === "Kupa" ? (
                  <div className="povrsina">
                    <div>
                      <label>r</label>
                      <NumberBox
                        name="k_r"
                        value={k_r}
                        onValueChanged={this.on_k_r_value_changed}
                      />
                    </div>
                    <div>
                      <label>v</label>
                      <NumberBox
                        name="k_v"
                        value={k_v}
                        onValueChanged={this.on_k_v_value_changed}
                      />
                    </div>
                  </div>
                ) : this.state.geom_tijelo.n === "Elipsoid" ? (
                  <div className="povrsina">
                    <div>
                      <label>a</label>
                      <NumberBox
                        name="e_a"
                        value={e_a}
                        onValueChanged={this.on_e_a_value_changed}
                      />
                    </div>
                    <div>
                      <label>b</label>
                      <NumberBox
                        name="e_b"
                        value={e_b}
                        onValueChanged={this.on_e_b_value_changed}
                      />
                    </div>
                    <div>
                      <label>c</label>
                      <NumberBox
                        name="e_c"
                        value={e_c}
                        onValueChanged={this.on_e_c_value_changed}
                      />
                    </div>
                </div>
              :*/
                  ''
                )}
              </div>
              <br />
              <div
                id="float-buttons"
                style={{
                  visibility: this.state.visibleButtons ? 'visible' : 'hidden',
                }}
              >
                <Button
                  icon={add}
                  type="default"
                  style={{ borderRadius: '50%' }}
                  value={this.state.geom_tijelo.n}
                  onClick={this.add}
                  disabled={!this.state.validate}
                />
                <Button
                  icon={erase}
                  type="default"
                  style={{ borderRadius: '50%' }}
                  value={this.state.geom_tijelo.n}
                  onClick={this.erase}
                  disabled={!this.state.validate}
                />{' '}
                {/* <Button
                  icon="edit"
                  type="default"
                  style={{ borderRadius: "50%" }}
                  value={this.state.geom_tijelo.n}
                  onClick={this.edit}
              />*/}
              </div>{' '}
              <br />
              <div className="g_tijela">
                <div className="geom-tijelo">
                  <div
                    onClick={() => {
                      this.onGTValueChanged({ value: 'Puni kvadar' })
                      this.setState({
                        geom_tijelo: { s: 'PK', n: 'Puni kvadar' },
                      })
                    }}
                  >
                    <div>
                      <ModalImage
                        src={localStorage.getItem('d_m') ? kvadar_d : kvadar}
                      />
                    </div>
                    <div className="povrsina">
                      <div>
                        <div className="dx-field-label naziv-geom-tijela">
                          {prevod &&
                          prevod.length !== 0 &&
                          prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              84
                          ]
                            ? prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  84
                              ].item2
                            : 'Puni kvadar'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="geom-tijelo">
                  <div
                    onClick={() => {
                      this.onGTValueChanged({ value: 'Šuplji kvadar' })
                      this.setState({
                        geom_tijelo: { s: 'ŠK', n: 'Šuplji kvadar' },
                      })
                    }}
                  >
                    <div>
                      {' '}
                      <ModalImage
                        src={
                          localStorage.getItem('d_m')
                            ? suplji_kvadar_d
                            : suplji_kvadar
                        }
                      />
                    </div>
                    <div className="povrsina">
                      <div>
                        <div className="dx-field-label naziv-geom-tijela">
                          {prevod &&
                          prevod.length !== 0 &&
                          prevod[
                            oz_j.indexOf(localStorage.getItem('lng')) *
                              (prevod.length / oz_j.length) +
                              85
                          ]
                            ? prevod[
                                oz_j.indexOf(localStorage.getItem('lng')) *
                                  (prevod.length / oz_j.length) +
                                  85
                              ].item2
                            : 'Šuplji kvadar'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="g_tijela">
              {' '}
              <div className="geom-tijelo">
                <div
                  onClick={() => {
                    this.onGTValueChanged({ value: 'Puni valjak' })
                    this.setState({
                      geom_tijelo: { s: 'PV', n: 'Puni valjak' },
                    })
                  }}
                >
                  <div>
                    {' '}
                    <ModalImage
                      src={localStorage.getItem('d_m') ? valjak_d : valjak}
                    />
                  </div>
                  <div className="povrsina">
                    <div>
                      <div className="dx-field-label naziv-geom-tijela">
                        {prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            86
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                86
                            ].item2
                          : 'Puni valjak'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="geom-tijelo">
                <div
                  onClick={() => {
                    this.onGTValueChanged({ value: 'Šuplji valjak' })
                    this.setState({
                      geom_tijelo: { s: 'ŠV', n: 'Šuplji valjak' },
                    })
                  }}
                >
                  <div>
                    <ModalImage
                      src={
                        localStorage.getItem('d_m')
                          ? suplji_valjak_d
                          : suplji_valjak
                      }
                    />
                  </div>
                  <div className="povrsina">
                    <div>
                      <div className="dx-field-label naziv-geom-tijela">
                        {prevod &&
                        prevod.length !== 0 &&
                        prevod[
                          oz_j.indexOf(localStorage.getItem('lng')) *
                            (prevod.length / oz_j.length) +
                            87
                        ]
                          ? prevod[
                              oz_j.indexOf(localStorage.getItem('lng')) *
                                (prevod.length / oz_j.length) +
                                87
                            ].item2
                          : 'Šuplji valjak'}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="geom-tijelo">
                  <div>
                    <div>
                      <img src={kupa} height="50px" width="50px" alt="kupa" />
                    </div>
                    <div className="povrsina">
                      <div>
                        <div className="dx-field-label naziv-geom-tijela">
                          {prevod &&
                          prevod.length !== 0 &&
                          prevod[
                            oz_j.indexOf(localStorage.getItem("lng")) *
                              (prevod.length / oz_j.length) +
                              88
                          ]
                            ? prevod[
                                oz_j.indexOf(localStorage.getItem("lng")) *
                                  (prevod.length / oz_j.length) +
                                  88
                              ].item2
                            : "Kupa / stožac"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br /> <br />
                <div className="geom-tijelo">
                  <div>
                    <div>
                      <img
                        src={elipsoid}
                        height="50px"
                        width="50px"
                        alt="elipsoid"
                      />
                    </div>
                    <div className="povrsina">
                      <div>
                        <div className="dx-field-label naziv-geom-tijela">
                          {prevod &&
                          prevod.length !== 0 &&
                          prevod[
                            oz_j.indexOf(localStorage.getItem("lng")) *
                              (prevod.length / oz_j.length) +
                              89
                          ]
                            ? prevod[
                                oz_j.indexOf(localStorage.getItem("lng")) *
                                  (prevod.length / oz_j.length) +
                                  89
                              ].item2
                            : "Elipsoid"}
                            </div>
                      </div>
                    </div>
                  </div>
                </div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    povrsine: state.povrsine,
    prevod: state.prevod,
    jezik: state.jezik,
    oz_j: state.oz_j,
  }
}

export default connect(mapStateToProps, null)(Modal)
