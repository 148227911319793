import { REQUEST_NOT_ALLOWED_USERS} from '../../const';


export default function UserReducer(state = [], action) {
  switch (action.type) {
      case REQUEST_NOT_ALLOWED_USERS:
      return action.notallowed;
    default:
      return state;
  }
}