import axios from "axios";
import { B_A, B_B, REQUEST_B_A, REQUEST_B_B } from "../../const";

export const fetchBAData = (boja_a) => {
  return {
    type: REQUEST_B_A,
    boja_a,
  };
};

export const fetchBojaAData = () => {
  return (dispatch) => {
    return axios
      .get(B_A)
      .then((response) => {
        dispatch(fetchBAData(response.data));
        //// // // // console.log("TU SAM" ,response.data)
      })
      .catch((error) => {
        //// // // // console.log("err")
        throw error;
      });
  };
};

export const fetchBBData = (boja_b) => {
  return {
    type: REQUEST_B_B,
    boja_b,
  };
};

export const fetchBojaBData = () => {
  return (dispatch) => {
    return axios
      .get(B_B)
      .then((response) => {
        dispatch(fetchBBData(response.data));
        //// // // // console.log("TU SAM" ,response.data)
      })
      .catch((error) => {
        //// // // // console.log("err")
        throw error;
      });
  };
};
