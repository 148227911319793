import React from "react";
export default function ConditionalIcon({ value }) {
  if (value) {
    return (
      <img
        src={`../../../Images/${value.slika}`}
        className="custom-icon"
        alt="conditional_icon"
      />
    );
  } else {
    return <div className="dx-dropdowneditor-icon"></div>;
  }
}
