import { REQUEST_S_C, S_C } from "../../const";
import axios from "axios";

export const fetchSData = (s_c) => {
  return {
    type: REQUEST_S_C,
    s_c,
  };
};

export const fetchSCData = () => {
  return (dispatch) => {
    return axios
      .get(S_C)
      .then((response) => {
        dispatch(fetchSData(response.data));
        //// // // // console.log(response.data)
      })
      .catch((error) => {
        //// // // // console.log("err")
        throw error;
      });
  };
};
