import React, { useState } from 'react'
import { Tooltip } from 'devextreme-react/tooltip'
import NPOpis from '../Opisi/NPOpis'
export default function TextItemDK(data) {
  return (
    <div>
      <TextItemToolTip props={data} />
    </div>
  )
}

class TextItemToolTip extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }

    this.toggleSDefault = this.toggleSDefault.bind(this)
    this.toggleDDefault = this.toggleDDefault.bind(this)
  }

  toggleSDefault(e) {
    localStorage.setItem('dk', this.props.props)
  }
  toggleDDefault(e) {
    if (localStorage.getItem('dk')) localStorage.setItem('dk', 'x')
  }

  render() {
    return (
      <div
        style={{ width: '100%' }}
        id={'product' + this.props.props}
        onMouseEnter={this.toggleSDefault}
        onMouseLeave={this.toggleDDefault}
      >
        <p>{this.props.props}</p>
      </div>
    )
  }
}
