import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { OZNAKE_JEZIKA, PREVOD } from '../../../const'

export default function TextItemNpo(data) {
  return (
    <div>
      <TextItemToolTip props={data} />
    </div>
  )
}
function NPZFieldF({ data }) {
  const [prevod, setPrevod] = useState([])
  const [oz_j, setOzJ] = useState([])

  useEffect(() => {
    axios.get(PREVOD).then((resp) => setPrevod(resp.data))
    axios.get(OZNAKE_JEZIKA).then((resp) => setOzJ(resp.data))
  }, [])
  return data && data == 'Standardno eloksiranje - S' ? (
    <p id={'product' + data}>
      {prevod &&
      prevod.length !== 0 &&
      prevod[
        oz_j.indexOf(localStorage.getItem('lng')) *
          (prevod.length / oz_j.length) +
          70
      ]
        ? prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              70
          ].item2
        : 'Standardno eloksiranje - S'}
    </p>
  ) : data && data == 'Tvrdo eloksiranje - XH4' ? (
    <p id={'product' + data}>
      {prevod &&
      prevod.length !== 0 &&
      prevod[
        oz_j.indexOf(localStorage.getItem('lng')) *
          (prevod.length / oz_j.length) +
          71
      ]
        ? prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              71
          ].item2
        : 'Tvrdo eloksiranje - XH4'}
    </p>
  ) : (
    <p id={'product' + data}>
      {' '}
      {prevod &&
      prevod.length !== 0 &&
      prevod[
        oz_j.indexOf(localStorage.getItem('lng')) *
          (prevod.length / oz_j.length) +
          72
      ]
        ? prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              72
          ].item2
        : 'Kromatiranje Surtec 650 - C'}
    </p>
  )
}
class TextItemToolTip extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }

    this.toggleSDefault = this.toggleSDefault.bind(this)
    this.toggleDDefault = this.toggleDDefault.bind(this)
  }

  toggleSDefault(e) {
    console.log(this.props.props)
    localStorage.setItem('npz', this.props.props)
  }
  toggleDDefault(e) {
    if (localStorage.getItem('npz')) localStorage.setItem('npz', 'x')
  }
  render() {
    return (
      <div
        style={{ width: '100%', height: '100%' }}
        onMouseEnter={this.toggleSDefault}
        onMouseLeave={this.toggleDDefault}
      >
        <NPZFieldF data={this.props.props} />
      </div>
    )
  }
}
