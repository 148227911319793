import React from "react";

const gmail = "testni425@gmail.com"; // testni mail, testna komponenta
const component = { b: "SI" };

export default class EmailButton extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    window.location.href = `mailto:${gmail}?body=${JSON.stringify(component)}`;
  }
  render() {
    return (
      <button className="k-button" onClick={this.onClick}>
        EmailButton
      </button>
    );
  }
}
// pomoćna komponenta za slanje emaila ( ne treba )
