import React from 'react'

export default function PrikazCrteza(props) {
  return (
    <div>
      {props.props.ext == undefined ? (
        ''
      ) : props.props.ext == 'pdf' ? (
        <div style={{ height: '80vh' }}>
          <object
            style={{ width: '100%', height: '100%' }}
            type="application/pdf"
            data={'data:application/pdf;base64,' + props.props.src}
          ></object>
        </div>
      ) : (
        <img src={'data:image/jpeg;base64,' + props.props.src} />
      )}
    </div>
  )
}
