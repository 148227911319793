import React from 'react'
import { connect } from 'react-redux'
import DataGrid, {
  Column,
  Editing,
  SearchPanel,
  Selection,
} from 'devextreme-react/data-grid'
import { Button } from 'devextreme-react/button'
import axios from 'axios'

class OdobrenjeKorisnika extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedItemKeys: [],
      notallowed: [],
      edit: false,
    }
    this.mounted = false
    this.selectionChanged = this.selectionChanged.bind(this)
    this.odobri = this.odobri.bind(this)
    this.onRowRemoving = this.onRowRemoving.bind(this)
    this.makeTimer = setInterval(() => {
      if (!this.state.edit) this.getData()
    }, 1000)
  }

  getData = () => {
    axios.get('https://api.gat-crm.com:1121/notallowed').then((resp) => {
      this.setState({ notallowed: resp.data })

      //this.props.povrsine = resp.data;
    })
  }
  onRowRemoving = async (e) => {
    const { prevod, oz_j } = this.props
    this.setState({ edit: true })
    axios
      .delete('https://api.gat-crm.com:1121/users/' + e.key, { id: e.key })
      .then(
        () => {
          prevod &&
          prevod.length !== 0 &&
          prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              74
          ]
            ? alert(
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    74
                ].item2
              )
            : alert('Nije odobren korisnik!')

          this.setState({ edit: false })
        } /* window.location.reload(false)*/
      )
      .catch((err) => {
        // // // // console.log(err);
        this.setState({ edit: false })
      })
  }

  componentDidMount = async () => {
    this.getData()
  }

  componentWillMount() {
    this.mounted = true
  }
  componentWillUnmount() {
    this.mounted = false
    clearInterval(this.makeTimer)
  }
  render() {
    const { prevod, oz_j } = this.props
    return (
      <div id="data-grid-demo">
        <br /> <br />
        <h3>
          {prevod &&
          prevod.length !== 0 &&
          prevod[
            oz_j.indexOf(localStorage.getItem('lng')) *
              (prevod.length / oz_j.length) +
              222
          ]
            ? prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  222
              ].item2
            : 'Neodobreni korisnici'}
        </h3>
        <Button
          id="gridDeleteSelected"
          text={
            prevod &&
            prevod.length !== 0 &&
            prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                39
            ]
              ? prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    39
                ].item2
              : 'Odobri izabrane korisnike'
          }
          type="default"
          height={34}
          disabled={!this.state.selectedItemKeys.length}
          onClick={this.odobri}
        />
        <div className="adminPanelGrid">
          <DataGrid
            id="gridContainer"
            dataSource={this.state.notallowed}
            keyExpr="id"
            showBorders={true}
            // onEditorPreparing={this.onEditorPreparing}
            onRowRemoving={this.onRowRemoving}
            selectedRowKeys={this.state.selectedItemKeys}
            onSelectionChanged={this.selectionChanged}
            noDataText={
              prevod &&
              prevod.length !== 0 &&
              prevod[
                oz_j.indexOf(localStorage.getItem('lng')) *
                  (prevod.length / oz_j.length) +
                  40
              ]
                ? prevod[
                    oz_j.indexOf(localStorage.getItem('lng')) *
                      (prevod.length / oz_j.length) +
                      40
                  ].item2
                : 'Trenutno nema korisnika kojima se treba odobriti registracija'
            }
          >
            <SearchPanel
              visible={true}
              placeholder={
                prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    41
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        41
                    ].item2
                  : 'Pretraži...'
              }
            />

            <Selection
              mode="multiple"
              visible="true"
              showCheckBoxesMode="always"
            />
            <Editing mode="row" useIcons={true} allowDeleting={true} />

            <Column
              dataField="companyName"
              caption={
                prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    42
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        42
                    ].item2
                  : 'Firma'
              }
              allowEditing={false}
            />
            <Column
              dataField="firstName"
              caption={
                prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    44
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        44
                    ].item2
                  : 'Ime'
              }
              allowEditing={false}
            />
            <Column
              dataField="lastName"
              caption={
                prevod &&
                prevod.length !== 0 &&
                prevod[
                  oz_j.indexOf(localStorage.getItem('lng')) *
                    (prevod.length / oz_j.length) +
                    43
                ]
                  ? prevod[
                      oz_j.indexOf(localStorage.getItem('lng')) *
                        (prevod.length / oz_j.length) +
                        43
                    ].item2
                  : 'Prezime'
              }
              allowEditing={false}
            />
            <Column dataField="email" caption="Email" allowEditing={false} />
            <Column type="buttons" width={110}>
              <Button name="delete" />
            </Column>
          </DataGrid>
        </div>
      </div>
    )
  }

  updateUsers = () => {
    this.state.selectedItemKeys.forEach((key) => {
      // console.log(key);
      axios
        .put('https://api.gat-crm.com:1121/users/' + key, {
          isAllowed: true,
        })
        .then(
          (resp) => axios.get('https://api.gat-crm.com:1121/mailOdobren/' + key)
          //.then((resp) => // console.log(resp))
          //.catch((err) =>  console.log(err))
        )
    })
    this.setState({ edit: false })
  }
  odobri = async () => {
    const { prevod, oz_j } = this.props
    this.setState({ edit: true })
    try {
      this.updateUsers()
      prevod &&
      prevod.length !== 0 &&
      prevod[
        oz_j.indexOf(localStorage.getItem('lng')) *
          (prevod.length / oz_j.length) +
          45
      ]
        ? alert(
            prevod[
              oz_j.indexOf(localStorage.getItem('lng')) *
                (prevod.length / oz_j.length) +
                45
            ].item2
          )
        : alert('Odobreni korisnici')
      this.props.parentCallback(true)

      // // // // console.log("edit", this.state.edit, this.state.notallowed);
    } catch (err) {
      // // // // console.log(err);
      this.setState({ edit: false })
    }
    this.setState({
      selectedItemKeys: [],
    })
    //dataSource.reload();
  }
  selectionChanged(data) {
    // // // // console.log("selection changed");
    this.setState({
      selectedItemKeys: data.selectedRowKeys,
    })
    // // // // console.log(data.selectedRowKeys);
  }
}

const mapStateToProps = (state) => {
  return {
    prevod: state.prevod,
    jezik: state.jezik,
    oz_j: state.oz_j,
  }
}

export default connect(mapStateToProps, null)(OdobrenjeKorisnika)
